import httpClient from '../../utils/httpClient'
import Vue from 'vue'
import Promise from 'lodash/_Promise'
import moment from 'moment'

export const tree = {
  namespaced: true,

  state: {
    loadingStack: [],
    livingUnitsWithResidents: {
      children: []
    },
    dates: [],
    expandedLivingUnits: [],
    expandedResidents: [],
    collapsedOrderLivingUnits: [],
    collapsedOrderResidents: [],
    allExpandedLivingUnits: []
  },

  getters: {
    livingUnitsWithResidents: (state) => state.livingUnitsWithResidents,

    dates: (state) => state.dates,

    /**
     *
     * @param state
     * @returns {function(*): *|*[]}
     */
    data: (state) => (dateString) => {
      const date = state.dates.find(d => d.dateString === dateString)

      return date ? date.data : []
    },

    dataTree: (state) => (dateString) => {
      const date = state.dates.find(d => d.dateString === dateString)

      const data = date ? date.data : []

      // merge living units with residents data
      const dataTree = []

      state.livingUnitsWithResidents.children.forEach(livingUnit => {
        var livingUnitData = Object.assign({}, livingUnit)
        livingUnitData.children = data.filter(d => d.wohneinheiten_id === livingUnitData.internalID)

        dataTree.push(livingUnitData)
      })

      return dataTree
    },

    tree: (state) => (dateString) => {
      const date = state.dates.find(d => d.dateString === dateString)

      return date ? date.tree : null
    },

    /**
     *
     * @param state
     * @returns {(function(*, *): ([]|*))|*}
     */
    livingUnitData: (state) => (dateString, id) => {
      const date = state.dates.find(d => d.dateString === dateString)

      if (!date || !date.tree)
        return []

      return date.tree.children.find(d => d.internalID === id)
    },

    /**
     *
     * @param state
     * @returns {(function(*, *): ([]|*))|*}
     */
    residentData: (state) => (dateString, id) => {
      const date = state.dates.find(d => d.dateString === dateString)

      if (!date || !date.data)
        return null

      return date.data.find(d => d.internalID === id) ?? null
    },

    /**
     *
     * @param state
     * @returns {(function(*, *): ([]|*))|*}
     */
    livingUnitResidentData: (state) => (dateString, id) => {
      const date = state.dates.find(d => d.dateString === dateString)

      if (!date)
        return []

      return date.data.filter(d => d.wohneinheiten_id === id)
    },

    /**
     *
     * @param state
     * @returns {(function(*): (null|null|(function(): *)|*))|*}
     */
    consumptionData: (state) => (dateString) => {
      const date = state.dates.find(d => d.dateString === dateString)

      if (!date || !date.consumption)
        return null

      return date.consumption
    },

    /**
     *
     * @param state
     * @returns {(function(*): (null|*))|*}
     */
    effectiveConsumptionData: (state) => (dateString) => {
      const date = state.dates.find(d => d.dateString === dateString)

      if (!date || !date.effectiveConsumption)
        return null

      return date.effectiveConsumption
    },

    expandedLivingUnits: (state) => state.expandedLivingUnits,
    expandedResidents: (state) => state.expandedResidents,
    collapsedOrderLivingUnits: (state) => state.collapsedOrderLivingUnits,
    collapsedOrderResidents: (state) => state.collapsedOrderResidents,
  },

  actions: {
    /**
     * @param commit
     */
    fetchLivingUnits ({ commit }, dateString) {
      httpClient.get(process.env.VUE_APP_API_SHARED + 'get/living-units-with-residents/' + dateString)
        .then(response => {
          commit('setLivingUnitsWithResidents', response.tree)
        })
    },

    /**
     * @todo
     */
    fetchResidentTree ({ commit }, { residentId, dateString }) {

    },

    /**
     *
     * @param commit
     * @param livingUnitId
     * @param dateString
     */
    fetchLivingUnitTree ({ commit }, { livingUnitId, dateString }) {
      httpClient.get(process.env.VUE_APP_API_SHARED + `tree/living-unit/${livingUnitId}/${dateString}`)
        .then(response => {
          commit('setLivingUnitData', {
            dateString: dateString,
            livingUnitTree: response
          })
        })
    },

    /**
     *
     * @param commit
     * @param livingUnitId
     * @param dateString
     */
    fetchLivingUnitData ({ commit }, { livingUnitId, dateString }) {
      httpClient.get(process.env.VUE_APP_API_SHARED + `tree/living-unit/${livingUnitId}/${dateString}/data`)
        .then(response => {
          commit('replaceLivingUnitData', {
            dateString: dateString,
            livingUnitTree: response
          })
        })
    },

    /**
     *
     * @param commit
     * @param dateString
     */
    fetchWholeTree ({ commit }, dateString) {
      httpClient.get(process.env.VUE_APP_API_SHARED + `tree/full/${dateString}`)
        .then(response => {
          commit('setTreeData', {
            dateString: dateString,
            tree: response
          })
        })
    },

    fetchWholeTreeWithConsumption ({ state, commit }, dateString) {
      const loadingStackKey = 'fetchWholeTreeWithConsumption-' + dateString

      if (state.loadingStack.includes(loadingStackKey)) {
        return
      }

      state.loadingStack.push(loadingStackKey)

      httpClient.get(process.env.VUE_APP_API_SHARED + `tree/full-consumption/${dateString}?incl-living-units`)
        .then(response => {
          commit('setTreeData', {
            dateString: dateString,
            tree: response
          })
        })
        .finally(() => {
          state.loadingStack = state.loadingStack.filter(i => i !== loadingStackKey)
        })
    },

    fetchEffectiveConsumption ({ state, commit }, dateString) {
      const loadingStackKey = 'fetchEffectiveConsumption-' + dateString

      if (state.loadingStack.includes(loadingStackKey)) {
        return
      }

      state.loadingStack.push(loadingStackKey)

      httpClient.get(process.env.VUE_APP_API_SHARED + `tree/effective-consumption/${dateString}`)
        .then(response => {
          commit('setEffectiveConsumption', {
            dateString: dateString,
            effectiveConsumptionData: response
          })
        })
        .finally(() => {
          state.loadingStack = state.loadingStack.filter(i => i !== loadingStackKey)
        })
    },

    /**
     *
     * @param commit
     * @param prodId
     * @param bewohnerID
     * @param dateString
     * @returns {Promise<unknown>}
     */
    removeArticle ({ commit, state }, { prodId, bewohnerID, dateString }) {
      return new Promise((resolve, reject) => {
        httpClient.post(process.env.VUE_APP_API_CHECKLIST + 'remove/product', {
          monthYear: dateString,
          prodID: prodId,
          bewohnerID: bewohnerID,
        })
          .then(response => {
            if (response.type === 'success') {
              // reset all dates as this may have influence on other dates
              commit('resetDates', dateString)

              commit('setResidentData', {
                dateString: dateString,
                residentTree: response.tree
              })
            }

            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /**
     *
     * @param commit
     * @param prodId
     * @param internalID
     * @param targetType
     * @param dateString
     * @returns {Promise<unknown>}
     */
    assignArticle ({ commit }, { prodId, internalID, targetType, dateString }) {
      return new Promise((resolve, reject) => {
        httpClient.post(process.env.VUE_APP_API_CHECKLIST + 'assign/product', {
          monthYear: dateString,
          prodID: prodId,
          internalID: internalID,
          targetType: targetType
        })
          .then(response => {
            if (response.type === 'success') {
              // reset all dates as this may have influence on other dates
              commit('resetDates', dateString)

              if (targetType === 'bewohner' || targetType === 'resident') {
                commit('setResidentData', {
                  dateString: dateString,
                  residentTree: response.tree
                })
              } else if (targetType === 'wohneinheit') {
                commit('setLivingUnitData', {
                  dateString: dateString,
                  livingUnitTree: response.tree
                })
              } else if (targetType === 'kunde') {
                commit('setTreeData', {
                  dateString: dateString,
                  tree: response.tree
                })
              }
            }

            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })

    },

    /**
     *
     * @param commit
     * @param prodId
     * @param bewohnerID
     * @param targetType
     * @param dateString
     * @returns {*}
     */
    detachArticle ({ commit }, { prodId, bewohnerID, dateString }) {
      return new Promise((resolve, reject) => {
        httpClient.post(process.env.VUE_APP_API_CHECKLIST + 'remove/product', {
          monthYear: dateString,
          prodID: prodId,
          bewohnerID: bewohnerID
        })
          .then(response => {
            if (response.type === 'success') {
              // reset all dates as this may have influence on other dates
              commit('resetDates', dateString)

              commit('setResidentData', {
                dateString: dateString,
                residentTree: response.tree
              })

            }
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    /**
     *
     * @param commit
     * @param dateString
     * @param entityId
     * @param article
     * @param type
     * @returns {*}
     */
    updateInterval ({ commit }, { dateString, entityId, article, type }) {
      return new Promise((resolve, reject) => {
        type = type === undefined ? 'resident' : type

        httpClient.put(process.env.VUE_APP_API_SHARED + `tree/interval`, {
          type: type,
          dateString: dateString,
          entityId: entityId,
          artnr: article.internalID,
          count: article.currPlandaten.count,
          interval: article.currPlandaten.intervall,
        })
          .then(response => {
            if (response.type === 'success') {
              // reset all dates as this may have influence on other dates
              commit('resetDates', dateString)
            }
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    }
    ,

    /**
     *
     * @param commit
     * @param artno
     * @param livingUnitId
     * @param date
     * @returns {*}
     */
    assignArticleToLivingUnit ({ commit }, { artno, livingUnitId, date }) {
      return new Promise((resolve, reject) => {
        httpClient.post(process.env.VUE_APP_API_SHARED + `tree/living-unit/${livingUnitId}/assign-product`, {
          date: date,
          livingUnitId: livingUnitId,
          artno: artno,
        })
          .then((response) => {
            commit('replaceLivingUnitData', {
              dateString: date,
              livingUnitTree: response
            })

            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    detachArticleFromLivingUnit ({ commit }, { artno, livingUnitId, date }) {
      return new Promise((resolve, reject) => {
        httpClient.post(process.env.VUE_APP_API_SHARED + `tree/living-unit/${livingUnitId}/detach-product`, {
          date: date,
          livingUnitId: livingUnitId,
          artno: artno,
        })
          .then((response) => {
            // @todo: does not remove article on the fly
            commit('replaceLivingUnitData', {
              dateString: date,
              livingUnitTree: response
            })

            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    closeAllExpandedResidents ({ commit }) {
      commit('resetExpandedResidents')
    },

    openAllExpandedOrderResidents ({ commit }) {
      commit('resetExpandedOrderResidents')
    },
  },

  mutations: {

    resetExpandedResidents (state) {
      state.expandedResidents = []
      state.expandedLivingUnits = []
    },

    resetExpandedOrderResidents (state) {
      state.collapsedOrderResidents = []
      state.collapsedOrderLivingUnits = []
    },

    /**
     *
     * @param state
     * @param livingUnitsWithResidents
     */
    setLivingUnitsWithResidents (state, livingUnitsWithResidents) {
      state.livingUnitsWithResidents = livingUnitsWithResidents
    },

    /**
     *
     * @param state
     * @param keepCurrent
     */
    resetDates (state, preserveCurrentDate = null) {
      // preserve current date if given
      if (preserveCurrentDate) {
        const datePos = state.dates.findIndex(date => date.dateString === preserveCurrentDate)

        if (datePos !== -1) {
          state.dates.forEach((date, index) => {
            if (index !== datePos) {
              Vue.delete(state.dates, index)
            }
          })
        }
      } else {
        Vue.set(state, 'dates', [])
      }
    },

    toggleExpandState (state, { type, id }) {
      if (type === 'living-unit') {
        const pos = state.expandedLivingUnits.indexOf(id)
        if (pos === -1) {
          state.expandedLivingUnits.push(id)
        } else {
          state.expandedLivingUnits.splice(pos, 1)
        }
      } else if (type === 'resident') {
        const pos = state.expandedResidents.indexOf(id)
        if (pos === -1) {
          state.expandedResidents.push(id)
        } else {
          state.expandedResidents.splice(pos, 1)
        }
      }
    },

    toggleOrderExpandState (state, { type, id }) {
      if (type === 'living-unit') {
        const pos = state.collapsedOrderLivingUnits.indexOf(id)
        if (pos === -1) {
          state.collapsedOrderLivingUnits.push(id)
        } else {
          state.collapsedOrderLivingUnits.splice(pos, 1)
        }
      } else if (type === 'resident') {
        const pos = state.collapsedOrderResidents.indexOf(id)
        if (pos === -1) {
          state.collapsedOrderResidents.push(id)
        } else {
          state.collapsedOrderResidents.splice(pos, 1)
        }
      }
    },

    setExpandedState (state, { type, id, expandedState }) {
      if (type === 'living-unit') {
        if (expandedState) {
          if (state.expandedLivingUnits.indexOf(id) === -1) {
            state.expandedLivingUnits.push(id)
          }
        } else {
          const pos = state.expandedLivingUnits.indexOf(id)
          if (pos !== -1) {
            state.expandedLivingUnits.splice(pos, 1)
          }
        }
      } else if (type === 'resident') {
        if (expandedState) {
          if (state.expandedResidents.indexOf(id) === -1) {
            state.expandedResidents.push(id)
          }
        } else {
          const pos = state.expandedResidents.indexOf(id)
          if (pos !== -1) {
            state.expandedResidents.splice(pos, 1)
          }
        }
      }
    },

    setOrderExpandedState (state, { type, id, expandedState }) {
      if (type === 'living-unit') {
        if (!expandedState) {
          if (state.collapsedOrderLivingUnits.indexOf(id) === -1) {
            state.collapsedOrderLivingUnits.push(id)
          }
        } else {
          const pos = state.collapsedOrderLivingUnits.indexOf(id)
          if (pos !== -1) {
            state.collapsedOrderLivingUnits.splice(pos, 1)
          }
        }
      } else if (type === 'resident') {
        if (!expandedState) {
          if (state.collapsedOrderResidents.indexOf(id) === -1) {
            state.collapsedOrderResidents.push(id)
          }
        } else {
          const pos = state.collapsedOrderResidents.indexOf(id)
          if (pos !== -1) {
            state.collapsedOrderResidents.splice(pos, 1)
          }
        }
      }
    },

    /**
     *
     * @param state
     * @param dateString
     * @param tree
     */
    setTreeData (state, { dateString, tree }) {
      const datePos = state.dates.findIndex(date => date.dateString === dateString)

      // if date is not initialised
      if (datePos === -1) {
        const treeData = {
          dateString: dateString,
          tree: tree,
          data: [],
          consumption: null,
          effectiveConsumption: null
        }

        if (tree.consumption) {
          treeData.consumption = tree.consumption
        }

        tree.children.forEach(livingUnitTree => {
          livingUnitTree.children.forEach(residentTree => {
            treeData.data.push(residentTree)
          })
        })

        Vue.set(state.dates, state.dates.length, treeData)
      }
      // if date is already initialised but no tree data is present
      else if (state.dates[datePos].tree === undefined || state.dates[datePos].tree.length === 0) {

        const dateData = state.dates[datePos]

        dateData.tree = tree

        if (tree.consumption) {
          dateData.consumption = Object.assign({}, tree.consumption)
        }

        tree.children.forEach(livingUnitTree => {
          livingUnitTree.children.forEach(residentTree => {
            dateData.data.push(residentTree)
          })
        })

        Vue.set(state.dates, datePos, dateData)
      }
      // update date data
      else {
        Vue.set(state.dates[datePos], 'tree', tree)

        if (tree.consumption) {
          Vue.set(state.dates[datePos], 'consumption', tree.consumption)
        }

        tree.children.forEach(residentTree => {
          const dataPos = state.dates[datePos].data.findIndex(r => r.internalID === residentTree.internalID)

          if (dataPos === -1) {
            Vue.set(state.dates[datePos].data, state.dates[datePos].data.length, residentTree)
          } else {
            Vue.set(state.dates[datePos].data, dataPos, residentTree)
          }
        })
      }
    },

    setEffectiveConsumption (state, { dateString, effectiveConsumptionData }) {
      const datePos = state.dates.findIndex(date => date.dateString === dateString)

      if (datePos === -1) {
        Vue.set(state.dates, state.dates.length, {
          dateString: dateString,
          tree: [],
          data: [],
          consumption: null,
          effectiveConsumption: effectiveConsumptionData
        })
      } else {
        Vue.set(state.dates[datePos], 'effectiveConsumption', effectiveConsumptionData)
      }
    },

    /**
     *
     * @param state
     * @param dateString
     * @param livingUnitTree
     */
    setLivingUnitData (state, { dateString, livingUnitTree }) {
      const datePos = state.dates.findIndex(d => d.dateString === dateString)

      if (datePos === -1) {
        Vue.set(state.dates, state.dates.length, {
          dateString: dateString,
          tree: [],
          data: livingUnitTree.children ?? [],
          consumption: null,
          effectiveConsumption: null
        })
      } else {
        livingUnitTree.children.forEach(residentTree => {
          const dataPos = state.dates[datePos].data.findIndex(r => r.internalID === residentTree.internalID)

          if (dataPos === -1) {
            state.dates[datePos].data.push(residentTree)
          } else {
            state.dates[datePos].data.splice(dataPos, 1, residentTree)
          }
        })
      }
    },

    replaceLivingUnitData (state, { dateString, livingUnitTree }) {
      const datePos = state.dates.findIndex(d => d.dateString === dateString)
      if (datePos !== -1) {
        const livingUnitPos = state.dates[datePos].tree.children.findIndex(l => l.internalID === +livingUnitTree.internalID)

        if (livingUnitPos !== -1) {
          if (livingUnitTree.data) {
            Vue.set(state.dates[datePos].tree.children[livingUnitPos], 'data', livingUnitTree.data)
          }
          if (livingUnitTree.children) {
            Vue.set(state.dates[datePos].tree.children[livingUnitPos], 'children', livingUnitTree.children)
          }
        }
      }
    },

    /**
     *
     * @param state
     * @param dateString
     * @param residentTree
     */
    setResidentData (state, { dateString, residentTree }) {
      if (!residentTree) {
        return
      }

      const datePos = state.dates.findIndex(date => date.dateString === dateString)

      if (datePos === -1) {
        state.dates.push({
          dateString: dateString,
          data: [residentTree]
        })
      } else {
        const dataPos = state.dates[datePos].data.findIndex(r => r.internalID === residentTree.internalID)

        if (dataPos === -1) {
          state.dates[datePos].data.push(residentTree)
        } else {
          state.dates[datePos].data.splice(dataPos, 1, residentTree)
        }

        if (state.dates[datePos].tree) {
          const livingUnitPos = state.dates[datePos].tree.children.findIndex(l => l.internalID === residentTree.wohneinheiten_id)

          if (livingUnitPos !== -1) {
            const residentPos = state.dates[datePos].tree.children[livingUnitPos].children.findIndex(r => r.internalID === residentTree.internalID)

            if (residentPos !== -1) {
              state.dates[datePos].tree.children[livingUnitPos].children.splice(residentPos, 1, residentTree)
            }
          }
        }
      }
    },

    adjustConsumption (state, { dateString, consumptionData }) {
      const datePos = state.dates.findIndex(date => date.dateString === dateString)

      if (datePos === -1 || !state.dates[datePos].consumption) {
        return
      }

      // get day of consumption
      const day = consumptionData.day !== undefined ? consumptionData.day : moment(consumptionData.datum).format('D')

      // generate key
      const key = consumptionData.bewohnerID + '-' + consumptionData.artnr + '-' + day

      if (!state.dates[datePos].consumption[key]) {
        if (consumptionData.count > 0) {
          Vue.set(state.dates[datePos].consumption, key, consumptionData)
        }
      } else {
        if (consumptionData.count === 'unset') {
          Vue.delete(state.dates[datePos].consumption, key)
        } else {
          Vue.set(state.dates[datePos].consumption, key, consumptionData)
        }
      }
    }
  },

  persist: false
}
