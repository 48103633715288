import {mapGetters} from 'vuex'

export const consumptionMixin = {
    methods: {

        calculateConsumptionTotal(consumption) {
            var total = 0

            Object.keys(consumption)
                .forEach(key => {
                    if (consumption[key].orderCount !== undefined) {
                        total += consumption[key].orderCount
                    } else if (consumption[key].timelineCount !== undefined) {
                        total += consumption[key].timelineCount
                    } else {
                        total += consumption[key].count
                    }
                })

            return total
        },

        residentArticleData(residentId, articleId) {
            if (!this.consumption) {
                return {}
            }

            const prefix = this.generatePrefix(residentId, articleId)

            return Object.keys(this.consumption)
                .filter(key => key.startsWith(prefix))
                .reduce((result, key) => {
                    const newKey = key.slice(prefix.length)
                    result[newKey] = this.consumption[key]
                    return result
                }, {})
        },

        /**
         *
         * @param residentId
         * @param articleId
         * @returns {{}}
         */
        residentArticlePlanData(residentId, articleId) {
            if (!this.consumption) {
                return {}
            }

            const prefix = this.generatePrefix(residentId, articleId)

            return Object.keys(this.consumption)
                .filter(key => key.startsWith(prefix) && this.consumption[key].planung === undefined)
                .reduce((result, key) => {
                    const newKey = key.slice(prefix.length)
                    result[newKey] = this.consumption[key]
                    return result
                }, {})
        },

        /**
         *
         * @param residentId
         * @param articleId
         * @returns {{}}
         */
        residentArticleConsumption(residentId, articleId) {
            if (!this.consumption) {
                return {}
            }

            var prefix = this.generatePrefix(residentId, articleId)

            return Object.keys(this.consumption)
                .filter(key => key.startsWith(prefix) && this.consumption[key].intervall !== undefined)
                .reduce((result, key) => {
                    const newKey = key.slice(prefix.length)
                    result[newKey] = this.consumption[key]
                    return result
                }, {})
        },


        residentEffectiveArticleConsumption(residentId, articleId) {
            if (!this.effectiveConsumption) {
                return {}
            }

            const prefix = this.generatePrefix(residentId, articleId)

            return Object.keys(this.effectiveConsumption)
                .filter(key => key.startsWith(prefix))
                .reduce((result, key) => {
                    const newKey = key.slice(prefix.length)
                    result[newKey] = this.effectiveConsumption[key]
                    return result
                }, {})
        },

        generatePrefix(residentId, articleId) {
            var prefix = residentId + '-' + articleId + '-'

            if (this.type === 'living-unit') {
                prefix = 'lu_' + prefix
            }

            return prefix
        }
    }
}
