<template>
  <Layout>
    model c
  </Layout>
</template>


<script>
import Layout from '@/components/SmartLogistics/Layout.vue'

export default {
  name: 'ModelC',
  components: { Layout },
}
</script>


<style scoped lang="scss">

</style>