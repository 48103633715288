<template>
  <v-form v-model="valid" ref="form">
    <div class="grid" v-if="bewohnerMedication">
      <div class="custom-box">
        <h4>Verordnung MiGe-Produkte</h4>

        <div class="specification-details">
          <div>
            <h4>Inkontinenzversorgung</h4>
            <v-radio-group
                v-model="editablePrescription.medication.inkograd"
                row
                :rules="[rules.required]"
            >
              <v-radio
                  v-for="product in config['migel-products'].inkograd.values"
                  :key="product.value"
                  :label="product.label"
                  :value="product.value"
                  :disabled="disabled"
              />
            </v-radio-group>

            <v-checkbox
                v-for="data in config['migel-products'].inkontinenzversorgung.values"
                :key="data.label"
                v-model="editablePrescription.medication['migel-products'].inkontinenzversorgung[data.label]"
                :label="data.label"
                :disabled="disabled"
                hide-details
            />
          </div>

          <div class="sub-sections">
            <div>
              <h4>Wundversorgung</h4>

              <v-checkbox
                  v-for="data in config['migel-products'].wundversorgung.values"
                  :key="data.label"
                  v-model="editablePrescription.medication['migel-products'].wundversorgung[data.label]"
                  :label="data.label"
                  :disabled="disabled"
                  hide-details
              />
            </div>

            <div>
              <h4>Stomaversorgung</h4>

              <v-checkbox
                  v-for="data in config['migel-products'].stomaversorgung.values"
                  :key="data.label"
                  v-model="editablePrescription.medication['migel-products'].stomaversorgung[data.label]"
                  :label="data.label"
                  :disabled="disabled"
                  hide-details
              />
            </div>

            <div>
              <h4>Tracheostoma</h4>

              <v-checkbox
                  v-for="data in config['migel-products'].tracheostoma.values"
                  :key="data.label"
                  v-model="editablePrescription.medication['migel-products'].tracheostoma[data.label]"
                  :label="data.label"
                  :disabled="disabled"
                  hide-details
              />
            </div>
          </div>

          <div class="sub-sections">
            <div>
              <h4>Inhalations- und Atemtherapie</h4>

              <v-checkbox
                  v-for="data in config['migel-products']['inhalations-atemtherapie'].values"
                  :key="data.label"
                  v-model="editablePrescription.medication['migel-products']['inhalations-atemtherapie'][data.label]"
                  :label="data.label"
                  :disabled="disabled"
                  hide-details
              />
            </div>

            <div>
              <h4>Verschiedenes Material</h4>

              <v-checkbox
                  v-for="data in config['migel-products']['verschiedenes-material'].values"
                  :key="data.label"
                  v-model="editablePrescription.medication['migel-products']['verschiedenes-material'][data.label]"
                  :label="data.label"
                  :disabled="disabled"
                  hide-details
              />
            </div>
          </div>
        </div>
      </div>

      <div class="custom-box">
        <div class="d-flex align-start relative" style="gap: 10px;">
          <h4>Produktdetails</h4>
          <v-tooltip
              bottom
              color="#F4EDE7"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  v-bind="attrs"
                  v-on="on"
              >
                mdi-information-outline
              </v-icon>
            </template>

            <div class="pa-4 product-tooltip">
              Hier werden die entsprechenden MiGe-Produkte angezeigt, die dem Bewohner im Versorgungsplan zugeteilt sind.
            </div>

          </v-tooltip>

        </div>

        <div class="article-list">
          <div class="article-list__item -header">
            <div>
              Art.Nr.
            </div>
            <div>
              Artikelname
            </div>
            <div>
              MiGeL-Nr.
            </div>
            <div>
              Pharmacode
            </div>
            <div class="-right">
              <v-checkbox
                  v-model="isAllArticlesSelected"
                  :disabled="disabled"
                  hide-details
              />
            </div>
          </div>
          <div
              v-for="article in filteredArticles"
              :key="article.ArtikelNrLAG"
              class="article-list__item"
              :class="{ disabled: !editablePrescription.medication['selected-products'].includes(article.ArtikelNrLAG) }"
          >
            <div>
              {{ article.ArtikelNrLAG }}
            </div>
            <div>
              {{ article.name }}
            </div>
            <div>
              {{ article.migel }}
            </div>
            <div>
              {{ article.pharmacode }}
            </div>
            <div class="-right">
              <v-checkbox
                  v-model="editablePrescription.medication['selected-products']"
                  :value="article.ArtikelNrLAG"
                  :disabled="disabled"
                  hide-details
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MedicalPrescriptionGeneratorMedication',
  emits: ['input'],

  props: {
    editablePrescription: {
      type: Object,
      required: false,
    },
    bewohnerData: {
      type: Object,
      required: true,
    },
    bewohnerMedication: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  /**
   * Data
   */
  data () {
    return {
      valid: false,
      migelPrefixes: [
          '15.01.01.00.1',
          '15.01.02.00.1',
          '15.01.03.00.1'
      ],
      rules: {
        required: value => !!value || 'Dieses Feld wird benötigt',

      }
    }
  },

  /**
   * Created
   */
  created () {
    Object.keys(this.config['migel-products']).forEach((key) => {
      if (key === 'inkograd') {
        this.editablePrescription.medication.inkograd = this.bewohnerMedication.inkograd
        return
      }

      this.config['migel-products'][key].values.forEach((value) => {
        const prefixes = value['migel-prefix'].split(';')
        this.migelPrefixes = this.migelPrefixes.concat(prefixes)

        const matchedMigels = this.productMigels.filter(migel => migel && prefixes.some(prefix => migel.startsWith(prefix)))

        if (matchedMigels.length > 0) {
          this.editablePrescription.medication['migel-products'][key][value.label] = true
        }
      })
    })

    // activate all articles by default
    this.toggleAllArticles(true)
  },

  /**
   * Computed
   */
  computed: {
    ...mapGetters({
      config: 'prescription/generatorConfig',
    }),

    isAllArticlesSelected: {
      get () {
        return this.editablePrescription.medication['selected-products'].length === this.bewohnerMedication.articles.length
      },
      set () {
        this.toggleAllArticles(!this.isAllArticlesSelected)
      }
    },

    productMigels () {
      return this.bewohnerMedication.articles.map(article => article.migel)
    },

    filteredArticles() {
      return this.bewohnerMedication.articles.filter(a => a.migel && this.migelPrefixes.some(prefix => a.migel.startsWith(prefix)))
    }
  },

  /**
   * Methods
   */
  methods: {
    toggleAllArticles (status) {
      if (status) {
        this.editablePrescription.medication['selected-products'] = this.bewohnerMedication.articles.map(article => article.ArtikelNrLAG)
      } else {
        this.editablePrescription.medication['selected-products'] = []
      }
    },

    validate () {
      this.$refs.form.validate()
      this.$emit('input', this.valid)
    },
  },

  watch: {
    editablePrescription: {
      handler: function () {
        this.$emit('input', this.valid)
      },
      deep: true,
    },
  },
}
</script>

<style scoped lang="scss">
.grid {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  margin-bottom: 40px;
}

.sub-sections {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
}

.specification-details {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;

  h4 {
    margin-bottom: 5px;
  }
}

.article-list {
  padding: 10px;
  background-color: #ffffff;
  border-radius: 10px;

  .article-list__item {
    display: grid;
    grid-template-columns: 100px 1fr 120px 120px 50px;
    gap: 1rem;

    &.-header {
      font-weight: 700;
    }

    &.disabled {
      opacity: 0.5;
    }

    .-right {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.product-tooltip {
  width: 260px;
  color: #000000;
}

::v-deep {
  .v-input {
    margin-top: 0;
  }

  .v-input--checkbox {
    margin-bottom: .5rem;
  }

  .v-input--radio-group__input {
    display: flex;
    flex-direction: column;
    gap: .5rem;
  }

  .v-input__slot {
    align-items: flex-start;

    .v-input--selection-controls__input {
      margin-top: -2px;
    }
  }

  .v-radio {
    align-items: flex-start;
  }
}
</style>
