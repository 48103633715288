<template>
  <div class="timeline-container" :class="{ 'prevent-events': disabled, 'passive': isLivingUnit && article.collective && !isEditable}">
    <div class="article-timeline__info">
      <ArticleImage :article="article"/>
      <div>
        <div class="body-2 heavy d-flex align-center">
          {{ article.internalID }}
          <v-tooltip
              v-if="article.collective"
              left
              max-width="390"
              content-class="custom-tooltip"
          >
            <template v-slot:activator="{ on, attrs }">
              <span
                  v-bind="attrs"
                  v-on="on"
                  class="icon-gemeinschaftsartikel cursor-help ml-4"
                  style="font-size: 16px;"
              ></span>
            </template>
            <span>{{ $t('sl.collectiveArticles.tooltipPlanner_' + $store.state.smartLogisticSettings.model) }}</span>
          </v-tooltip>
        </div>
        <div>
          {{ article.name }}
        </div>
        <div class="body-2 caption mt-1" style="font-size: 11px !important;">
          {{ article.zaehleinheit }}
        </div>
      </div>
    </div>

    <div class="article-timeline__timeline">
      <div class="article-timeline__days" ref="days">
        <div
            class="article-timeline__day"
            v-for="(day, x) in dayMatrix"
            :key="x"
            :class="{
              'is-today': day.isSame(today, 'day'),
              'is-weekend': day.day() === 0 || day.day() === 6,
              'editable': isEditable
            }"
            @click="dayClicked($event, day)"
        >
          <div class="day">
              <span class="day-number">
                {{ day.format('D') }}
              </span>
          </div>
          <div class="content">
            <template v-if="!isEditable">
              <template v-if="isDaily || (isWeekly && day.day() === 1) || (isMonthly && x === 0)">
                <span class="interval" v-if="!isEditable">
                  {{ intervalCount > 0? intervalCount: '' }}
                </span>
              </template>
            </template>
            <template v-else-if="isEditable">
              <!-- check if day has an order-->
              <template v-if="articleEffectiveConsumption[x+1] && articleEffectiveConsumption[x+1].orderCount > 0">
                <!-- we need to use articlePlanData as articleEffectiveConsumption is not updated as it is calculated by orders-->
                <span
                    v-if="articlePlanData[x+1] && articlePlanData[x + 1].timelineCount && articlePlanData[x + 1].timelineCount > 0"
                    class="manuel">
                  {{ articleEffectiveConsumption[x + 1].orderCount + articlePlanData[x + 1].timelineCount }}
                </span>
                <!-- make font size small when the value is too high - usually just on unit level for the collective articles-->
                <span v-else class="effective" :style="articleEffectiveConsumption[x + 1].orderCount > 999? 'font-size: 12px': ''">
                  {{ articleEffectiveConsumption[x + 1].orderCount }}
                </span>
              </template>
              <span v-else-if="articlePlanData[x+1]" class="manuel">
                <template v-if="articlePlanData[x + 1].timelineCount && articlePlanData[x + 1].timelineCount > 0">
                  <template v-if="articlePlanData[x + 1].artnr.includes('SKU')">
                    <span :class="{ planned: articlePlanData[x + 1].count === articlePlanData[x + 1].intervalCount }">
                      {{ articlePlanData[x + 1].count }}
                    </span>
                  </template>
                  <template v-else>
                    {{ articlePlanData[x + 1].timelineCount }}
                  </template>
                </template>
              </span>
            </template>
          </div>
          <div class="day" v-if="isIntervalDay(day)">
            <span class="icon-intervall-kennzeichnung"></span>
          </div>
          <div class="day" v-else>
            {{ day.format('dd') }}
          </div>
        </div>
      </div>

      <div class="article-timeline__total">
        <div class="label">
          Total
        </div>
        <div class="content">
          <span  :style="(isEditable ? consumptionTotal : intervalTotal) > 999? 'font-size: 12px': ''">
            {{ isEditable ? consumptionTotal : intervalTotal }}
          </span>
        </div>
        <div class="label">

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ArticleImage from '@/components/SmartLogistics/Article/ArticleImage.vue'
import { intervalMixin } from '@/mixins/SmartLogistics/intverval'
import { consumptionMixin } from '@/mixins/SmartLogistics/consumption'
import { first } from 'lodash'

export default {
  name: 'TimelineArticle',
  components: { ArticleImage },
  mixins: [intervalMixin, consumptionMixin],
  emits: ['dayClicked'],

  props: {
    type: {
      type: String,
      default: 'resident',
      validator: function (value) {
        return ['resident', 'living-unit'].indexOf(value) !== -1
      }
    },
    entityId: {
      type: Number,
      required: true,
    },
    article: {
      type: Object,
      required: true,
    },
    consumption: {
      type: Object,
      default: () => {
      },
    },
    effectiveConsumption: {
      type: Object,
      default: () => {
      },
    },
    date: {
      type: Object,
      required: true,
    },
    interval: {
      type: Number,
      required: false,
    },
    intervalStartDate: {
      type: Object,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    ...mapGetters({
      section: 'smartLogisticSettings/currentTimelineSection',
    }),

    isEditable () {
      return this.section !== 'planning'
    },

    isLivingUnit () {
      return this.type === 'living-unit'
    },

    today () {
      return this.$moment()
    },

    hasInterval () {
      return this.intervalStartDate && this.interval
    },

    intervalDates () {
      const startOfMonth = this.date.clone().startOf('month')
      const endOfMonth = startOfMonth.clone().endOf('month')
      const intervalDayDifference = this.interval * 7

      // calculate weeks difference of interval start date and end selected month
      const weeksDifference = endOfMonth.diff(this.intervalStartDate, 'weeks')

      // calculate interval occurrence
      const intervalCount = Math.floor(weeksDifference / this.interval)

      // get last interval date
      var intervalDate = this.intervalStartDate.clone().add(intervalCount * intervalDayDifference, 'days')

      const intervalDates = []

      // add all interval dates of selected month
      while (intervalDate.isAfter(startOfMonth)) {
        intervalDates.push(intervalDate.format('YYYY-MM-DD'))
        intervalDate.subtract(intervalDayDifference, 'days')
      }

      return intervalDates
    },

    articleConsumption () {
      return this.residentArticleConsumption(this.entityId, this.article.internalID)
    },

    articleData () {
      return this.residentArticleData(this.entityId, this.article.internalID)
    },

    articlePlanData () {
      return this.residentArticlePlanData(this.entityId, this.article.internalID)
    },

    articleEffectiveConsumption () {
      return this.residentEffectiveArticleConsumption(this.entityId, this.article.internalID)
    },

    consumptionTotal () {
      var total = this.calculateConsumptionTotal(this.articlePlanData)
      return total + this.calculateConsumptionTotal(this.articleEffectiveConsumption)
    },

    intervalCount () {
      const keys = Object.keys(this.articleConsumption)[0]

      if (!keys || keys.length === 0 || this.articleConsumption[keys[0]].intervalCount === undefined) {
        return this.article.currPlandaten.count
      }

      return this.articleConsumption[keys[0]].intervalCount
    },

    intervalTotal () {
      return this.intervalCount * this.intervalDays
    },
  },

  methods: {

    /**
     * handling flex wrap style
     */
    flexWrapHandler () {
      if (this.$refs.days) {
        const days = Array.from(this.$refs.days.querySelectorAll('.article-timeline__day'))
        var top = days[0].getBoundingClientRect().top

        days.forEach(day => {
          const curTop = day.getBoundingClientRect().top
          if (curTop > top) {
            day.classList.add('next-line')
            top = curTop
          }
        })
      }
    },

    /**
     *
     * @param day
     * @returns {*|boolean}
     */
    isIntervalDay (day) {
      return this.hasInterval && this.intervalDates.includes(day.format('YYYY-MM-DD'))
    },

    /**
     *
     * @param day
     */
    dayClicked (event, day) {
      if (this.disabled) {
        return
      }

      if (this.isEditable) {
        const curDay = +day.format('D')

        const count = this.articleData[curDay] ? this.articleData[curDay].count : 0
        const intervalCount = this.articleData[curDay] && this.articleData[curDay].intervalCount ? this.articleData[curDay].intervalCount : 0
        const timelineCount = this.articleData[curDay] && this.articleData[curDay].timelineCount ? this.articleData[curDay].timelineCount : 0

        this.$emit('dayClicked', {
          day: day,
          target: event.currentTarget,
          count: count,
          timelineCount: timelineCount,
          intervalCount: intervalCount,
        })
      }
    },
  },

  mounted () {
    window.addEventListener('resize', this.flexWrapHandler)
    setTimeout(() => {
      this.flexWrapHandler()
    }, 200)
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.flexWrapHandler)
  }
}
</script>

<style scoped>
.prevent-events * {
  cursor: not-allowed !important;
}

.passive {
  opacity: 0.4;
}

</style>
