<template>
  <div class="custom-toggle mb-8">
    <span
        class="toggle-icon"
        :class="[
        'icon-bewohner-einzeln',
        viewMode === 'resident' ? 'primary--text active' : 'grey--text'
      ]"
        @click="viewMode = 'resident'"
    ></span>
    <span
        class="toggle-icon"
        :class="[
        'icon-warenkrob',
        viewMode === 'basket' ? 'primary--text active' : 'grey--text'
      ]"
        @click="viewMode = 'basket'"
    ></span>
  </div>
</template>

<script>
export default {
  name: 'OrderResidentBasketSelector',
  computed: {
    viewMode: {
      get () {
        return this.$store.state.smartLogisticSettings.order.viewMode
      },
      set (value) {
        this.$store.commit('smartLogisticSettings/setViewMode', value)
      }
    }
  }
}
</script>

<style scoped>
.custom-toggle {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 12px;
  padding: 5px;
  width: fit-content;
}

.toggle-icon {
  padding: 8px 12px;
  cursor: pointer;
  font-size: 25px;
  transition: all 0.3s ease;
}

.toggle-icon:hover {
  color: #464646 !important;
}

.toggle-icon.active {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
</style>
