<template>
  <v-dialog
      v-model="show"
      persistent
      width="1300"
  >
    <v-card class="main">
      <header class="flex f-full row">
        <div class="title" v-if="orderPreview">
          <h1>
            Bestellvorschlag <strong>{{ wohneinheitById(orderPreview[0].whID)?.name }}</strong>
          </h1>
          <p>Verbrauchszeitraum {{ moment(dateRange).locale('de').format('MMMM YYYY') }} | Bedarfszeitraum
            {{ calcTimeSpans[period] }}</p>
        </div>
        <div class="title" v-else>
          <h1>
            Bestellvorschlag wird generiert</strong>
          </h1>
          <p>Einen Moment bitte...</p>
        </div>
        <div class="close" @click="cancel()"><img :src="require('@/assets/img/ico/schliessen.svg')" width="25">
        </div>
        <div class="hover-link text-center print-icon" style="width: 80px;" @click="print()"  v-if="!loading && orderPreview && !printLoading">
          <div class="icon-drucken text-center mb-2" style="font-size: 35px;" ></div>
          <div style="font-size: 14px">Drucken</div>
        </div>
        <div class="hover-link text-center print-icon" style="width: 80px;" v-if="!loading && orderPreview && printLoading">
          <v-progress-circular size="35" indeterminate color="primary" style="margin-bottom: 8px"></v-progress-circular>
          <div style="font-size: 14px">Drucken</div>
        </div>
      </header>
      <template v-if="!loading">
      <div class="container" v-if="orderPreview">
        <div class="table d-flex row" style="gap: 30px">
          <div class="header d-flex row w-full" style="gap: 30px">
            <div class="description flex">Bezeichnung</div>
            <div class="consumption flex">Verbrauch<br/>Verbrauchseinheit
            </div>
            <div class="consumption-period flex">Bedarf für {{ calcTimeSpans[period] }}<br/>Verbrauchseinheit</div>
            <div class="order-suggestion flex">Bestellvorschlag<br>Verpackungseinheit</div>
            <div class="input flex">Bestellmenge</div>
          </div>
          <div class="line d-flex row w-full" style="gap: 30px" v-for="(p,index2) in orderPreview[0].data"
               :key="'da-'+p.artnr">
            <div class="description flex d-flex flex-col" style="gap: 30px">
              <div>
              <span v-if="bilder[p.artnr] ">
                              <v-img :src="bilder[p.artnr]['Z_URL'] +'?width=80'" height="50"
                                     contain></v-img>
                              </span>
                <span v-else-if="p.bild">
                              <v-img :src="p.bild" alt="" width="45"></v-img>
                          </span>
                <span v-else>
                            <v-img :src="require('@/assets/img/ico/sortimentsverwaltung-aktiv.svg')" alt=""
                                   width="45"></v-img>
                          </span>
              </div>
              <div>
                <product-caption :artnr="p.showID" :name="p.name"
                                 :ownartno="false"></product-caption>
              </div>
            </div>
            <div class="consumption flex">{{ p.verbrauchMt }}</div>
            <div class="consumption-period flex"> {{ p.count }}</div>
            <div class="order-suggestion d-flex flex align-center"><span class="recommendation">{{ p.recommendation }}</span><span class="verpackung">{{ p.verpackung }}</span></div>
            <div class="input flex">
              <input type="number" v-model.number="changedAmounts[p.artnr]" min="0" @input="validateInput(p.artnr)" :disabled="printLoading"/></div>
          </div>
          <div class="remark row mt-16 w-full">
            <strong class="mb-2">Bemerkung</strong><br/>
            <textarea v-model="remark" :disabled="printLoading"></textarea>
          </div>
<!--          <div class="row w-full">-->
<!--            <div class="hover-link text-center" style="width: 80px;" @click="print()"  v-if="!loading && orderPreview">-->
<!--              <div class="icon-drucken text-center mb-2" style="font-size: 35px;" ></div>-->
<!--              <div style="font-size: 14px">Drucken</div>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
        <div v-if="!orderPreview">
          Kein Bestellvorschlag verfügbar
        </div>
      </template>
      <template v-if="loading">
        <v-progress-circular size="35" color="primary" indeterminate></v-progress-circular>
      </template>
    </v-card>
  </v-dialog>

</template>

<script>


import {simpleOrderSuggestion} from "@/store/modules/simpleOrderSuggestion";
import moment from 'moment';
import {mapGetters} from "vuex";
import ProductCaption from "@/components/products/ProductCaption.vue";
import {wohneinheitenGetters} from "@/components/observables/wohneinheiten";

export default {
  name: 'SimpleOrderSuggestionPreviewDialog',
  components: {ProductCaption},
  emits: ['cancel', 'delete'],
  props: {},
  data() {
    return {
      show: false,
      moment: moment,
      remark: null,
      changedAmounts: {},
      calcTimeSpans:
          {
            1: '1 Woche',
            2: '2 Wochen',
            4: '1 Monat',
          }
    }
  },
  computed: {
    ...mapGetters({
      orderPreview: 'simpleOrderSuggestion/orderPreview',
      dateRange: 'simpleOrderSuggestion/dateRange',
      period: 'simpleOrderSuggestion/period',
      loading: 'simpleOrderSuggestion/loading',
      printLoading: 'simpleOrderSuggestion/printLoading',
    }),
    ...wohneinheitenGetters,
    bilder: {
      get() {
        return this.$store.state['bilder'].produktbilder
      }
    },
  },
  methods: {
    open() {
      this.show = true;
      this.changedAmounts = {};
    },
    cancel() {
      this.show = false;
      this.$emit('cancel')
    },
    initData() {

    },

    wohneinheitById(id) {
      return this.wohneinheiten.find(w => w.id === id);
    },

    print() {
      this.$store.dispatch('simpleOrderSuggestion/getPrint', {livingUnit: this.orderPreview[0].whID, date: this.dateRange, period: this.period, changedAmounts: this.changedAmounts, remark: this.remark})
    },

    validateInput(artNr) {
      if (this.changedAmounts[artNr] < 0) {
        this.changedAmounts[artNr] = 0; // Prevent negative values
      }
    }
  },

  mounted() {
    this.initData();
  }
}
</script>

<style scoped lang="scss">
  .print-icon {
    position: absolute;
    top: 90px;
    right: 100px;
    margin-left: 100px;
    cursor: pointer;
  }

.main {
  padding: 60px 100px;
}

.title {
  h1 {
    margin: 0;
    margin-top: 0 !important;
    font-family: "Avenir LT Std";
    font-weight: 300 !important;
    font-size: 33px !important;
    line-height: 43px;
    margin-bottom: 2rem;

    strong {
      font-family: "Avenir LT Std Heavy" !important;
    }
  }

  margin-bottom: 4rem;
}

.close {
  position: absolute;
  top: 30px;
  right: 40px;
  cursor: pointer;
}

.table {
  .header {
    font-weight: bold;
    text-align: left;
    align-items: flex-end;
    padding-bottom: 5px;

    > div {
      display: flex;
    }
  }

  .line {
    text-align: left;
    margin-top: 0;
    padding: 15px 0;
    align-items: center;

    input {
      background: #D3E9F5;
      width: 90px;
      padding: 9px 0 9px 15px;
      text-align: center
    }
  }

  .description {
    flex: 2
  }

  .consumption {
    flex: 0.7;
    display: flex;
    justify-content: flex-end;
    text-align: right;
  }

  .consumption-period {
    flex: 1.2;
    margin-right: 70px;
    display: flex;
    text-align: right;
    justify-content: flex-end;
  }

  .order-suggestion {
    flex: 1.2;
    margin-right: 20px;

    .recommendation {
      display: inline-flex;
      width: 40px;
    }
    .verpackung {
      display: inline-flex;
      width: calc(100% - 40px);
    }
  }

  .input {
    flex: 0.5;
  }
}

textarea {
  background: #D3E9F5;
  width: 100%;
  height: 200px;
  padding: 9px 0 9px 9px;
}

.header, .line {
  border-bottom: 1px solid lightgrey;
}

.title {
  h1 {
    line-height: 1.1em;
  }
}
</style>
