<template>
  <v-row>
    <v-col cols="12">
      <v-alert
          elevation="0"
          icon="mdi-information"
      >
       <slot/>
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: 'AlertMessage'
}
</script>
