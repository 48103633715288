<template>
  <div id="reports">
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1 class="primary--text mb-0">
            Leistungsabrechnung
          </h1>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          Bitte wählen Sie Ihren Report und die gewünschten Optionen.
        </v-col>
        <v-col class="pb-12">
          <v-btn depressed color="primary" class="float-right mt-4  ml-4" @click="exportExcel">Excel öffnen</v-btn>
          <v-btn v-if="$store.state.benutzer.settings.erp !== 'none'" depressed color="primary"
                 class="float-right mt-4 mb-0" @click="exportERP">
            {{ $store.state.benutzer.settings.erp.toUpperCase() }}-Export
          </v-btn>
        </v-col>
      </v-row>
      <v-divider class="mb-12"></v-divider>


      <v-row>
        <v-col>
          <v-card>
            <v-card-title>
              <b>Abrechnungszeitraum</b>
            </v-card-title>
            <v-card-text class="text-center">

              <v-date-picker
                  v-model="date"
                  width="270"
                  flat
                  type="month"
                  locale="ch-de"
                  color="primary"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card>
            <v-card-title>
              <b>Kategorien</b>
            </v-card-title>
            <v-card-text>
              <v-checkbox dense small light flat v-model="categories.all" :true-value="true" :false-value="false"
                          @click.stop="toggleAllCategories" label="">
                <template v-slot:label>
                      <span class="body-1 normal">
                        <span class="body-1 normal">Alle Kategorien</span> <span class="body-2 pl-1 text--primary">{{
                          categories.selected.length
                        }}</span><span style="color:#ccc"> / {{ categories.list.length }}</span>
                      </span>
                </template>
              </v-checkbox>

              <v-divider class="my-2"/>
              <div v-for="(category,i) in categories.list" :key="category.id">
                <v-checkbox dense small light flat v-model="selectedCategories[category.id]"
                            @click="toggleCategory(category.id)">
                  <template v-slot:label="">
                          <span class="body-1">
                              {{ category.name }}
                          </span>
                  </template>
                </v-checkbox>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col>
          <v-card>
            <v-card-title>
              <b>Leistungsauswahl-Optionen</b>
            </v-card-title>
            <v-card-text>
              <v-checkbox dense small light flat :disabled="this.$store.state.benutzer.settings.erp === 'abacus'"
                          v-model="options.migelKategorieB" label="">
                <template v-slot:label>
                      <span class="body-1 normal">
                        Abrechnung an Krankenkasse (MiGeL)
                      </span>
                </template>
              </v-checkbox>
              <v-checkbox class="ml-4" dense small light flat v-model="options.maxHVBPflege" label=""
                          :disabled="this.$store.state.benutzer.settings.erp === 'abacus'"
                          v-if="(this.$store.state.benutzer.settings.erp === 'nexus' || this.$store.state.benutzer.settings.erp === 'abacus') && this.options.migelKategorieB">
                <template v-slot:label>
                      <span class="body-2 normal">
                        max. HVB Pflege berücksichtigen<br><i class="text-caption">ausgenommen Produkte mit Jahrespauschalen</i>
                      </span>
                </template>
              </v-checkbox>

              <v-checkbox dense small light flat v-model="options.chargingBewohner" label=""
                          :disabled="this.$store.state.benutzer.settings.erp === 'abacus'">
                <template v-slot:label>
                      <span class="body-1 normal">
                        Verrechnung an Bewohner
                      </span>
                </template>
              </v-checkbox>

              <v-checkbox dense small light flat v-model="options.ownProducts" label=""
                          :disabled="this.$store.state.benutzer.settings.erp === 'abacus'">
                <template v-slot:label>
                      <span class="body-1 normal">
                        Eigene Produkte
                      </span>
                </template>
              </v-checkbox>

              <v-checkbox dense small light flat v-model="options.betriebsmaterial" label=""
                          :disabled="this.$store.state.benutzer.settings.erp === 'abacus'">
                <template v-slot:label>
                      <span class="body-1 normal">
                        Betriebsmaterial
                      </span>
                </template>
              </v-checkbox>

              <v-checkbox dense small light flat v-model="options.migelKategorieA" label=""
                          :disabled="this.$store.state.benutzer.settings.erp === 'abacus'">
                <template v-slot:label>
                      <span class="body-1 normal">
                        nicht verrechenbare Leistung (MiGeL Kategorie A)
                      </span>
                </template>
              </v-checkbox>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col>
          <v-card>
            <v-card-title>
              <b>Wohneinheiten/Bewohner</b>
            </v-card-title>
            <v-card-subtitle>
              <v-text-field
                  label="Suche nach Bewohner"
                  v-model="bewohner.searchTerm"
                  @click:clear="bewohner.searchTerm=''"
                  clearable
                  hide-details="auto"
              />
            </v-card-subtitle>
            <v-card-text>
              <div v-if="bewohner.searchTerm && bewohner.searchTerm.length > 0">
                <div v-for="bewohner in searchBewohner" :key="bewohner.id">
                  <v-checkbox dense small light flat v-model="selectedBewohner[bewohner.id]"
                              @click="toggleBewohner(bewohner.id)">
                    <template v-slot:label="">
                      <v-tooltip v-if="bewohner.deleted" top class="body-2 py-1 px-2 ">
                        <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on" class="body-2 text-decoration-line-through">
                                    {{ bewohner.vorname }} {{
                                    bewohner.name
                                  }} / {{ wohneinheitName(bewohner['wohneinheitenID']) }}
                                </span>
                        </template>
                        <span>
                                Dieser Bewohner wurde am {{
                            $moment(bewohner.deleted_at).format('DD.MM.YY')
                          }} gelöscht<br>und ist nur bis Ende Monat des Lösch-Datums verfügbar
                              </span>
                      </v-tooltip>
                      <span class="body-2" v-else>
                              {{ bewohner.vorname }} {{
                          bewohner.name
                        }} / {{ wohneinheitName(bewohner['wohneinheitenID']) }}
                          </span>
                    </template>
                  </v-checkbox>
                </div>
              </div>
              <div v-else>
                <v-checkbox dense small light flat v-model="bewohner.all" @click.stop="toggleAllBewohner" label="">
                  <template v-slot:label>
                      <span class="body-1 normal">
                        <span class="body-1 normal">Alle Bewohner</span>
                        <span class="pl-1" style="color:#ccc">{{
                            filteredBewohner.length
                          }}</span>
                      </span>
                  </template>
                </v-checkbox>

                <v-divider class="my-2"/>

                <v-expansion-panels multiple accordion flat class="default">
                  <v-expansion-panel v-for="wh in wohneinheiten.list" :key="wh.id" accordion>
                    <v-expansion-panel-header class="py-0 pl-0 pt-0 pr-10 body-1">
                      <v-checkbox @click="toggleWhBewohner(wh.id)" v-model="selectedWohneinheiten[wh.id]" @click.stop
                                  class="pl-0" dense light>
                        <template v-slot:label>
                      <span class="body-1 normal">
                        <span class="body-1 normal">{{ wh.name }}</span> <span class="pl-0 body-2" style="color:#ccc">
                          <span class="text--primary">{{
                              wohneinheitenActiveBewohnerCount[wh.id]
                            }}</span> / {{ filteredBewohner[wh.id].length }}
                        </span>
                      </span>
                        </template>
                      </v-checkbox>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="pl-8 pb-5">
                      <div v-for="bewohner in filteredBewohner[wh.id]" :key="bewohner.id">
                        <v-checkbox dense small light flat v-model="selectedBewohner[bewohner.id]"
                                    @click="toggleBewohner(bewohner.id)">
                          <template v-slot:label="">
                            <v-tooltip v-if="bewohner.deleted" top class="body-1 py-1 px-2 ">
                              <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on" class="body-1 text-decoration-line-through">
                                    {{ bewohner.vorname }} {{ bewohner.name }}
                                </span>
                              </template>
                              <span>
                                Dieser Bewohner wurde am {{
                                  $moment(bewohner.deleted_at).format('DD.MM.YY')
                                }} gelöscht<br>und ist nur bis Ende Monat des Lösch-Datums verfügbar
                              </span>
                            </v-tooltip>
                            <span class="body-1" v-else>
                              {{ bewohner.vorname }} {{ bewohner.name }}
                          </span>
                          </template>
                        </v-checkbox>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog persistent v-model="exportModal" max-width="90%" style="overflow-y:auto;max-height:90% !important">
      <export-preview
          :key="'export_'+rerender"
          :run="runErpSync"
          @downloadERP="downloadERP"
          @transferERP="transferERP"
          @exportERP="exportERP"
          @generateXMLForERP="generateXMLForERP"
          @closeExportModal="closeExportModal"
          :bewohner="bewohner.list"
          :month="date"
          :report="exportResponse"
          :availableLeistungsnummern="availableLeistungsnummern"
          :inactiveLeistungsnummern="inactiveLeistungsnummern"
      >
      </export-preview>
    </v-dialog>

    <v-overlay :value="loading">
      <v-progress-circular
          color="primary"
          indeterminate
          size="64"
      />
    </v-overlay>
  </div>
</template>

<script>
import httpClient from "../utils/httpClient";
import XLSX from "xlsx";
import ExportPreview from "../components/reports/ExportPreview";

export default {
  name: 'ReportFilter',
  components: {ExportPreview},
  data() {
    return {
      loading: true,
      exportModal: false,
      date: this.$moment().subtract(1, 'months').format('YYYY-MM'),
      categories: {
        list: [],
        all: true,
        selected: []
      },
      wohneinheiten: {
        list: [],
        selected: []
      },
      options: {
        migelKategorieB: true,
        maxHVBPflege: true,
        chargingBewohner: false,
        ownProducts: false,
        migelKategorieA: false,
        betriebsmaterial: false
      },
      bewohner: {
        list: [],
        all: true,
        selected: [],
        searchTerm: ''
      },
      monthFilterData: null,

      rerender: 0,
      runErpSync: false,
      exportResponse: false,
      availableLeistungsnummern: [],
      inactiveLeistungsnummern: [],
    }
  },

  computed: {
    selectedDate() {
      return this.$moment(this.date, 'YYYY-MM')
    },

    /**
     *
     */
    selectedCategories: {
      get() {
        const selectedCategories = {}

        this.categories.list.map(c => {
          selectedCategories[c.id] = this.categories.selected.includes(c.id)
        })

        return selectedCategories
      },
      set(value) {
      }
    },

    /**
     *
     */
    selectedWohneinheiten: {
      get() {
        const selectedWohneinheiten = {}

        this.wohneinheiten.list.map(c => {
          selectedWohneinheiten[c.id] = this.wohneinheiten.selected.includes(c.id)
        })

        return selectedWohneinheiten
      },
      set(value) {
      }
    },

    /**
     *
     */
    selectedBewohner: {
      get() {
        const selectedBewohner = {}

        this.bewohner.list.map(b => {
          selectedBewohner[b.id] = this.bewohner.selected.includes(b.id)
        })

        return selectedBewohner
      },
      set(value) {
      }
    },

    /**
     *
     * @return {{}}
     */
    filteredBewohner() {
      const data = {}

      this.wohneinheiten.list.forEach(wh => {
        data[wh.id] = []
      })

      this.bewohner.list.forEach(b => {
        if (!this.monthFilterData) {
          // bugfix if unknown wh
          if (data[b.wohneinheitenID] === undefined) {
            data[b.wohneinheitenID] = []
          }

          data[b.wohneinheitenID].push(b)
        } else {
          const bData = this.monthFilterData.find(d => d.id === b.id)

          if (!bData || !bData.products) {
            return
          }

          // all products
          var bProducts = bData.products


          bProducts = bProducts.filter(p => this.categories.selected.includes(p.klasse) || (this.options.ownProducts && p.ownProduct) || (this.options.betriebsmaterial && p.betriebsmaterial))

          bProducts = bProducts.filter(p =>
              (this.options.migelKategorieA && p.migelKategorieA)
              || (this.options.migelKategorieB && p.migelKategorieB)
              || (this.options.chargingBewohner && p.chargingBewohner)
              || (this.options.ownProducts && p.ownProduct)
              || (this.options.betriebsmaterial && p.betriebsmaterial)
          )


          if (bProducts.length > 0) {
            data[b.wohneinheitenID].push(b)
          }
        }
      })

      return data;
    },

    wohneinheitenActiveBewohnerCount() {
      const data = {}

      this.wohneinheiten.list.forEach(wh => {
        data[wh.id] = 0
      })

      this.bewohner.selected.forEach(id => {
        const bewohner = this.bewohner.list.find(b => b.id === id)

        if (bewohner) {
          data[bewohner.wohneinheitenID]++
        }
      })

      return data
    },

    searchBewohner() {
      var bewohner = []

      const searchTerm = this.bewohner.searchTerm.toLowerCase()

      for (const [key, value] of Object.entries(this.filteredBewohner)) {
        bewohner = bewohner.concat(value)
      }

      bewohner = bewohner.filter(b => b.name.toLowerCase().includes(searchTerm) || b.vorname.toLowerCase().includes(searchTerm))

      return bewohner
    }
  },

  methods: {
    /**
     *
     * @param value
     */
    toggleCategory(value) {
      const pos = this.categories.selected.findIndex(id => id === value)

      if (pos > -1) {
        if (this.categories.all) {
          this.categories.all = false
        }

        this.categories.selected.splice(pos, 1)
      } else {
        this.categories.selected.push(value)

        if (this.categories.selected.length === this.categories.list.length) {
          this.categories.all = true
        }
      }
    },

    /**
     *
     */
    toggleAllCategories() {
      if (this.categories.all) {
        this.categories.selected = this.categories.list.map(c => c.id)
      } else {
        this.categories.selected = []
      }
    },

    /**
     *
     * @param value
     */
    toggleBewohner(value) {
      const pos = this.bewohner.selected.findIndex(id => id === value)

      if (pos > -1) {
        const bewohner = this.bewohner.list.find(b => b.id === value)
        this.bewohner.selected.splice(pos, 1)

        if (bewohner) {
          const whPos = this.wohneinheiten.selected.findIndex(id => id === bewohner.wohneinheitenID)

          if (whPos > -1) {
            this.wohneinheiten.selected.splice(whPos, 1)
          }
        }
      } else {
        this.bewohner.selected.push(value)
      }
    },

    /**
     *
     * @param value
     */
    toggleWhBewohner(value) {
      const pos = this.wohneinheiten.selected.findIndex(id => id === value)

      const whBewohner = this.filteredBewohner[value]


      if (pos > -1) {
        this.wohneinheiten.selected.splice(pos, 1)

        this.bewohner.all = false

        whBewohner.forEach(b => {
          const bPos = this.bewohner.selected.findIndex(id => id === b.id)

          if (bPos > -1) {
            this.bewohner.selected.splice(bPos, 1)
          }
        })

      } else {
        this.wohneinheiten.selected.push(value)

        whBewohner.forEach(b => {
          if (!this.bewohner.selected.includes(b.id)) {
            this.bewohner.selected.push(b.id)
          }
        })
      }
    },

    /**
     *
     */
    toggleAllBewohner() {
      if (this.bewohner.all) {
        this.wohneinheiten.selected = this.wohneinheiten.list.map(c => c.id)

        var bewohnerList = []
        for (const [key, value] of Object.entries(this.filteredBewohner)) {
          bewohnerList = bewohnerList.concat(value.map(b => b.id))
        }
        this.bewohner.selected = bewohnerList

      } else {
        this.wohneinheiten.selected = []
        this.bewohner.selected = []
      }
    },

    /**
     *
     */
    fetchMonthFilter(year, month) {
      this.loading = true

      httpClient.get(process.env.VUE_APP_API_CHECKLIST + `erp/billing/${year}/${month}`)
          .then(response => {
            this.monthFilterData = response
            this.loading = false
          })
    },

    /**
     *
     */
    exportExcel() {
      this.loading = true

      httpClient({
        url: process.env.VUE_APP_API_CHECKLIST + 'erp/billing/export/excel',
        method: 'post',
        data: {
          year: this.selectedDate.year(),
          month: this.selectedDate.month() + 1,
          bewohner: this.bewohner.selected,
          categories: this.categories.selected,
          migelKategorieA: this.options.migelKategorieA,
          migelKategorieB: this.options.migelKategorieB,
          chargingBewohner: this.options.chargingBewohner,
          ownProducts: this.options.ownProducts,
          maxHVBPflege: this.options.maxHVBPflege,
          betriebsmaterial: this.options.betriebsmaterial
        }
      })
          .then(response => {
            if (response.type == 'success') {
              // only array possible
              var xls = XLSX.utils.aoa_to_sheet(response.xls)

              // A workbook is the name given to an Excel file
              var wb = XLSX.utils.book_new()

              // add Worksheet to Workbook
              // Workbook contains one or more worksheets
              XLSX.utils.book_append_sheet(wb, xls, 'report')

              var date = this.$moment()
              // export Excel file
              XLSX.writeFile(wb, 'PURAS-VM-Leistungen-' + this.month + '-' + date.format('DDMMYYYY') + '-' + date.format('hhmm') + '.xlsx')
            }
            this.loading = false
          })
          .catch(() => {
            this.loading = false
          })
    },

    closeExportModal() {
      this.exportModal = false;
    },

    /**
     *
     */
    exportERP() {
      this.loading = true

      httpClient({
        url: process.env.VUE_APP_API_CHECKLIST + 'erp/billing/export/' + this.$store.state.benutzer.settings.erp,
        method: 'post',
        data: {
          year: this.selectedDate.year(),
          month: this.selectedDate.month() + 1,

          bewohner: this.bewohner.selected,
          categories: this.categories.selected,
          migelKategorieA: this.options.migelKategorieA,
          migelKategorieB: this.options.migelKategorieB,
          chargingBewohner: this.options.chargingBewohner,
          ownProducts: this.options.ownProducts,
          maxHVBPflege: this.options.maxHVBPflege,
          betriebsmaterial: this.options.betriebsmaterial
        }
      })
          .then(response => {
            this.exportResponse = response.erpPreview
            this.availableLeistungsnummern = response.availableLeistungsnummern
            this.inactiveLeistungsnummern = response.inactiveLeistungsnummern
            this.rerender++
            this.exportModal = true
            this.loading = false
          })
    },

    downloadERP() {
      var csv = this.json2CSV(this.exportResponse)

      var date = this.$moment()
      var exportedFilenmae = 'PURAS-VM-Leistungen-ERP-' + this.date + '-' + date.format('DDMMYYYY') + '-' + date.format('hhmm') + '.csv';

      var blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportedFilenmae);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },

    json2CSV(objArray) {
      var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
      var str = '';

      for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
          if (index > 0) line += ';'

          line += array[i][index];
        }

        str += line + '\r\n';
      }

      return str;
    },

    async generateXMLForERP() {
      const response = await httpClient({
        url: process.env.VUE_APP_API_CHECKLIST + 'erp/billing/get-monthly-benefits-xml',
        method: 'post',
        data: {
          year: this.selectedDate.year(),
          month: this.selectedDate.month() + 1,
          bewohner: this.bewohner.selected,
          categories: this.categories.selected,
          migelKategorieA: this.options.migelKategorieA,
          migelKategorieB: this.options.migelKategorieB,
          chargingBewohner: this.options.chargingBewohner,
          ownProducts: this.options.ownProducts,
          maxHVBPflege: this.options.maxHVBPflege,
          betriebsmaterial: this.options.betriebsmaterial
        }
      })

      if (response.type == 'success') {
        var date = this.$moment()
        var exportedFilenmae = 'PURAS-VM-Leistungen-ABACUS-' + this.date + '-' + date.format('DDMMYYYY') + '-' + date.format('hhmm') + '.xml';

        var blob = new Blob([response.xml], {type: 'text/xml;charset=utf-8;'});
        if (navigator.msSaveBlob) { // IE 10+
          navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
          var link = document.createElement("a");
          if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      }

    },

    async transferERP() {
      this.$confirm('Dies startet den Abgleich zu ' + this.$store.state.benutzer.settings.erp.toUpperCase() + '.<br><br>Bereits übermittelte Daten werden nicht erneut gesendet (keine Änderungen möglich).<br><br> Sie können das Browserfenster schliessen oder auch den Verlauf verfolgen. Der aktuelle Status wird ihnen immer angezeigt.', {
        title: 'Achtung',
        buttonTrueText: 'Ja, jetzt übermitteln',
        buttonFalseText: 'Abbrechen'
      }).then(async confirmed => {
        if (confirmed) {
          this.runErpSync = true;
          const response = await httpClient({
            url: process.env.VUE_APP_API_CHECKLIST + 'erp/billing/export-monthly-benefits',
            method: 'post',
            data: {
              year: this.selectedDate.year(),
              month: this.selectedDate.month() + 1,
              bewohner: this.bewohner.selected,
              categories: this.categories.selected,
              migelKategorieA: this.options.migelKategorieA,
              migelKategorieB: this.options.migelKategorieB,
              chargingBewohner: this.options.chargingBewohner,
              ownProducts: this.options.ownProducts,
              maxHVBPflege: this.options.maxHVBPflege,
              betriebsmaterial: this.options.betriebsmaterial
            }
          })
        } else {
          this.runErpSync = false;
        }
      })
    },

    wohneinheitName(id) {
      const wohneinheit = this.wohneinheiten.list.find(wh => wh.id === id)

      return wohneinheit ? wohneinheit.name : ''
    },

    updateSelectedWohneinheiten() {
      if (!this.bewohner.all && this.bewohner.selected.length > 0) {
        this.wohneinheiten.list.forEach(wh => {
          if (this.wohneinheiten.selected && this.filteredBewohner[wh.id].length > 0 && !this.wohneinheiten.selected.includes(wh.id)) {
            if (this.filteredBewohner[wh.id].length === this.wohneinheitenActiveBewohnerCount[wh.id]) {
              this.wohneinheiten.selected.push(wh.id)
            } else {
              this.wohneinheiten.selected = this.wohneinheiten.selected.filter(id => id !== wh.id)
            }
          }
        })
      }
    },

    updateSelectedBewohner() {
      var selectedBewohner = []

      this.wohneinheiten.list.forEach(wh => {
        const validIds = this.filteredBewohner[wh.id].map(b => b.id)

        if (this.wohneinheiten.selected.includes(wh.id) && this.filteredBewohner[wh.id].length > this.wohneinheitenActiveBewohnerCount[wh.id]) {
          selectedBewohner = selectedBewohner.concat(validIds)
        } else {
          selectedBewohner = selectedBewohner.concat(this.bewohner.selected.filter(id => validIds.includes(id)))
        }

      })

      this.bewohner.selected = selectedBewohner
    }
  },

  watch: {
    date() {
      const date = this.$moment(this.date, 'YYYY-MM')

      this.fetchMonthFilter(date.year(), date.month() + 1)
    },

    filteredBewohner() {
      if (this.bewohner.all) {
        this.toggleAllBewohner()
      }
    },

    /**
     * mark wohneinheit as selected if all it's bewohner are manually selected
     */
    'bewohner.selected': function () {
      this.updateSelectedWohneinheiten()
    },

    options: {
      handler() {
        if (!this.options.migelKategorieB) {
          this.options.maxHVBPflege = false
        }

        this.updateSelectedBewohner()
      },
      deep: true
    }
  },

  /**
   *
   * @return {Promise<void>}
   */
  async mounted() {
    httpClient.get(process.env.VUE_APP_API_CHECKLIST + 'erp/billing/filter-data').then(response => {
      this.categories.list = response.categories
      this.wohneinheiten.list = response.wohneinheiten
      this.bewohner.list = response.bewohner

      this.toggleAllCategories()
      this.toggleAllBewohner()
    })

    const curDate = this.$moment()
    this.fetchMonthFilter(curDate.year(), curDate.month())
  }
}
</script>

<style scoped lang="scss">
.v-messages {
  display: none !important;
}

.v-input--checkbox {
  margin: 0 !important;
}
</style>
