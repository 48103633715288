<template>
  <div v-if="isDeleted() || (article.nachrichten && article.nachrichten.length > 0) "
       class="article-message"
       style="display: inline-block; color: #fff; font-size: 12px;line-height:17px;padding: 2px 5px 0px 0px;font-weight: bold">
    <v-tooltip top class="body-2 py-1 px-2 ">
      <template v-slot:activator="{ on, attrs }">
        <v-hover v-slot="{ hover }" open-delay="0">
          <v-icon :color="isDeleted() ? 'red' : 'orange'" v-bind="attrs"
                  size="22" v-on="on" style="margin-top: -4px;">
            mdi-information-outline
          </v-icon>
        </v-hover>
      </template>
      <div>
                <span v-if="isDeleted()">
                  <span v-if="articleNo().indexOf('SKU-') === -1"
                        style="font-size: 13px;">Dieses Produkt ist nicht mehr im Puras Sortiment</span>
                  <span v-else>Dieses Produkt wurde aus dem Sortiment gelöscht</span>
                  <span v-if="article.ersatzartikel" style="font-size: 13px;"><br>Als Ersatzartikel empfehlen wir: {{
                      article.ersatzartikel
                    }}</span>
                  <br><br>
                </span>
        <div v-for="(n,index) in article.nachrichten">
          <span style="font-size: 13px;">{{ n.Titel_DE }}<br>{{ n.Text_DE }}</span>
        </div>
      </div>
    </v-tooltip>
  </div>
</template>

<script>

export default {
  name: 'ArticleCaption',

  props: {
    article: {
      type: Object,
      required: true,
    },
  },

  methods: {
    isDeleted() {
      return this.article.geloescht === 1 || this.article.geloscht === 1;
    },
    articleNo() {
      if (this.article.internalID) {
        return this.article.internalID
      }
      if (this.article.objectID) {
        return this.article.objectID
      }
    }
  }
}
</script>