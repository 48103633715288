import httpClient
    from "../../utils/httpClient"
import moment
    from "moment";

export const simpleOrderSuggestion = {
    namespaced: true,


    state: {
        orderPreview: '',
        loading: false,
        printLoading: false
    },

    getters: {
        orderPreview: state => state.orderPreview ? state.orderPreview.orderPreview : null,
        dateRange: state => state.orderPreview ? state.orderPreview.dateRange : null,
        period: state => state.orderPreview ? state.orderPreview.period : null,
        loading: state => state.loading ? state.loading : null,
        printLoading: state => state.printLoading ? state.printLoading : null,
    },

    actions: {
        async getPreview({commit, state}, payload) {
            state.orderPreview = null;
            state.loading = true;
            const res = await httpClient({
                url: process.env.VUE_APP_API_CHECKLIST + 'get/order-suggestion',
                method: 'post',
                data: {
                    'livingUnit': payload.livingUnit,
                    'date': payload.date,
                    'period': payload.period
                }
            })

            if (res.type == 'success') {
                state.orderPreview = res;
            }
            state.loading = false;
        },
        async getPrint({commit, state}, payload) {
            state.printLoading = true;
            httpClient.post(process.env.VUE_APP_API_CHECKLIST + 'print/simple-order-suggestion',{
                'livingUnit': payload.livingUnit,
                    'date': payload.date,
                    'period': payload.period,
                    'changedAmounts': payload.changedAmounts,
                    'remark': payload.remark
            },{
                responseType: 'arraybuffer',
            })
                .then((response) => {
                    const blob = new Blob([response], {type: 'application/pdf'});
                    if (navigator.msSaveBlob) {
                        navigator.msSaveBlob(blob, "bestellvorschlag.pdf");
                    } else {
                        const link = document.createElement('a');
                        if (link.download !== undefined) {
                            const url = URL.createObjectURL(blob);
                            link.setAttribute('href', url);
                            link.setAttribute('download', "bestellvorschlag.pdf");
                            link.style.visibility = 'hidden';
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        }
                    }
                })
                .finally(() => {
                    // Optional: Add any final steps here
                    state.printLoading = false;
                });
        },
    },

    mutations: {}
}
