<template>
  <div id="smart-logistic-settings" class="mt-8">
    <v-row>
      <v-col cols="12">
        <div class="custom-box">
          <div class="title">
            <h2 class="mb-2">Bewohnerbezogene Bestellung</h2>
            <span class="text--primary font-weight-bold">Gewisse Einstellungen können nur durch einen Systemadministrator von Puras vorgenommen werden. Sollten Sie Fragen haben, wenden Sie
            sich bitte an den Support.</span>
          </div>
          <div class="content">
            <v-row class="mt-6">
              <v-col cols="3">
                <h3>Modell</h3>
                <v-select
                    :disabled="!isSuperadmin"
                    v-model="$store.state.smartLogisticSettings.model"
                    :items="$store.state.smartLogisticSettings.blueprint.model"
                    @change="changeModel()"
                    @focus="saveOldModel($store.state.smartLogisticSettings.model)"
                    item-text="label"
                    item-value="value"
                />
              </v-col>
              <v-col v-if="model && model !== 'a'" cols="6">
                <div class="pt-8 pl-4">
                  <v-switch :disabled="!isSuperadmin"
                            dense small light flat inset
                            @change="$store.dispatch('smartLogisticSettings/settings', {'call': 'logistics-direct-order-allowed','direct_order_allowed':$store.state.smartLogisticSettings.directOrderAllowed})"
                            v-model="$store.state.smartLogisticSettings.directOrderAllowed"
                            :true-value="1" :false-value="0"
                            label="Sofortbestellung ermöglichen"></v-switch>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="model && model !== 'a'">
      <v-col cols="12">
        <div class="custom-box">
          <div class="title">
            <h2>Bestelloptionen</h2>
          </div>
          <v-row class="mt-5">
            <v-col cols="4" v-for="livingUnit in $store.state.smartLogisticSettings.livingUnitSettings">
              <v-card elevation="0" class="px-6 py-5">
                <h3>{{ livingUnit.name }}</h3>
                <div class="mt-6">
                  <v-select
                      :disabled="!isSuperadmin"
                      label="Bestell-Intervall"
                      style="margin-top: -8px"
                      v-model="livingUnit.interval"
                      :items="$store.state.smartLogisticSettings.blueprint.interval"
                      @focus="saveOldInterval(livingUnit.interval)"
                      @change="changeInterval(livingUnit,livingUnit.interval)"
                      item-text="label"
                      item-value="value"
                  />
                  <div class="mt-4">
                    <CustomDatePicker
                        @focus="saveOldDate(livingUnit.start_date)"
                        label="Startdatum (erste Bestellung)"
                        :minDate="minDate"
                        :disabled="!isSuperadmin"
                        format="dddd, DD.MM.YYYY"
                        @update:modelValue="changeDate(livingUnit)"
                        :formValues="livingUnit"
                        v-model="livingUnit.start_date"
                        :firstDayOfWeek="1"
                        :allowedDates="allowedDates"
                        classes="px-0"
                        name="start_date"></CustomDatePicker>
                  </div>
                  <!-- only manual atm -->
                  <div class="mt-3" style="display: none">
                    <h5>Bestellauslösung <span v-if="model === 'c'">(intern)</span></h5>
                    <v-radio-group
                        :disabled="!isSuperadmin"
                        v-model="livingUnit.order_type"
                        class="mt-0"
                        @change="$store.dispatch('smartLogisticSettings/update',{livingUnit: livingUnit})"
                    >
                      <div>
                        <v-radio
                            label="Automatisch"
                            value="automatic"
                        />
                      </div>
                      <div class="mt-2">
                        <v-radio
                            label="Manuell"
                            value="manual">
                        </v-radio>
                      </div>
                    </v-radio-group>
                  </div>
                  <div class="mb-4">
                    <h5 class="label-style">Benachrichtigungen</h5>
                    <div>
                      <v-checkbox :true-value="1" :false-value="0"
                                  @change="$store.dispatch('smartLogisticSettings/update',{livingUnit: livingUnit})"
                                  label="Hinweis zur Bestellfreigabe per E-Mail"
                                  class="mt-0"
                                  v-model="livingUnit.notification_approval"></v-checkbox>
                    </div>
                    <div v-if="model !== 'c'" style="margin-top: -30px">
                      <v-checkbox :true-value="1" :false-value="0"
                                  @change="$store.dispatch('smartLogisticSettings/update',{livingUnit: livingUnit})"
                                  label="Bestellbestätigung per E-Mail"
                                  class="mt-2 checkbox-no-messages"
                                  v-model="livingUnit.notification_order">
                      </v-checkbox>
                    </div>
                  </div>
                  <div class="mt-0">
                    <v-combobox class="notemail" v-model="livingUnit.notification_emails"
                                label="E-Mail"
                                mails
                                @change="handleBlur(livingUnit)"
                                flat
                                :rules="emailRules"
                                multiple>
                      <template v-slot:selection="data">
                        <v-chip :input-value="data.selected"
                                small
                                text-color="black"
                                class="mb-2 secondary"
                                close
                                @click:close="remove(livingUnit,data.item)">
                          <strong>{{ data.item }}</strong>&nbsp;
                        </v-chip>
                      </template>
                      <template v-slot:append>
                        <v-tooltip right max-width="300" content-class="custom-tooltip">
                          <template v-slot:activator="{on, attrs}">
                             <span class="icon-informationen cursor-help"
                                   v-bind="attrs"
                                   v-on="on"></span>
                          </template>
                          <div>Falls dieses Feld leer gelassen wird, werden die Einstellungen aus der Wohneinheit
                            übernommen<span v-if="baseLivingUnitNotificationEmails(livingUnit.internalID)">: {{
                                baseLivingUnitNotificationEmails(livingUnit.internalID)
                              }}</span>
                          </div>
                        </v-tooltip>
                      </template>
                    </v-combobox>
                  </div>
                  <div v-if="model !== 'c'" class="mt-2">
                    <v-text-field
                        label="Abladestelle"
                        @keydown.enter="$store.dispatch('smartLogisticSettings/update',{livingUnit: livingUnit})"
                        @blur="$store.dispatch('smartLogisticSettings/update',{livingUnit: livingUnit})"
                        v-model="livingUnit.pick_up_point">
                      <template v-slot:append>
                        <v-tooltip right max-width="300" content-class="custom-tooltip">
                          <template v-slot:activator="{on, attrs}">
                             <span class="icon-informationen cursor-help"
                                   v-bind="attrs"
                                   v-on="on"></span>
                          </template>
                          <div>Falls dieses Feld leer gelassen wird, werden die Einstellungen aus der Wohneinheit
                            übernommen<span v-if="baseLivingUnitDeliveryInformation(livingUnit.internalID)">: {{
                                baseLivingUnitDeliveryInformation(livingUnit.internalID)
                              }}</span>
                          </div>
                        </v-tooltip>
                      </template>
                    </v-text-field>

                    <v-text-field
                        label="Information Spediteur"
                        @keydown.enter="$store.dispatch('smartLogisticSettings/update',{livingUnit: livingUnit})"
                        @blur="$store.dispatch('smartLogisticSettings/update',{livingUnit: livingUnit})"
                        v-model="livingUnit.delivery_information">
                      <template v-slot:append>
                        <v-tooltip right max-width="300" content-class="custom-tooltip">
                          <template v-slot:activator="{on, attrs}">
                             <span class="icon-informationen cursor-help"
                                   v-bind="attrs"
                                   v-on="on"></span>
                          </template>
                          <div>Falls dieses Feld leer gelassen wird, werden die Einstellungen aus der Wohneinheit
                            übernommen<span v-if="baseLivingUnitPickUpPoint(livingUnit.internalID)">: {{
                                baseLivingUnitPickUpPoint(livingUnit.internalID)
                              }}</span>
                          </div>
                        </v-tooltip>
                      </template>
                    </v-text-field>

                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import CustomDatePicker from '@/components/form/CustomDatePicker.vue'
import moment from 'moment'
import httpClient from "../../utils/httpClient";

export default {
  name: 'SmartLogisticsSettings',
  components: {CustomDatePicker},
  data() {
    return {
      oldModel: null,
      oldInterval: null,
      oldDate: null,
      emailRules: [
        v => this.validateEmails() || 'Keine gültige E-Mail-Adresse (mindestens eine Adresse wird so nicht gespeichert)'
      ]
    }
  },
  computed: {
    isSuperadmin() {
      return this.$store.state.benutzer.info.superadmin === 1
    },
    minDate() {
      return moment().format('YYYY-MM-DD')
    },
    model() {
      return this.$store.state.smartLogisticSettings.model
    },

  },
  methods: {
    baseLivingUnitNotificationEmails(id) {
      return this.$store.getters['smartLogisticSettings/baseLivingUnitNotificationEmailsById'](id)
    },

    baseLivingUnitPickUpPoint(id) {
      return this.$store.getters['smartLogisticSettings/baseLivingUnitPickUpPointById'](id)
    },

    baseLivingUnitDeliveryInformation(id) {
      return this.$store.getters['smartLogisticSettings/baseLivingUnitDeliveryInformationById'](id)
    },

    //allow only weekdays
    allowedDates: val => {
      const day = moment(val).day()
      return day !== 0 && day !== 6
    },

    remove(livingUnit, item) {
      let emails = livingUnit.notification_emails
      if (!this.isSuperadmin) {
        return
      }
      emails.splice(emails.indexOf(item), 1)
      emails = [...emails]
      this.$store.dispatch('smartLogisticSettings/update', {livingUnit: livingUnit})
    },
    handleBlur(livingUnit) {

      this.$store.dispatch('smartLogisticSettings/update', {livingUnit: livingUnit})

      /*
       let emails = livingUnit.notification_emails
      if (this.validateEmails(emails)) {
        this.$store.dispatch('smartLogisticSettings/update', {livingUnit: livingUnit})
      } else {
        emails.pop()
        emails = [...emails]
        this.$store.dispatch('smartLogisticSettings/update', {livingUnit: livingUnit})
      }*/
    },
    validateEmails(emails) {
      if (!emails || emails.length === 0) {
        return true
      }

      const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
      return emails.every(email => emailRegex.test(email))
    },

    saveOldInterval(interval) {
      this.oldInterval = interval
    },

    saveOldDate(date) {
      this.oldDate = date
    },

    saveOldModel(model) {
      this.oldModel = model
    },

    changeModel() {

      console.log(this.oldModel)

      let text = ''

      if(!this.oldModel || this.oldModel === 'a'){
        text = 'Möchten Sie das Care Pro Modell wirklich anpassen? Bestehende Plandaten werden nicht in die aktuelle Abrechnung übernommen. Nur effektive Bestellungen oder manuelle Einträge werden in Zukunft für die Abrechnung berücksichtigt.'
      }else{
        text = 'Möchten Sie das Care Pro Modell wirklich anpassen? Alle offenen Bestellvorschläge sowie offene Warenkörbe werden gelöscht'
      }

      this.$confirm(text, {
        title: 'Achtung',
        buttonTrueText: 'Ja',
        buttonFalseText: 'Abbrechen'
      }).then(async confirmed => {
        if (confirmed) {
          this.$store.dispatch('smartLogisticSettings/settings', {
            'call': 'logistics-model',
            'model': this.$store.state.smartLogisticSettings.model
          })

        } else {
          this.$store.state.smartLogisticSettings.model = this.oldModel
        }
      })
    },

    changeInterval(livingUnit, intervalNow) {

      if (!this.oldInterval) {
        this.$store.dispatch('smartLogisticSettings/update', {
          livingUnit: livingUnit,
          clearOpenBasketsAndSuggestions: true
        })

        this.oldInterval = livingUnit.interval
      } else {
        this.$confirm('Möchten Sie den Bestell-Intervall wirklich anpassen? Alle offenen Bestellvorschläge sowie offene Warenkörbe werden gelöscht', {
          title: 'Achtung',
          buttonTrueText: 'Ja',
          buttonFalseText: 'Abbrechen'
        }).then(async confirmed => {
          if (confirmed) {
            this.$store.dispatch('smartLogisticSettings/update', {
              livingUnit: livingUnit,
              clearOpenBasketsAndSuggestions: true
            })

          } else {
            livingUnit.interval = this.oldInterval
          }
        })
      }
    },

    changeDate(livingUnit) {
      if (!this.oldDate) {
        this.$store.dispatch('smartLogisticSettings/update', {
          livingUnit: livingUnit,
          clearOpenBasketsAndSuggestions: true
        })
        this.oldDate = livingUnit.start_date
      } else {
        this.$confirm('Möchten Sie das Startdatum wirklich anpassen? Alle offenen Bestellvorschläge sowie offene Warenkörbe werden gelöscht', {
          title: 'Achtung',
          buttonTrueText: 'Ja',
          buttonFalseText: 'Abbrechen'
        }).then(async confirmed => {
          if (confirmed) {
            this.$store.dispatch('smartLogisticSettings/update', {
              livingUnit: livingUnit,
              clearOpenBasketsAndSuggestions: true
            })

          } else {
            livingUnit.start_date = this.oldDate
          }
        })
      }
    }
  },
  mounted() {
    this.$store.dispatch('smartLogisticSettings/fetch')
  }
}

</script>
<style lang="scss">
.compact-calendar {
  padding: 0 !important;
}

.checkbox-no-messages {
  .v-messages {
    display: none;
  }
}

.label-style {
  line-height: 20px;
  letter-spacing: normal;
  font-size: 16px;
  transform: scale(0.75);
  transform-origin: left;
  font-weight: normal;
}
</style>
