<template>
  <div class="order-history-article align-center" :class="{'special': isSpecialItem(article)}">
    <div class="image" v-if="!isSpecialItem(article)">
      <ArticleImage :article="article"/>
    </div>

    <div class="name">
      <ArticleCaption :article="article" v-if="!isSpecialItem(article)"/>
      <span v-else>{{ article.name }}</span>
    </div>

    <div class="shipped" :data-mobile-label="cleanLineBreaks($t('sl.order-history.headers.shipped'))">
      <template v-if="article.doc_delivery_to_send">
        {{ article.doc_delivery_to_send }} / {{ article.doc_delivery_sent }}
      </template>
    </div>

    <div class="unit" :data-mobile-label="cleanLineBreaks($t('sl.order-history.headers.unit'))">
      {{ article.unit }}
    </div>

    <div class="quantity" :data-mobile-label="cleanLineBreaks($t('sl.order-history.headers.quantity'))">
      {{ article.quantity }}
    </div>

    <div class="price" :data-mobile-label="cleanLineBreaks($t('sl.order-history.headers.price'))">
      {{ getPrice(article) }}
    </div>

    <div class="row-total" :data-mobile-label="cleanLineBreaks($t('sl.order-history.headers.row-total'))">
      {{ getRowTotal(article) }}
    </div>

    <div class="status" :data-mobile-label="cleanLineBreaks($t('sl.order-history.headers.status'))">
      {{ article.doc_status ? article.doc_status : 'in Bearbeitung' }}
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import ArticleImage from '../../Article/ArticleImage.vue'
import ArticleCaption from '../../Article/ArticleCaption.vue'
import {cleanLineBreaks} from '@/utils/String'

export default {
  name: 'OrderHistoryArticle',
  components: {ArticleCaption, ArticleImage},

  props: {
    article: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters({
      images: 'bilder/produktbilder'
    })
  },
  data () {
    return {
      specialItemIds: ['logistic_surcharge','9931012']
    }
  },

  methods: {
    cleanLineBreaks,
    getPrice(article) {
      if (!article.price) {
        return ''
      }
      return Number(article.price).toFixed(2)
    },

    getRowTotal(article) {
      if (!article.quantity || !article.price) {
        return ''
      }
      return Number(article.quantity * article.price).toFixed(2)
    },

    isSpecialItem(article) {
      return this.specialItemIds.includes(article.internalID);
    }
  }
}
</script>
