import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from "@/views/Login";
import Sortimentsverwaltung from "@/views/Sortimentsverwaltung";
import Reports from "@/views/Reports";
import Wohneinheiten from "@/views/Wohneinheiten";
import Versorgungsplan from "@/views/Versorgungsplan.vue";
import {store} from "../store";
import {isLoggedIn} from "../utils/auth";
import Superadmin from "../views/Superadmin";
import Tooladmin from "../views/Tooladmin";
import Passwortreset from "../views/Passwortreset";
import Account from "../views/Account";
import ReportFilter from "../views/ReportFilter";
import UserManagement from "../views/SuperAdmin/UserManagement";
import Direktabrechnung from "../views/Direktabrechnung.vue";
import Bewohnerverwaltung from "@/views/Bewohnerverwaltung.vue";
import Settings from "@/views/Settings.vue";
import SmartLogistics from '@/views/SmartLogistics/SmartLogistics.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: {
      name: "login"
    }
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: "/neues-passwort",
    name: "passwortreset",
    component: Passwortreset,
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: "/reports",
    name: "Reports",
    component: Reports
  },
  {
    path: "/versorgungsplan",
    name: "Versorgungsplan",
    component: Versorgungsplan
  },
  {
    path: "/leistungsabrechnung",
    name: "leistungsabrechnung",
    component: ReportFilter
  },
  {
    path: "/wohneinheiten",
    name: "Wohneinheiten",
    component: Wohneinheiten
  },
  {
    path: "/bewohnerverwaltung",
    name: "Bewohnerverwaltung",
    component: Bewohnerverwaltung
  },
  {
    path: "/sortimentsverwaltung",
    name: "Sortimentsverwaltung",
    component: Sortimentsverwaltung
  },
  // {
  //   path: "/bestellvorschlag",
  //   name: "Bestellvorschlag",
  //   component: Bestellvorschlag
  // },
  {
    path: "/superadmin",
    name: "superadmin",
    component: Superadmin
  },
  {
    path: "/superadmin/user-management",
    name: "user-management",
    component: UserManagement
  },
  {
    path: "/settings",
    name: "settings",
    component: Settings
  },
  {
    path: "/tooladmin",
    name: "tooladmin",
    component: Tooladmin
  },
  {
    path: "/account",
    name: "account",
    component: Account
  },
  {
    path: "/direktabrechnung",
    name: "direktabrechnung",
    component: Direktabrechnung
  },

  {
    path: "/versorgung-bewohner",
    name: "smartlogistics",
    component: SmartLogistics
  }
]

const router = new VueRouter({
  routes,
  mode: 'history',
})
//protected routes -> wenn nicht "allowAnonymous: true" dann redirect auf login sofern keine authorisierung vorhanden ist
router.beforeEach((to, from, next) => {
  if (!to.meta.allowAnonymous && (store.state.benutzer.id === undefined || store.state.benutzer.id == 0  || !store.state.benutzer.id || !isLoggedIn())) {

    next({
      path: '/login',
      query: { redirect: to.fullPath }
    })
  }
  else {
    next()
  }
})



export default router
