<template>
  <v-col cols="12" class="mt-6 pb-2 b-bottom">
    <div class="order-history-article -header">
      <div class="label">
        <HeaderLabel :text="$t('sl.order-history.headers.label')"/>
      </div>

      <div v-if="!isPending" class="shipped">
        <HeaderLabel :text="$t('sl.order-history.headers.shipped')"/>
      </div>

      <div class="unit">
        <HeaderLabel :text="$t('sl.order-history.headers.unit')"/>
      </div>

      <div class="quantity">
        <HeaderLabel :text="$t('sl.order-history.headers.quantity')" add-classes="text-right"/>
      </div>

      <div class="price">
        <HeaderLabel :text="$t('sl.order-history.headers.price')" add-classes="text-right"/>
      </div>

      <div class="row-total">
        <HeaderLabel :text="$t('sl.order-history.headers.row-total')" add-classes="text-right"/>
      </div>

      <div class="status">
        <HeaderLabel :text="$t('sl.order-history.headers.status')"/>
      </div>
    </div>
  </v-col>
</template>
<script>
import HeaderLabel from '../../Design/HeaderLabel.vue'

export default {
  name: 'OrderHistoryArticleHeader',
  components: {HeaderLabel},
  props: {
    isPending: {
      type: Boolean,
      required: false
    }
  }
}
</script>

<style>

</style>
