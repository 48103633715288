export const inlineSearchMixin = {
    props: {
        residentFields: {
            type: Object,
            default: () => ({
                name: 'name',
                room: 'zimmer',
                id: 'internalID'
            })
        },
        articleFields: {
            type: Object,
            default: () => ({
                name: 'name',
                id: 'internalID'
            })
        },
        highlightClass: {
            type: String,
            default: 'highlightText'
        }
    },

    computed: {
        // These should be implemented by the component using the mixin
        searchableResident() {
            return null // Should be overridden
        },
        searchableArticles() {
            return null // Should be overridden
        },

        // Rest of the computed properties
        searchIsActive() {
            return this.$store.getters['inlineSearch/searchIsActive']
        },
        searchTerm() {
            return this.$store.getters['inlineSearch/searchTerm']
        },
        searchTarget() {
            return this.$store.getters['inlineSearch/searchTarget']
        },

        // Search matches
        searchMatches() {
            if (!this.searchIsActive) return true


            if (this.searchTarget === 'both') {
                return this.matchesResident || this.matchesArticles
            }

            return this.searchTarget === 'resident'
                ? this.matchesResident
                : this.matchesArticles
        },

        matchesResident() {

            if (!this.searchIsActive || !this.searchableResident) {
                return true
            }
            if (this.searchTarget !== 'resident' && this.searchTarget !== 'both') {
                return true
            }

            const nameField = this.residentFields.name
            const roomField = this.residentFields.room

            if (this.includesSearchTerm(this.searchableResident[nameField]) ||
                (this.searchableResident[roomField] && this.includesSearchTerm(this.searchableResident[roomField]))) {
                this.setExpandedState('resident', this.searchableResident[this.residentFields.id], true)

                return true
            }

            return false
        },

        matchesArticles() {
            if (!this.searchIsActive) {
                return true
            }
            if (this.searchTarget !== 'article' && this.searchTarget !== 'both') {
                return true
            }

            if (!this.searchableArticles) {
                return false
            }

            let isOpen = false

            return this.searchableArticles.some(article => {
                const nameField = this.articleFields.name
                const idField = this.articleFields.id


                if (this.includesSearchTerm(article[nameField]) ||
                    this.includesSearchTerm(article[idField])) {

                        if (!isOpen) {
                            this.setExpandedState('resident', this.searchableResident[this.residentFields.id], true)
                            this.setExpandedState('living-unit', this.searchableResident['wohneinheiten_id'], true)
                            isOpen = true
                        }

                    return true
                }

                return false
            })
        },

        // Highlighted fields
        highlightedResidentFields() {
            const fields = {}

            if (!this.searchableResident) {
                return fields
            }

            Object.keys(this.residentFields).forEach(key => {
                const fieldName = this.residentFields[key]
                const value = this.searchableResident[fieldName]

                if (this.searchTarget === 'article' && this.searchTarget !== 'both') {
                    fields[key] = value
                } else {
                    fields[key] = this.getHighlightedText(value)
                }

            })

            return fields
        },

        highlightedResidentName() {
            return this.highlightedResidentFields.name
        },

        highlightedResidentRoom() {
            return this.highlightedResidentFields.room
        },
    },

    methods: {
        setExpandedState(type, id, expandedState) {
            setTimeout(() => {
                this.$store.commit('tree/setExpandedState', {type, id, expandedState})
            }, 100)
        },

        highlightedArticleName(articleName) {

            if (this.searchTarget === 'resident' && this.searchTarget !== 'both') {
                return articleName
            }

            return this.getHighlightedText(articleName)
        },

        highlightedArticleId(articleID) {

            if (this.searchTarget === 'resident' && this.searchTarget !== 'both') {
                return articleID
            }

            return this.getHighlightedText(articleID)
        },

        includesSearchTerm(value) {
            try {
                return String(value).toLowerCase().includes(this.searchTerm.toLowerCase())
            } catch (e) {
                return false
            }
        },

        getHighlightedText(text) {
            if (!text || !this.searchIsActive || !this.searchTerm) {
                return text
            }

            try {
                const regex = new RegExp(`(${this.escapeRegExp(this.searchTerm)})`, 'gi')
                return String(text).split(regex).map((part) => {
                    if (part.toLowerCase() === this.searchTerm.toLowerCase()) {
                        return `<span class="${this.highlightClass}">${part}</span>`
                    }
                    return part
                }).join('')
            } catch (e) {
                return text
            }
        },

        escapeRegExp(string) {
            return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
        }
    }
}