<template>
  <Dialog :width="360" @open="getProductDetail">
    <template v-slot:button="{ on, attrs }">
      <span :class="linkClasses" v-bind="attrs" v-on="on">{{ linkText }}</span>
    </template>

    <template #header>
      <a v-if="!loading && !info['ownprod']" class="text-sm-body-2 pt-8 webshop-link"
         :href="'https://www.puras.ch/de/shop/p-'+info['artnr']" target="_blank">Produkt im
        Webshop</a>
    </template>

    <v-row class="pt-0 mt-0" v-if="!loading">
      <v-col>
        <v-img v-if="info['bild']" :src="info['bild']" width="100%" height="180px" max-width="140px"
               max-height="140px" class="mx-auto" contain="contain"></v-img>
        <div v-else style="min-height:140px;padding-top: 25px;">
          <img :src="require('@/assets/img/ico/sortimentsverwaltung-aktiv.svg')" width="100%" height="75px"
               max-width="80px"
               max-height="140px" class="mx-auto" contain="contain">
        </div>
        <div class="font-weight-bold text-sm-body-2 mt-3">
          <span>
              {{ info['artnr'] }}
          </span>
          <span>
              <ArticleMessageIndicator  :article="info"></ArticleMessageIndicator>
          </span>
        </div>
        <div class="product-name">
          <span>
             {{  info['name'] }}
          </span>
          <ul class="article-tags" v-if="info['careProLabel']">
            <li>{{ info['careProLabel'] }}</li>
          </ul>
        </div>
        <div class="pt-2 mt-1 mb-3 text-sm-body-2">
          {{ info['txt'] }}
        </div>
        <div class="pt-2 text-sm-body-2 font-weight-bold" v-if="info['kundenpreis']">
          <span v-if="info['uom']">{{ info['uom'] }} -</span> CHF {{ info['kundenpreis'] }}
          <span v-if="!info['ownprod']" class="font-weight-regular">exkl. MwSt.</span>
          <span v-else class="font-weight-regular">inkl. MwSt.</span>
        </div>

        <div v-if="info['isMigel']">
          <v-divider class="my-3" color="black"></v-divider>
          <div>
               <span class="text-sm-body-2 font-weight-bold">
                MiGeL
              </span>
            <span class="text-sm-body-2">
                 {{ info['migelNo'] }}
              </span>
          </div>
          <div>
               <span class="text-sm-body-2 font-weight-bold">
               Preis pro {{ info['zaehleinheit'] }}
              </span>
          </div>
          <div class="pl-4" v-if="!info['ownprod']">
               <span class="text-sm-body-2 font-weight-bold">
               HVB Pflege
              </span>
            <span class="text-sm-body-2">
                <span v-if="info['Pauschale'] > 0">
                 pro Jahr (pro rata)
                </span>
                <span v-if="info['Pauschale'] < 1 &&  info['Kategorie_A'] > 0">
                 nicht anwendbar
                </span>
                <span v-if="info['Pauschale'] < 1 && info['Kategorie_A'] < 1">
                   CHF {{ info['hvb'] }} <span class="font-weight-regular">inkl. MwSt.</span>
                </span>
              </span>
          </div>
          <div class="pl-4">
               <span class="text-sm-body-2 font-weight-bold">
               Kundenpreis
              </span>
            <span class="text-sm-body-2">
                 CHF {{ info['kundenstkpreis'] }} <span class="font-weight-regular">inkl. MwSt.</span>
              </span>
          </div>
        </div>
        <div v-if="info['gtin']">
            <span class="text-sm-body-2 font-weight-bold">
               GTIN
            </span>
          <span class="text-sm-body-2">
                 {{ info['gtin'] }}
            </span>
        </div>
        <div v-if="info['pharmacode']">
            <span class="text-sm-body-2 font-weight-bold">
               Pharmacode
            </span>
          <span class="text-sm-body-2">
                 {{ info['pharmacode'] }}
            </span>
        </div>
        <v-divider class="my-3" color="black"></v-divider>
        <template v-if="isCollective">
          <div class="text-sm-body-2 font-weight-bold">
            Gemeinschaftsartikel
          </div>
          <v-divider class="my-3" color="black"></v-divider>
        </template>
        <div class="text-sm-body-2" v-if="info['lastBuy'] && !info['ownprod']">
          zuletzt gekauft am: {{ toMoment(info['lastBuy']).locale('de').format('DD.MM. YYYY') }}
        </div>
        <div class="text-sm-body-2 mt-4">
          <v-row>
            <v-col cols="8">
              <div class="pt-4">
                  <span v-if=" info['lpnr']">
                     LeistungsNr. {{ info['lpnr'] }}
                  </span>
              </div>
            </v-col>
            <v-col cols="4">
                <span class="float-right">
                  <div class="float-left">
                     <img :src="require('@/assets/img/ico/anzahl-bewohner.svg')" class="mt-3" height="20px"
                          contain="contain">
                  </div>
                  <div class="font-weight-bold float-left ml-2">
                    <div style="margin-top:17px;">
                      ({{ info['usedBy'] }})
                    </div>
                  </div>
                </span>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
  </Dialog>
</template>

<script>
import Dialog from '@/components/SmartLogistics/Design/Dialog.vue'
import moment from "moment";
import httpClient from "@/utils/httpClient";
import ArticleMessageIndicator from "@/components/SmartLogistics/Article/ArticleMessageIndicator.vue";

export default {
  name: 'ArticleDetailDialog',
  components: {ArticleMessageIndicator, Dialog},
  props: {
    article: {
      type: Object,
      default: null
    },
    linkText: {
      type: String,
      default: "Mehr zum Produkt"
    },
    linkClasses: {
      type: String,
      default: "text-decoration-underline more-about"
    },
    noScroll: {
      type: Boolean,
      default: false
    }
  },
  emits: ['assignProduct'],

  data() {
    return {
      loading: true,
      info: null,
    }
  },

  computed: {
    isCollective: {
      get() {
        return this.$store.getters['articleSettings/isCollectiveByArticle'](this.article.objectID, (!!this.article.isCollective || this.article.collective));
      }
    },

  },

  methods: {
    async getProductDetail() {
      let prodId = this.article.objectID ?? this.article.artikelNrLAG ?? this.article.internalID;
      const res = await httpClient({
        url: process.env.VUE_APP_API_CHECKLIST + 'get/product-detail',
        method: 'post',
        data: {prodID: prodId}
      })
      if (res.type == 'success') {
        this.info = res.detail
        this.loading = false
      }
    },

    toMoment(date) {
      return moment(date);
    }
  },
}
</script>

<style lang="scss" scoped>
.more-about {
  display: inline-block;
  padding: 5px;
  margin: -5px;
  color: black;

  &:hover {
    color: #CE20A8 !important;
    border-color: #CE20A8 !important;
  }
}

.webshop-link {
  color: black;
}

.row {
  margin: 0 -12px;
}

</style>
