<template>
  <v-col cols="12" class="px-0">
    <v-row class="b-bottom mx-0">
      <v-col sm="12" md="8" lg="8" xl="8" class="d-flex pa-0">
        <div class="d-flex justify-end pb-2 h-full pr-3 pt-3">
          <HeaderLabel text="Bezeichnung"></HeaderLabel>
        </div>
      </v-col>

      <v-col sm="12" md="3" lg="3" xl="3" class="pa-0">
        <div :class="planModel === 'b' ? 'rounded-t-lg leightbeige pr-4' : 'pr-8'" class="d-flex justify-end pb-2 h-full">
          <HeaderLabel text="Menge" add-classes="text-right"></HeaderLabel>
        </div>
      </v-col>

      <v-col v-if="planModel !== 'b'" sm="12" md="2" lg="2" xl="2" class="pa-0">
        <div class="rounded-tl-lg leightbeige pl-4 pb-2 d-flex h-full justify-end pr-6">
          <HeaderLabel text="Bestellung anpassen"></HeaderLabel>
        </div>
      </v-col>

      <v-col v-if="planModel !== 'b'" sm="12" md="2" lg="3" xl="3" class="pa-0">
        <div class="leightbeige rounded-tr-lg d-flex pb-2  h-full pr-4">
          <HeaderLabel text="Verpackungseinheit"></HeaderLabel>
        </div>
      </v-col>

      <v-col sm="12" md="2" lg="1" xl="1" class="pa-0 pl-3">
        <div class="beige rounded-t-lg d-flex justify-end h-full pb-2 pr-3">
          <HeaderLabel text="Preis" add-classes="text-right"></HeaderLabel>
        </div>
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
import HeaderLabel from '../../Design/HeaderLabel.vue'

export default {
  name: 'OrderBasketHeader',
  components: {HeaderLabel},

  computed: {
    planModel() {
      return this.$store.getters['smartLogisticSettings/planModel']
    },
  }

}
</script>

<style>

</style>
