<template>
  <transition name="fade">
    <div>
      <template v-if="isLoading">
        <v-progress-circular
            class="mt-2 mb-8"
            color="primary"
            indeterminate
        />
      </template>

      <BasketConfirmedCard v-else-if="['confirmed', 'processing'].includes(basket.status)" :unit="unit"/>

      <div v-else class="mb-12">
        <template v-if="articles.length === 0">
          <div class="font-weight-bold align-self-end">
            {{ $t('sl.basket.empty') }}
          </div>
        </template>
        <template v-else>
          <OrderBasketHeader/>

          <template v-for="article in articles">
            <BasketArticle :article="article" :is-editable="isBasketEdtiable"/>
          </template>

          <BasketTotals/>
          <template v-if="isBasketEdtiable || ((['draft', 'edited'].includes(basket.status)) && this.$store.state.benutzer.info.superadmin == 1)">
            <BasketAdditionalInfos/>

            <v-row>
              <v-col cols="9">
                <BasketAGB v-model="termsAccepted"/>
              </v-col>
              <v-col cols="3">
                <BasketOrderButton
                    :disabled="!termsAccepted"
                    :date-object="cartDate"
                    :direct-order="directOrder"
                    :unit="unit"
                />
              </v-col>
            </v-row>
          </template>
        </template>
      </div>
    </div>
  </transition>
</template>

<script>

import BasketArticle from './BasketArticle.vue'
import OrderBasketHeader from './OrderBasketHeader.vue'
import BasketTotals from './BasketTotals.vue'
import BasketAdditionalInfos from './BasketAdditionalInfos.vue'
import BasketAGB from './BasketAGB.vue'
import BasketOrderButton from './BasketOrderButton.vue'
import {mapGetters} from 'vuex'
import BasketConfirmedCard from '@/components/SmartLogistics/Order/Basket/BasketConfirmedCard.vue'
import is from "vue2-datepicker/locale/es/is";

export default {
  name: 'Basket',
  components: {
    BasketConfirmedCard,
    BasketOrderButton,
    BasketAGB,
    BasketAdditionalInfos,
    BasketTotals,
    OrderBasketHeader,
    BasketArticle
  },

  props: {
    unit: {
      type: Object,
      required: true
    },
    directOrder: {
      type: Boolean,
      required: false,
      default: false,
    }
  },

  data() {
    return {
      termsAccepted: false
    }
  },

  computed: {
    is() {
      return is
    },
    ...mapGetters({
      slIsLoading: 'smartLogisticBasket/isLoading',
      basket: 'smartLogisticBasket/basket',
      isBasketEdtiable: 'smartLogisticOrderSuggestion/isBasketEditable'
    }),

    isLoading() {
      return this.slIsLoading || !this.basket
    },
    articles() {
      return this.basket.items
    },

    cartDate() {
      return this.$store.getters['smartLogisticOrderSuggestion/chosenDateObject']
    }
  },

  mounted() {
    if (this.directOrder) {
      this.$store.dispatch('smartLogisticBasket/fetchDirectOrderBasket', {unit: this.unit.internalID})
    } else {
      this.$store.dispatch('smartLogisticBasket/fetch', {unit: this.unit.internalID, dateObject: this.cartDate})
    }
  }
}
</script>
