<template>
  <v-card
      class="rounded-lg p-4 planner-article"
      style="height: 100%; "
  >
    <v-card-text class="pa-8">
      <div class="remove-article" v-if="showDeleteButton">
        <ConfirmDialog
            title="Wollen Sie dieses Produkt wirklich entfernen?"
            @confirm="removeArticle"
        >
          Achtung: Alle Einträge im aktuellen Monat werden gelöscht und erscheinen nicht mehr in den Abrechnungsdaten. Einträge in der Vergangenheit bleiben bestehen.
          <template #button>
            <div class="icon-loeschen"></div>
          </template>

          <template v-slot:confirm="slotProps">
            <v-btn
                depressed
                color="primary"
                @click="slotProps.confirm"
            >
              Entfernen
            </v-btn>
          </template>
        </ConfirmDialog>
      </div>
      <div class="reset-article" v-if="showReset">
        <ConfirmDialog
            title="Zählstand zurücksetzen?"
            @confirm="resetArticle"
        >
          <template #button>
            <div class="icon-radiergummi"></div>
          </template>

          <template v-slot:confirm="slotProps">
            <v-btn
                depressed
                color="primary"
                @click="slotProps.confirm"
            >
              zurücksetzen
            </v-btn>
          </template>
          Möchten Sie alle Einträge dieses Monats auf dem Produkt löschen? Das Produkt selber bleibt bestehen.
        </ConfirmDialog>
      </div>

      <v-row>
        <v-col cols="5" class="my-0 py-0">
          <ArticleImage :article="article" :height="70"/>
        </v-col>
        <v-col cols="7" class="my-0 py-0 pt-1 relative">
          <div class="body-2 heavy">
            <span v-html="highlightedArticleId(article.showID)" class="body-2 heavy">
            </span>
            <span>
                <ArticleMessageIndicator  :article="article"></ArticleMessageIndicator>
            </span>
          </div>
          <div v-html="highlightedArticleName(article.name)">
          </div>
          <div class="body-2 caption mt-1" style="font-size: 11px !important;">
            {{ article.zaehleinheit }}
          </div>
          <ul class="article-tags" v-if="tags.length > 0">
            <li v-for="tag in tags">{{ tag }}</li>
          </ul>
          <div class="collective-article-indicator d-flex" v-if="collectiveMode && !isLivingUnit && isCollectiveArticle">
            <v-tooltip left max-width="390" content-class="custom-tooltip">
              <template v-slot:activator="{ on, attrs }">
              <span class="icon-gemeinschaftsartikel cursor-help"
                    v-bind="attrs"
                    v-on="on"></span>
              </template>
              <span>{{ $t('sl.collectiveArticles.generalInfo') }}</span>
            </v-tooltip>
          </div>
        </v-col>
        <v-row class="mb-0 mt-3 pt-2 interval-box" v-if="!collectiveMode || (!isLivingUnit || (isLivingUnit && !isCollectiveArticle))">
          <v-col
              :cols="8"
              class="mb-0 pt-2 pb-3 bg-sandgrey rounded-lg"
              :class="{'bg-lightorange': article.inkosync, 'bg-darkergrey': article.currPlandaten.intervall == 0}"
          >
            <v-row class="px-2">
              <v-col cols="8" class="px-2 py-3">
                <v-select style="font-size: 14px;"
                          v-if="!article.inkosync"
                          v-model="article.currPlandaten.intervall"
                          @change="updateInterval"
                          :items="intervals"
                          label="Interval"
                          item-text="name"
                          item-value="key"
                />
                <template v-else>
                  <div class="inko-pro-sync d-flex flex-row">
                    <span class="d-flex icon-synchronisation"></span>
                    <span class="d-flex">Inko Pro Synchronisation</span>
                  </div>
                </template>
              </v-col>
              <v-col :cols="4" class="px-2 py-3">
                <v-text-field
                    v-if="article.currPlandaten.intervall != 0"
                    v-model="article.currPlandaten.count"
                    @change="updateInterval"
                    :rules="intervalRules"
                    oninput="validity.valid||(value='');"
                    :validate-on-blur="true"
                    min="1"
                    max="1000"
                    label="Anzahl"
                    class="text-right"
                    type="number"
                    style="font-size: 14px;"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="3" class="py-0 pt-2 mb-0 px-0 relative">
            <span class="body-2 caption mb-2 d-block text-center" style="font-size: 11px !important;">Zählstand</span>
            <span class="font-weight-light d-block text-center" style="font-size: 32px;">
              {{ count ?? 0 }}
            </span>
            <v-tooltip top class="body-2 py-1 px-2" v-if="showCountingUp">
                                                      <template v-slot:activator="{ on, attrs }">
                                                        <v-hover v-slot="{ hover }" open-delay="0">
                                                          <v-img v-bind="attrs" v-on="on"
                                                                 @click="countingUp"
                                                                 class="mt-5 ml-4 counting-up cursor-pointer"
                                                                 :src="require('@/assets/img/ico/plus-aktiv.svg')" alt="" width="20"
                                                                 height="20"></v-img>
                                                        </v-hover>
                                                      </template>
                                                      <span style="font-size: 13px;">+1 für heute, {{ moment().format('D.M') }}, hinzufügen </span>
                                                    </v-tooltip>
          </v-col>
        </v-row>

      </v-row>
      <div class="collective-article-wrapper" v-if="collectiveMode && isLivingUnit && isCollectiveArticle">
        <v-tooltip left max-width="390" content-class="custom-tooltip">
          <template v-slot:activator="{ on, attrs }">
              <span class="icon-gemeinschaftsartikel cursor-help"
                    v-bind="attrs"
                    v-on="on"></span>
          </template>
          <span>{{ $t('sl.collectiveArticles.tooltipPlanner_' + $store.state.smartLogisticSettings.model) }}</span>
        </v-tooltip>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import ArticleImage from '@/components/SmartLogistics/Article/ArticleImage.vue'
import ArticleMessageIndicator from '@/components/SmartLogistics/Article/ArticleMessageIndicator.vue'
import {consumptionMixin} from '@/mixins/SmartLogistics/consumption'
import {intervalMixin} from '@/mixins/SmartLogistics/intverval'
import {mapGetters} from 'vuex'
import ConfirmDialog from '@/components/shared/ConfirmDialog.vue'
import {inlineSearchMixin} from "../../mixins/SmartLogistics/inlineSearchMixin"
import moment from 'moment'

export default {
  name: 'ArticlePlanner',
  components: {ConfirmDialog, ArticleImage, ArticleMessageIndicator},
  mixins: [consumptionMixin, intervalMixin, inlineSearchMixin],
  emits: ['remove', 'updateInterval', 'countingUp', 'reset'],

  props: {
    entityId: {
      type: Number,
      required: false,
    },
    article: {
      type: Object,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
    showCountingUp: {
      type: Boolean,
      required: false,
      default: false
    },
    showDelete: {
      type: Boolean,
      required: false,
      default: true
    },
    showReset: {
      type: Boolean,
      required: false,
      default: false
    },
    collectiveMode: {
      type: Boolean,
      required: false,
      default: true
    },
    type: {
      type: String,
      required: false,
      default: 'resident',
      validator: function (value) {
        return ['resident', 'living-unit'].indexOf(value) !== -1
      }
    },
  },


  data() {
    return {
      moment: moment,
      intervals: [
        {
          key: 0,
          name: 'kein Intervall'
        },
        {
          key: 1,
          name: 'täglich'
        }, {
          key: 7,
          name: 'wöchentlich'
        }, {
          key: 28,
          name: 'monatlich'
        }
      ],
      intervalRules: [
        v => !!v || '',
        v => (v && v >= 1) || '',
        v => (v && v <= 999) || '',
      ],
    }
  },

  computed: {
    ...mapGetters({
      date: 'smartLogisticSettings/selectedDate',
      consumptionData: 'tree/consumptionData',
      effectiveConsumptionData: 'tree/effectiveConsumptionData',
      images: 'bilder/produktbilder'
    }),

    isLivingUnit() {
      return this.type === 'living-unit'
    },

    isCollectiveArticle() {
      return this.article.collective
    },

    consumption() {
      return this.consumptionData(this.date.format('YYYY-MM-DD'))
    },

    effectiveConsumption() {
      return this.effectiveConsumptionData(this.date.format('YYYY-MM-DD'))
    },

    articleConsumption() {
      return this.residentArticleConsumption(this.entityId, this.article.internalID)
    },

    articleEffectiveConsumption() {
      return this.residentEffectiveArticleConsumption(this.entityId, this.article.internalID)
    },

    articleData() {
      return this.residentArticleData(this.entityId, this.article.internalID)
    },

    articlePlandata() {
      return this.residentArticlePlanData(this.entityId, this.article.internalID)
    },

    showDeleteButton() {
      return this.showDelete && (!this.collectiveMode || (!this.isLivingUnit || (this.isLivingUnit && !this.isCollectiveArticle)))
    },

    tags() {
      const tags = []

      if (this.article.CarePro_Abrechnung_DE) {
        tags.push(this.article.CarePro_Abrechnung_DE)
      }

      return tags
    }
  },

  methods: {
    updateInterval() {
      this.$emit("updateInterval");
    },

    removeArticle() {
      this.$emit("remove");
    },
    resetArticle() {
      this.$emit("reset");
    },
    countingUp() {
      this.$emit("countingUp");
    }
  }
}
</script>
<style lang="scss" scoped>
.remove-article, .reset-article {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  transition: color 200ms ease-in-out;
  z-index: 9;

  &:hover {
    color: #CE20A8;
  }
}

.collective-article-indicator {
  position: absolute;
  right: 0;
  bottom: 0;
}

.collective-article-wrapper {
  position: absolute;
  bottom: 15px;
  right: 25px;

  .icon-gemeinschaftsartikel {
    font-size: 30px;
  }

}

.inko-pro-sync {
  gap: 12px;

  span {
    line-height: 20px;
  }
}

.article-tags {
  display: inline-block;
}

.relative {
  position: relative;
}

.counting-up {
  position: absolute;
  right: -5px;
  top: 15px;
}
</style>
