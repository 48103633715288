const activeCards = (carts, showType) => {
    return carts.filter(c => isCartActive(c, showType))
}

const isCartActive = (cart, showType) => {
    return cart.status === 'prepared' && (showType === 'krankenkasse' && cart.KrankenKassenTotalVerrechenbar > 0 && cart.validation.length == 0)  /*|| (showType === 'bewohner' && cart.BewohnerTotalVerrechenbar > 0)  || (showType === 'company' && cart.BetriebsmaterialTotal > 0)*/
}

export {
    activeCards,
    isCartActive
}