const orderSuggestionStatIcon = (state, status) => {

    if (['current', 'past'].includes(state) && (['completed', 'order_confirmed'].includes(status))) {
        return 'icon-warenkorb-heakchen neutral'
    }
    if (['past'].includes(state)) {
        return 'icon-ausrufezeichen-ausgefuellt highlight'
    }

    if (state === 'future') {
        return 'icon-sanduhr'
    }

    return 'icon-warenkrob highlight'
}

const orderSuggestionStatColor = (state, status) => {

    if (['current', 'past'].includes(state) && (['completed', 'order_confirmed'].includes(status))) {
        return '#D3C1B2'
    }

    return '#959595'
}

export {orderSuggestionStatIcon, orderSuggestionStatColor}
