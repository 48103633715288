import Vue from 'vue'
import Vuex from 'vuex'
import {system} from './modules/system'
import {loader} from './modules/loader'
import {benutzer} from './modules/benutzer'
import {bilder} from './modules/bilder'
import {direktabrechnung} from './modules/direktabrechnung'
import {tree} from './modules/tree'
import {smartLogisticSettings} from './modules/smartLogisticSettings'
import {simpleOrderSuggestion} from './modules/simpleOrderSuggestion'
import {smartLogisticHistory} from './modules/smartLogisticHistory'
import {smartLogisticOrderSuggestion} from './modules/smartLogisticOrderSuggestion'
import {smartLogisticBasket} from './modules/smartLogisticBasket'
import {smartLogisticDirectOrder} from './modules/smartLogisticDirectOrder'
import {articleSettings} from './modules/articleSettings'
import {articles} from './modules/articles'
import {prescription} from '@/store/modules/prescription'
import {inlineSearch} from "./modules/inlineSearch";
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export const store = new Vuex.Store({
    plugins: [createPersistedState({
        paths: ['benutzer']
    })],
    state: {},
    modules: {
        system,
        loader,
        benutzer,
        bilder,
        direktabrechnung,
        tree,
        smartLogisticSettings,
        smartLogisticHistory,
        smartLogisticOrderSuggestion,
        smartLogisticBasket,
        smartLogisticDirectOrder,
        simpleOrderSuggestion,
        articleSettings,
        articles,
        prescription,
        inlineSearch,
    }
})
