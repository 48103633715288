<template>
  <div>
    <slot v-bind:eventHandlers="eventHandlers" v-bind:loading="loading">
      <span @click="download($event)">download</span>
    </slot>
  </div>
</template>

<script>
import httpClient from '@/utils/httpClient'

export default {
  name: 'PrintPdf',

  props: {
    uri: {
      type: String,
      required: true
    },
    filename: {
      type: String,
      required: true
    },
  },

  data() {
    return {
      loading: false,
      eventHandlers: {
        click: this.download
      }
    }
  },

  methods: {
    download(event) {
      event.stopPropagation();
      this.loading = true;
      httpClient.get(process.env.VUE_APP_API_LOGISTICS + 'print/' + this.uri, {
        responseType: 'arraybuffer',
      })
          .then((response) => {
            const blob = new Blob([response], { type: 'application/pdf' });
            if (navigator.msSaveBlob) {
              navigator.msSaveBlob(blob, this.filename);
            } else {
              const link = document.createElement('a');
              if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', this.filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }
            }
          })
          .finally(() => {
            // Optional: Add any final steps here
            this.loading = false;
          });
    }
  }
}
</script>
