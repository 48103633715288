import httpClient from '../../utils/httpClient'

export const articles = {
    namespaced: true,

    state: {
        categoriesSortArray: [],
        articleInfos: [],
        mySortiment: [],
    },

    getters: {},

    actions: {
        async fetchCategoriesSortArray({commit, state}, params) {
            const data = await httpClient({
                url: process.env.VUE_APP_API_SHARED + 'get/categories-sort-array',
                method: 'get'
            })

            if (data.type == 'success') {
                commit('setCategoriesSortArray', data.categories)
            }
        },

        async fetchArticleInfos({commit, state}, params) {
            const data = await httpClient({
                url: process.env.VUE_APP_API_SHARED + 'get/prodinfo',
                method: 'get'
            })
            if (data.type == 'success') {
                commit('setArticleInfos', data.infos)
            }
        },
        async fetchMySortiment({commit, state}, params) {

            const data = await httpClient({
                url: process.env.VUE_APP_API_SHARED + 'get/mein-sortiment',
                method: 'get'
            })

            if (data.type == 'success') {
                commit('setMySortiment', data.mysortiment)
            }
        }
    },

    mutations: {
        setCategoriesSortArray(state, val) {
            state.categoriesSortArray = val;
        },
        setArticleInfos(state, val) {
            state.articleInfos = val;
        },
        setMySortiment(state, val) {
            state.mySortiment = val;
        },
    }
}
