<template>
  <v-col cols="12" class="mt-6 pb-2 b-bottom">
    <div class="order-suggestion-article -header">
      <div class="image">
        <HeaderLabel :text="$t('sl.order-suggestion.headers.label')"></HeaderLabel>
      </div>

      <div class="last-order" v-if="(owner === 'living-unit' || !collective)">
        <HeaderLabel :text="$t('sl.order-suggestion.headers.last-order')"/>
      </div>
      <div class="unit">
        <HeaderLabel :text="$t('sl.order-suggestion.headers.unit')"/>
      </div>
      <div class="single-unit">
        <HeaderLabel
            v-if="owner === 'living-unit' && collective"
            :text="$t('sl.order-suggestion.headers.single-unit')"
        />
      </div>

      <div class="suggestion">
        <template v-if="owner === 'resident' && collective">
          <HeaderLabel
              :text="$t('sl.order-suggestion.headers.suggestion-resident')"
          />
        </template>
        <template v-else>
          <HeaderLabel
              :text="$t('sl.order-suggestion.headers.suggestion-living-unit')"
          />
        </template>
      </div>

      <div class="quantity">
        <template v-if="owner === 'resident' && collective">
          <HeaderLabel
              :text="$t('sl.order-suggestion.headers.quantity-resident')"
          />
        </template>
        <template v-else>
          <HeaderLabel
              :text="$t('sl.order-suggestion.headers.quantity-living-unit')"
          />
        </template>
      </div>
    </div>
  </v-col>
</template>
<script>
import HeaderLabel from '../../Design/HeaderLabel.vue'

export default {
  name: 'OrderSuggestionArticleHeader',
  components: { HeaderLabel },

  props: {
    owner: {
      type: String,
      required: true
    },
    collective: {
      type: Boolean,
      required: true
    }
  }

}
</script>
