<template>
  <Layout>
    <ExpansionPanels v-if="isTreeReady">
      <ExpansionPanel
          v-for="(unit, unitIndex) in tree.children"
          :key="unit.internalID"
          :unit="unit"
          :detail-view-is-basket="detailViewIsBasket"
          :is-direct-order="directOrderActive"
          @selectedUnitChange="changeSelectedUnit($event)"
      >

        <!-- Headers -->
        <template #name>
          {{ unit.name }}
        </template>

        <template #notification>
          <div v-if="showBasketChangedMessage(unit) && planModel !== 'b'" class="d-flex">
            <div class="mr-3">
              <span class="icon-achtung-hinweis-ausgefuellt red--text" style="font-size: 18px"></span>
            </div>
            <div>
              <b>Achtung: </b>Im Warenkorb wurden Mengen angepasst. Er wird nun nicht mehr durch Veränderungen im
              Bestellvorschlag aktualisiert. Klicken Sie dazu im Warenkorb auf "Neu laden".
            </div>
          </div>
          <div v-if="showOrderSuggestionChangedMessage(unit)" class="d-flex" style="margin-top: -8px">
            <div class="mr-3">
              <span class="icon-achtung-hinweis-ausgefuellt red--text" style="font-size: 18px">

              </span>
            </div>
            <div>
              Der <b>Bestellvorschlag</b> wurde bearbeitet. Es werden nun keine Anpassungen mehr übernommen, die im <b>Versorgungsplan</b>
              gemacht werden. Klicken Sie dazu im Bestellvorschlag auf <b>«Bestellvorschlag neu laden»</b>.
            </div>
          </div>
        </template>

        <template #info>
          <template v-if="sectionIsOrder">
            <OrderSuggestionState v-if="orderStates" :living-unit="unit.internalID"/>
          </template>
        </template>

        <TitleSection
            :search-is-active="searchIsActive"
            :section-is-order="sectionIsOrder"
            :is-direct-order="directOrderActive"
            :detail-view-is-order-suggestion="detailViewIsOrderSuggestion"
            :detail-view-is-direct-order="detailViewIsDirectOrder"
            :detail-view-is-basket="detailViewIsBasket"
            :detail-view-is-direct-order-basket="detailViewIsDirectOrderBasket"
            :detail-view-is-history="detailViewIsHistory"
            :detail-view-is-care-plan="detailViewIsCarePlan"
            :order-view-mode-is-resident="orderViewModeIsResident"
            :order-view-mode-is-basket="orderViewModeIsBasket"
            :unit="unit"
        />
        <!-- Living Units -->
        <template
            v-if="(detailViewIsOrderSuggestion || detailViewIsCarePlan || detailViewIsDirectOrder) && !orderLoading">
          <div class="expand-all-wrapper d-flex mb-2">
            <div class="d-block text-left" style="flex-grow: 1; margin-top: -15px">
              <InlineSearch/>
            </div>
            <div style="align-self: flex-end" v-if="detailViewIsCarePlan">
              <div @click="openAllExpanded(unit)" v-if="showExpandAllToggle(unit)" class="expand-all-toggle mb-2">
                Alle ausklappen
              </div>
              <div @click="closeAllExpanded(unit)" v-if="!showExpandAllToggle(unit)" class="expand-all-toggle mb-2">
                Alle einklappen
              </div>
            </div>
          </div>
          <v-lazy>
            <UnitLevel
                :unit-level="unit"
                :current-section="currentSection"
                :direct-order-active="directOrderActive"
            >

              <template #articleHeader>
                <template v-if="detailViewIsOrderSuggestion">
                  <OrderSuggestionArticleHeader owner="living-unit" :collective="false"/>
                </template>
                <template v-if="detailViewIsDirectOrder">
                  <DirectOrderHeader/>
                </template>
              </template>

              <template #article="{ article }">
                <v-col
                    v-if="detailViewIsOrderSuggestion"
                    cols="12"
                    class="b-bottom"
                >
                  <OrderSuggestionArticle
                      :article="article"
                      :collective="false"
                      owner-type="living-unit"
                      :owner-id="unit.internalID"
                  />
                </v-col>

                <v-col cols="12" v-if="detailViewIsDirectOrder">
                  <DirectOrderArticle :article="article"/>
                </v-col>

                <v-col md="6" lg="4" xl="3" cols="12" v-if="detailViewIsCarePlan">
                  <ArticlePlanner
                      :entity-id="unit.internalID"
                      :article="article"
                      type="living-unit"
                  />
                </v-col>
              </template>

              <template #collectiveArticleInfo>
                <template v-if="detailViewIsOrderSuggestion">
                  <OrderSuggestionCollectiveArticleInfo/>
                </template>
              </template>

              <template #collectiveArticleHeader>
                <template v-if="detailViewIsOrderSuggestion">
                  <OrderSuggestionArticleHeader owner="living-unit" :collective="true"/>
                </template>
              </template>

              <template #collectiveArticle="{ article }">
                <v-col
                    v-if="detailViewIsOrderSuggestion"
                    cols="12"
                    class="b-bottom"
                >
                  <OrderSuggestionArticle :article="article" :collective="true" owner-type="living-unit"
                                          :owner-id="unit.internalID"/>
                </v-col>
                <v-col md="6" lg="4" xl="3" cols="12" v-if="detailViewIsCarePlan">
                  <ArticlePlanner
                      :entity-id="unit.internalID"
                      :article="article"
                      type="living-unit"
                  />
                </v-col>
              </template>
            </UnitLevel>
          </v-lazy>
        </template>

        <!-- Residents -->
        <template
            v-if="(detailViewIsOrderSuggestion || detailViewIsCarePlan || detailViewIsDirectOrder) && !orderLoading">
          <v-lazy
              v-for="resident in unit.children"
              :key="resident.internalID"
          >
            <Resident
                :current-section="currentSection"
                :resident="resident"
                :direct-order-active="directOrderActive"
            >
              <template #articleHeader>
                <template v-if="detailViewIsOrderSuggestion">
                  <OrderSuggestionArticleHeader owner="resident" :collective="false"/>
                </template>
                <template v-if="detailViewIsDirectOrder">
                  <DirectOrderHeader/>
                </template>
              </template>

              <template #article="{ article }">
                <v-col
                    v-if="detailViewIsOrderSuggestion"
                    cols="12"
                    class="b-bottom"
                >
                  <OrderSuggestionArticle
                      :article="article"
                      :collective="false"
                      owner-type="resident"
                      :owner-id="resident.internalID"
                  />
                </v-col>

                <v-col cols="12" v-if="detailViewIsDirectOrder">
                  <DirectOrderArticle :article="article"/>
                </v-col>

                <v-col md="6" lg="4" xl="3" cols="12" v-if="detailViewIsCarePlan">
                  <ArticlePlanner
                      :entity-id="resident.internalID"
                      :article="article"
                      @requiredLivingUnitReload="reloadLivingUnit(unit.internalID)"
                  />
                </v-col>
              </template>

              <template #collectiveArticleInfo>
                <template v-if="detailViewIsOrderSuggestion">
                  <OrderSuggestionCollectiveArticleInfo/>
                </template>
              </template>

              <template #collectiveArticleHeader>
                <template v-if="detailViewIsOrderSuggestion">
                  <OrderSuggestionArticleHeader owner="resident" :collective="true"/>
                </template>
              </template>

              <template #collectiveArticle="{ article }">
                <v-col cols="12" class="b-bottom" v-if="detailViewIsOrderSuggestion">
                  <OrderSuggestionArticle
                      :article="article"
                      :collective="true"
                      owner-type="resident"
                      :owner-id="resident.internalID"
                  />
                </v-col>
              </template>

            </Resident>
          </v-lazy>
        </template>

        <v-btn @click="setViewModeToBasket()" class="mt-8 float-end" color="primary" depressed medium
               v-if="detailViewIsOrderSuggestion && isExpanded(unit) && !suggestionsLoading">
          <div>
            Zum Warenkorb
          </div>
        </v-btn>

        <!-- Order / Basket -->
        <template v-if="(detailViewIsBasket && !suggestionsLoading && isExpanded(unit))">
          <div>
            <Basket :unit="unit" :key="'basket_'+unit.internalID+'_'+Date.now()"/>
          </div>
        </template>

        <template v-if="(detailViewIsDirectOrderBasket && !suggestionsLoading  && isExpanded(unit))">
          <div>
            <Basket :unit="unit" :direct-order="true" :key="'direct_'+unit.internalID+'_'+Date.now()"/>
          </div>
        </template>

        <!-- Order History -->
        <template v-if="detailViewIsHistory  && isExpanded(unit)">
          <div>
            <History :unit="unit"/>
          </div>
        </template>

        <template v-if="suggestionsLoading || directOrderLoading">
          <div>
            <v-progress-circular
                class="mt-2 mb-8"
                color="primary"
                indeterminate
            />
          </div>
        </template>
      </ExpansionPanel>
    </ExpansionPanels>
    <v-progress-circular
        v-else
        color="primary"
        indeterminate
    />
    <TimelineDialog/>
  </Layout>
</template>
<script>
import Layout from '@/components/SmartLogistics/Layout.vue'
import ExpansionPanels from '@/components/SmartLogistics/ExpansionPanel/ExpansionPanels.vue'
import ExpansionPanel from '@/components/SmartLogistics/ExpansionPanel/ExpansionPanel.vue'
import {mapGetters} from 'vuex'
import Resident from '@/components/SmartLogistics/Resident/Resident.vue'
import ArticlePlanner from '@/components/SmartLogistics/Article/ArticlePlanner.vue'
import UnitLevel from '@/components/SmartLogistics/UnitLevel/UnitLevel.vue'
import OrderSuggestionArticle from '@/components/SmartLogistics/Order/Suggestion/OrderSuggestionArticle.vue'
import TimelineDialog from '@/components/SmartLogistics/Timeline/TimelineDialog.vue'
import IntervalTextLabel from '../../components/SmartLogistics/UnitLevel/IntervalTextLabel.vue'
import OrderSuggestionArticleHeader
  from '../../components/SmartLogistics/Order/Suggestion/OrderSuggestionArticleHeader.vue'
import BasketArticle from '../../components/SmartLogistics/Order/Basket/BasketArticle.vue'
import OrderHistoryPills from '../../components/SmartLogistics/Order/OrderHistoryPills.vue'
import OrderResidentBasketSelector from '../../components/SmartLogistics/Order/OrderResidentBasketSelector.vue'
import Basket from '../../components/SmartLogistics/Order/Basket/Basket.vue'
import History from '../../components/SmartLogistics/Order/History/History.vue'
import DirectOrderSwitch from '../../components/SmartLogistics/Order/DirectOrderSwitch.vue'
import ArticlePickerDialog from '@/components/SmartLogistics/Article/ArticlePickerDialog.vue'
import DirectOrderHeader from '../../components/SmartLogistics/Order/Direct/DirectOrderHeader.vue'
import DirectOrderArticle from '../../components/SmartLogistics/Order/Direct/DirectOrderArticle.vue'
import OrderSuggestionCollectiveArticleInfo
  from '../../components/SmartLogistics/Order/Suggestion/OrderSuggestionCollectiveArticleInfo.vue'
import OrderSuggestionDateInfo from '../../components/SmartLogistics/Order/Suggestion/OrderSuggestionDateInfo.vue'
import {sortResidents} from '../../composables/sortResidents'
import TitleSection from '../../components/SmartLogistics/ExpansionPanel/TitleSection.vue'
import CountDown from '@/components/SmartLogistics/Design/CountDown.vue'
import OrderSuggestionState from '../../components/SmartLogistics/Order/Suggestion/OrderSurggestionState.vue'
import basket from '../../components/SmartLogistics/Order/Basket/Basket.vue'
import moment from 'moment';
import InlineSearch from "../../components/search/InlineSearch.vue";
import {smartLogisticHistory} from "../../store/modules/smartLogisticHistory";

export default {
  name: 'ModelB',
  components: {
    InlineSearch,
    OrderSuggestionState,
    CountDown,
    TitleSection,
    OrderSuggestionDateInfo,
    OrderSuggestionCollectiveArticleInfo,
    DirectOrderArticle,
    DirectOrderHeader,
    ArticlePickerDialog,
    DirectOrderSwitch,
    History,
    Basket,
    OrderResidentBasketSelector,
    OrderHistoryPills,
    BasketArticle,
    OrderSuggestionArticleHeader,
    IntervalTextLabel,
    TimelineDialog,
    OrderSuggestionArticle,
    UnitLevel,
    ArticlePlanner,
    Resident,
    ExpansionPanel,
    ExpansionPanels,
    Layout
  },

  data() {
    return {
      panelsCache: [],
      selectedUnit: null,
    }
  },

  computed: {
    ...mapGetters({
      selectedDate: 'smartLogisticSettings/selectedDate',
      currentOrderViewMode: 'smartLogisticSettings/currentOrderViewMode',
      treeDates: 'tree/dates',
      treeDate: 'tree/tree',
      currentSection: 'smartLogisticSettings/currentSection',
      searchIsActive: 'smartLogisticSettings/searchIsActive',
      settingsLoading: 'smartLogisticSettings/isLoading',
      suggestionsLoading: 'smartLogisticOrderSuggestion/isLoading',
      directOrderLoading: 'smartLogisticDirectOrder/isLoading',
      basketLoading: 'smartLogisticBasket/isLoading',
      orderStates: 'smartLogisticOrderSuggestion/orderStates',
    }),

    isTreeReady() {
      const date = this.treeDates.find(d => d.dateString === this.selectedDate.format('YYYY-MM-DD'))

      if (!date) {
        return false
      }

      // ensure all data are present
      return date.tree && date.consumption && date.effectiveConsumption
    },

    orderLoading() {
      return this.settingsLoading || this.suggestionsLoading || this.directOrderLoading
    },

    sectionIsOrder() {
      return this.currentSection === 'order'
    },

    directOrderActive() {
      return this.$store.state.smartLogisticSettings.directOrder;
    },

    orderViewModeIsResident() {
      return this.currentOrderViewMode === 'resident'
    },

    orderViewModeIsBasket() {
      return this.currentOrderViewMode === 'basket'
    },

    detailViewIsHistory() {
      return this.currentSection === 'order-history'
    },

    detailViewIsCarePlan() {
      return this.currentSection === 'care-plan'
    },

    //order suggestion
    detailViewIsOrderSuggestion() {
      return this.currentSection === 'order' && !this.directOrderActive && this.currentOrderViewMode === 'resident'
    },

    //direct order
    detailViewIsDirectOrder() {
      return this.currentSection === 'order' && this.directOrderActive && this.currentOrderViewMode === 'resident'
    },

    //2 basket types (direct order or normal basket)
    detailViewIsBasket() {
      return this.currentSection === 'order' && this.currentOrderViewMode === 'basket' && !this.directOrderActive
    },

    detailViewIsDirectOrderBasket() {
      return this.currentSection === 'order' && this.currentOrderViewMode === 'basket' && this.directOrderActive
    },

    planModel() {
      return this.$store.getters['smartLogisticSettings/planModel']
    },

    tree() {
      const tree = this.treeDate(this.selectedDate.format('YYYY-MM-DD'))

      return this.sortTree(tree)
    },

    showExpandAllToggle() {
      return (unit) => {
        if(this.detailViewIsCarePlan) {
          if (this.$store.getters['tree/expandedLivingUnits'].indexOf(unit.internalID) > -1) {
            return false
          } else {
            let hasExpendedChildren = unit.children.find(resident => {
              return this.$store.getters['tree/expandedResidents'].indexOf(resident.internalID) > -1
            })
            if (hasExpendedChildren) {
              return false
            }
          }
          return true
        }
       if(this.detailViewIsOrderSuggestion) {
         if (this.$store.getters['tree/collapsedOrderLivingUnits'].indexOf(unit.internalID) > -1) {
           return true;
         } else {
           let hasCollapsedChildren = unit.children.find(resident => {
             return this.$store.getters['tree/collapsedOrderResidents'].indexOf(resident.internalID) > -1
           })
           if (hasCollapsedChildren) {
             return true;
           }
         }
         return false
       }
      }
    }
  },

  created() {
    this.$store.dispatch('tree/fetchWholeTreeWithConsumption', this.selectedDate.format('YYYY-MM-DD'))
    this.$store.dispatch('tree/fetchEffectiveConsumption', this.selectedDate.format('YYYY-MM-DD'))
  },

  methods: {

    setViewModeToBasket() {
      this.$store.commit('smartLogisticSettings/setViewMode', 'basket')

      setTimeout(function () {
        const el = document.querySelector('.order-pills')
        if (el) {
          window.scrollTo({
            top: el.offsetTop - 100,
            behavior: 'smooth'
          })
        }
      }, 500)
    },

    changeSelectedUnit(unitID) {
      this.$store.dispatch('inlineSearch/resetSearch')
      this.$store.commit('smartLogisticSettings/setDirectOrder', false)
      this.$store.commit('smartLogisticSettings/setViewMode', 'resident')
      this.selectedUnit = unitID;
    },
    basketHasChanged(unit) {
      let basket = this.$store.getters['smartLogisticBasket/basket']
      return basket ? basket.living_unit_id == unit.internalID && basket.edited : false
    },

    showOrderSuggestionChangedMessage(unit) {

      //don't show when loading
      if (this.basketLoading || this.suggestionsLoading) {
        return false;
      }

      //only show when expanded
      if (!this.isExpanded(unit)) {
        return false;
      }

      if (this.detailViewIsOrderSuggestion && this.orderSuggestionHasChanged(unit)) {
        return true;
      }

      return false;
    },

    showBasketChangedMessage(unit) {
      //don't show when loading
      if (this.basketLoading || this.suggestionsLoading) {
        return false;
      }
      //only show when expanded
      if (!this.isExpanded(unit)) {
        return false;
      }
      if (this.detailViewIsBasket && this.basketHasChanged(unit)) {
        return true;
      }
      //also show for order suggestion
      if (this.detailViewIsOrderSuggestion && this.basketHasChanged(unit)) {
        return true;
      }
      return false;
    },

    orderSuggestionHasChanged() {
      let orderSuggestionStatus = this.$store.getters['smartLogisticOrderSuggestion/currentStatus'];
      return orderSuggestionStatus == 'edited';
    },
    sortTree(tree) {
      if (!tree) {
        return
      }

      for (var i in tree.children) {
        tree.children[i].children = sortResidents(tree.children[i].children)
      }
      return tree
    },

    reloadLivingUnit(unitId) {
      this.$store.dispatch('tree/fetchLivingUnitData', {
        dateString: this.selectedDate.format('YYYY-MM-DD'),
        livingUnitId: unitId,
      })
    },

    // fetchBasket() {
    //   if (this.currentSection !== 'order' || !this.selectedUnit) {
    //     return;
    //   }
    //   const params = {
    //     unit: this.selectedUnit,
    //     date: null,
    //   }
    //   this.$store.dispatch('smartLogisticBasket/fetch', params);
    // },

    fetchOrderSuggestion(resetChosenDate = false, loadBasket = false) {
      if (this.currentSection !== 'order' || !this.selectedUnit) {
        return
      }

      const params = {
        unit: this.selectedUnit,
        date: null,
        resetChosenDate: resetChosenDate,
        loadBasket: loadBasket,
      }


      if (this.directOrderActive) {
        this.$store.dispatch("smartLogisticDirectOrder/fetch", params)
      } else {
        this.$store.dispatch("smartLogisticOrderSuggestion/fetch", params)
      }
      // const actionType = this.directOrderActive
      //     ? 'smartLogisticDirectOrder/fetch'
      //     : 'smartLogisticOrderSuggestion/fetch'
      //
      // this.$store.dispatch(actionType, params)
    },

    closeAllExpanded(unit) {
      if(this.detailViewIsCarePlan) {
        this.$store.commit('tree/setExpandedState', {type: 'living-unit', id: unit.internalID, expandedState: false})
        unit.children.forEach(resident => {
          this.$store.commit('tree/setExpandedState', {type: 'resident', id: resident.internalID, expandedState: false})
        })
      }
      if(this.detailViewIsOrderSuggestion) {
        this.$store.commit('tree/setOrderExpandedState', {type: 'living-unit', id: unit.internalID, expandedState: false})
        unit.children.forEach(resident => {
          this.$store.commit('tree/setOrderExpandedState', {type: 'resident', id: resident.internalID, expandedState: false})
        })
      }
    },
    openAllExpanded(unit) {
      if(this.detailViewIsCarePlan) {
        this.$store.commit('tree/setExpandedState', {type: 'living-unit', id: unit.internalID, expandedState: true})
        unit.children.forEach(resident => {
          this.$store.commit('tree/setExpandedState', {type: 'resident', id: resident.internalID, expandedState: true})
        })
      }
      if(this.detailViewIsOrderSuggestion) {
        this.$store.commit('tree/setOrderExpandedState', {type: 'living-unit', id: unit.internalID, expandedState: true})
        unit.children.forEach(resident => {
          this.$store.commit('tree/setOrderExpandedState', {type: 'resident', id: resident.internalID, expandedState: true})
        })
      }
    },

    isExpanded(unit) {
      return this.selectedUnit == unit.internalID;
    }
  },

  watch: {

    detailViewIsOrderSuggestion() {
      if(this.currentOrderViewMode === 'resident') {
        this.fetchOrderSuggestion(false, false)
      }
    },

    selectedUnit() {
      this.fetchOrderSuggestion(true, true);
    },
    selectedDate() {
      if (!this.tree) {
        this.$store.dispatch('tree/fetchWholeTreeWithConsumption', this.selectedDate.format('YYYY-MM-DD'))
        this.$store.dispatch('tree/fetchEffectiveConsumption', this.selectedDate.format('YYYY-MM-DD'))
      }
    },

    currentSection() {
      if (this.currentSection === 'order') {
        this.$store.dispatch('smartLogisticOrderSuggestion/fetchStates')
      }
      this.fetchOrderSuggestion(true, true)
    },
  },

  destroyed() {
    this.$store.commit('smartLogisticSettings/setPanels', [])
  }
}
</script>
<style lang="scss" scoped>
.expand-all-wrapper {
  text-align: right;

  .expand-all-toggle {
    font-size: 12px;
    font-family: "Avenir LT Std Heavy" !important;
    text-align: right;
    display: inline-block;
    color: black;
    border-bottom: 1px solid black;
    cursor: pointer;
  }
}
</style>
