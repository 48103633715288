<template>
  <v-menu
      v-model="picker"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      content-class="compact-calendar-menu"
      offset-y
      min-width="auto">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          :disabled="disabled"
          v-model="formattedDate"
          :placeholder="placeholder"
          :label="label"
          :class="'compact-calendar'.concat(' ').concat(classes)"
          v-bind="attrs"
          v-on="on"
          :rules="rules"
          :clearable="clearable"
          @focus="emitUpdate($event)"
          @click:clear="clickClear()"
          @update:modelValue="emitValue($event)"
      >
        <template v-slot:append>
          <img v-if="!disabled" width="28" height="28" :src="require('@/assets/img/ico2023/kalender.svg')"
               class="cursor-pointer custom-calender-icon" @click="picker = true">
        </template>
      </v-text-field>
    </template>
    <v-date-picker
        v-model="formValues[name]"
        :active-picker.sync="activePicker"
        color="primary"
        :min="minDate"
        :max="maxDate"
        :allowed-dates="allowedDates"
        :first-day-of-week="firstDayOfWeek"
        no-title
        :type="inputType"
        locale="locale"
        @input="picker = false; emitValue($event)"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import { bewohnerActions, bewohnerMutations } from '../observables/bewohner'
import moment from 'moment'

export default {
  name: 'CustomDatePicker',
  emits: ['update:modelValue'],
  props: {
    formValues: {
      required: true,
    },
    name: {
      required: true,
    },
    label: {
      required: true,
    },
    placeholder: {
      required: false,
      default: 'TT.MM.JJJJ'
    },
    formatPlaceholder: {
      required: false,
    },
    textInput: {
      required: false,
      default: true,
    },
    teleport: {
      required: false,
      default: true
    },
    locale: {
      required: false,
      default: 'ch-de'
    },
    format: {
      required: false,
      default: 'DD.MM.YYYY'
    },
    classes: {
      required: false,
      default: ""
    },
    minDate: {
      required: false,
      default: null
    },
    maxDate: {
      required: false,
      default: null
    },
    startDate: {
      required: false,
      default: moment().format('YYYY-MM-DD')
    },
    type: {
      required: false,
      default: 'date'
    },
    yearPicker: {
      required: false,
      default: false
    },
    enableTimePicker: {
      required: false,
      default: false
    },
    clearable: {
      required: false,
      default: false
    },
    disabled: {
      required: false,
      default: false
    },
    rules: {
      required: false,
      default () {
        return []
      }
    },
    allowedDates: {
      required: false,
      default: null
    },
    firstDayOfWeek: {
      required: false,
      default: 0
    }
  },

  data () {
    return {
      activePicker: 'DATE',
      picker: false,
      inputType: 'text',
    }
  },

  computed: {
    formattedDate: {
      get () {
        return this.formValues[this.name] ? moment(this.formValues[this.name]).format(this.format) : null
      },
      set (value) {
        if (moment(value, this.format, true).isValid()) {
          this.formValues[this.name] = moment(value, this.format).format('YYYY-MM-DD')
        }
      }
    }
  },
  methods: {
    emitValue (value) {
      this.$emit('update:modelValue', value)
    },

    emitUpdate (event) {
      this.$emit('focus', event.target.value)
    },

    clickClear () {
      this.formValues[this.name] = null
    }
  },

  watch: {
    picker (val) {
      if (this.type === 'birthday') {
        this.inputType = 'date'
        val && setTimeout(() => (this.activePicker = 'YEAR'))
      }
    },
  },

  mounted () {
    this.inputType = this.type
  }
}
</script>

<style lang="scss" scoped>
.date-select-actions {
  .v-image {
    margin: 0px !important;
  }
}
</style>
