<template>
  <div class="float-right mt-6">
    <v-btn
        color="primary"
        depressed
        medium
        class="order-button"
        @click="order"
        :disabled="disabled"
    >
      <div class="d-flex w-full mt-1">
        <div>
          <span class="icon-warenkrob"></span>
        </div>
        <div class="ml-4">
          <template v-if="directOrder">
            Sofort bestellen
          </template>
          <template v-else>
            Für {{ moment(dateObject.date).locale('de').format('dddd DD.MM.YYYY') }} bestellen
          </template>
        </div>
      </div>
    </v-btn>
  </div>

</template>

<script>

import moment from 'moment'

export default {
  name: 'BasketOrderButton',

  props: {
    directOrder: {
      type: Boolean,
      required: false,
      default: false,
    },
    dateObject: {
      type: Object,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    unit: {
      type: Object,
      required: true
    }
  },

  methods: {
    order() {
      this.$store.dispatch('smartLogisticBasket/order', {
        isDirectOrder: this.directOrder,
        unit: this.unit.internalID,
        date: null
      });
    },

    moment(date) {
      return moment(date)
    }
  },
}
</script>

<style scoped lang="scss">
.order-button {
  height: 35px !important;
}
</style>
