<template>
  <div class="os-tate-info" v-if="stats && stats.editableUntil">
    <div class="os-tate-info_date">
      <div class="mb-2" :data-mobile-label="$t('sl.order-suggestion.order-till')">
        <b>{{ $moment(stats.nextOrderDate).format('dddd DD.MM.YYYY') }}</b>
      </div>
      <CountDown
          :date="$moment(stats.editableUntil)"
          class="text--darkestgrey mt-1"
      >
        <template #default="{ days, dayString, hours, minutes }">
          noch {{ days }} {{ dayString }} {{ hours }} Std {{ minutes }} Min
        </template>
      </CountDown>
    </div>
    <div class="os-tate-info_state">
      <span
          style="font-size: 23px;"
          :style="{color: orderSuggestionStatColor('current',stats.orderSuggestionState)}"
          :class="orderSuggestionStatIcon('current',stats.orderSuggestionState)"
      ></span>
    </div>
  </div>
</template>

<script>
import CountDown from "../../Design/CountDown.vue";
import {
  orderSuggestionStatIcon,
  orderSuggestionStatColor
} from "../../../../composables/SmartLogistics/orderSuggestionStatIcon";

export default {
  name: 'OrderSuggestionState',
  components: {CountDown},
  props: {
    livingUnit: {
      type: Number,
      required: true
    }
  },

  methods: {
    orderSuggestionStatIcon, orderSuggestionStatColor
  },

  computed: {
    stats() {
      return this.$store.getters['smartLogisticOrderSuggestion/orderStateByLinvingUnitId']({id: this.livingUnit})
    }
  }
}
</script>

<style lang="scss" scoped>
.os-tate-info {
  display: grid;
  grid-template-columns: 200px 95px;
  align-items: center;
  text-align: right;

  .os-tate-info_date {
    text-align: left;
  }
}
</style>
