import httpClient from '../../utils/httpClient'
import moment from 'moment'

export const smartLogisticOrderSuggestion = {
    namespaced: true,

    state: {
        currentOrderSuggestion: null,
        currentOrderStatus: null,
        currentSuggestionId: null,
        unitLevelLoading: false,
        loading: false,
        chosenDateObject: null,
        suggestionDates: [],
        orderStates: null,
        currentDate: null,
        selectedDate: null,
        updateTimeout: null,
    },

    getters: {
        isLoading: state => state.loading,
        isUnitLevelLoading: state => state.unitLevelLoading,

        suggestionDates: (state) => {

            if (!state.suggestionDates) {
                return []
            }

            return state.suggestionDates
        },

        orderStates: (state) => {
            return state.orderStates
        },

        chosenDateObject: (state) => {
            return state.chosenDateObject
        },

        orderStateByLinvingUnitId: (state) => (params) => {
            return state.orderStates.find(item => item.livingUnit === params.id)
        },

        currentSuggestionId: (state) => {
            return state.currentSuggestionId
        },

        currentDate: (state) => {
            return state.suggestionDates.find(item => item.state === 'current')
        },


        currentStatus: (state) => {
            return state.currentOrderStatus
        },

        hasChanged: (state) => {
            return state.currentOrderStatus === 'edited'
        },

        isOrderSuggestionEditable: (state) => {
            try {
                return !state.currentOrderStatus || (['draft', 'edited'].includes(state.currentOrderStatus) && state.chosenDateObject.state === 'current')
            } catch (e) {
                return false
            }
        },

        isBasketEditable: (state) => {
            try {
                return state.chosenDateObject.state === 'current'
            } catch (e) {
                return false
            }
        },

        orderSuggestionLivingUnit: (state) => (params) => {
            if (!state.currentOrderSuggestion) {
                return []
            }

            return state.currentOrderSuggestion.filter(l => l.ownerType === 'living-unit' && l.ownerId === params.unit && l.collective === false)
        },

        orderSuggestionLivingUnitCollective: (state) => (params) => {
            if (!state.currentOrderSuggestion) {
                return []
            }

            return state.currentOrderSuggestion.filter(l => l.ownerType === 'living-unit' && l.ownerId === params.unit && l.collective === true)
        },

        orderSuggestionResidentAll: (state) => (params) => {
            if (!state.currentOrderSuggestion) {
                return []
            }

            return state.currentOrderSuggestion.filter(l => l.ownerType === 'resident' && l.ownerId === params.resident)
        },

        orderSuggestionResident: (state) => (params) => {
            if (!state.currentOrderSuggestion) {
                return []
            }

            return state.currentOrderSuggestion.filter(l => l.ownerType === 'resident' && l.ownerId === params.resident && l.collective === false)
        },

        orderSuggestionResidentCollective: (state) => (params) => {
            if (!state.currentOrderSuggestion) {
                return []
            }

            return state.currentOrderSuggestion.filter(l => l.ownerType === 'resident' && l.ownerId === params.resident && l.collective === true)
        },
    },

    actions: {

        async fetchStates({commit, state}) {
            let response = await httpClient({
                url: process.env.VUE_APP_API_LOGISTICS + 'order-states',
                method: 'get',
            })

            if (response && Array.isArray(response)) {
                commit('setOrderStates', response)
            }
        },

        async fetch({commit, state, dispatch}, payload) {
            state.loading = true
            let dateQuery;
            if (payload.resetChosenDate) {
                commit('setChosenDateObject', null)
                dateQuery = payload.date ? '/' + payload.date : ''
                if (!dateQuery) {
                    state.unitLevelLoading = true;
                }
            }
            else {
                dateQuery = state.chosenDateObject?.date ? '/' + state.chosenDateObject.date : '';
            }

            let response = await httpClient({
                url: process.env.VUE_APP_API_LOGISTICS + 'order-suggestion/by-living-unit/' + payload.unit + dateQuery,
                method: 'get',
            })

            if (response.data) {
                commit('setCurrentSuggestionId', response.data.id)
                commit('setCurrentOrderSuggestions', response.data.items)
                commit('setCurrentOrderSuggestionsStatus', response.data.status)
                commit('setSuggestionDates', response.data.dates)

                if (state.chosenDateObject) {
                    let object = response.data.dates.find(item => item.date === state.chosenDateObject.date);
                    if (object) {
                        commit('setChosenDateObject', object);
                    } else {
                        commit('setChosenDateObject', response.data.dates.find(item => item.state === 'current'))
                    }
                } else {
                    commit('setChosenDateObject', response.data.dates.find(item => item.state === 'current'))
                }
                if (payload.loadBasket) {
                    const params = {
                        unit: payload.unit,
                        dateObject: state.chosenDateObject
                    }
                    dispatch('smartLogisticBasket/fetch', params, {root: true})
                }

            } else {
                commit('setCurrentOrderSuggestions', [])
                commit('setCurrentOrderSuggestionsStatus', null)
                commit('setSuggestionDates', [])
                commit('setChosenDateObject', null)
            }

            state.loading = false
            state.unitLevelLoading = false
            dispatch('fetchStates')
        },

        async updateArticle({commit, state}, payload) {

            if (state.updateTimeout) {
                clearTimeout(state.updateTimeout)
            }

            state.updateTimeout = setTimeout(async () => {

                let response = await httpClient({
                    url: process.env.VUE_APP_API_LOGISTICS + 'order-suggestion/' + state.currentSuggestionId + '/item',
                    method: 'put',
                    data: {
                        artikelNrLAG: payload.artikelNrLAG,
                        ownerType: payload.ownerType,
                        ownerId: payload.ownerId,
                        plannedSingleUnitsEdited: payload.plannedSingleUnitsEdited,
                        orderSuggestionEdited: payload.orderSuggestionEdited,

                    }
                })
                if (response.data.items) {
                    commit('setCurrentSuggestionId', response.data.id)
                    commit('setCurrentOrderSuggestions', response.data.items)
                    commit('setSuggestionDates', response.data.dates)
                    commit('setCurrentOrderSuggestionsStatus', response.data.status)
                }
            }, 500)
        },

        async removeArticle({commit, state}, payload) {
            let response = await httpClient({
                url: process.env.VUE_APP_API_LOGISTICS + 'order-suggestion/' + state.currentSuggestionId + '/item',
                method: 'delete',
                data: payload
            })
            if (response.data.items) {
                commit('setCurrentSuggestionId', response.data.id)
                commit('setCurrentOrderSuggestions', response.data.items)
                commit('setSuggestionDates', response.data.dates)
                commit('setCurrentOrderSuggestionsStatus', response.data.status)
            }
        }
        ,

        async assignArticle({commit, state}, payload) {
            let response = await httpClient({
                url: process.env.VUE_APP_API_LOGISTICS + 'order-suggestion/' + state.currentSuggestionId + '/item',
                method: 'post',
                data: payload
            })
            if (response.data.items) {
                commit('setCurrentSuggestionId', response.data.id)
                commit('setCurrentOrderSuggestions', response.data.items)
                commit('setSuggestionDates', response.data.dates)
                commit('setCurrentOrderSuggestionsStatus', response.data.status)
            }
        },
        async reset({commit, state}) {
            let response = await httpClient({
                url: process.env.VUE_APP_API_LOGISTICS + 'order-suggestion/' + state.currentSuggestionId,
                method: 'delete',
            })
            if (response.data) {
                commit('setCurrentOrderSuggestions', response.data.items);
                commit('setCurrentOrderSuggestionsStatus', response.data.status)
            }
        },

        async updateSuggestionStatus({commit, state, dispatch}, payload) {
            commit('setCurrentOrderSuggestionsStatus', payload.status)
            dispatch('fetch', payload)
        }
    },

    mutations: {
        setCurrentOrderSuggestions(state, data) {
            state.currentOrderSuggestion = data
        }
        ,

        setOrderStates(state, data) {
            state.orderStates = data
        }
        ,

        setChosenDateObject(state, data) {
            state.chosenDateObject = data
        }
        ,

        setCurrentOrderSuggestionsStatus(state, status) {
            state.currentOrderStatus = status
        }
        ,

        setCurrentSuggestionId(state, data) {
            state.currentSuggestionId = data
        }
        ,

        setSuggestionDates(state, data) {
            state.suggestionDates = data
        }
        ,
    }
    ,
    persist: false
}
