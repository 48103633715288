<template>
  <div v-if="basket">
    <div class="b-top mt-6 mb-6"></div>
    <div class="d-flex">
      <div>
        <v-text-field
            light
            placeholder="Ihre Bestellnummer"
            autocomplete="off"
            v-model="customerOrderNo"
            style="width: 230px"
        ></v-text-field>
      </div>
      <div class="ml-4"  style="width: 230px">
<!--        <FieldDatePicker-->
<!--            v-model="customOrderDate"-->
<!--            append-icon="icon-kalender"-->
<!--            label="Gewünschter Liefertermin"-->
<!--            :clearable="true"-->
<!--            :min="new Date().toISOString().slice(0, 10)"></FieldDatePicker>-->
      </div>
      <div class="ml-8 grey--text pt-5">
       <!--@TODO!  Nächste Inko Pro Lieferung: 04.10.2024-->
      </div>
    </div>
    <div>
      <v-textarea
          light
          class="leightbeige rounded px-4 order-note-textarea"
          placeholder="Kommentar"
          autocomplete="off"
          no-resize
          height="100"
          v-model="comment"
          hide-details
          style="height: 100px"
      ></v-textarea>
    </div>
  </div>
</template>
<script>

import FieldDatePicker from '../../../form/FieldDatePicker.vue'

export default {
  name: 'BasketAdditionalInfos',
  components: { FieldDatePicker },
  computed: {
    basket() {
      return this.$store.getters['smartLogisticBasket/basket']
    },
    customerOrderNo: {
      get() {
        return this.basket.customer_order_no
      },
      set(value) {
        this.basket.customer_order_no = value
        //this.$store.dispatch('smartLogisticBasket/update', {customer_order_no: value})
      }
    },
    comment: {
      get() {
        return this.basket.comment
      },
      set(value) {
        this.basket.comment = value
        //this.$store.dispatch('smartLogisticBasket/update', {comment: value})
      }
    }
  }
}
</script>
<style>
.order-note-textarea  .v-input__slot::before,
.order-note-textarea  .v-input__slot::after {
  border-color: transparent !important;
  border-width: 0 !important;
}

.order-note-textarea textarea {
 height: 100px;
}
::v-deep .v-input__slot::before {
  border-color: transparent !important;
}
</style>
