import Vue from 'vue'
import httpClient from "@/utils/httpClient";
import {bewohnerMutations} from "@/components/observables/bewohner";

const wohneinheitenState = Vue.observable({
    "wohneinheiten": []
});

export const wohneinheitenGetters = {
    wohneinheiten: () => wohneinheitenState.wohneinheiten,
}

export const wohneinheitenMutations = {
    setWohneinheiten(wohneinheiten) {
        wohneinheitenState.wohneinheiten = wohneinheiten;
    },

    addWohneinheit(){

        let newWH =  wohneinheitenState.wohneinheiten.filter(function(el){
            return el.id == '-1';
        });

        if(newWH.length < 1){
            wohneinheitenState.wohneinheiten.unshift(
                {
                    'name': '',
                    'AdressNrADR': this.$store.state['benutzer'].AdressNrADR,
                    'Firma': '',
                    'Adresszeile1': '',
                    'Adresszeile2': '',
                    'Strasse': '',
                    'PLZ': '',
                    'Ort': '',
                    'id': '-1'
                }
            )
        }
    }
}

export const wohneinheitenActions = {
    fetchWohneinheiten(){
        //if(wohneinheitenState.wohneinheiten.length < 1){
            httpClient({
                url: process.env.VUE_APP_API_SHARED+'get/wohneinheiten',
                method: 'GET'
            }).then(function (res) {
                wohneinheitenMutations.setWohneinheiten(res.wohneinheiten)
            })
        //}

    },

    deleteWohneinheit(whID){
        httpClient({
            url: process.env.VUE_APP_API_SHARED+'delete/wohneinheit',
            method: 'post',
            data: {'whid':whID }
        }).then(function (res) {
            if(res.type === 'success'){
                wohneinheitenMutations.setWohneinheiten(res.wohneinheiten)
            }
        })
    },

    saveWohneinheiten(){
        httpClient({
            url: process.env.VUE_APP_API_SHARED+'save/wohneinheiten',
            method: 'post',
            data: { 'wohneinheiten': wohneinheitenState.wohneinheiten }
        }).then(function (res) {
            if(res.type === 'success'){
                wohneinheitenMutations.setWohneinheiten(res.wohneinheiten)
            }
        })
    },

    updateWohneinheitenNote(wohneinheit) {
        return new Promise((resolve, reject) => {
            httpClient({
                url: process.env.VUE_APP_API_SHARED + 'update/living-unit-checklistnote', method: 'put', data: {
                    wohneinheit: wohneinheit
                }
            }).then(response => {
                if (response && response.type == 'success') {
                    bewohnerMutations.setbewohner(response.bewohner)
                    resolve(response);
                }
                reject();
            })
                .finally(() => {
                    this.loadingBewohner = false;
                })
                .catch(error => {
                    reject();
                })
        })
    }
}

export default {
    wohneinheitenState,
    wohneinheitenGetters,
    wohneinheitenMutations,
    wohneinheitenActions
}
