import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'
import vuetify from './plugins/vuetify'
import i18n from './plugins/i18n'
import InstantSearch from 'vue-instantsearch';
import { setupInterceptors } from './utils/httpInterceptors';
const moment = require('moment')
require('moment/locale/de')

import "./plugins/vuetify-money.js";
import browserDetect from "vue-browser-detect-plugin";

import "./scss/layout.scss"

Vue.use(browserDetect);
Vue.use(InstantSearch);

Vue.use(require('vue-moment'), {
  moment
});
Vue.config.productionTip = false

import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective);

import { ObserveVisibility } from 'vue-observe-visibility'
Vue.directive('observe-visibility', ObserveVisibility)

//ersetzt durch vue XLSX
/*import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);*/

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
  created() {
    setupInterceptors(store);
  },
}).$mount('#app')
