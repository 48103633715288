<template>
  <div v-if="basket">
    <div class="d-flex mt-6">
      <div>
        Zwischensumme
      </div>
      <div class="justify-end flex-grow-1 text-right">
        {{ basket.item_total.toLocaleString('de-CH', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }} CHF
      </div>
    </div>
    <div class="d-flex mt-2" v-if="basket.min_quantity_surcharge">
      <div>
        Mindermengenzuschlag
      </div>
      <div class="justify-end flex-grow-1 text-right">
        {{ basket.min_quantity_surcharge.toLocaleString('de-CH', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }} CHF
      </div>
    </div>
    <div class="d-flex mt-2">
      <div>
        Logistikzuschlag {{ basket.energy_and_transport_surcharge_percent.toLocaleString('de-CH', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}%
      </div>
      <div class="justify-end flex-grow-1 text-right">
        {{ basket.energy_and_transport_surcharge_amount.toLocaleString('de-CH', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }} CHF
      </div>
    </div>
    <div class="d-flex mt-2">
      <div>
        <b>Total</b> (exkl. MwSt.)
      </div>
      <div class="justify-end flex-grow-1 text-right">
        <b>{{ basket.total ? basket.total.toLocaleString('de-CH', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-' }} CHF</b>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'BasketTotals',
  computed: {
    basket () {
      return this.$store.getters['smartLogisticBasket/basket']
    }
  }
}
</script>
