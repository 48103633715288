<template>

  <v-card
      rounded
      class="product-card"
      :class="{ 'is-assigned': !isDisabledArticle && isAlreadyAssigned, 'is-disabled': isDisabledArticle, 'strict-assigned': strictAssigned && !isCollectiveArticle }"
      style="height:100%;"

  >
    <div class="d-flex flex-column justify-start" :class="{'cursor-pointer': !isDisabledArticle && !strictAssigned}" style="height:100%;" @click="toggleAssignState">
      <div class="px-4 py-2 d-flex justify-space-between">
        <div>
          <span class="icon-kreis-leer" v-if="isDisabledArticle"></span>
          <div v-else-if="strictAssigned && !isCollectiveArticle">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
              <span
                  v-bind="attrs"
                  v-on="on"
                  class="icon-kreis-haekchen cursor-help"
              ></span>
              </template>

              <span>Bereits hinzugefügt</span>
            </v-tooltip>
          </div>
          <span v-else-if="isAlreadyAssigned && isCollectiveArticle" class="icon-kreis-haekchen"></span>
          <span v-else-if="isAlreadyAssigned" class="icon-kreis-haekchen cursor-pointer"></span>
          <span class="icon-kreis-leer cursor-pointer" v-else></span>
        </div>
        <div class="text-right">
          <ArticleDetailDialog :article="item" link-classes="more-about"></ArticleDetailDialog>
        </div>
      </div>


      <!-- product card content upper -->
      <div>
        <div class="px-4 py-2 thumbnail">
          <v-img v-if="item.hauptbild" :src="item.hauptbild" width="100%" height="80px"
                 max-width="80px" max-height="100px" class="mx-auto"
                 contain="contain"></v-img>

          <div v-else style="min-height:140px;padding-top: 25px;">
            <img :src="require('@/assets/img/ico/sortimentsverwaltung-aktiv.svg')" width="100%" height="75px" max-width="80px"
                 max-height="80px" class="mx-auto" contain="contain">
          </div>

          <v-tooltip bottom :nudge-top="100" max-width="400" class="body-2 py-1 px-2" :disabled="!isCollectiveArticle">
            <template v-slot:activator="{ on, attrs }">
              <div
                  v-bind="attrs"
                  v-on="on"
                  v-if="isCollectiveArticle"
                  class="icon-gemeinschaftsartikel collective-article-indicator cursor-help"
              ></div>
            </template>
            <div class="py-2 px-2" style="max-width: 380px;">
              <template v-if="isDisabledArticle">
                <template v-if="isDirectOrder || isBasket">
                  Gemeinschaftsartikel werden auf dem Stockwerk gelagert und müssen in Versorgungsplan einem Bewohner zugeordnet werden.
                </template>
                <template v-else-if="isLivingUnit">
                  Gemeinschaftsartikel können dem Stockwerk nicht zugeordnet werden. Er wird automatisch auf dem Stockwerk angezeigt, wenn Sie ihn einem Bewohner hinzufügen.
                </template>

              </template>
              <template v-else>
                Gemeinschaftsartikel werden auf dem Stockwerk gelagert und unter den Bewohner geteilt. Es werden Einzelstücke im Bestellvorschlag angezeigt.
              </template>
            </div>
          </v-tooltip>

        </div>
        <div class="pa-4">
          <p class="ma-0 body-2 caption" style="font-size: 15px;">
            <span v-if="articleInfos">
              {{ articleInfos.value }}
            </span>
            <span v-else>
              {{ item.artikelNr }}
            </span>
            <ArticleMessageIndicator :article="item"></ArticleMessageIndicator>
          </p>
          <div class="ma-0">
            <div class="pr-0 " style="font-size: 15px;">
               <span>
                {{ item.name }}
            </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>


<script>
import { mapGetters } from 'vuex'
import ArticleDetailDialog from '@/components/SmartLogistics/Article/ArticleDetailDialog.vue'
import ArticleMessageIndicator from '@/components/SmartLogistics/Article/ArticleMessageIndicator.vue'
import ArticleCaption from '@/components/SmartLogistics/Article/ArticleCaption.vue'
import th from 'vue2-datepicker/locale/es/th'

export default {
  name: 'ArticlePickerTeaser',
  components: { ArticleCaption, ArticleDetailDialog, ArticleMessageIndicator },
  emits: ['assign', 'detach'],

  props: {
    item: {
      type: Object,
      required: true
    },
    recentButDeletedArticles: {
      type: Array,
      default: () => []
    },
    alreadyAssignedArtnos: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'resident',
      validator: function (value) {
        return ['resident', 'living-unit', 'basket'].indexOf(value) !== -1
      }
    },
    strictAssigned: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      collectiveArticles: 'smartLogisticSettings/collectiveArticles',
      isDirectOrder: 'smartLogisticSettings/isDirectOrder'
    }),

    isBasket() {
      return this.type === 'basket'
    },

    isCollectiveArticle () {
      return this.$store.getters['articleSettings/isCollectiveByArticle'](this.item.objectID, !!this.item.isCollective)
    },

    isAlreadyAssigned() {
      return this.alreadyAssignedArtnos.includes(this.item.objectID)
    },

    isRecentButDeletedProduct() {
      return this.recentButDeletedArticles.includes(this.item.objectID)
    },

    isDisabledArticle() {
      return this.isCollectiveArticle && (this.isLivingUnit || this.isDirectOrder || this.isBasket)
    },

    isLivingUnit () {
      return this.type === 'living-unit'
    },

    articleInfos() {
      return this.$store.state.articles.articleInfos[this.item.objectID + '-' + 'customerartno']
    }
  },

  methods: {
    toggleAssignState () {
      if (this.isDisabledArticle || this.strictAssigned) return

      if (this.isAlreadyAssigned) {
        this.detachArticle()
      } else {
        this.assignArticle()
      }
    },
    assignArticle() {
      // if(this.isAlreadyAssigned) return

      this.$emit('assign', {
        artno: this.item.objectID,
        isCollectiveArticle: this.isCollectiveArticle
      })
    },

    detachArticle() {
      if (!this.isAlreadyAssigned) return

      this.$emit('detach', {
        artno: this.item.objectID,
        isCollectiveArticle: this.isCollectiveArticle
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.product-card {
  box-shadow: 2px 3px 10px #BAB6B6 !important;

  &.is-assigned {
    background-color: #f4ede7 !important;
  }

  &.is-disabled {
    color: #6A6868;
    opacity: 0.7;

    &:hover {
      background-color: #ffffff !important;
    }
  }

  &.strict-assigned {
    opacity: .4;
    background-color: #f4ede7 !important;
  }
}

.thumbnail {
  position: relative;
}

.collective-article-indicator {
  position: absolute;
  top: 0;
  right: 10px;
}
</style>
<style lang="scss">
.product-card {
  .more-about {
    border-bottom: 1px solid black;
    text-decoration: none !important;
    color: #000;
    font-size: 13px;
    line-height: 1;
    padding: 0 !important;
  }
}
</style>
