const sortResidents = (residents) => {
  return residents.sort((a, b) => {
    const zimmerA = a.zimmer? a.zimmer.toLowerCase(): ''
    const zimmerB = b.zimmer? b.zimmer.toLowerCase(): ''

    const zimmerAParsed = zimmerA ? zimmerA.match(/(\d+|\D+)/g) : '';
    const zimmerBParsed = zimmerB ? zimmerB.match(/(\d+|\D+)/g): '';

    for (let i = 0; i < Math.max(zimmerAParsed.length, zimmerBParsed.length); i++) {
      const partA = zimmerAParsed[i] || ''
      const partB = zimmerBParsed[i] || ''

      const numA = parseInt(partA, 10)
      const numB = parseInt(partB, 10)

      if (!isNaN(numA) && !isNaN(numB)) {
        if (numA < numB) return -1
        if (numA > numB) return 1
      } else {
        if (partA < partB) return -1
        if (partA > partB) return 1
      }
    }

    // If zimmer is the same, sort by name
    const nameA = a.name? a.name.toLowerCase(): ''
    const nameB = b.name? b.name.toLowerCase(): ''

    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  })
}

export {
  sortResidents
}
