<template>
  <div class="d-flex" v-if="this.$store.state.smartLogisticSettings.directOrderAllowed" style="margin-top: -5px">
    <div class="mt-5 mr-4" >
      Sofortbestellung
    </div>
    <div>
      <v-switch
          v-model="directOrder"
          dense small light flat inset
          label=""></v-switch>
    </div>

  </div>

</template>

<script>
export default {
  name: 'DirectOrderSwtich',
  computed: {
    directOrder: {
      get () {
        return this.$store.state.smartLogisticSettings.directOrder
      },
      set (value) {
        this.$store.commit('smartLogisticSettings/setDirectOrder', !!value)
      }
    }
  }

}

</script>

