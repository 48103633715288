<template>
  <TreeArticle
      :article="article"
      :entity-id="entityId"
      :type="type" :count="overallTotal"
      @remove="removeArticle"
      @updateInterval="updateInterval"
      :showDelete="moment(date).format('YYYYMM') >= moment().format('YYYYMM')"
  />

</template>

<script>
import ArticleImage from '@/components/SmartLogistics/Article/ArticleImage.vue'
import ArticleMessageIndicator from '@/components/SmartLogistics/Article/ArticleMessageIndicator.vue'
import {consumptionMixin} from '@/mixins/SmartLogistics/consumption'
import {intervalMixin} from '@/mixins/SmartLogistics/intverval'
import {mapGetters} from 'vuex'
import ConfirmDialog from '@/components/shared/ConfirmDialog.vue'
import TreeArticle from '@/components/shared/TreeArticle.vue'
import moment from 'moment'

export default {
  name: 'ArticlePlanner',
  components: {TreeArticle, ConfirmDialog, ArticleImage, ArticleMessageIndicator},
  mixins: [consumptionMixin, intervalMixin],
  emits: ['requiredLivingUnitReload'],

  props: {
    entityId: {
      type: Number,
      required: false,
    },
    article: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: 'resident',
      validator: function (value) {
        return ['resident', 'living-unit'].indexOf(value) !== -1
      }
    },
  },

  data() {
    return {
      moment: moment,
      intervals: [
        {
          key: 0,
          name: 'kein Intervall'
        },
        {
          key: 1,
          name: 'täglich'
        }, {
          key: 7,
          name: 'wöchentlich'
        }, {
          key: 28,
          name: 'monatlich'
        }
      ],
      intervalRules: [
        v => !!v || '',
        v => (v && v >= 1) || '',
        v => (v && v <= 999) || '',
      ],
    }
  },

  computed: {
    ...mapGetters({
      date: 'smartLogisticSettings/selectedDate',
      consumptionData: 'tree/consumptionData',
      effectiveConsumptionData: 'tree/effectiveConsumptionData',
      images: 'bilder/produktbilder'
    }),

    isLivingUnit() {
      return this.type === 'living-unit'
    },

    isCollectiveArticle() {
      return this.article.collective
    },

    consumption() {
      return this.consumptionData(this.date.format('YYYY-MM-DD'))
    },

    effectiveConsumption() {
      return this.effectiveConsumptionData(this.date.format('YYYY-MM-DD'))
    },

    articleConsumption() {
      return this.residentArticleConsumption(this.entityId, this.article.internalID)
    },

    articleEffectiveConsumption() {
      return this.residentEffectiveArticleConsumption(this.entityId, this.article.internalID)
    },

    articleData() {
      return this.residentArticleData(this.entityId, this.article.internalID)
    },

    articlePlandata() {
      return this.residentArticlePlanData(this.entityId, this.article.internalID)
    },

    overallTotal() {
      return this.calculateConsumptionTotal(this.articlePlandata) + this.calculateConsumptionTotal(this.articleEffectiveConsumption)
    },

    tags() {
      const tags = []

      if (this.article.CarePro_Abrechnung_DE) {
        tags.push(this.article.CarePro_Abrechnung_DE)
      }

      return tags
    }
  },

  methods: {
    updateInterval() {
      this.$store.dispatch('tree/updateInterval', {
        dateString: this.date.format('YYYY-MM-DD'),
        entityId: this.entityId,
        article: this.article,
        type: this.type,
      })
          .then(response => {
            if (response.type === 'success') {
              // @obsolete
              //this.$store.dispatch('tree/fetchResidentTree', this.date.format('YYYY-MM-DD'))
            }
          })
    },

    removeArticle() {
      if (this.isLivingUnit) {
        this.$store.dispatch('tree/detachArticleFromLivingUnit', {
          date: this.date.format('YYYY-MM-DD'),
          artno: this.article.internalID,
          livingUnitId: this.entityId,
        })
      } else {
        this.$store.dispatch('tree/detachArticle', {
          dateString: this.date.format('YYYY-MM-DD'),
          prodId: this.article.internalID,
          bewohnerID: this.entityId,
        })

        // removing a collective article requires a living unit update
        if (this.article.collective) {
          this.$emit('requiredLivingUnitReload')
        }
      }
    }
  }
}
</script>

