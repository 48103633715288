import Vue from 'vue'
import VueI18n from 'vue-i18n'

const messages = {
    de: {
        direktabrechnung: {
            nonmigel: 'Persönliches Material',
            regular: 'MiGeL-Material',
            yearly: 'MiGeL-Material Pauschalen',
            status: {
                flawed: 'fehlerhaft',
                prepared: 'bereit',
                sent: 'eingereicht',
                declined: 'zurückgewiesen'
            }
        },
        sl: {
            basket: {
                empty: 'Der Warenkorb ist leer',
            },
            sections: {
                'care-plan': 'Versorgungsplan',
                order: 'Bestellung',
                'order-history': 'Bestellhistorie',
                'order-suggestions': 'Bestellvorschlag'
            },
            timelineSections: {
                planning: 'Planung',
                delivery: 'Lieferung',
                order: 'Abrechnungsdaten'
            },
            collectiveArticles: {
                collectiveArticle: 'Gemeinschaftsartikel',
                generalInfo: 'Gemeinschaftsartikel',
                tooltipPlanner_b: 'Dieser Artikel ist ein Gemeinschaftsartikel, der auf dem Stockwerk gelagert wird. Der Artikel wird hier angezeigt, weil er einem Bewohner zugeteilt ist.',
                tooltipPlanner_c: 'Dieser Artikel ist ein Gemeinschaftsartikel, der auf dem Stockwerk gelagert wird. Der Artikel wird hier angezeigt, weil er einem Bewohner zugeteilt ist.',
                tooltipSettings_b: '<strong>Gemeinschaftsartikel</strong> werden auf dem Stockwerk gelagert und unter den Bewohner geteilt. Es werden <strong>Einzelstücke</strong> im Bestellvorschlag angezeigt.',
                tooltipSettings_c: '<strong>Gemeinschaftsartikel</strong> werden unter den Bewohner geteilt. Es werden <strong>Einzelstücke</strong> im Bestellvorschlag angezeigt.'
            },
            'order-suggestion': {
                'order-till': 'Spätestens bestellen',
                headers: {
                    label: 'Bezeichnung',
                    'last-order': 'Zuletzt bestellt',
                    unit: 'Einheit',
                    'single-unit': 'Geplanter Verbrauch<br>Einzelstück',
                    'suggestion-resident': 'Geplanter Verbrauch<br>Einzelstück',
                    'suggestion-living-unit': 'Bestellvorschlag<br>Verpackungseinheit',
                    'quantity-resident': 'Geplanter Verbrauch<br>anpassen',
                    'quantity-living-unit': 'Bestellung<br>anpassen'
                }
            },
            'order-history': {
                headers: {
                    label: 'Bezeichnung',
                    shipped: 'bestellt/geliefert',
                    unit: 'Verkaufseinheit',
                    quantity: 'Anzahl',
                    price: 'Einzelpreis',
                    'row-total': 'Zwischentotal',
                    status: 'Status',
                },
                'printClearingList': 'Einräumliste drucken'
            }
        }
    }
}

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: 'de', // set locale
    messages, // set locale messages
})

export default i18n
