<template>
  <v-col cols="12" class="px-0">
    <v-row class="b-bottom mx-0">
      <v-col cols="1">
        <ArticleImage :article="article"/>
      </v-col>
      <v-col cols="7">
        <ArticleCaption :article="article"/>
        <div class="d-flex">
          <BasketArticleDelete v-if="article.manuallyAdded && currentStatus !== 'completed'" :article="article"/>
        </div>
      </v-col>
      <v-col cols="3" :class="planModel === 'b' ? 'pa-0' : ''">
        <div  :class="planModel === 'b' ? 'leightbeige' : ''" class="h-full d-flex align-center justify-end pr-6">
          <template v-if="article.manuallyAdded">

            <span class="float-right" style="width: 150px;">
               {{ manuallyAddedPackageUnit }}
            </span>
            <span class="float-right" style="width: 100px;">
              <v-text-field
                  class="float-right number-input primary-input-color"
                  type="number"
                  v-model="quantity"
                  min="0"
                  light
                  autocomplete="off"
                  style="width: 70px;margin-right: -10px;"
                  :disabled="!isEditable"
              ></v-text-field>
            </span>
          </template>
          <template v-else>
            <span class="float-right" style="width: 150px;">
              {{ orderSuggestionPackageUnit }}
            </span>
              <span class="float-right text-right" style="width: 100px;">
              {{ article.orderSuggestionQuantity }}
            </span>
          </template>
        </div>
      </v-col>

      <v-col v-if="planModel !== 'b'" cols="2" class="pa-0">
        <div class="leightbeige h-full text-right pr-8 pt-3">
          <v-text-field
              class="float-right number-input"
              :class=" article.orderSuggestionQuantity !== quantity ? 'primary-input-color' : ''"
              type="number"
              v-model="quantity"
              min="0"
              light
              autocomplete="off"
              style="width: 70px;"
              :disabled="!isEditable"
          ></v-text-field>
        </div>
      </v-col>
      <v-col v-if="planModel !== 'b'" cols="3" class="pa-0">
        <div class="leightbeige h-full d-flex align-center pr-8 pt-6">
          <BasketPackageSelect :is-editable="isEditable" :quantity="quantity" :packages="article.packages" :selected-package-id="article.articlePackageUnitId" :artno="article.artikelNrLAG"/>
        </div>
      </v-col>
      <v-col cols="1" class="pa-0">
        <div class="beige h-full ml-3 d-flex align-center justify-end">
          <span class="pr-3">
            {{ totalPrice ? totalPrice.toLocaleString('de-CH', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-' }}
          </span>
        </div>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>

import ArticleImage from '../../Article/ArticleImage.vue'
import ArticleCaption from '../../Article/ArticleCaption.vue'
import BasketPackageSelect from './BasketPackageSelect.vue'
import ArticleDetailDialog from "@/components/SmartLogistics/Article/ArticleDetailDialog.vue";
import BasketArticleDelete from "./BasketArticleDelete.vue";
import ArticleMessageIndicator from "@/components/SmartLogistics/Article/ArticleMessageIndicator.vue";

export default {
  name: 'BasketArticle',
  components: {BasketArticleDelete, ArticleDetailDialog, BasketPackageSelect, ArticleCaption, ArticleImage, ArticleMessageIndicator },

  props: {
    article: {
      type: Object,
      required: true
    },
    isEditable: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    currentStatus () {
      return this.$store.getters['smartLogisticOrderSuggestion/currentStatus']
    },

    planModel () {
      return this.$store.getters['smartLogisticSettings/planModel']
    },

    quantity: {
      get () {
        return this.article.quantity
      },
      set (value) {
        this.$store.dispatch('smartLogisticBasket/updateArticle', { artikelNrLAG: this.article.artikelNrLAG, packageUnitID: this.article.articlePackageUnitId, quantity: value })
      }
    },

    totalPrice: {
      get () {
        let p = this.article.price * this.article.quantity

        return Math.round(p * 100) / 100
      }
    },
    currentPackageUnit () {
      try {
        return this.article.packages.filter((item) => {
          return item.id === this.article.artikelNrLAG + '-' + this.article.articlePackageUnitId
        })[0].label
      } catch (e) {
        return ''
      }
    },

    manuallyAddedPackageUnit () {
      try {
        return this.article.packages.filter((item) => {
          return item.id === this.article.artikelNrLAG + '-' + this.article.articlePackageUnitId
        })[0].label
      } catch (e) {
        return ''
      }
    },

    orderSuggestionPackageUnit () {
      try {
        return this.article.packages.filter((item) => {
          return item.id === this.article.artikelNrLAG + '-' + this.article.orderSuggestionPackageUnitId
        })[0].label
      } catch (e) {
        return ''
      }
    }
  }
}
</script>
<style>
.custom-select .v-input__control {
  min-height: 32px !important; /* Adjust this value as needed */
}

.custom-select .v-input__slot {
  min-height: 32px !important;
}

.custom-select .v-select__slot {
  height: 32px !important;
}

.custom-select .v-icon {
  top: -4px;
}

.primary-input-color{
  input{
    color: #ce20a8 !important;
  }
}

.article-message {
  margin-left: 5px;
}
</style>

