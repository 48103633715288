<template>
  <OwnerBox v-if="searchMatches" :ownerId="resident.internalID">
    <template #name>
      <span
          v-html="highlightedResidentName"
          class="text-h6 strong name"
          :style="resident.isDeleted ? 'color: #bbb': ''"
          :class="resident.isDeleted ? 'text-decoration-line-through' : ''"
          :data-bewohnerid="resident.internalID"
      >
      </span>
      <span
          v-html="highlightedResidentRoom"
          class="text-h6 font-weight-light room"
      ></span>
    </template>

    <template #calendar>
      <template v-if="currentSection === 'care-plan'">
        <TimelineOpener :entity-id="resident.internalID"/>
      </template>
    </template>

    <template #actions>
      <div class="d-flex">
        <template v-if="currentSection === 'care-plan'">
          <div class="d-flex align-center">
            <CommentDialog :owner="resident"/>
          </div>
          <div class="v-line mx-6 my-1"></div>
        </template>
        <template v-if="currentSection === 'care-plan'">
          <ArticlePickerDialog :owner="resident" type="resident" current-view="care-plan"/>
        </template>
        <template v-if="currentSection === 'order'">
          <template v-if="directOrderActive">
            <ArticlePickerDialog :owner="resident" type="resident" current-view="direct-order"/>
          </template>
          <template v-else>
            <ArticlePickerDialog
                :owner="resident"
                type="resident"
                current-view="order-suggestion"
                v-if="!['order_confirmed'].includes(orderSuggestionState) && (chosenDateObject && (chosenDateObject.state !== 'past' && chosenDateObject.status !== 'order_confirmed'))"
            />
          </template>
        </template>
      </div>
    </template>

    <slot name="articleHeader" v-if="articles.length > 0"/>

    <template v-for="article in articles">
      <slot name="article" :article="article"/>
    </template>

    <AlertMessage v-if="articles.length === 0 && collectiveArticles.length === 0">
      Keine Artikel zugewiesen
    </AlertMessage>

    <slot name="collectiveArticleInfo" v-if="collectiveArticles.length > 0"/>

    <slot name="collectiveArticleHeader" v-if="collectiveArticles.length > 0"/>

    <template v-for="collectiveArticle in collectiveArticles">
      <slot name="collectiveArticle" :article="collectiveArticle"/>
    </template>

  </OwnerBox>
</template>

<script>
import { mapGetters } from 'vuex'
import ArticlePlanner from '@/components/SmartLogistics/Article/ArticlePlanner.vue'
import OwnerBox from '@/components/SmartLogistics/Design/OwnerBox.vue'
import TimelineOpener from '@/components/SmartLogistics/Timeline/TimelineOpener.vue'
import CommentDialog from '@/components/SmartLogistics/Resident/CommentDialog.vue'
import ArticlePickerDialog from '@/components/SmartLogistics/Article/ArticlePickerDialog.vue'
import AlertMessage from '../Design/AlertMessage.vue'
import { inlineSearchMixin } from '../../../mixins/SmartLogistics/inlineSearchMixin'

export default {
  name: 'Resident',
  components: { AlertMessage, ArticlePickerDialog, CommentDialog, TimelineOpener, OwnerBox, ArticlePlanner },
  mixins: [inlineSearchMixin],

  props: {
    resident: {
      type: Object,
      required: true,
    },
    currentSection: {
      type: String,
      required: true,
      default: 'care-plan',
    },
    directOrderActive: {
      type: Boolean | Number,
      default: 0,
    },
  },

  computed: {
    ...mapGetters({
      searchIsActive: 'inlineSearch/searchIsActive',
      searchTerm: 'inlineSearch/searchTerm',
      searchTarget: 'inlineSearch/searchTarget',
      orderSuggestionState: 'smartLogisticOrderSuggestion/currentStatus',
      chosenDateObject: 'smartLogisticOrderSuggestion/chosenDateObject'
    }),

    articles () {
      if (this.currentSection === 'care-plan') {
        return this.resident.children
      }

      if (this.currentSection === 'order' && !this.directOrderActive) {
        return this.$store.getters['smartLogisticOrderSuggestion/orderSuggestionResident']({ resident: this.resident.internalID })
      }
      if (this.currentSection === 'order' && this.directOrderActive) {
        return this.$store.getters['smartLogisticDirectOrder/articlesByResident']({ resident: this.resident.internalID })
      }

      return []
    },

    collectiveArticles () {
      if (this.currentSection === 'order' && !this.directOrderActive) {
        return this.$store.getters['smartLogisticOrderSuggestion/orderSuggestionResidentCollective']({ resident: this.resident.internalID })
      }

      return []
    },

    //pass to mixin (inlineSearchMixin)
    searchableResident () {
      return this.resident
    },

    searchableArticles () {
      //merge articles and collectiveArticles
      return this.articles.concat(this.collectiveArticles)
    }
  },
}
</script>
<style>
.v-line {
  width: 1.5px;
  background: black;
}
</style>
