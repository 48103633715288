<template>
  <v-dialog width="900" v-model="dialog" content-class="custom-dialog">
    <template v-slot:activator="{ on, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
          <v-hover v-slot="{ hover }">
        <span
            class="icon-gemeinschaftsartikel"
            v-bind:class="{ 'text--primary': isCollective || hover }"
            v-bind="{ ...attrs, ...tooltipAttrs }"
            v-on="{ ...on, ...tooltipOn }">
        </span>
          </v-hover>
        </template>
        <span>Gemeinschaftsartikel-Einstellungen</span>
      </v-tooltip>

    </template>
    <v-card class="main py-4 px-2" v-if="prodDetails">
      <v-card-title class="px-12" style="position: relative">
        <div>
          <span class="text-h2 text--primary hyphens-auto">{{ article.artikelNr }} - {{
              article.name
            }}</span><br/>
          <span class="text-small medium">{{ prodDetails.uom ? prodDetails.uom : '' }}</span>
        </div>

        <div class="close-btn" @click="dialog=false">
          <img alt="" :src="require('@/assets/img/ico/schliessen.svg')" width="25"/>
        </div>
      </v-card-title>
      <v-card-text class="px-12 pb-0">
        <div class="row">
          <div class="col-4">
            <v-switch dense small light flat inset
                      v-model="isCollective"
                      class="is_collective_switch"
                      v-bind:class="{'bold':logisticsModel === 'c'}"
                      label="Gemeinschaftsartikel">
              <template v-slot:append>
                <v-tooltip bottom max-width="390" content-class="custom-tooltip sand large">
                  <template v-slot:activator="{ on, attrs }">
              <span class="icon-informationen cursor-help"
                    v-bind="attrs"
                    v-on="on"></span>
                  </template>
                  <span v-html="$t('sl.collectiveArticles.tooltipSettings_'+$store.state.smartLogisticSettings.model)"></span>
                </v-tooltip>
              </template>
            </v-switch>
          </div>
          <div class="col-4" v-if="logisticsModel === 'c' && isCollective">
            <v-radio-group v-model="storageLocation"
                           label="Lagerort für Abgabe"
                           class="storage_location_group mt-[18px]"
                           v-bind:class="{'bold':logisticsModel === 'c'}">
              <v-radio label="Stockwerk" value="floor"></v-radio>
              <v-radio label="Hauptlager" value="warehouse"></v-radio>
            </v-radio-group>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import {mapActions} from 'vuex'
import httpClient from "@/utils/httpClient";

export default {
  name: 'ArticleSettingsDialog',
  props: {
    article: {
      type: Object,
      required: true
    },
    logisticsModel: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      prodDetails: null
    }
  },

  watch: {
    dialog() {
      if(this.dialog) {
        this.getProductDetails(this.article.artikelNr);
      }
    }
  },

  computed: {
    isCollective: {
      get() {
        return this.$store.getters['articleSettings/isCollectiveByArticle'](this.article.artikelNr, !!this.article.isCollective);
      },
      set(value) {
        this.setArticleSettings({id: this.article.artikelNr, updateData: {is_collective: value, storage_location: this.storageLocation}})
      }
    },
    storageLocation: {
      get() {
        let storageLocation  = this.$store.getters['articleSettings/storageLocationByArticle'](this.article.artikelNr);
        if(storageLocation === null) {
          storageLocation = this.article.storageLocation ? this.article.storageLocation : null;
        }
        return storageLocation;
      },
      set(value) {
        this.setArticleSettings({id: this.article.artikelNr, updateData: {storage_location: value, is_collective: this.isCollective}})
      }
    },
  },

  methods: {
    ...mapActions('articleSettings', ['setArticleSettings', 'fetchArticleSettings']),

    async getProductDetails(prodID)  {
      const res = await httpClient({
        url: process.env.VUE_APP_API_CHECKLIST + 'get/product-detail',
        method: 'post',
        data: {prodID: prodID}
      })

      if (res.type == 'success') {
        this.prodDetails = res.detail;
      }
    },
  },
}
</script>

<style lang="scss">
.storage_location_group {
  legend.v-label {
    font-size: 16px;
  }

  &.bold {
    legend.v-label {
      font-weight: 600;
    }
  }
}

.is_collective_switch {
  &.bold {
    .v-label {
      font-weight: 600;
    }
  }
}
</style>
