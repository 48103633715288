<template>
  <v-menu
      v-model="dateMenu"
      :close-on-content-click="true"
      content-class="compact-calendar-menu"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          v-model="formattedDate"
          label=""
          readonly
          v-bind="attrs"
          v-on="on"
      >
          <template v-slot:append>
              <img width="28" height="28" :src="require('@/assets/img/ico2023/kalender.svg')"
                   class="cursor-pointer custom-calender-icon" @click="dateMenu = true">
          </template>
      </v-text-field>
    </template>
    <v-date-picker
        class="compact-calendar"
        color="primary"
        v-model="val"
        type="month"
        :landscape="true"
        :min="minDate ? minDate.format('YYYY-MM-DD') : ''"
        locale="ch-de"
        :flat="true"
        @input="$emit('updateDate', $event)"
        :no-title="true"
        :show-current="false"
        :rules="[rules]"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import moment from "moment";

export default {
  name: 'CustomMonthPicker',
  props: {
    minDate: {
      required: false,
      default: false
    },
    rules: {
      required: false,
      default: () => []
    },
    dateValue: {
      required: false,
      default: null
    }
  },

  data() {
    return {
      dateMenu: false,
      val: null,
    }
  },

  computed: {
    formattedDate: {
      get() {
        const date = moment(this.val, 'YYYY-MM');
        return date.format('MMMM YYYY');
      },
      set(value) {
        if(value != null) {
          const date = moment(value, 'MMMM YYYY');
          this.val = date.format('YYYY-MM');
        }
      }
    },
  },
  mounted() {
    if(this.dateValue != null){
      this.val = moment(this.dateValue).format('YYYY-MM');

    }else if(this.minDate != null && this.minDate){
      this.val = moment(this.minDate).format('YYYY-MM');
    }

  }
}
</script>
