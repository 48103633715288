<template>
  <div class="order-suggestion-article" :class="article.geloescht == '1' ? 'deleted' : ''">
    <div class="image">
      <ArticleImage :article="article"/>
    </div>

    <div class="name">
      <ArticleCaption :article="article"/>
      <div class="d-flex align-center">
        <template v-if="article.manuallyAdded && currentStatus !== 'completed'">
          <v-hover v-slot="{hover}">
            <img :src="hover ? require('@/assets/img/ico/loeschen-hover.svg') : require('@/assets/img/ico/loeschen.svg')"
                 @click="removeArticle(article.internalID, $event)"  class="cursor-pointer mr-3" width="27">
          </v-hover>
        </template>
        <ArticleDetailDialog :article="article" linkClasses="text-decoration-underline more-about cursor-pointer" linkText="Mehr" :noScroll="true"></ArticleDetailDialog>
      </div>
    </div>

    <div class="last-order" :data-mobile-label="cleanLineBreaks($t('sl.order-suggestion.headers.last-order'))" v-if="(ownerType === 'living-unit' || !collective)">
      {{ article.lastOrder ? article.lastOrder : '-' }}
    </div>
    <div class="unit" :data-mobile-label="cleanLineBreaks($t('sl.order-suggestion.headers.unit'))">
      {{ article.unit }}
    </div>
    <div class="single-unit" :data-mobile-label="(ownerType === 'living-unit' && collective)? cleanLineBreaks($t('sl.order-suggestion.headers.single-unit')): ''">
      {{ (ownerType === 'living-unit' && collective) ? article.plannedSingleUnits : '' }}
    </div>

    <div class="suggestion" :data-mobile-label="cleanLineBreaks($t('sl.order-suggestion.headers.suggestion-' + (ownerType === 'resident'? 'resident': 'living-unit')))">
      <template v-if="ownerType === 'resident' && collective">
        {{ article.plannedSingleUnits }}
      </template>
      <template v-else>
        {{ article.orderSuggestion }}
      </template>
    </div>

    <div class="quantity" :data-mobile-label="cleanLineBreaks($t('sl.order-suggestion.headers.quantity-' + (ownerType === 'resident'? 'resident': 'living-unit')))">
        <v-text-field
            v-model="count"
            class="float-right number-input"
            min="0"
            type="number"
            light
            autocomplete="off"
            style="width: 70px"
            :disabled="!isEditable || article.geloescht == '1'"
        />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ArticleImage from '../../Article/ArticleImage.vue'
import ArticleCaption from '../../Article/ArticleCaption.vue'
import ArticleDetailDialog from "@/components/SmartLogistics/Article/ArticleDetailDialog.vue";
import { cleanLineBreaks } from '@/utils/String'
import ArticleMessageIndicator from "@/components/SmartLogistics/Article/ArticleMessageIndicator.vue";

export default {
  name: 'OrderSuggestionArticle',
  components: {ArticleMessageIndicator, ArticleDetailDialog, ArticleCaption, ArticleImage },
  props: {
    article: {
      type: Object,
      required: true
    },
    ownerType: {
      type: String,
      required: true
    },
    ownerId: {
      type: Number,
      required: true
    },
    collective: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      images: 'bilder/produktbilder',
      isEditable: 'smartLogisticOrderSuggestion/isOrderSuggestionEditable',
    }),

    currentStatus () {
      return this.$store.getters['smartLogisticOrderSuggestion/currentStatus']
    },

    count: {
      get () {
        if (this.collective && this.ownerType === 'resident') {
          return this.article.plannedSingleUnitsEdited
        }

        return this.article.orderSuggestionEdited
      },
      set (value) {
        if(value < 0) {
          value = 0
        }
        this.$store.dispatch('smartLogisticOrderSuggestion/updateArticle', {
          artikelNrLAG: this.article.internalID,
          ownerType: this.ownerType,
          ownerId: this.ownerId,
          plannedSingleUnitsEdited: this.collective && this.ownerType === 'resident' ? parseInt(value) : null,
          orderSuggestionEdited: this.collective && this.ownerType === 'resident' ? null : parseInt(value)
        })
      }
    }
  },
  methods: {
    removeArticle (artikelNrLAG, event) {
      event.preventDefault();
      this.$store.dispatch('smartLogisticOrderSuggestion/removeArticle', { artikelNrLAG: artikelNrLAG, ownerType: this.ownerType, ownerId: this.ownerId })
    },
    cleanLineBreaks
  },
  mounted() {
    if(this.article.geloescht == '1'){
      this.article.orderSuggestionEdited = 0;
    }
  }
}
</script>
<style lang="scss">
.order-suggestion-article.deleted{
  *{
    color: #9E9E9E;
  }
}
</style>
