import Vue from 'vue'
import httpClient from "@/utils/httpClient";

const bewohnerState = Vue.observable({
    "bewohner": []
});


export const bewohnerGetters = {
    bewohner: () => {
        return bewohnerState.bewohner
    },
    getActiveBewohner: () => {
        return !bewohnerState.bewohner ? [] : bewohnerState.bewohner.filter(b => !b.deleted);
    },
    getDeletedBewohner: () => {
        return !bewohnerState.bewohner ? [] : bewohnerState.bewohner.filter(b => b.deleted && !b.austritt);
    },
    getAustritteBewohner: () => {
        return !bewohnerState.bewohner ? [] : bewohnerState.bewohner.filter(b => b.deleted && b.austritt);
    },
    getById: (state) => (id) => !state.bewohner ? null : state.bewohner.find(b => b.bewohnerID === +id),
}

export const bewohnerMutations = {

    setbewohner(bewohner) {
        bewohnerState.bewohner = bewohner;
    }
}

export const bewohnerActions = {
    fetchBewohner(incldeleted = false) {
        //if(bewohnerState.bewohner.length < 1){

        let inclDeletedParam = incldeleted ? '?incldeleted=1' : '';
        httpClient({
            url: process.env.VUE_APP_API_SHARED + 'get/bewohner' + inclDeletedParam,
            method: 'GET'
        }).then(function (res) {
            bewohnerMutations.setbewohner(res.bewohner)
        })
        //}
    },

    saveBewohner(editBewohner, ignoreLock = false) {

        //darf ein Lock ignoriert werden? zb. von Notizchange in Planung

        return new Promise((resolve, reject) => {
            httpClient({
                url: process.env.VUE_APP_API_SHARED + 'save/bewohner',
                method: 'post',
                data: {'bewohner': editBewohner, 'ignoreLock': ignoreLock}
            }).then(function (res) {
                bewohnerMutations.setbewohner(res.bewohner)
                resolve(res);
            })
        })
    },

    updateBewohner(bewohner) {
        return new Promise((resolve, reject) => {
            httpClient({
                url: process.env.VUE_APP_API_SHARED + 'update/bewohner', method: 'put', data: {
                    id: bewohner.id,
                    bewohner: bewohner
                }
            }).then(response => {
                if (response && response.type == 'success') {
                    bewohnerMutations.setbewohner(response.bewohner)
                    resolve(response);
                }
                reject();
            })
                .finally(() => {
                    this.loadingBewohner = false;
                })
                .catch(error => {
                    reject();
                })
        })
    },

    restoreBewohner(bewohnerID, mode = false) {
        //darf ein Lock ignoriert werden? zb. von Notizchange in Planung

        return new Promise((resolve, reject) => {
            return httpClient({
                url: process.env.VUE_APP_API_SHARED + 'restore/bewohner',
                method: 'post',
                data: {'bewohnerid': bewohnerID, 'mode': mode}
            }).then(function (res) {
                if (res.type === 'success') {
                    bewohnerMutations.setbewohner(res.bewohner)
                    resolve(res);
                }
            })
        })
    },

    deleteBewohner(bewohnerID) {

        return new Promise((resolve, reject) => {
            return httpClient({
                url: process.env.VUE_APP_API_SHARED + 'delete/bewohner',
                method: 'post',
                data: {'bewohnerid': bewohnerID}
            }).then(function (res) {
                if (res.type === 'success') {
                    bewohnerMutations.setbewohner(res.bewohner)
                    resolve(res);
                }
            })
        })

    },

    updateBewohnerNote(bewohner) {
        return new Promise((resolve, reject) => {
            httpClient({
                url: process.env.VUE_APP_API_SHARED + 'update/bewohner-checklistnote', method: 'put', data: {
                    bewohner: bewohner
                }
            }).then(response => {
                if (response && response.type == 'success') {
                    bewohnerMutations.setbewohner(response.bewohner)
                    resolve(response);
                }
                reject();
            })
                .finally(() => {
                    this.loadingBewohner = false;
                })
                .catch(error => {
                    reject();
                })
        })
    }
}

export default {
    bewohnerState,
    bewohnerGetters,
    bewohnerMutations,
    bewohnerActions
}
