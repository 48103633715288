<template>
  <div>
    <v-btn
        v-if="isMobile && !isVisible"
        class="mobile-nav-btn"
        fab
        dark
        color="primary"
    >
      <v-app-bar-nav-icon
          v-if="isMobile"
          @click="toggleNavigation"
      />
    </v-btn>

    <v-navigation-drawer
        id="rightNav"
        v-model="isVisible"
        :permanent="!isMobile"
        :temporary="isMobile"
        :expand-on-hover="!isMobile"
        light
        fixed
        color="secondary"
        width="230"
        right
        style="z-index:95;border:none !important;background-position: right"
    >
      <v-btn
          v-if="isMobile"
          @click="toggleNavigation"
          class="close"
          icon
      >
        <img :src="require('@/assets/img/ico/schliessen.svg')" />
      </v-btn>

      <v-list class="mb-0 pa-0">
        <v-list-item class="px-2">
          <v-list-item-avatar class="mt-5 mb-2" width="40" height="40" min-height="40" min-width="40">
            <v-btn class="mx-2" fab dark small color="primary">
              {{ this.$store.state.benutzer.info.vorname.substring(0, 1) }}{{ this.$store.state.benutzer.info.name.substring(0, 1) }}
            </v-btn>
          </v-list-item-avatar>
        </v-list-item>
        <v-list-item class="pl-2">
          <v-list-item-content>
            <v-list-item-title class="title">
              {{ this.$store.state.benutzer.info.vorname }} {{ this.$store.state.benutzer.info.name }}
            </v-list-item-title>
            <v-list-item-subtitle class="email">{{ this.$store.state.benutzer.info.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list nav dense class="mt-0">
        <div class="top-nav">
          <v-list-item link to="/account"  v-if="!isMobile">
            <v-list-item-icon>
              <img :src="this.$route.name == 'account' ? require('@/assets/img/ico2023/account-aktiv.svg') : require('@/assets/img/ico2023/account.svg')"/>
            </v-list-item-icon>
            <v-list-item-title class="subtitle-1">Account</v-list-item-title>
          </v-list-item>

          <v-divider light class="my-4"  v-if="!isMobile" />

          <v-list-item link to="/versorgung-bewohner" v-if="['b', 'c'].includes(activePlanModel)">
            <v-list-item-icon>
              <img :src="this.$route.name == 'Versorgungsplan' ? require('@/assets/img/ico2023/verbrauchsliste-aktiv.svg') : require('@/assets/img/ico2023/verbrauchsliste.svg')"/>
            </v-list-item-icon>
            <v-list-item-title class="subtitle-1">Versorgung Bewohner</v-list-item-title>
          </v-list-item>

          <v-list-item link to="/versorgungsplan" v-else-if="!isMobile">
            <v-list-item-icon>
              <img :src="this.$route.name == 'Versorgungsplan' ? require('@/assets/img/ico2023/verbrauchsliste-aktiv.svg') : require('@/assets/img/ico2023/verbrauchsliste.svg')"/>
            </v-list-item-icon>
            <v-list-item-title class="subtitle-1">Versorgung Bewohner</v-list-item-title>
          </v-list-item>

          <v-list-item link to="/reports" v-if="!isMobile && ['a'].includes(activePlanModel)">
            <v-list-item-icon>
              <img :src="this.$route.name == 'Reports'  ? require('@/assets/img/ico2023/liste-drucken-aktiv.svg') : require('@/assets/img/ico2023/liste-drucken.svg')"/>
            </v-list-item-icon>
            <v-list-item-title class="subtitle-1">Versorgungsplan drucken</v-list-item-title>
          </v-list-item>

          <v-list-item link to="/leistungsabrechnung" v-if="!isMobile">
            <v-list-item-icon>
              <img :src="this.$route.name == 'leistungsabrechnung'  ? require('@/assets/img/ico2023/statistik-aktiv.svg') : require('@/assets/img/ico2023/statistik.svg')"/>
            </v-list-item-icon>
            <v-list-item-title class="subtitle-1">Leistungsabrechnung</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="isPurasdirectActive() && !isMobile" link to="/direktabrechnung">
            <v-list-item-icon>
              <img :src="this.$route.name == 'direktabrechnung'  ? require('@/assets/img/ico/direktabrechnung-aktiv.svg') : require('@/assets/img/ico/direktabrechnung.svg')"/>
            </v-list-item-icon>
            <v-list-item-title class="subtitle-1">Direktabrechnung</v-list-item-title>
          </v-list-item>

<!--          <v-list-item link to="/bestellvorschlag" v-if="!['b', 'c'].includes(activePlanModel) && !isMobile">-->
<!--            <v-list-item-icon>-->
<!--              <img :src="this.$route.name == 'Bestellvorschlag' ? require('@/assets/img/ico2023/bestellung.svg') : require('@/assets/img/ico2023/bestellung.svg')"/>-->
<!--            </v-list-item-icon>-->
<!--            <v-list-item-title class="subtitle-1">Bestellvorschlag</v-list-item-title>-->
<!--          </v-list-item>-->

          <v-divider light class="my-4" />

          <v-list-item link to="/sortimentsverwaltung" v-if="!isMobile">
            <v-list-item-icon>
              <img :src="this.$route.name == 'Sortimentsverwaltung' ? require('@/assets/img/ico2023/sortiment-aktiv.svg') : require('@/assets/img/ico2023/sortiment.svg')"/>
            </v-list-item-icon>
            <v-list-item-title class="subtitle-1">Sortimentsverwaltung</v-list-item-title>
          </v-list-item>

          <v-list-item link to="/wohneinheiten" v-if="!isMobile">
            <v-list-item-icon>
              <img class="whimg" :src="this.$route.name == 'Wohneinheiten' ? require('@/assets/img/ico2023/wohneinheit-aktiv.svg') : require('@/assets/img/ico2023/wohneinheit.svg')"/>
            </v-list-item-icon>
            <v-list-item-title class="subtitle-1">Wohneinheiten</v-list-item-title>
          </v-list-item>

          <v-list-item link to="/bewohnerverwaltung" v-if="!isMobile">
            <v-list-item-icon>
              <img :src="this.$route.name == 'Bewohnerverwaltung' ? require('@/assets/img/ico2023/bewohner-aktiv.svg') : require('@/assets/img/ico2023/bewohner.svg')" alt="" width="28" height="28" contain="contain"/>
            </v-list-item-icon>
            <v-list-item-title class="subtitle-1">Bewohner</v-list-item-title>
          </v-list-item>

          <v-divider light class="my-4"  v-if="!isMobile" />

          <v-list-item v-if="superadmin == 1 && !isMobile" link to="/superadmin">
            <v-list-item-icon>
              <img :src="this.$route.name == 'superadmin' ? require('@/assets/img/ico2023/account-aktiv.svg') : require('@/assets/img/ico2023/account.svg')"/>
            </v-list-item-icon>
            <v-list-item-title class="subtitle-1">Superadmin</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="(tooladmin == 1 || superadmin == 1) && !isMobile" link to="/tooladmin">
            <v-list-item-icon>
              <img :src="this.$route.name == 'tooladmin' ? require('@/assets/img/ico2023/account-aktiv.svg') : require('@/assets/img/ico2023/account.svg')"/>
            </v-list-item-icon>
            <v-list-item-title class="subtitle-1">Benutzerverwaltung</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="(accountant == 1 || superadmin == 1 || tooladmin == 1) && !isMobile" link to="/settings">
            <v-list-item-icon>
              <img :src="this.$route.name == 'settings' ? require('@/assets/img/ico/einstellungen-aktiv.svg') : require('@/assets/img/ico/einstellungen.svg')"/>
            </v-list-item-icon>
            <v-list-item-title class="subtitle-1">Einstellungen</v-list-item-title>
          </v-list-item>

          <v-divider light class="my-4" v-if="(accountant == 1 || tooladmin == 1 || superadmin == 1) && !isMobile"/>

          <v-list-item link @click="logoutUser()">
            <v-list-item-icon>
              <img :src="require('@/assets/img/ico2023/logout.svg')" alt="" width="22" height="22" contain="contain"/>
            </v-list-item-icon>
            <v-list-item-title class="subtitle-1">Logout</v-list-item-title>
          </v-list-item>
        </div>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
import { logoutUser } from '../../utils/auth'
import { hasModuleActive } from '@/composables/clientModules'
import { mapGetters } from 'vuex'

export default {
  name: 'Navigation',
  props: ['superadmin', 'tooladmin', 'accountant'],

  data: () => ({
    show: false,
  }),

  computed: {
    ...mapGetters({
      activePlanModel: 'smartLogisticSettings/planModel',
      isMobile: 'system/isMobile'
    }),

    isVisible: {
      get () {
        return !this.isMobile || (this.isMobile && this.show)
      },
      set (value) {
        this.show = value
      }
    },
  },

  methods: {
    logoutUser () {
      logoutUser()
      this.$router.push('/login')
    },

    isPurasdirectActive () {
      return hasModuleActive('purasdirect')
    },

    toggleNavigation () {
      this.show = !this.show
    },
  },

  watch: {
    isMobile() {
      this.show = false
    }
  },

  created () {
    this.$store.dispatch('smartLogisticSettings/fetch')
  },
}
</script>
<style lang="scss">

@import '@/scss/smartlogistics/mixins/_breakpoint';


.mobile-nav-btn {
  position: fixed;
  right: 20px;
  bottom: 40px;
  z-index: 96;
}

#rightNav {
  height: calc(100% - 116px) !important;
  top: 116px !important;
  border: none !important;
  padding: 0 7px;
  box-sizing: content-box;

  &.v-navigation-drawer--mini-variant {
    .v-list-item > :first-child {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.v-navigation-drawer {
    @media all and (max-width: map-get($breakpoints, 'navigation')) {
      width: 500px !important;
      max-width: 100%;
      padding: 20px;
      box-sizing: border-box;
    }
  }

  &.v-navigation-drawer--is-mouseover {
    @include breakpoint(navigation) {
      width: 260px !important;
    }
  }

  .close {
    position: absolute;
    top: 40px;
    right: 20px;
    z-index: 90;
  }

  .v-navigation-drawer__border {
    background: none !important;
  }

  .v-image {
    width: 28px !important;
    margin-right: 10px !important;
    height: 28px !important;
  }

  .v-list-item {
    padding: 0;
    text-align: left;
  }

  .v-list-item__icon {
    padding: 0 4px;
    width: 100%;
    max-width: 30px;

    .v-image__image {
      background-size: 100% auto;
    }

    img {
      max-width: 36px;
      max-height: 100%;
      width: 100%;
    }
  }

  .whimg {
    .v-image__image {
      width: 21px !important;
      margin-right: 10px !important;
      height: 21px !important;
    }
  }

  .v-avatar {
    button {
      height: 40px;
      width: 40px;
      font-size: 15px;
    }
  }

  .title {
    font-size: 21px !important;
    font-family: 'Avenir LT Std Heavy' !important;
    margin-bottom: 5px;
  }

  .email {
    font-family: "Avenir LT Std";
    font-weight: 300;
    font-size: 14px;
  }

  &.v-navigation-drawer--is-mouseover {
    @include breakpoint(navigation) {
      padding: 0 20px 0 30px !important;
    }
  }

  &.v-navigation-drawer--right {
    //fix hover bug
    right: -1px
  }
}
</style>
