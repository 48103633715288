import httpClient from '../../utils/httpClient'
import {smartLogisticSettings} from "@/store/modules/smartLogisticSettings";


export const smartLogisticHistory = {
    namespaced: true,

    state: {
        loading: false,
        loadingDetail: false,
        historyOverview: [],
        articles: null,
        openLastOrder: false,
        openOrderById: null,
        currentHistory: null
    },

    getters: {
        historyOverview: state => state.historyOverview,
        openLastOrder: state => state.openLastOrder,
        openOrderById: state => state.openOrderById,
        articles: state => state.articles,
        isLoading: state => state.loading,
        isLoadingDetail: state => state.loadingDetail,
        currentHistory: state => state.currentHistory
    },

    actions: {
        async fetchOverview({commit, state}, payload) {
            state.loading = true
            let response = await httpClient({
                url: process.env.VUE_APP_API_LOGISTICS + 'living-unit/' + payload.unit + '/order-history',
                method: 'get',
            })
            if (response.data) {
                commit('setHistoryOverview', response.data)
                if (state.openLastOrder) {
                    commit('setCurrentHistory', 0)
                    commit('setOpenLastOrder', false)
                }
                if(state.openOrderById !== null) {
                    let index = response.data.findIndex(history => history.id === state.openOrderById);
                    if(index > -1) {
                        commit('setCurrentHistory', index);
                    }
                    commit('setOpenOrderById',null);
                }
            }
            state.loading = false
        },

        async fetchDetail({commit, state}, payload) {
            state.loadingDetail = true
            let response = await httpClient({
                url: process.env.VUE_APP_API_LOGISTICS + 'living-unit/' + payload.unit + '/order-history/' + payload.id,
                method: 'get',
            })
            if (response.data.items) {
                let items = response.data.items;
                if(response.data.energy_and_transport_surcharge_amount) {
                    items.push({
                        internalID: 'logistic_surcharge',
                        name: 'Logistikzuschlag '+ response.data.energy_and_transport_surcharge_percent.toLocaleString('de-CH', { minimumFractionDigits: 2, maximumFractionDigits: 2 })+'%',
                        quantity: 1,
                        price: response.data.energy_and_transport_surcharge_amount,
                        total: response.data.energy_and_transport_surcharge_amount
                    })
                }
                commit('setArticles', response.data.items)
            }
            state.loadingDetail = false
        },
    },

    mutations: {

        setHistoryOverview(state, data) {
            state.historyOverview = data
        },

        setCurrentHistory(state, data) {
            state.currentHistory = data
        },

        setOpenLastOrder(state, data) {
            state.openLastOrder = data
        },
        setOpenOrderById(state, data) {
            state.openOrderById = data
        },

        setArticles(state, data) {
            state.articles = data
        },
    },
    persist: false
}
