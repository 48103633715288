<template>
  <OwnerBox v-if="searchMatches" header-type="living-unit" :ownerId="unitLevel.internalID">
    <template #name>
      <div class="d-flex">
        <div class="text-h6 strong name">
          {{ unitLevel.name }}
        </div>
      </div>
    </template>

    <template #calendar>
      <template v-if="currentSection === 'care-plan'">
        <TimelineOpener :entity-id="unitLevel.internalID" type="living-unit"/>
      </template>
    </template>

    <template #actions>
      <div class="d-flex">
        <template v-if="currentSection === 'care-plan'">
          <div class="d-flex align-center">
            <CommentDialog :owner="unitLevel" type="living-unit"/>
          </div>
          <div class="v-line mx-6 my-1"></div>
        </template>
        <template v-if="currentSection === 'care-plan'">
          <ArticlePickerDialog
              :owner="unitLevel"
              type="living-unit"
              current-view="care-plan"
          />
        </template>
        <template v-if="currentSection === 'order'">
          <template v-if="directOrderActive">
            <ArticlePickerDialog
                :owner="unitLevel"
                type="living-unit"
                current-view="direct-order"
            />
          </template>
          <template v-else>
            <ArticlePickerDialog
                v-if="!['order_confirmed'].includes(orderSuggestionState) && (chosenDateObject && (chosenDateObject.state !== 'past'  && chosenDateObject.status !== 'order_confirmed'))"
                type="living-unit"
                :owner="unitLevel"
                current-view="order-suggestion"
            />
          </template>

        </template>
      </div>
    </template>

    <slot v-if="articles.length > 0" name="articleHeader"/>

    <template v-for="article in articles">
      <slot name="article" :article="article"/>
    </template>

    <AlertMessage v-if="articles.length === 0 && collectiveArticles.length === 0">
      Keine Artikel zugewiesen
    </AlertMessage>

    <slot name="collectiveArticleInfo" v-if="collectiveArticles.length > 0"/>

    <slot name="collectiveArticleHeader" v-if="collectiveArticles.length > 0"/>

    <template v-for="collectiveArticle in collectiveArticles">
      <slot name="collectiveArticle" :article="collectiveArticle"/>
    </template>
  </OwnerBox>
</template>

<script>
import OwnerBox from '@/components/SmartLogistics/Design/OwnerBox.vue'
import CommentDialog from '@/components/SmartLogistics/Resident/CommentDialog.vue'
import ArticlePickerDialog from '@/components/SmartLogistics/Article/ArticlePickerDialog.vue'
import TimelineOpener from '@/components/SmartLogistics/Timeline/TimelineOpener.vue'
import AlertMessage from '../Design/AlertMessage.vue'
import { mapGetters } from 'vuex'
import { inlineSearchMixin } from '../../../mixins/SmartLogistics/inlineSearchMixin'

export default {
  name: 'UnitLevel',
  components: { AlertMessage, TimelineOpener, ArticlePickerDialog, CommentDialog, OwnerBox },
  mixins: [inlineSearchMixin],
  props: {
    unitLevel: {
      type: Object,
      required: true,
    },
    currentSection: {
      type: String,
      required: true,
      default: 'care-plan',
    },
    directOrderActive: {
      type: Boolean | Number,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      searchIsActive: 'inlineSearch/searchIsActive',
      chosenDateObject: 'smartLogisticOrderSuggestion/chosenDateObject'
    }),

    orderSuggestionState () {
      return this.$store.getters['smartLogisticOrderSuggestion/currentStatus']
    },

    isCarePlan () {
      return this.currentSection === 'care-plan'
    },

    isOrder () {
      return this.currentSection === 'order'
    },

    articles () {
      if (this.currentSection === 'care-plan') {
        return this.unitLevel && this.unitLevel.data ? this.unitLevel.data.filter(a => !a.collective) : []
      }

      if (this.currentSection === 'order' && !this.directOrderActive) {
        return this.$store.getters['smartLogisticOrderSuggestion/orderSuggestionLivingUnit']({ unit: this.unitLevel.internalID })
      }

      if (this.currentSection === 'order' && this.directOrderActive) {
        return this.$store.getters['smartLogisticDirectOrder/articlesByLivingUnit']({ unit: this.unitLevel.internalID })
      }

      return []
    },

    collectiveArticles () {

      if (this.currentSection === 'care-plan') {
        return this.unitLevel && this.unitLevel.data ? this.unitLevel.data.filter(a => a.collective) : []
      }

      if (this.currentSection === 'order' && !this.directOrderActive) {
        return this.$store.getters['smartLogisticOrderSuggestion/orderSuggestionLivingUnitCollective']({ unit: this.unitLevel.internalID })
      }

      return []
    },

    //pass to mixin (inlineSearchMixin)
    searchableArticles () {
      //merge articles and collectiveArticles
      return this.articles.concat(this.collectiveArticles)
    },

    searchableResident () {
      return this.unitLevel
    },
  },
}
</script>
