<template>
  <v-row>
    <v-col cols="6">
      <h2 class="font-weight-medium pt-4 pb-3">
        <span v-if="order.immediate_order">Sofortbestellung </span>
        <span v-else> Bestellung </span>
        <span>{{ order.order_no }}</span>
      </h2>

    </v-col>
    <v-col cols="6">
      <div class="d-flex  align-center justify-right h-full">
        <div class="b-right-black pr-6">
          <div>
            <b>Ihre Bestellnummer</b>
          </div>
          <div>
            {{ order.customer_order_no ? order.customer_order_no : '-' }}
          </div>
        </div>

        <div class="b-right-black px-6" style="width: 120px;">
          <div>
            <b>Datum</b>
          </div>
          <div>
            <template v-if="!order.is_pending">
              {{ $moment.unix(order.completed_at).format('DD.MM.YYYY') }}
            </template>
            <template v-else>
              -
            </template>
          </div>
        </div>

        <div class="b-right-black px-6">
          <div>
            <b>Gesamtbetrag</b>
          </div>
          <div>
           {{ order.total }}
          </div>
        </div>
        <div class="px-6 b-right-black " style="width: 150px;">
          <div>
            <b>Status</b>
          </div>
          <div>
           {{ order.status }}
          </div>
        </div>
        <div class="px-2" style="width: 80px;">
          <div>
            <v-tooltip bottom max-width="390">
              <template v-slot:activator="{ on, attrs }">
                <PrintPdf
                    :filename="'einrauemliste_'+order.living_unit_id+'_'+$moment.unix(order.completed_at).format('YYYY-MM-DD')+'.pdf'"
                    :uri="`clearing-list/${order.id}`"
                >
                  <template v-slot:default="{ eventHandlers, loading }">
                    <div v-on="eventHandlers" class="hover-link text-center">
                      <v-progress-circular color="primary" size="25" width="2" class="mb-2 text-center"
                                           indeterminate v-if="loading"></v-progress-circular>
                      <div class="icon-drucken text-center" style="font-size: 25px;" v-if="!loading"  v-bind="attrs"
                           v-on="on"></div>
                    </div>
                  </template>
                </PrintPdf>
              </template>
              <span>{{ $t('sl.order-history.printClearingList') }}</span>
            </v-tooltip>

          </div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>
<script>

import PrintPdf from "@/components/SmartLogistics/Print/PrintPdf.vue";

export default {
  name: 'OrderHistoryPanelHeader',
  components: {PrintPdf},
  props: {
    order: {
      type: Object,
      required: true
    }
  }
}
</script>

<style>

</style>
