<template>
  <v-dialog
      v-model="dialog"
      :width="width"
      class="rounded-lg"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="button" :on="on" :attrs="attrs"/>
    </template>

    <div class="card pt-0" v-bind:style="{ height: fixHeight ? fixHeight : 'auto'}">
      <div class="card-header">
        <slot name="header"/>
        <slot name="close-button" :close="close">
        <img
            class="close"
            @click="close"
            :src="require('@/assets/img/ico/schliessen.svg')"
        >
        </slot>
      </div>

      <div class="card-content" :class="{ '-no-scroll': noScroll }">
        <slot/>
      </div>
      <div class="card-footer" v-if="$slots.footer">
        <slot name="footer"/>
      </div>
      <div class="card-actions">
        <slot name="actions" :close="close" />
      </div>
    </div>
  </v-dialog>
</template>

<script>

export default {
  name: 'Dialog',
  emits: ['open', 'close'],

  props: {
    width: {
      type: Number,
      default: 800,
    },
    fixHeight: {
      type: String,
      default: null,
    },
    forceOpen: {
      type: Boolean,
      default: false,
    },
    noScroll: {
      type: Boolean,
      default: false,
    }
  },

  data () {
    return {
      dialog: false,
    }
  },
  methods: {
    close () {
      this.dialog = false
    },
  },

  watch: {
    dialog (val) {
      this.$emit(val ? 'open' : 'close')
    },
    forceOpen (val) {
      this.dialog = val
    },
  },

  mounted () {
    if (this.forceOpen) {
      this.dialog = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/smartlogistics/mixins/_breakpoint.scss";
@import "@/scss/smartlogistics/mixins/_scroll-bar.scss";

::v-deep {
  .v-dialog {
    border-radius: 20px;
    overflow: hidden;
    margin: 12px;

    @include breakpoint('md') {
      margin: 24px;
    }
  }
}

.card {
  display: flex;
  flex-direction: column;
  padding: 40px 0 23px 0;
  background-color: #ffffff;
  max-height: 90vh;
}

.card-content {
  flex: 1;
  padding: 0 20px;
  overflow-y: auto;
  max-height: 100%;

  @include breakpoint('md') {
    padding: 0 50px;
  }

  &.-no-scroll {
    @include breakpoint(md) {
      overflow-y: hidden;
    }
  }
}

.card-header {
  position: sticky;
  top: 0;
  z-index: 10;
  background: white;
  padding: 20px 20px 18px;

  @include breakpoint('md') {
    padding: 40px 83px 18px 50px
  }

  h1 {
    line-height: 1.1em;
    color: #CE20A8;
  }
  ::v-deep {
    .close {
      position: absolute;
      top: 25px;
      right: 20px;
      width: 25px;
      height: 25px;
      cursor: pointer;

      @include breakpoint(md) {
        top: 40px;
        right: 50px;
      }
    }
  }
}

.card-footer {
  border-top: 1px solid #000000;
  padding: 0 50px;
}

.card-actions {
  padding: 0 50px;
}

</style>
