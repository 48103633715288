
<template>
  <v-row v-if="state">
    <v-col :sm="smColwidth" :md="mdColwidth" :cols="colwidth" v-for="hit in state.hits" :key="hit.objectID">
        <slot name="item" :item="hit">
         {{ hit.objectID}}
        </slot>
      </v-col>
      <v-col class="sentinel" v-observe-visibility="visibilityChanged" />
  </v-row>
</template>

<script>
import { createWidgetMixin } from 'vue-instantsearch';
import { connectInfiniteHits } from 'instantsearch.js/es/connectors';

export default {
  props: ['colwidth', 'smColwidth', 'mdColwidth'],
  name: 'InfiniteHits',
  mixins: [createWidgetMixin({ connector: connectInfiniteHits })],
  methods: {
    visibilityChanged(isVisible) {
      if (isVisible && !this.state.isLastPage) {
        this.state.showMore();
      }
    },
  }
};
</script>

<style scoped>
.sentinel {
  list-style-type: none;
}
</style>
