<template>
  <Dialog
      :forceOpen="forceOpen"
      @close="close"
      :width="1620"
      @open="updateDate"
  >
    <template v-slot:button="{ on, attrs }">

    </template>

    <template #header>
      <div class="timeline-container" v-if="entity">
        <div></div>
        <div class="timeline-navigator">
          <div class="tab-panel">
            <template>
              <button
                  v-for="section in sections"
                  :key="section"
                  class="tab-button"
                  :class="{ 'active': activeSection === section }"
                  @click="activeSection = section"
              >
                {{ $t('sl.timelineSections.' + section) }}
              </button>
            </template>
          </div>

          <MonthNavigator
              v-model="selectedDate"
              :isNextMonthActive="isActiveNextMonth"
          />
        </div>
      </div>
    </template>

    <template #footer>
      <div class="-space py-4 timeline-footer" v-if="entity">
        <div class="interval-info d-none d-md-block">
          <span class="icon-intervall-kennzeichnung mr-3"></span> Bestellintervall {{ intervalText }}
        </div>

        <div class="list timeline-resident-navigator">
          <v-btn icon @click="previousTimeline">
            <span class=" icon-weiter-nach-links"></span>
          </v-btn>
          <v-btn icon @click="nextTimeline">
            <span class="icon-weiter-nach-rechts"></span>
          </v-btn>
        </div>

        <div class="list status-info d-none d-md-block">
          <div class="timeline-status -status-planned">
            geplant
          </div>
          <div class="timeline-status -status-ordered">
            bestellt
          </div>
          <div class="timeline-status -status-adjusted" v-if="!isLivingUnit">
            manuell angepasst
          </div>
        </div>
      </div>
    </template>
    <Timeline
        v-if="entity"
        :type="type"
        :entity="entity"
        :date="selectedDate"
        :key="entityId"
    />
  </Dialog>
</template>

<script>
import Timeline from '@/components/SmartLogistics/Timeline/Timeline.vue'
import Dialog from '@/components/SmartLogistics/Design/Dialog.vue'
import moment from 'moment'
import MonthNavigator from '@/components/SmartLogistics/Design/MonthNavigator.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'TimelineDialog',
  components: { MonthNavigator, Dialog, Timeline },

  data () {
    return {
      forceOpen: false,
      selectedDate: null,
      type: 'resident'
    }
  },

  computed: {
    ...mapGetters({
      currentResident: 'smartLogisticSettings/currentResident',
      currentLivingUnit: 'smartLogisticSettings/currentLivingUnit',
      overviewDate: 'smartLogisticSettings/selectedDate',
      blueprint: 'smartLogisticSettings/blueprint',
      treeByDate: 'tree/tree',
      residentByDate: 'tree/residentData',
      livingUnitByDate: 'tree/livingUnitData',
    }),

    /**
     *
     */
    activeSection: {
      get () {
        return this.$store.getters['smartLogisticSettings/currentTimelineSection']
      },
      set (val) {
        this.$store.commit('smartLogisticSettings/setCurrentTimelineSection', val)
      }
    },

    /**
     *
     */
    isLivingUnit () {
      return this.type === 'living-unit'
    },

    /**
     *
     */
    resident () {
      return this.residentByDate(this.overviewDate.format('YYYY-MM-DD'), this.currentResident)
    },

    /**
     *
     */
    livingUnit () {
      return this.livingUnitByDate(this.overviewDate.format('YYYY-MM-DD'), this.currentLivingUnit)
    },

    /**
     *
     */
    entity () {
      return this.isLivingUnit ? this.livingUnit : this.resident
    },

    /**
     *
     * @returns {number|*}
     */
    entityId () {
      return this.entity.internalID
    },

    /**
     *
     * @returns {*}
     */
    livingUnitId () {
      try {
        return this.isLivingUnit ? this.entityId : this.entity.wohneinheiten_id
      } catch (e) {
        return null
      }
    },

    /**
     *
     */
    tree () {
      return this.treeByDate(this.overviewDate.format('YYYY-MM-DD')).children
    },

    /**
     *
     */
    sections () {
      const sections = this.$store.getters['smartLogisticSettings/planConfig'].timelineSections

      this.activeSection = sections[0]

      return sections
    },

    /**
     *
     * @returns {*}
     */
    settings () {
      return this.$store.getters['smartLogisticSettings/livingUnitSettingsById'](this.livingUnitId)
    },

    /**
     *
     * @returns {string}
     */
    intervalText () {
      if (!this.blueprint || !this.settings) {
        return ''
      }
      const interval = this.blueprint.interval.find(i => i.value == this.settings.interval)
      return (interval ? interval.label : '') + ' ' + this.$moment(this.settings.start_date).format('dddd').toLowerCase() + 's'
    },

    /**
     *
     * @returns {boolean}
     */
    isActiveNextMonth () {
      return this.isResidentActiveNextMonth(this.entity)
    },
  },

  methods: {
    /**
     *
     * @param entity
     * @returns {boolean}
     */
    isResidentActiveNextMonth (entity) {
      if (entity.type === 'wohneinheit') {
        return true
      }

      if (entity.deleted_at === undefined || entity.deleted_at === null) {
        return true
      }

      const deletedDate = this.$moment(entity.deleted_at)
      const deletedDateMonth = deletedDate.clone().endOf('month').subtract(1, 'month')

      return deletedDateMonth.isAfter(this.selectedDate)
    },

    /**
     *
     * @param entity
     * @returns {boolean}
     */
    isResidentActiveThisMonth(entity) {
      if (entity.type === 'wohneinheit') {
        return true
      }

      if (entity.deleted_at === undefined || entity.deleted_at === null) {
        return true
      }

      const deletedDate = this.$moment(entity.deleted_at)
      const deletedDateMonth = deletedDate.clone().endOf('month')

      return deletedDateMonth.isAfter(this.selectedDate)
    },

    /**
     *
     */
    previousTimeline () {
      const livingUnitPos = this.tree.findIndex(l => l.internalID === this.livingUnitId)

      var residentPos = 0

      if (this.isLivingUnit) {
        residentPos = -1
      } else {
        residentPos = this.tree[livingUnitPos].children.findIndex(r => r.internalID === this.entityId)
      }

      // if it is first resident, show living unit
      if (residentPos === 0) {
        var livingUnit = this.livingUnitId

        this.$store.commit('smartLogisticSettings/setCurrentResident', null)
        this.$store.commit('smartLogisticSettings/setCurrentLivingUnit', livingUnit)
        return
      }

      var resident = null
      var curResidentPos = residentPos -1

      if(residentPos !== -1) {
      // find next not deleted resident
      while (curResidentPos >= 0) {
        if (
            this.tree[livingUnitPos].children[curResidentPos]
            && (
                this.tree[livingUnitPos].children[curResidentPos].deleted_at === null
                || this.isResidentActiveThisMonth(this.tree[livingUnitPos].children[curResidentPos])
            )
        ){
          resident = this.tree[livingUnitPos].children[curResidentPos]
          break
        }

        curResidentPos--
      }
      }


      if (resident === null) {
        // if no resident is found but current resident is not first resident, then previous residents has been deleted. Therefore, show living unit.
        if(residentPos > 0) {
          this.$store.commit('smartLogisticSettings/setCurrentResident', null)
          this.$store.commit('smartLogisticSettings/setCurrentLivingUnit', this.tree[livingUnitPos].internalID)
          return
        }

        const prevLivingUnitPos = livingUnitPos === 0 ? this.tree.length - 1 : livingUnitPos - 1
        const nResidents = this.tree[prevLivingUnitPos].children.length

        // if no residents in current living unit, show previous living unit
        if (nResidents === 0) {
          this.$store.commit('smartLogisticSettings/setCurrentResident', null)
          this.$store.commit('smartLogisticSettings/setCurrentLivingUnit', this.tree[prevLivingUnitPos].internalID)
          return
        }

        curResidentPos = this.tree[prevLivingUnitPos].children.length - 1

        // find next not deleted resident
        while (curResidentPos >= 0) {
          if (
              this.tree[prevLivingUnitPos].children[curResidentPos]
              && (
                  this.tree[prevLivingUnitPos].children[curResidentPos].deleted_at === null
                  || this.isResidentActiveThisMonth(this.tree[prevLivingUnitPos].children[curResidentPos])
              )
          ) {
            resident = this.tree[prevLivingUnitPos].children[curResidentPos]
            break
          }

          curResidentPos--
        }
      }

      if (resident) {
        this.$store.commit('smartLogisticSettings/setCurrentLivingUnit', null)
        this.$store.commit('smartLogisticSettings/setCurrentResident', resident.internalID)
      }
    },

    /**
     *
     */
    nextTimeline () {
      const livingUnitPos = this.tree.findIndex(l => l.internalID === this.livingUnitId)

      var residentPos = 0

      if (this.isLivingUnit) {
        residentPos = -1
      } else {
        residentPos = this.tree[livingUnitPos].children.findIndex(r => r.internalID === this.entityId)
      }

      // if it is last resident, show next living unit
      if (residentPos === this.tree[livingUnitPos].children.length - 1) {
        var livingUnit = this.livingUnitId

        if (this.isLivingUnit || residentPos === this.tree[livingUnitPos].children.length - 1) {
          var nextLivingUnitPos = livingUnitPos === this.tree.length - 1 ? 0 : livingUnitPos + 1
          livingUnit = this.tree[nextLivingUnitPos].internalID
        }

        this.$store.commit('smartLogisticSettings/setCurrentResident', null)
        this.$store.commit('smartLogisticSettings/setCurrentLivingUnit', livingUnit)
        return
      }

      var resident = null
      var curResidentPos = residentPos

      // find next not deleted resident
      while (curResidentPos < this.tree[livingUnitPos].children.length - 1) {
        curResidentPos++

        if (
            this.tree[livingUnitPos].children[curResidentPos]
            && (
                this.tree[livingUnitPos].children[curResidentPos].deleted_at === null
                || this.isResidentActiveThisMonth(this.tree[livingUnitPos].children[curResidentPos])
            )
        ){
          resident = this.tree[livingUnitPos].children[curResidentPos]
          break
        }
      }

      if (resident === null) {
        const nextLivingUnitPos = livingUnitPos === this.tree.length - 1 ? 0 : livingUnitPos + 1
        const nResidents = this.tree[nextLivingUnitPos].children.length

        if (nResidents === 0) {
          this.$store.commit('smartLogisticSettings/setCurrentResident', null)
          this.$store.commit('smartLogisticSettings/setCurrentLivingUnit', this.tree[nextLivingUnitPos].internalID)
          return
        }

        curResidentPos = 0

        // find next not deleted resident
        while (curResidentPos < this.tree[livingUnitPos].children.length - 1) {
          if (
              this.tree[livingUnitPos].children[curResidentPos]
              && (
                  this.tree[livingUnitPos].children[curResidentPos].deleted_at === null
                  || this.isResidentActiveThisMonth(this.tree[livingUnitPos].children[curResidentPos])
              )
          ) {
            resident = this.tree[livingUnitPos].children[curResidentPos]
            break
          }

          curResidentPos++
        }
      }

      if (resident) {
        this.$store.commit('smartLogisticSettings/setCurrentLivingUnit', null)
        this.$store.commit('smartLogisticSettings/setCurrentResident', resident.internalID)
      }
    },

    close () {
      this.forceOpen = false
      this.$store.commit('smartLogisticSettings/setCurrentResident', null)
      this.$store.commit('smartLogisticSettings/setCurrentLivingUnit', null)
    },

    updateDate () {
      this.selectedDate = this.overviewDate
    }
  },

  watch: {
    /**
     *
     * @param val
     */
    currentResident (val) {
      if (val !== null && val !== undefined) {
        this.forceOpen = true
        this.type = 'resident'
      }
    },

    /**
     *
     * @param val
     */
    currentLivingUnit (val) {
      if (val !== null && val !== undefined) {
        this.forceOpen = true
        this.type = 'living-unit'
      }
    },
  },

  mounted () {
    this.selectedDate = this.overviewDate
  }
}
</script>

<style lang="scss" scoped>
.tab-panel {

}

.title {
  h1 {
    line-height: 1.1em;

    .name {
      color: #CE20A8;
    }
  }
}
</style>
