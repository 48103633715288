<template>
  <v-card>
    <v-card-text class="body-1">
      <img :src="require('@/assets/img/ico/schliessen.svg')" @click="$emit('closeProdInfo')" width="24px" height="24px"
           class="close-btn"/>
      <v-row class="mt-0">
        <div>
          <div class="d-flex justify-space-between align-center pa-4 pl-0 mt-3 pt-0 text-sm-body-2">
            <!-- icon visible -->
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="pt-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23"
                       viewBox="0 0 28 29">
                    <circle @click="$emit('addToMySortiment',info['artnr'])" id="Ellipse_356"
                            data-name="Ellipse 356" cx="11.5" cy="11.5"
                            r="11.5"
                            :stroke="mySortimentIDs.indexOf(info['objectID']) == -1 ? '#000' : '#fff'"
                            :fill="mySortimentIDs.indexOf(info['objectID']) == -1 ? '#fff' : '#CE20A8'"/>
                  </svg>
                </div>
              </template>
              <span class="body-2" v-if="mySortimentIDs.indexOf(info['artnr']) == -1">meinem Sortiment hinzufügen</span>
              <span class="body-2" v-else>meinem Sortiment entfernen</span>
            </v-tooltip>
          </div>
        </div>
        <div class="mt-4 webshop-link-container">
          <a v-if="!info['ownprod']" class="text-sm-body-2 pt-8 webshop-link"
             :href="'https://www.puras.ch/de/shop/p-'+info['artnr']" target="_blank">Produkt im
            Webshop</a>
        </div>
      </v-row>
      <v-row class="pt-0 mt-0">
        <v-col>
          <v-img v-if="info['bild']" :src="info['bild']" width="100%" height="180px" max-width="140px"
                 max-height="140px" class="mx-auto" contain="contain"></v-img>
          <div v-else style="min-height:140px;padding-top: 25px;">
            <img :src="require('@/assets/img/ico/sortimentsverwaltung-aktiv.svg')" width="100%" height="75px"
                 max-width="80px"
                 max-height="140px" class="mx-auto" contain="contain">
          </div>
          <div class="font-weight-bold text-sm-body-2 mt-3">
            {{ info['artnr'] }}
          </div>
          <div class="product-name">
            {{ info['name'] }}

            <ul class="article-tags" v-if="info['careProLabel']">
              <li>{{ info['careProLabel'] }}</li>
            </ul>

          </div>
          <div class="pt-2 mt-1 mb-3 text-sm-body-2">
            {{ info['txt'] }}
          </div>
          <div class="pt-2 text-sm-body-2 font-weight-bold" v-if="info['kundenpreis']">
            <span v-if="info['uom']">{{ info['uom'] }} -</span> CHF {{ info['kundenpreis'] }}
            <span v-if="!info['ownprod']" class="font-weight-regular">exkl. MwSt.</span>
            <span v-else class="font-weight-regular">inkl. MwSt.</span>
          </div>

          <div v-if="info['isMigel']">
            <v-divider class="my-3" color="black"></v-divider>
            <div>
               <span class="text-sm-body-2 font-weight-bold">
                MiGeL
              </span>
              <span class="text-sm-body-2">
                 {{ info['migelNo'] }}
              </span>
            </div>
            <div>
               <span class="text-sm-body-2 font-weight-bold">
               Preis pro {{ info['zaehleinheit'] }}
              </span>
            </div>
            <div class="pl-4" v-if="!info['ownprod']">
               <span class="text-sm-body-2 font-weight-bold">
               HVB Pflege
              </span>
              <span class="text-sm-body-2">
                <span v-if="info['Pauschale'] > 0">
                 pro Jahr (pro rata)
                </span>
                <span v-if="info['Pauschale'] < 1 &&  info['Kategorie_A'] > 0">
                 nicht anwendbar
                </span>
                <span v-if="info['Pauschale'] < 1 && info['Kategorie_A'] < 1">
                   CHF {{ info['hvb'] }} <span class="font-weight-regular">inkl. MwSt.</span>
                </span>
              </span>
            </div>
            <div class="pl-4">
               <span class="text-sm-body-2 font-weight-bold">
               Kundenpreis
              </span>
              <span class="text-sm-body-2">
                 CHF {{ info['kundenstkpreis'] }} <span class="font-weight-regular">inkl. MwSt.</span>
              </span>
            </div>
          </div>
          <div v-if="info['gtin']">
            <span class="text-sm-body-2 font-weight-bold">
               GTIN
            </span>
            <span class="text-sm-body-2">
                 {{ info['gtin'] }}
            </span>
          </div>
          <div v-if="info['pharmacode']">
            <span class="text-sm-body-2 font-weight-bold">
               Pharmacode
            </span>
            <span class="text-sm-body-2">
                 {{ info['pharmacode'] }}
            </span>
          </div>
          <v-divider class="my-3" color="black"></v-divider>
          <div class="text-sm-body-2" v-if="info['lastBuy'] && !info['ownprod']">
            zuletzt gekauft am: {{ moment(info['lastBuy']).locale('de').format('DD.MM. YYYY') }}
          </div>
          <div class="text-sm-body-2 mt-4">
            <v-row>
              <v-col cols="8">
                <div class="pt-4">
                  <span v-if=" info['lpnr']">
                     LeistungsNr. {{ info['lpnr'] }}
                  </span>
                </div>
              </v-col>
              <v-col cols="4">
                <span class="float-right">
                  <div class="float-left">
                     <img :src="require('@/assets/img/ico/anzahl-bewohner.svg')" class="mt-3" height="20px"
                          contain="contain">
                  </div>
                  <div class="font-weight-bold float-left ml-2">
                    <div style="margin-top:17px;">
                      ({{ info['usedBy'] }})
                    </div>
                  </div>
                </span>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import moment from 'moment'

export default {
  props: ['info', 'mySortimentIDs'],

  name: 'ProductDetailCard',

  data() {
    return {
      moment: moment
    }
  }

}
</script>

<style scoped>
.v-card * {
  font-size: 13.125px !important;
}

.close-btn {
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
}

.webshop-link-container {
  display: inline-block;

}

.webshop-link {
  color: black;
  font-size: 12px;
  text-decoration: none;
  border-bottom: 1px solid black;
  padding-bottom: 3px;
}

.product-name {
  font-size: 16px !important;
}

.font-weight-bold {
  font-family: "Avenir LT Std Heavy" !important;
}
</style>
