<template>
  <div class="month-navigator">
    <v-btn v-if="!viewOnly"
        @click="previousMonth"
        icon
    >
      <span class="icon-slider-links"></span>
    </v-btn>
    <div class="date-indicator">
      {{ value.format('MMMM YYYY') }}
    </div>

    <v-btn
        v-if="!viewOnly && isNextMonthActive"
        @click="nextMonth"
        icon
    >
      <span class="icon-slider-rechts"></span>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'MonthNavigator',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    viewOnly: {
      type: Boolean,
      default: false
    },
    isNextMonthActive: {
      type: Boolean,
      default: true,
    }
  },

  methods: {
    previousMonth () {
      this.$emit('input', this.$moment(this.value).subtract(1, 'month'))
    },

    nextMonth () {
      this.$emit('input', this.$moment(this.value).add(1, 'month'))
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/smartlogistics/mixins/_breakpoint.scss";
.month-navigator {
  display: flex;
  align-items: center;
  gap: 5px;
}

.date-indicator {
  padding-top: 5px;
  text-align: center;
  font-size: 1.5rem;
  line-height: 1;
}
</style>
