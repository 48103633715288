<template>
  <div>
    <v-dialog
        v-model="show"
        persistent
        width="650"
    >
      <template v-slot:activator="{ props }">
        <slot name="button" :open="open"></slot>
      </template>
      <v-card class="main">
        <header>
          <div class="title">
            <h1>
              Optionen Bestellvorschlag
            </h1>
          </div>
          <div class="close" @click="cancel()"><img :src="require('@/assets/img/ico/schliessen.svg')" width="25">
          </div>
        </header>
        <div class="d-flex" style="gap: 40px;">
          <div class="flex">
            <strong>Verbrauchszeitraum</strong><br/>
            <custom-month-picker :dateValue="date" @updateDate="date = $event"></custom-month-picker>
          </div>
          <div class="flex">
            <strong>Wohneinheit</strong><br/>
            <v-radio-group v-model="livingUnit" color="primary" :rules="[rules.ruleNotEmpty]">
              <v-radio :value="livingUnit.id" :label="livingUnit.name"
                       v-for="livingUnit in wohneinheiten"></v-radio>
            </v-radio-group>
          </div>
          <div class="flex">
            <strong>Bedarfszeitraum</strong><br/>
            <v-radio-group v-model="period" color="primary" :rules="[rules.ruleNotEmpty]">
              <v-radio value="1" label="1 Woche"></v-radio>
              <v-radio value="2" label="2 Wochen"></v-radio>
              <v-radio value="4" label="1 Monat"></v-radio>
            </v-radio-group>
          </div>
        </div>
        <v-card-actions class="edit-actions">
          <v-btn variant="flat" color="danger" elevation="0" rounded @click="close()">Abbrechen</v-btn>
          <v-btn color="primary" variant="flat" elevation="0" rounded :disabled="!isValid || downloading"
                 @click="download()">
            <v-progress-circular v-if="downloading" indeterminate size="20" width="2"
                                 style="margin-right:8px;"></v-progress-circular>
            <template v-if="!downloading">Bestellvorschlag generieren</template>
            <template v-if="downloading">Bestellvorschlag wird generiert</template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <SimpleOrderSuggestionPreviewDialog ref="previewDialog"></SimpleOrderSuggestionPreviewDialog>
  </div>
</template>

<script>

import LogoUpload from "@/components/Systemsettings/dialogs/components/LogoUpload.vue";
import CustomMonthPicker from "@/components/form/CustomMonthPicker.vue";
import SimpleOrderSuggestionPreviewDialog
  from "@/components/SimpleOrderSuggestion/dialogs/SimpleOrderSuggestionPreviewDialog.vue";
import moment from "moment";
import {globalRules} from "@/composables/rules";
import {wohneinheitenActions, wohneinheitenGetters} from "@/components/observables/wohneinheiten";

export default {
  name: 'SimpleOrderSuggestionPrintDialog',
  components: {CustomMonthPicker, SimpleOrderSuggestionPreviewDialog},
  emits: ['cancel', 'delete'],
  props: {},
  data() {
    return {
      show: false,
      date: moment().format("YYYY-MM"),
      livingUnit: null,
      period: null,
      rules: globalRules,
      downloading: false
    }
  },
  computed: {
    ...wohneinheitenGetters,

    isValid() {
      return this.date && this.livingUnit && this.period;
    }
  },
  methods: {
    ...wohneinheitenActions,
    open() {
      this.show = true;
    },
    cancel() {
      this.show = false;
      this.$emit('cancel')
    },
    download() {
      this.$refs.previewDialog.open();
      this.$store.dispatch('simpleOrderSuggestion/getPreview', {livingUnit: this.livingUnit, date: this.date, period: this.period})
      this.cancel();
    },
    initData() {
      this.downloading = false;
      this.fetchWohneinheiten();
    }

  },
  watch: {
    show() {
      this.initData();
    }
  },

  mounted() {
    this.initData();
  }
}
</script>

<style scoped lang="scss">
.main {
  padding: 40px 35px 23px 30px;
}

.title {
  h1 {
    margin: 0;
    margin-top: 0 !important;
    font-family: "Avenir LT Std";
    font-weight: 300 !important;
    font-size: 33px !important;
    line-height: 43px;
    margin-bottom: 2.5rem;
  }
}

.close {
  position: absolute;
  top: 30px;
  right: 40px;
  cursor: pointer;
}

.v-alert {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.v-card-text {
  padding-left: 0 !important;
}

.v-card__actions {
  padding: 0 !important;
  padding-top: 10px !important;
  justify-content: right;
}

.hidden {
  opacity: 0;
  height: 0;
  overflow: hidden;
  padding: 0 !important;
  margin: 0 !important;
}

.main {
  float: left;
  width: 100%;
}

.logo-container {
  padding: 0 40px 40px 40px;

  img {
    max-width: 100%;
    max-height: 200px;
    margin: 0 auto;
  }
}

.delete {
  img, .filename {
    display: block;
    float: left;
  }

  .filename {
    width: calc(100% - 37px);
    margin-left: 10px;
    line-height: 28px
  }
}

.title {
  h1 {
    line-height: 1.1em;
  }
}
</style>
