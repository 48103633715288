<template>
  <div v-if="!isUnitLevelLoading">
    <div class="d-flex" style="gap: .8rem;">
      <div v-for="item in dates">
        <div
            @click="changeDate(item)"
            :class="[currentSuggestionId == item.id ? 'active' : '', item.state === 'future' ? 'disabled' : '']"
            class="d-flex cursor-pointer tab-button pt-2 "
            style="gap: .5rem;"
        >
          <div
              :style="{color: orderSuggestionStatColor(item.state,item.status)}"
              style="font-size: 18px" :class="orderSuggestionStatIcon(item.state,item.status)"
          ></div>
          <div>{{ $moment(item.date).format('DD.MM.YYYY') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  orderSuggestionStatIcon,
  orderSuggestionStatColor
} from '../../../composables/SmartLogistics/orderSuggestionStatIcon'

import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'OrderHistoryPills',

  props: {
    unit: {
      type: Number,
      required: true
    }
  },

  computed: {

    ...mapGetters({
      suggestionsLoading: 'smartLogisticOrderSuggestion/isLoading',
      directOrderLoading: 'smartLogisticDirectOrder/isLoading',
      basketLoading: 'smartLogisticBasket/isLoading',
      unitLevelLoading: 'smartLogisticOrderSuggestion/isUnitLevelLoading',
    }),

    isLoading () {
      return this.suggestionsLoading || this.directOrderLoading || this.basketLoading
    },

    isUnitLevelLoading () {
      return this.unitLevelLoading
    },

    dates () {
      return this.$store.getters['smartLogisticOrderSuggestion/suggestionDates']
    },
    currentSuggestionId () {
      return this.$store.getters['smartLogisticOrderSuggestion/currentSuggestionId']
    },
  },

  methods: {
    orderSuggestionStatIcon,
    orderSuggestionStatColor,
    changeDate (item) {
      if (item.state === 'future') {
        return
      }

      this.$store.commit('smartLogisticOrderSuggestion/setChosenDateObject', item)
      //force deactivation of direct order if date is in the past
      if (item.state === 'past') {
        this.$store.commit('smartLogisticSettings/setDirectOrder', false)
      }

      this.$store.dispatch('smartLogisticOrderSuggestion/fetch', { date: item.date, unit: this.unit, loadBasket: true})
    }
  }
}
</script>
