import httpClient from '../../utils/httpClient'
import moment from "moment";

export const smartLogisticDirectOrder = {
  namespaced: true,

  state: {
    saving: false,
    loading: false,
    articles: null,
    currentId: null,
  },

  getters: {
    articlesByLivingUnit: (state) => (params) => {
      if (!state.articles) {
        return []
      }
      return state.articles.filter(l => l.ownerType === 'living-unit' && l.ownerId === params.unit)
    },
    articlesByResident: (state) => (params) => {
      if (!state.articles) {
        return []
      }

      return state.articles.filter(l => l.ownerType === 'resident' && l.ownerId === params.resident && !!l.collective === false)
    },
    isLoading: state => state.loading,
    isSaving: state => state.saving,
  },

  actions: {
    async fetch ({ commit, state }, payload) {
      state.loading = true
      let response = await httpClient({
        url: process.env.VUE_APP_API_LOGISTICS + 'direct-order/by-living-unit/' + payload.unit+"/"+moment().format('YYYY-MM-DD'),
        method: 'get',
      })
      if (response.data.items) {
        commit('setCurrentDirectOrderId', response.data.id)
        commit('setArticles', response.data.items)
      }
      state.loading = false
    },


    async removeArticle({commit, state}, payload) {
      let response = await httpClient({
        url: process.env.VUE_APP_API_LOGISTICS + 'direct-order/' + state.currentId + '/item',
        method: 'delete',
        data: payload
      })
      if (response.data.items) {
        commit('setCurrentDirectOrderId', response.data.id)
        commit('setArticles', response.data.items)
      }
    },

    async assignArticle({commit, state}, payload) {
      let response = await httpClient({
        url: process.env.VUE_APP_API_LOGISTICS + 'direct-order/' + state.currentId + '/item',
        method: 'post',
        data: payload
      })
      if (response.data.items) {
        commit('setCurrentDirectOrderId', response.data.id)
        commit('setArticles', response.data.items)
      }
    },

    async updateArticle ({ commit, state }, payload) {
      if (state.updateTimeout) {
        clearTimeout(state.updateTimeout)
      }

      state.updateTimeout = setTimeout(async () => {
        state.saving = true
        let response = await httpClient({
          url: process.env.VUE_APP_API_LOGISTICS + 'direct-order/' + state.currentId + '/item',
          method: 'put',
          data: {
            artikelNrLAG: payload.artikelNrLAG,
            ownerType: payload.ownerType,
            ownerId: payload.ownerId,
            quantity: payload.quantity,
            active: payload.active,
          }
        })
        if (response.data.items) {
          commit('setCurrentDirectOrderId', response.data.id)
          commit('setArticles', response.data.items)
        }
        state.saving = false
      }, 400)
    },
  },

  mutations: {
    setArticles (state, data) {
      state.articles = data;
    },
    setCurrentDirectOrderId (state, data) {
      state.currentId = data;
    },
  },
  persist: false
}
