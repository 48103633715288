<template>
  <Dialog
      :width="1110"
      fix-height="90vh"
      @close="atClose"
  >
    <template v-slot:button="{ on, attrs }">
      <div v-if="isLivingUnit || isResident" class="d-flex align-center hov-opacity" v-bind="attrs" v-on="on">
        <span class="d-block text-right mr-3 mt-1 caption cursor-pointer extra-small"
              style="line-height:1.5;font-weight: bold !important">
          Produkte für <br> {{ productsForLabel }}
        </span>
        <v-img :src="require('@/assets/img/ico/hinzufuegen-box.svg')" alt=""
               class="cursor-pointer" style="width:46px; height:auto;" max-width="46px">
        </v-img>
      </div>

      <div v-if="currentView === 'basket' && canBeEdited" class="d-flex align-center hov-opacity mt-8" v-bind="attrs" v-on="on">
        <div>
          <span class="icon-hinzufuegen primary--text" style="font-size: 25px"></span>
        </div>
        <div class="primary--text ml-3 mr-0">
          Zusätzliche Artikel
        </div>
      </div>
    </template>

    <template #header>
      <h2 v-if="isLivingUnit">Produkte hinzufügen für <span class="text--primary">Wohneinheit {{ owner.name }}</span></h2>
      <h2 v-if="isResident">Produkte hinzufügen für <span class="text--primary">{{ owner.name }}</span></h2>
      <h2 v-if="isBasket">Artikel für <span class="text--primary">{{ owner.name }}</span>  bestellen</h2>
    </template>

    <div>
      <ArticlePicker
          :alreadyAssignedArticles="alreadyAssignedArticles"
          :filteredArticles="filteredArticles"
          :type="type"
          :currentView="currentView"
          @assignArticle="assign"
          @detachArticle="detach"
      />
    </div>
    <ConfirmDialog ref="confirmDialog">
      <template v-slot:button="{ on, attrs }">
      </template>
      <template #header>
        <h2>Produkt permanent hinzufügen</h2>
      </template>
      <template #confirmText>Ja, permanent hinzufügen</template>
      <template #cancelText>Nein, nur in diesem Monat</template>
      <div>Möchten Sie dieses Produkt auch zum Versorgungsplan
        {{ isResident ? "dieses Bewohners" : "dieser Wohneinheit" }} hinzufügen?
      </div>
    </ConfirmDialog>
  </Dialog>
</template>

<script>
import Dialog from '@/components/SmartLogistics/Design/Dialog.vue'
import ConfirmDialog from '@/components/SmartLogistics/Design/ConfirmDialog.vue'
import ProductChooser from '@/components/products/ProductChooser.vue'
import {mapGetters} from 'vuex'
import ArticlePicker from '@/components/SmartLogistics/Article/ArticlePicker.vue'

export default {
  name: 'ArticlePickerDialog',
  components: {ArticlePicker, ProductChooser, Dialog, ConfirmDialog},
  props: {
    owner: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: 'resident',
      validator: function (value) {
        return ['resident', 'living-unit', 'basket'].indexOf(value) !== -1
      }
    },

    currentView: {
      type: String,
      default: 'care-plan',
      validator: function (value) {
        return ['care-plan', 'order-suggestion', 'direct-order', 'basket'].indexOf(value) !== -1
      }
    }
  },
  emits: ['assignProduct'],
  data() {
    return {
      ask: false,
      activeSection: null,
      selectedDate: null,
      saving: false,
      requiresLivingUnitReload: false,
    }
  },

  computed: {
    ...mapGetters({
      date: 'smartLogisticSettings/selectedDate',
      suggestionsLoading: 'smartLogisticOrderSuggestion/isLoading',
      directOrderLoading: 'smartLogisticDirectOrder/isLoading',
      basketLoading: 'smartLogisticBasket/isLoading',
      basket: 'smartLogisticBasket/basket',
    }),

    isBasketOrSuggestionLoading() {
      return this.suggestionsLoading || this.directOrderLoading || this.basketLoading
    },

    canBeEdited() {
      return !['confirmed', 'processing'].includes(this.basket.status)
    },

    alreadyAssignedArticles() {
      if (this.currentView === 'care-plan') {
        if (this.isLivingUnit) {
          return this.owner.data
        }

        if (this.isResident) {
          return this.owner.children
        }
      } else if (this.currentView === 'order-suggestion') {
        if (this.isLivingUnit) {
          return this.$store.getters['smartLogisticOrderSuggestion/orderSuggestionLivingUnit']({unit: this.owner.internalID});
        }

        if (this.isResident) {
          return this.$store.getters['smartLogisticOrderSuggestion/orderSuggestionResidentAll']({resident: this.owner.internalID});
        }
      } else if (this.currentView === 'direct-order') {
        if (this.isLivingUnit) {
          return this.$store.getters['smartLogisticDirectOrder/articlesByLivingUnit']({unit: this.owner.internalID});
        }

        if (this.isResident) {
          return this.$store.getters['smartLogisticDirectOrder/articlesByResident']({resident: this.owner.internalID});
        }
      } else if (this.currentView === 'basket') {
        return this.$store.getters['smartLogisticBasket/basketItems']
      }

      //no pre-assigned articles for order-suggestion and baskets
      return []
    },

    filteredArticles() {
      switch (this.currentView) {
        case 'care-plan':
          return [];
        case 'order-suggestion':
          if (this.isLivingUnit) {
            return this.$store.getters['smartLogisticOrderSuggestion/orderSuggestionLivingUnit']({unit: this.owner.internalID}).filter(item => !item.manuallyAdded).map(item => item.internalID);
          }
          else if (this.isResident) {
            return this.$store.getters['smartLogisticOrderSuggestion/orderSuggestionResidentAll']({resident: this.owner.internalID}).filter(item => !item.manuallyAdded).map(item => item.internalID);
          }
        case 'direct-order':
          if (this.isLivingUnit) {
            return this.$store.getters['smartLogisticDirectOrder/articlesByLivingUnit']({unit: this.owner.internalID}).filter(item => !item.manuallyAdded).map(item => item.internalID);
          }
          else if (this.isResident) {
            return this.$store.getters['smartLogisticDirectOrder/articlesByResident']({resident: this.owner.internalID}).filter(item => !item.manuallyAdded).map(item => item.internalID);
          }
        case 'basket':
          return this.$store.getters['smartLogisticBasket/basketItems'].map(item => item.artikelNrLAG);

      }
    },

    productsForLabel() {
      if (this.isLivingUnit) {
        return 'Wohneinheit'
      }

      if (this.isResident) {
        return 'Bewohner'
      }
    },

    isResident() {
      return this.type === 'resident'
    },

    isBasket() {
      return this.type === 'basket'
    },

    isLivingUnit() {
      return this.type === 'living-unit'
    },
  },

  methods: {
    /**
     *
     * @param artno
     * @param isCollectiveArticle
     */
    async assign({artno, isCollectiveArticle}) {
      if (this.currentView === 'care-plan') {
        if (this.isLivingUnit) {
          this.$store.dispatch('tree/assignArticleToLivingUnit', {
            artno: artno,
            livingUnitId: this.owner.internalID,
            date: this.date.format('YYYY-MM-DD'),
          })
        } else {
          this.$store.dispatch('tree/assignArticle', {
            dateString: this.date.format('YYYY-MM-DD'),
            prodId: artno,
            targetType: this.type,
            internalID: this.owner.internalID,
          })

          if (isCollectiveArticle) {
            this.requiresLivingUnitReload = true
          }
        }
      }

      if (this.currentView === 'order-suggestion') {
        this.$refs.confirmDialog.open().then(result => {
          if (result) {
            // do not use tree/assignArticle on livingunits
            // => tree/AssignArticle on livingunits is legacy and causes the article to be assigned to all residents of the unit
            // assigning products to livingunits directly is new since smart logistics, therefore another endpoint is needed.
            if(this.type === 'living-unit' || this.type === 'livingunit') {
              this.$store.dispatch('tree/assignArticleToLivingUnit', {
                artno: artno,
                livingUnitId: this.owner.internalID,
                date: this.date.format('YYYY-MM-DD'),
              })

            } else {
              this.$store.dispatch('tree/assignArticle', {
                dateString: this.date.format('YYYY-MM-DD'),
                prodId: artno,
                targetType: this.type,
                internalID: this.owner.internalID,
              })
            }

            this.$store.dispatch('smartLogisticOrderSuggestion/assignArticle', {
              artikelNrLAG: artno,
              ownerType: this.type,
              ownerId: this.owner.internalID,
              removable: false,
            })

            // ensures that the timeline will be refreshed
            this.$store.commit('tree/resetDates', this.date.format('YYYY-MM-DD'))
          } else {
            this.$store.dispatch('smartLogisticOrderSuggestion/assignArticle', {
              artikelNrLAG: artno,
              ownerType: this.type,
              ownerId: this.owner.internalID,
              removable: true
            })
          }
        });
      }
      if (this.currentView === 'direct-order') {
        this.$refs.confirmDialog.open().then(result => {
          if (result) {
            this.$store.dispatch('tree/assignArticle', {
              dateString: this.date.format('YYYY-MM-DD'),
              prodId: artno,
              targetType: this.type,
              internalID: this.owner.internalID,
            })
            this.$store.dispatch('smartLogisticDirectOrder/assignArticle', {
              artikelNrLAG: artno,
              ownerType: this.type,
              ownerId: this.owner.internalID,
              removable: false,
            })
          } else {
            this.$store.dispatch('smartLogisticDirectOrder/assignArticle', {
              artikelNrLAG: artno,
              ownerType: this.type,
              ownerId: this.owner.internalID,
              removable: true
            })
          }
        });
      }

      if (this.currentView === 'basket') {
        this.$store.dispatch('smartLogisticBasket/assignArticle', {
          artikelNrLAG: artno,
          packageUnitID: 10,
          quantity: 1,
        })
      }
    },

    /**
     *
     * @param artno
     * @param isCollectiveArticle
     */
    detach({artno, isCollectiveArticle}) {

      if (this.currentView === 'care-plan') {
        if (this.isLivingUnit) {
          this.$store.dispatch('tree/detachArticleFromLivingUnit', {
            artno: artno,
            livingUnitId: this.owner.internalID,
            date: this.date.format('YYYY-MM-DD'),
          })
        } else {
          this.$store.dispatch('tree/detachArticle', {
            prodId: artno,
            bewohnerID: this.owner.internalID,
            dateString: this.date.format('YYYY-MM-DD'),
          })

          if (isCollectiveArticle) {
            this.requiresLivingUnitReload = true
          }
        }
      } else if (this.currentView === 'order-suggestion') {
        this.$store.dispatch('smartLogisticOrderSuggestion/removeArticle', {
          artikelNrLAG: artno,
          ownerType: this.type,
          ownerId: this.owner.internalID,
        })
      } else if (this.currentView === 'direct-order') {
        this.$store.dispatch('smartLogisticDirectOrder/removeArticle', {
          artikelNrLAG: artno,
          ownerType: this.type,
          ownerId: this.owner.internalID,
        })
      }
    },

    /**
     * at close hook
     */
    atClose() {
      // check if living unit data needs to be reloaded
      if (!this.isLivingUnit && this.requiresLivingUnitReload) {
        this.$store.dispatch('tree/fetchLivingUnitData', {
          dateString: this.date.format('YYYY-MM-DD'),
          livingUnitId: this.owner.wohneinheiten_id,
        })
      }
    }
  },
}
</script>

<style lang="scss">
.icon-kommentar {
  font-size: 30px;
}

.card-actions {
  text-align: right;

  button + button {
    margin-left: 10px;
  }
}
</style>
