<template>
  <div class="direktabrechnung-data">
    <div>
      <div class="data-row -header align-end">
        <div
            v-for="(col, index) in dataCols"
            :key="'h-col'+index"
            style="flex-shrink: 0; align-items: center; border-top-left-radius: 10px; border-top-right-radius: 10px;"
            :style="{ 'flex-basis': col.width, 'justify-content': col.text, 'text-align': col.text }"
            class="d-flex py-1"
            :class="(index===dataCols.length-1)? 'px-2 ' + (cartHasBeenSent? 'gray' : 'beige'): ''"
        >
          <b>{{ col.title }}</b>
        </div>

        <div class="controls"></div>
      </div>

      <RecordEditableRow
          v-for="(record, i) in nonMigelRecords"
          :key="'record'+i"
          :record="record"
          :columnConfig="dataCols"
          :cart="cart"
          :isBewohner="true"
      />

      <div class="data-row" v-if="nonMigelRecords.length === 0">
        <div
            v-for="(col, index) in dataCols"
            :key="'f-col'+index"
            style="flex-shrink: 0; align-items: center; "
            :style="{ 'flex-basis': col.width, 'justify-content': col.text }"
            class="d-flex py-1"
            :class="(index===dataCols.length-1)? 'px-2 ' + (cartHasBeenSent? 'gray' : 'beige'): ''"
        >
          <div v-if="index===0" class="py-1">
            Kein persönliches Material vorhanden
          </div>
        </div>
      </div>
    </div>

    <div class="data-row">
      <div
          v-for="(col, index) in dataColsMigel"
          :key="'sp-col'+index"
          style="height: 40px;flex-shrink: 0; align-items: center;"
          :style="{ 'flex-basis': col.width, 'justify-content': col.text }"
          class="d-flex py-1"
          :class="(index===dataColsMigel.length-1)? 'px-2 ' + (cartHasBeenSent? 'gray' : 'beige'): ''"
      >
      </div>
    </div>
    <div>
      <div class="data-row -header align-end">
        <div
            v-for="(col, index) in dataColsMigel"
            :key="'f-col'+index"
            style="flex-shrink: 0; align-items: center"
            :style="{ 'flex-basis': col.width, 'justify-content': col.text, 'text-align': col.text }"
            class="d-flex py-1"
            :class="(index===dataColsMigel.length-1)? 'px-2 ' + (cartHasBeenSent? 'gray' : 'beige'): ''"
        >
          <b>{{ col.title }}</b>
        </div>

        <div class="controls"></div>
      </div>

      <RecordEditableRow
          v-for="(record, i) in migelRecords"
          :key="'record'+i"
          :record="record"
          :columnConfig="dataColsMigel"
          :cart="cart"
          :is-migel="true"
          :isBewohner="true"
      />

      <div class="data-row"  v-if="migelRecords.length === 0">
        <div
            v-for="(col, index) in dataCols"
            :key="'f-col'+index"
            style="flex-shrink: 0; align-items: center; "
            :style="{ 'flex-basis': col.width, 'justify-content': col.text }"
            class="d-flex py-1"
            :class="(index===dataCols.length-1)? 'px-2 ' + (cartHasBeenSent? 'gray' : 'beige'): ''"
        >
          <div v-if="index===0" class="py-1">
            Keine MiGeL Produkte vorhanden
          </div>
        </div>
      </div>

      <div class="data-row -footer">
        <div
            v-for="(col, index) in dataCols"
            :key="'f-col'+index"
            style="flex-shrink: 0; align-items: center; border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;"
            :style="{ 'flex-basis': col.width, 'justify-content': col.text }"
            class="d-flex py-1"
            :class="(index===dataCols.length-1)? 'px-2 ' + (cartHasBeenSent? 'gray' : 'beige'): ''"
        >
          <b v-if="index===0">
            Total
          </b>
          <b v-else-if="col.function==='total_nicht_verrechenbar'">
            {{ totalNichtVerrechenbar }}
          </b>
          <b v-else-if="index===dataCols.length-1">
            {{ totalVerrechenbar }}
          </b>
        </div>
        <div class="controls"></div>
      </div>
    </div>
  </div>
</template>
<script>
import {rowConfig} from "@/config/DirektabrechnungDetailConfig"
import RecordEditableRow from "@/components/direktabrechnung/RecordEditableRow.vue";

export default {
  name: "BewohnerDetailListingBewohner",
  components: {RecordEditableRow},
  props: {
    cart: {
      type: Object,
      required: true
    },
  },

  computed: {
    dataCols() {
      return rowConfig['bewohner']
    },
    dataColsMigel() {
      return rowConfig['bewohner_migel']
    },
    records() {
      return this.cart['cart_bewohner'] ?? []
    },
    cartHasBeenSent() {
      return this.cart.status !== 'prepared'
    },

    nonMigelRecords() {
      return this.records.filter(r => !r.migelnr || r.migelnr.length === 0)
    },

    migelRecords() {
      return this.records.filter(r => r.migelnr && r.migelnr.length > 0)
    },

    totalVerrechenbar() {
      return this.records.reduce((a, r) => {
        return a + (parseFloat(r.verrechenbar) * r.menge);
      }, 0).toFixed(2)
    },

    totalNichtVerrechenbar() {
      var total = this.records.reduce((a, r) => {
        if(r.jahrespauschale) {
          return a + (r.menge * r.preis)
        } else {
          return a + (parseFloat(r.nicht_verrechenbar) * r.menge);
        }
      }, 0)

      return (Math.round(total * 20) / 20).toFixed(2)
    }
  },
  methods: {}
}
</script>