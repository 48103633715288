<template>
  <Teleport to="#page-header-actions">
    <slot />
  </Teleport>
</template>

<script>
import Teleport from 'vue2-teleport';

export default {
  name: 'PageHeaderActions',
  components: {Teleport},
}
</script>