<template>
  <div>
    <PageHeaderActions>
      <div class="search d-flex justify-center">
        <!--<v-text-field
            v-model="searchTerm"
            placeholder="Suche nach Name, Vorname, Zimmer"
            clearable
            light
            autocomplete="off"
            class="d-none d-md-block mt-4"
            style="margin-top: -9px;"
        />-->
        <MonthNavigator v-if="currentSection === 'care-plan'" v-model="selectedDate"/>
      </div>
    </PageHeaderActions>

    <v-container>
      <v-row>
        <v-col cols="12" class="md-pl-0 pb-0 mb-0 ">
          <h1 class="primary--text mb-0">
            Versorgung Bewohner
          </h1>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="d-md-none mt-4">
      <!--<v-text-field
          v-model="searchTerm"
          placeholder="Suche nach Name, Vorname, Zimmer"
          clearable
          light
          autocomplete="off"
          style="margin-top: -9px;"
      />-->
      <div class="d-flex justify-center">
        <MonthNavigator v-if="currentSection === 'care-plan'" v-model="selectedDate" class="d-sm-none"/>
      </div>
    </v-container>

    <v-container>
      <div class="sl-expansion-panel-header sticky-row section-switch">
        <div class="panel-title">
          <template v-if="isMobile">
            <v-menu
                offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    style="min-width: 200px"
                >
                  {{ $t('sl.sections.' + currentSection) }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                    v-for="(section, index) in planConfig.sections"
                    :key="index"
                    link
                >
                  <v-list-item-title
                      @click="currentSection = section"
                  >
                    {{ $t('sl.sections.' + section) }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-else>
            <div class="tab-panel">
              <button
                  v-for="(section, index) in planConfig.sections"
                  :key="index"
                  class="tab-button mr-3 mb-3"
                  :class="{ 'active': currentSection === section }"
                  @click="currentSection = section"
              >
                {{ $t('sl.sections.' + section) }}
              </button>
            </div>
          </template>
        </div>
        <div v-if="currentSection === 'order' && currentOrderViewMode == 'resident'"
             class="panel-info order-till-label flex-grow-1 text-right align-self-end">
          <div class="os-tate-info">
            <div class="os-tate-info_date">
              <b>{{ $t('sl.order-suggestion.order-till') }}</b>
            </div>
            <div class="os-tate-info_state"></div>
          </div>
        </div>
      </div>
      <slot/>
    </v-container>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import MonthNavigator from '@/components/SmartLogistics/Design/MonthNavigator.vue'
import PageHeader from "@/components/layout/PageHeader.vue";
import PageHeaderActions from "@/components/layout/PageHeaderActions.vue";

export default {
  name: 'Layout',
  components: {PageHeaderActions, PageHeader, MonthNavigator},
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll(event) {
      const stickyRow = document.querySelector('.sticky-row');
      if (stickyRow) {
        if (window.scrollY > 115) {
          stickyRow.classList.add('sticky');
        } else {
          stickyRow.classList.remove('sticky');
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'system/isMobile',
      planConfig: 'smartLogisticSettings/planConfig',
      isSearchActive: 'smartLogisticSettings/isSearchActive',
      currentOrderViewMode: 'smartLogisticSettings/currentOrderViewMode',
    }),


    selectedDate: {
      get() {
        return this.$store.getters['smartLogisticSettings/selectedDate']
      },
      set(value) {
        this.$store.commit('smartLogisticSettings/setSelectedDate', value)
      }
    },

    searchTerm: {
      get() {
        return this.$store.getters['smartLogisticSettings/searchTerm']
      },
      set(value) {
        this.$store.commit('smartLogisticSettings/setSearchTerm', value)
      }
    },

    currentSection: {
      get() {
        return this.$store.getters['smartLogisticSettings/currentSection']
      },
      set(value) {
        this.$store.commit('smartLogisticSettings/setCurrentSection', value)
      }
    }
  },
}
</script>
<style lang="scss" scoped>
@import "@/scss/smartlogistics/mixins/_breakpoint.scss";

.sticky-row {
  position: sticky;
  top: 115px;
  background: white;
  z-index: 12;
  margin-bottom: 10px;

  .order-till-label {
    opacity: 1;
    display: flex;
    margin-right: 75px;
  }

  &.sticky {
    .order-till-label {
      opacity: 0;
    }
  }
}
.os-tate-info {
  @include breakpoint(md) {
  display: grid;
  grid-template-columns: 200px 95px;
  align-items: center;
  text-align: right;
  justify-content: flex-end;

  .os-tate-info_date {
    text-align: left;
  }
  }
}
</style>
