<template>
  <v-btn
      outlined color="black"
      class="xsmall-button detail-button"
      @click="open($event)"
  >
    <div class="d-flex align-center px-1">
      <v-img
          :src="require('@/assets/img/ico/kalender.svg')" alt=""
          max-width="16px"
      ></v-img>
      <span class="d-block mt-1 ml-1" style="line-height:1; font-size: 16px;">Monatsübersicht</span>
    </div>
  </v-btn>
</template>

<script>
export default {
  name: 'TimelineOpener',

  props: {
    entityId: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      default: 'resident',
      validator: function (value) {
        return ['resident', 'living-unit'].indexOf(value) !== -1
      }
    },
  },

  methods: {
    open (e) {
      e.stopPropagation();
      if (this.type === 'living-unit') {
        this.$store.commit('smartLogisticSettings/setCurrentLivingUnit', this.entityId)
      } else {
        this.$store.commit('smartLogisticSettings/setCurrentResident', this.entityId)
      }
    }
  }
}
</script>
