<template>

  <v-hover v-slot="{hover}">
    <img :src="hover ? require('@/assets/img/ico/loeschen-hover.svg') : require('@/assets/img/ico/loeschen.svg')"
         @click="deleteArticle" class="cursor-pointer mr-3" style="margin-left: -5px" width="27">
  </v-hover>
</template>
<script>

export default {
  name: 'BasketArticleDelete',

  props: {
    article: {
      type: Object,
      required: true
    },
  },

  methods: {
    deleteArticle() {
      this.$store.dispatch('smartLogisticBasket/deleteArticle', {artikelNrLAG: this.article.artikelNrLAG, articlePackageUnitId: this.article.articlePackageUnitId})
    }
  }
}
</script>