<template>
  <div>
    <div class="data-row">
      <div
          v-for="(col, index) in columnConfig"
          :key="'col'+index"
          style="flex-shrink: 0; align-items: flex-start; padding: 10px 0;"
          :style="{ 'flex-basis': col.width, 'justify-content': col.text }"
          class="d-flex"
          :class="(col.function && col.function.startsWith('total_'))? 'px-2 ' + ((col.function === 'total_verrechenbar' || isBewohner)? (cartHasBeenSent? 'gray' : 'beige') : (cartHasBeenSent? 'lightgray' : 'leightbeige')): ''"
      >
        <template v-if="col.attrs">
          <template v-for="attr in col.attrs">
            <span class="mr-4">{{ record[attr] }}</span>
          </template>
        </template>
        <template v-else-if="col.attr">
          <template v-if="col.attr==='menge'">
            <v-text-field
                v-if="!isBewohner && (!fixedRateDepleted || (!isKrankenkasse && !isMigel))"
                type="number"
                v-model="record['menge']"
                :disabled="cartHasBeenSent"
                hide-details
                class="ma-0 pa-0"
                height="28"
                @input="function() { record['menge'] = parseInt(record['menge']); mengeChanged()}"
            />
            <span v-else>{{ record.menge }}</span>
          </template>
          <template v-else-if="col.attr==='hvb_pflege' && hasMigelFixedRate">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <img
                    :src="require(fixedRateDepleted? '@/assets/img/ico/pauschale-aufgebraucht.svg': '@/assets/img/ico/pauschale.svg')" alt=""
                    height="22"
                    v-bind="attrs"
                    v-on="on"
                />
              </template>
              {{ fixedRateDepleted ? 'Pauschale aufgebraucht' : 'Pauschale' }}
            </v-tooltip>
          </template>
          <span v-else>{{ isNaN(record[col.attr]) || col.attr === 'artikelnr'? record[col.attr]: parseFloat(record[col.attr]).toFixed(col.attr === 'preis' && isKrankenkasse ? 4 : 2) }}</span>
        </template>
        <template v-else-if="col.function">
          <template v-if="isBewohner">
            <span v-if="record.split && col.function==='total_verrechenbar'">{{ total_verrechenbar(record) }}</span>
            <span v-else-if="hasMigelFixedRate && col.function!=='total_verrechenbar'">{{ total_verrechenbar(record) }}</span>
            <span v-else-if="col.function!=='total_nicht_verrechenbar' || !record.split || (col.function==='total_nicht_verrechenbar' && isBewohner)">{{ callFunction(col.function) }}</span>
          </template>
          <template v-else>
            <span v-if="hasMigelFixedRate && fixedRateDepleted && col.function==='total_nicht_verrechenbar'">{{ total_verrechenbar(record, 4) }}</span>
            <template v-else-if="col.function==='total_nicht_verrechenbar'">
              <v-tooltip
                  v-if="!record.split"
                  top
              >
                <template v-slot:activator="{ on, attrs }">
                  <div
                      v-bind="attrs"
                      v-on="on"
                  >
                    {{ total_nicht_verrechenbar(record, 4) }}
                  </div>
                </template>
                <div>
                  Menge x (Preis - HVB-Pflege)
                </div>
                <div>
                  {{ record.menge }} x ({{ record.preis }} - {{ record.hvb_pflege }})
                </div>
              </v-tooltip>
            </template>
            <span v-else-if="col.function!=='total_verrechenbar' || col.function==='total_verrechenbar' && !fixedRateDepleted">{{ callFunction(col.function) }}</span>
          </template>
        </template>
      </div>

      <div class="controls">
        <v-switch
            v-if="isKrankenkasse && hasSwitcher"
            :disabled="cartHasBeenSent"
            dense
            flat
            inset
            hide-details
            v-model="record.split"
            class="ma-0 mt-1"
        />
      </div>
    </div>
    <div
        v-if="hasSwitcher && record.split && isKrankenkasse"
        class="data-row -editable"
    >
      <div
          v-for="(col, index) in columnConfig"
          :key="'col'+index"
          style="flex-shrink: 0; align-items: flex-start;"
          :style="{ 'flex-basis': col.width, 'justify-content': col.text }"
          class="d-flex py-3"
          :class="(index===columnConfig.length-1)? 'px-2 ' + (cartHasBeenSent? 'lightgray' : 'beige'): ''"
      >
        <template v-if="col.attr==='menge' && hasMigelFixedRate">
          <CounterInput
              v-if="isKrankenkasse"
              v-model="bewohnerAnteil"
              :max="totalMengeInAllCarts"
          />
        </template>
        <template v-else>
          <span v-if="index===1">
            Bewohneranteil
          </span>
          <span v-else-if="col.attr==='einheit' && hasMigelFixedRate">
            {{ record['einheit'] }}
          </span>
          <span v-else-if="col.function==='total_nicht_verrechenbar'">
            <span v-if="fixedRateDepleted">
              {{ (bewohnerAnteil * record.preis).toFixed(2) }}
            </span>
            <v-tooltip
                v-else
                top
            >
                <template v-slot:activator="{ on, attrs }">
                  <div
                      v-bind="attrs"
                      v-on="on"
                  >
                    <template v-if="!hasMigelFixedRate">
                      {{ total_nicht_verrechenbar(record, 4) }}
                    </template>
                    <template v-else>
                      {{ (bewohnerAnteil * record.preis).toFixed(2) }}
                    </template>
                  </div>
                </template>
                <div>
                  Menge x (Preis - HVB-Pflege)
                </div>
                <div>
                  {{ record.menge }} x ({{ record.preis }} - {{ record.hvb_pflege }})
                </div>
              </v-tooltip>
          </span>
        </template>
      </div>
      <div>
      </div>
    </div>
  </div>
</template>

<script>
import {isArray} from "lodash";
import CounterInput from "@/components/direktabrechnung/CounterInput.vue";
import {mapGetters} from "vuex";

export default {
  name: "RecordEditableRow",
  components: {CounterInput},

  props: {
    record: {
      type: Object,
      required: true
    },
    columnConfig: {
      type: Array,
      required: true
    },
    cart: {
      type: Object,
      require: true
    },
    isKrankenkasse: {
      type: Boolean,
      default: false
    },
    isMigel: {
      type: Boolean,
      default: false
    },
    isBewohner: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      bewohnerAnteil: 0
    }
  },

  computed: {
    ...mapGetters({
      pauschalen: 'direktabrechnung/bewohnerPauschalenStatus'
    }),
    cartHasBeenSent() {
      return this.cart.status !== 'prepared'
    },

    bewohnerRecord() {
      return this.cart.cart_bewohner.find(r => r.artikelnr === this.record.artikelnr)
    },

    hasMigelFixedRate() {
      return this.record.jahrespauschale
    },

    fixedRateDepleted() {
      return this.pauschalen && this.pauschalen.filter(p => !p.active && p.MiGeL_Nummern.includes(this.record.migelnr)).length > 0
    },

    totalMengeInAllCarts() {

      if (this.bewohnerRecord) {
        return +this.record.menge + +this.bewohnerRecord.menge
      }

      return +this.record.menge
    },

    hasSwitcher() {
      return (this.hasMigelFixedRate && this.fixedRateDepleted) || (this.total_nicht_verrechenbar(this.record,4) > 0)
    }
  },

  methods: {
    callFunction(func) {
      return this[func](this.record)
    }
    ,
    total_verrechenbar(r, digits) {
      const total = (parseFloat(r.verrechenbar) * r.menge).toFixed(digits ?? 2)

      if (total > 0)
        return total

      return ''
    },

    total_nicht_verrechenbar(r, digits) {
      const total = (parseFloat(r.nicht_verrechenbar) * r.menge).toFixed(digits ?? 2)

      if (total > 0)
        return total

      return ''
    },

    position_total(r) {
      return (parseFloat(r.preis) * r.menge).toFixed(2)
    },

    updateRecordSplitData() {
      if (this.hasSwitcher && this.record.split ) {
        if (this.bewohnerAnteil >= 0) {
          if (this.hasMigelFixedRate) {
            this.record.menge = this.totalMengeInAllCarts - this.bewohnerAnteil

            if (this.bewohnerRecord) {
              this.bewohnerRecord.menge = this.bewohnerAnteil
            } else {
              const bewohnerRecord = Object.assign({}, this.record)
              this.cart.cart_bewohner.push(Object.assign(bewohnerRecord, {menge: this.bewohnerAnteil}))
            }
          } else {
            if (this.bewohnerRecord) {
              this.bewohnerRecord.menge = this.record.menge
            } else {
              const bewohnerRecord = Object.assign({}, this.record)
              this.cart.cart_bewohner.push(Object.assign(bewohnerRecord, {menge: this.record.menge}))
            }
          }
        } else {
          if (this.bewohnerRecord) {
            var pos = this.cart.cart_bewohner.findIndex(r => r.artikelnr === this.record.artikelnr)

            if (pos !== -1) {
              this.cart.cart_bewohner.splice(pos, 1)
            }
          }
        }
      } else if (+this.record.menge === 0) {
        // if menge was set to 0, reset bewohner cart and all split settings
        this.record.split = false
        this.bewohnerAnteil = 0
        var pos = this.cart.cart_bewohner.findIndex(r => r.artikelnr === this.record.artikelnr)

        if (pos !== -1) {
          this.cart.cart_bewohner.splice(pos, 1)
        }
      }
    },

    mengeChanged() {
      this.updateRecordSplitData()
    }
  },

  watch: {
    bewohnerAnteil() {
      this.updateRecordSplitData()
    },


    'record.split': function () {
      if (this.hasSwitcher) {
        const totalMengeInAllCarts = this.totalMengeInAllCarts

        if (!this.record.split) {
          this.bewohnerAnteil = 0

          if (this.hasMigelFixedRate) {
            this.record.menge = totalMengeInAllCarts
          }

          if (this.bewohnerRecord) {
            // remove product from bewohner cart
            var pos = this.cart.cart_bewohner.findIndex(r => r.artikelnr === this.record.artikelnr)
            if (pos !== -1) {
              this.cart.cart_bewohner.splice(pos, 1)
            }
          }
        } else {
          this.bewohnerAnteil = totalMengeInAllCarts
        }
      }
    },

  },

  mounted() {
    // ensure split propery exists => preventing errors
    if (!this.record.split) {
      this.record.split = false
    }

    if (this.bewohnerRecord) {
      this.bewohnerAnteil = +this.bewohnerRecord.menge
    }
  }
}
</script>

<style lang="scss" scoped>

</style>