<template>
  <v-dialog
      v-model="dialog"
      persistent
      width="1100"
      content-class="custom-dialog"
  >
    <template v-slot:activator="{ props }">
      <slot name="button" :open="open"></slot>
    </template>

    <v-card class="main" v-if="dialog">
      <template v-if="editablePrescription">
        <header>
          <div class="title">
            <h1>Ärztliche Verordnung
              <template v-if="bewohnerData">- <span class="primary--text">{{
                  bewohnerData.vorname + ' ' + bewohnerData.name
                }}</span>
              </template>
            </h1>
          </div>
          <div
              @click="cancel()"
              class="close"
              :class="{ disabled: disabled}"
          >
            <img :src="require('@/assets/img/ico/schliessen.svg')" width="25"/>
          </div>
        </header>

        <v-divider></v-divider>

        <div class="content my-8">
          <div>
            <component
                :is="pages[curPage - 1].component"
                ref="childComponent"
                v-model="pages[curPage - 1].valid"
                :disabled="disabled"
                :editablePrescription="editablePrescription"
                :bewohnerData="bewohnerData"
                :bewohnerMedication="bewohnerMedication"
            />
          </div>

          <div class="mb-4 disclaimer">
            Die Verordnung wird nicht gespeichert. Drucken Sie sie aus und legen Sie das signierte Rezept unter
            «Verordnung / Rezept» ab.
          </div>

          <div class="navigator-container">
            <div></div>

            <div class="navigator">
              <v-hover v-slot="{ hover }" v-if="curPage !== 1">
                <img
                    :src="hover ? require('@/assets/img/ico/circle-arrow-active.svg') : require('@/assets/img/ico/circle-arrow.svg')"
                    class="previous"
                    :class="{ disabled: generating }"
                    @click="previous()"
                />
              </v-hover>
              <div v-else style="width: 32px;"></div>

              <div class="page-indicator">
                Seite {{ curPage }} / {{ nPages }}
              </div>

              <v-hover v-slot="{ hover }" v-if="!isLastPage">
                <img
                    :src="hover ? require('@/assets/img/ico/circle-arrow-active.svg') : require('@/assets/img/ico/circle-arrow.svg')"
                    class="next"
                    :class="{ disabled: (generating) }"
                    @click="next()"
                />
              </v-hover>
              <div v-else style="width: 32px;"></div>
            </div>

            <div
                v-if="isLastPage"
                @click="generatePdf()"
                class="generate cursor-pointer"
                :class="{ disabled: generating }"
            >
              <v-btn
                  small
                  fab
                  light
                  depressed
                  color="primary"
                  :class="{ disabled: (!pages[curPage - 1].valid || generating) }"
              >
                <img :src="require('@/assets/img/ico/drucken-weiss.svg')"/>
              </v-btn>
              <span class="text">PDF exportieren</span>
            </div>
          </div>
        </div>
      </template>
      <div v-else class="text-center">
        <v-progress-circular color="primary" indeterminate/>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import MedicalPrescriptionGeneratorGeneral from './MedicalPrescriptionGenerator/MedicalPrescriptionGeneratorGeneral.vue'
import MedicalPrescriptionGeneratorMedication
  from './MedicalPrescriptionGenerator/MedicalPrescriptionGeneratorMedication.vue'
import httpClient from '@/utils/httpClient'
import moment from 'moment'
import {mapGetters} from 'vuex'

export default {
  name: 'MedicalPrescriptionGeneratorDialog',
  emits: ['cancel', 'done'],
  props: {
    bewohnerData: {
      type: Object,
      required: false,
    },
  },

  /**
   * data
   * */
  data() {
    return {
      dialog: false,
      generating: false,
      curPage: 1,
      pages: [
        {
          component: MedicalPrescriptionGeneratorGeneral,
          valid: false,
        },
        {
          component: MedicalPrescriptionGeneratorMedication,
          valid: false,
        },
      ],
      editablePrescription: null,
      bewohnerMedication: null,
      prescriptionBlueprint: {
        institution: {
          email: ''
        },
        doc: {
          name: '',
          email: '',
          zsr: ''
        },
        patient: null,
        diagnose: {
          text: '',
          type: null,
          date: null,
        },
        medication: null
      },
    }
  },

  /**
   * Computed properties
   */
  computed: {
    ...mapGetters({
      config: 'prescription/generatorConfig'
    }),

    nPages() {
      return this.pages.length
    },

    isLastPage() {
      return this.curPage === this.nPages
    },

    disabled() {
      return this.generating
    }
  },

  /**
   * Methods
   */
  methods: {
    /**
     *
     */
    open() {
      this.curPage = 1
      this.$store.dispatch('prescription/fetchConfig')

      if (!this.bewohnerMedication) {
        httpClient.get(process.env.VUE_APP_API_SHARED + 'prescription/generate/bewohner/' + this.bewohnerData.bewohnerID)
            .then(response => {
              this.bewohnerMedication = response

              this.generatePrescription()
            })
      } else {
        this.generatePrescription()
      }

      this.dialog = true
    },

    /**
     *
     */
    cancel() {
      if (!this.generating) {
        this.dialog = false
        this.$emit('cancel')
      }
    },

    /**
     *
     */
    done() {
      this.dialog = false
      this.$emit('done')
    },

    /**
     *
     */
    previous() {
      if (this.curPage > 1) {
        this.curPage--
      }
    },

    /**
     *
     */
    next() {
      this.$refs.childComponent.validate()

      if (!this.isLastPage && this.pages[this.curPage - 1].valid) {
        this.curPage++
      }
    },

    /**
     *
     */
    generatePrescription() {
      this.editablePrescription = null

      const customData = {
        patient: {
          id: this.bewohnerData.bewohnerID,
          firstname: this.bewohnerData.vorname,
          lastname: this.bewohnerData.name,
          birthdate: this.bewohnerData.geburtsdatum,
          sex: this.bewohnerData.geschlecht,
          insuranceNumber: this.bewohnerMedication.krankenkassen_versichertennr,
          krankenkassen_id: this.bewohnerData.krankenkassen_id,
        },
        institution: {
          email: this.bewohnerMedication['institution_email'],
        },
        doc: {
          name: this.bewohnerMedication.doc?.name,
          email: this.bewohnerMedication.doc?.email,
          zsr: this.bewohnerMedication.doc?.zsr
        },
        medication: {
          'migel-products': {},
          'custom-products': {},
          'selected-products': [],
        },
        diagnose: {
          date: moment().format('YYYY-MM-DD'),
          type: 'Krankheit'
        }
      }

      // generate migel products for reactivity
      Object.keys(this.config['migel-products']).forEach((key) => {
        if (key === 'inkograd') {
          return
        }

        if (!customData.medication['migel-products'][key]) {
          customData.medication['migel-products'][key] = {}
        }

        this.config['migel-products'][key].values.forEach((value) => {
          // init reactivity
          customData.medication['migel-products'][key][value.label] = false
        })
      })

      const tempPrescription = JSON.parse(JSON.stringify(this.prescriptionBlueprint))
      this.editablePrescription = Object.assign(tempPrescription, customData)
    },

    generatePdf() {
      this.generating = true

      httpClient.post(process.env.VUE_APP_API_SHARED + 'prescription/generate/pdf', this.editablePrescription, {
        responseType: 'arraybuffer',
      })
          .then((response) => {
            var filename = `aerztliche-verschreibung_${this.editablePrescription.patient.lastname}-${this.editablePrescription.patient.firstname}_${moment().format('DD-MM-YYYY')}.pdf`;

            const blob = new Blob([response], {type: 'application/pdf'});
            if (navigator.msSaveBlob) {
              navigator.msSaveBlob(blob, filename);
            } else {
              const link = document.createElement("a");
              if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }
            }

            this.done()
          })
          .finally(() => {
            this.generating = false
          })
    }
  },

  watch: {
    dialog() {
      if (this.dialog) {
        // ensure user starts always on first page
        this.curPage = 1

        // reset validation
        this.pages.forEach((page) => {
          page.valid = false
        })
      }
    }
  }
}
</script>


<style scoped lang="scss">
.main {
  padding: 40px 50px 23px 50px;
}

.title {
  h1 {
    margin: 0;
    margin-top: 0 !important;
    font-family: "Avenir LT Std";
    font-weight: 300 !important;
    font-size: 33px !important;
    line-height: 43px;
    margin-bottom: 30px;
  }
}

.close {
  position: absolute;
  top: 30px;
  right: 40px;
  cursor: pointer;
}

.v-card-text {
  padding-left: 0 !important;
}

.disclaimer {
  color: #BE4648;
}

.navigator-container {
  display: grid;
  gap: 4rem;
  grid-template-columns: 120px 1fr 120px;
  align-items: center;

  .navigator {
    display: flex;
    gap: 4rem;
    justify-content: center;
    align-items: center;
  }

  .page-indicator {
    font-weight: 700;
  }

  img {
    width: 38px;
    cursor: pointer;
    opacity: 1;
    transition: opacity 300ms ease-out;

    &.disabled {
      pointer-events: none;
      opacity: 0.3;
    }

    &.next {
      transform: rotate(180deg);
    }
  }

  .generate {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    .v-btn {
      width: 60px;
      height: 60px;
      opacity: 1;
      transition: opacity 300ms ease-out;
    }

    img {
      width: 25px;
    }
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

::v-deep {
  h4 {
    margin-bottom: 20px;
  }
}

</style>