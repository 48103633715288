<template>
  <v-container>
    <div class="page-header" ref="pageHeader">
      <div class="home">
        <router-link
            :to="['b', 'c'].includes(activePlanModel) ? '/versorgung-bewohner':'/versorgungsplan'"
            class="kunden-name"
            :class="{'small-text': this.$store.state.benutzer.info.firma.length > 30}"
        >
          <img :src="require('@/assets/img/ico/planung.png')"/>
          <span :class="{'small-text': this.$store.state.benutzer.info.firma.length > 30}">{{
              this.$store.state.benutzer.info.firma
            }}</span>
        </router-link>
      </div>

      <div class="actions" id="page-header-actions">
        <slot></slot>
      </div>

      <div class="logo-container">
        <router-link
            :to="['b', 'c'].includes(activePlanModel) ? '/versorgung-bewohner':'/versorgungsplan'"
            class="logo"
        >
          <img :src="require('@/assets/img/logo-carepro.svg')" height="72" alt=""/>
        </router-link>
      </div>
    </div>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'PageHeader',
  emits: ['update:appBarHeight'],


  computed: {
    ...mapGetters({
      activePlanModel: 'smartLogisticSettings/planModel'
    }),

  },

}
</script>
