var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-toggle mb-8"},[_c('span',{staticClass:"toggle-icon",class:[
      'icon-bewohner-einzeln',
      _vm.viewMode === 'resident' ? 'primary--text active' : 'grey--text'
    ],on:{"click":function($event){_vm.viewMode = 'resident'}}}),_c('span',{staticClass:"toggle-icon",class:[
      'icon-warenkrob',
      _vm.viewMode === 'basket' ? 'primary--text active' : 'grey--text'
    ],on:{"click":function($event){_vm.viewMode = 'basket'}}})])
}
var staticRenderFns = []

export { render, staticRenderFns }