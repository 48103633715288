<template>
  <v-row>
    <v-col cols="12" :class="headerType === 'resident' ? 'person-header' : 'living-unit-header'">
      <div class="pl-6 py-3 owner-box-header" @click="toggleExpandState()">
        <div class="header-name">
          <slot name="name"/>
        </div>

        <div class="header-calendar">
          <slot name="calendar"/>
        </div>

        <div class="header-actions">
          <slot name="actions"/>
          <div class="expand-icon" :class="{'expanded': expanded}">
            <v-icon>mdi-chevron-down</v-icon>
          </div>
        </div>
      </div>
    </v-col>

    <v-col cols="12" class="mr-0 mb-8 pl-4" :class="{'hidden': !expanded }">
      <v-row>
        <slot/>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import ArticlePlanner from '@/components/SmartLogistics/Article/ArticlePlanner.vue'
import {mapGetters} from "vuex";

export default {
  name: 'OwnerBox',
  components: { ArticlePlanner },

  props: {
    headerType: {
      type: String,
      default: 'resident'
    },
    ownerId: {
      type: Number | String,
      default: null
    },
  },
  computed: {
    ...mapGetters({
      currentSection: 'smartLogisticSettings/currentSection',
    }),
    expanded () {
      if(this.detailViewIsCarePlan) {
        if (this.headerType === 'resident') {
          return this.$store.getters['tree/expandedResidents'].indexOf(this.ownerId) > -1
        } else {
          return this.$store.getters['tree/expandedLivingUnits'].indexOf(this.ownerId) > -1
        }
      } else {
        if (this.headerType === 'resident') {
          return this.$store.getters['tree/collapsedOrderResidents'].indexOf(this.ownerId) === -1
        } else {
          return this.$store.getters['tree/collapsedOrderLivingUnits'].indexOf(this.ownerId) === -1
        }
      }

    },

    detailViewIsCarePlan() {
      return this.currentSection === 'care-plan'
    },
  },
  methods: {
    toggleExpandState () {
      if(this.detailViewIsCarePlan) {
        this.$store.commit('tree/toggleExpandState', {type: this.headerType, id: this.ownerId})
      }
      else {
        this.$store.commit('tree/toggleOrderExpandState', {type: this.headerType, id: this.ownerId})
      }
    },

  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/smartlogistics/mixins/_breakpoint';

.owner-box-header {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
      "headerName"
      "headerActions"
      "headerCalendar";
  gap: 1rem;
  align-items: center;
  min-height: 70px;
  padding-right: 10px !important;

  &:hover {
    cursor: pointer;
  }

  @include breakpoint(md) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "headerName headerName"
      "headerCalendar headerActions";
  }

  @include breakpoint(lg) {
    grid-template-columns: 200px 1fr;
    grid-template-areas:
      "headerName headerActions"
      "headerCalendar headerCalendar";
  }

  @include breakpoint(xl) {
    grid-template-columns: 220px 1fr 1fr;
    grid-template-areas:
      "headerName headerCalendar headerActions";
  }
}

.header-name {
  grid-area: headerName;
}

.header-calendar {
  grid-area: headerCalendar;
}

.header-actions {
  grid-area: headerActions;
  flex-direction: row;
  display: flex;
  align-items: center;
  gap: 10px;

  @include breakpoint(md) {
    margin-left: auto;
  }
}

.expand-icon {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);

  &.expanded {
    transform: rotate(180deg);
  }

  .v-icon {
    font-weight: bold;
    color: black;
  }
}

.hidden {
  display: none;
}
</style>
