<template>
  <div class="timeline-wrapper">
    <div class="timeline-container timeline-header" :class="{ '-living-unit': isLivingUnit}">
      <div class="font-weight-bold mb-2 mb-md-0" style="font-size: 18px;">
        {{ entity.name }}
      </div>

      <ExpandableText text="Diese Artikel sind keinem Bewohner zugeteilt..." v-if="isLivingUnit">
        Für das Stockwerk bestellte Artikel werden nicht bewohnerbezogen abgerechnet. Ausser Gemeinschaftsartikel, diese werden beim Bewohnern geplant und als Einzelstücke abgerechnet. Gemeinschaftsartikel werden auf das Stockwerk bestellt und hier angezeigt.
      </ExpandableText>
      <ExpandableText text="Abgabe wird automatisch erfasst, wenn..." v-else>
        Abgabe wird automatisch erfasst, wenn Produkt bestellt wird. Falls Sie nicht für Bewohner bestellte Produkte abgeben, können Sie den Verbrauch manuell erfassen im Tab «Abrechnungsdaten».
      </ExpandableText>

      <div class="actions list" v-if="isEditable && !isLivingUnit">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" class="mt-4 hov-opacity" small depressed
                   :style="editMode !== 'add' ? 'background-color: #e0dbd7 !important;' : ''"
                   :color="editMode === 'add' ? 'primary' : 'secondary'" @click="editMode='add'">
              <span class="icon-akkordeon-oeffnen"></span>
            </v-btn>
          </template>
          <span>bei Klick +1</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" class="mt-4" small depressed
                   :style="editMode !== 'remove' ? 'background-color: #e0dbd7 !important;' : ''"
                   :color="editMode === 'remove' ? 'primary' : 'secondary'"
                   @click="editMode='remove'">
              <span class="icon-akkordeon-schliessen"></span>
            </v-btn>
          </template>
          <span>bei Klick -1</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" class="mt-4" small depressed
                   :style="editMode !== 'manual' ? 'background-color: #e0dbd7 !important;' : ''"
                   :color="editMode === 'manual' ? 'primary' : 'secondary'"
                   @click="editMode='manual'">
              <v-icon size="20">
                mdi-keyboard-outline
              </v-icon>
            </v-btn>
          </template>
          <span>manuelle Eingabe</span>
        </v-tooltip>
      </div>
    </div>
    <div class="timeline-article-list" v-bind:class="{'loading': isLoading}">
      <TimelineArticle
          v-for="article in filteredArticles"
          :key="article.internalID"
          :type="type"
          :entityId="entity.internalID"
          :article="article"
          :consumption="consumption"
          :effectiveConsumption="effectiveConsumption"
          :date="date"
          :interval="settings.interval"
          :intervalStartDate="$moment(settings.start_date)"
          @dayClicked="handleDayClicked($event, article)"
          :disabled="updatingDay"
      />

      <template v-if="ownArticles.length > 0">
        <div class="own-products mb-2 pt-4 d-flex align-center text--darkestgrey">
          <b class="mr-2">Eigene Produkte</b>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" class="icon-informationen"></span>
            </template>
            <div style="max-width: 380px;">Es werden die jeweils aktuellen Intervall-Plandaten in den Verbrauch geschrieben. Wöchentliche Intervalle immer am Montag, monatliche Intervalle immer am 1. jedes Monats ab Erfassung.</div>
          </v-tooltip>
        </div>

        <TimelineArticle
            v-for="article in ownArticles"
            :key="article.internalID"
            :type="type"
            :entityId="entity.internalID"
            :article="article"
            :consumption="consumption"
            :effectiveConsumption="effectiveConsumption"
            :date="date"
            :interval="settings.interval"
            :intervalStartDate="$moment(settings.start_date)"
            @dayClicked="handleDayClicked($event, article)"
            :disabled="updatingDay"
        />
      </template>

      <AlertMessage v-if="articles.length == 0" >
        Keine Artikel zugewiesen
      </AlertMessage>

      <div class="loading-indicator" v-if="isLoading">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
    </div>
    <div v-show="showInput" class="counterInput" ref="counterInput">
      <input
          type="number"
          @blur="updateCounter"
          @keydown.enter="updateCounter"
      />
    </div>

  </div>
</template>

<script>
import TimelineArticle from '@/components/SmartLogistics/Timeline/TimelineArticle.vue'
import httpClient from '@/utils/httpClient'
import { mapGetters } from 'vuex'
import ExpandableText from '@/components/SmartLogistics/Design/ExpandableText.vue'
import { consumptionMixin } from '@/mixins/SmartLogistics/consumption'
import ca from 'vue2-datepicker/locale/es/ca'
import AlertMessage from '@/components/SmartLogistics/Design/AlertMessage.vue'

export default {
  name: 'Timeline',
  components: { AlertMessage, ExpandableText, TimelineArticle },
  mixins: [consumptionMixin],

  props: {
    type: {
      type: String,
      default: 'resident',
      validator: function (value) {
        return ['resident', 'living-unit'].indexOf(value) !== -1
      }
    },
    entity: {
      type: Object,
      required: true,
    },
    date: {
      type: Object,
      required: true,
    },
  },

  data () {
    return {
      updatingDay: false,
      articles: [],
      showInput: false,
      consumption: {},
      effectiveConsumption: {},
      dataLoading: false,
      consumptionLoading: false,
      effectiveConsumptionLoading: false,
    }
  },

  computed: {
    ...mapGetters({
      overviewDate: 'smartLogisticSettings/selectedDate',
      section: 'smartLogisticSettings/currentTimelineSection',
    }),

    isLoading () {
      return this.dataLoading || this.consumptionLoading || this.effectiveConsumptionLoading
    },

    editMode: {
      get () {
        return this.$store.getters['smartLogisticSettings/editMode']
      },
      set (value) {
        this.$store.commit('smartLogisticSettings/setEditMode', value)
      }
    },

    /**
     *
     */
    isEditable () {
      return this.section !== 'planning'
    },

    /**
     *
     */
    isLivingUnit () {
      return this.type === 'living-unit'
    },

    /**
     *
     */
    livingUnitId () {
      return this.isLivingUnit ? this.entity.internalID : this.entity.wohneinheiten_id
    },

    /**
     *
     */
    settings () {
      return this.$store.getters['smartLogisticSettings/livingUnitSettingsById'](this.livingUnitId)
    },

    /**
     *
     * @returns {*[]}
     */
    filteredArticles () {
      let articles = this.articles.filter(a => !a.internalID.startsWith('SKU-'))

      // collective articles must now also be shown on the unit level
      // if (this.isLivingUnit) {
      //   // articles = articles.filter(a => !a.collective)
      // }

      return articles
    },

    ownArticles () {
      return this.articles.filter(a => a.internalID.startsWith('SKU-'))
    },

    articleEffectiveConsumption () {
      return this.residentEffectiveArticleConsumption(this.entityId, this.article.internalID)
    },
  },

  methods: {
    /**
     *
     */
    updateResidentData () {
      this.dataLoading = true
      httpClient.get(process.env.VUE_APP_API_SHARED + `tree/resident/${this.entity.internalID}/${this.date.format('YYYY-MM-DD')}`)
          .then(response => {
            this.articles = response.children
          })
          .finally(() => {
            this.dataLoading = false
          })
    },

    /**
     *
     */
    updateLivingUnitData () {
      this.dataLoading = true
      httpClient.get(process.env.VUE_APP_API_SHARED + `tree/living-unit/${this.entity.internalID}/${this.date.format('YYYY-MM-DD')}/data`)
          .then(response => {
            this.articles = response.data
          })
          .finally(() => {
            this.dataLoading = false
          })
    },

    /**
     *
     */
    updateConsumption () {
      const endpoint = this.isLivingUnit ? 'living-unit' : 'resident'
      this.consumptionLoading = true
      this.effectiveConsumptionLoading = true
      httpClient.get(process.env.VUE_APP_API_SHARED + `tree/consumption/${endpoint}/${this.entity.internalID}/${this.date.format('YYYY-MM-DD')}`)
          .then(response => {
            this.consumption = Object.keys(response).length > 0 ? response : {}

          })
          .finally(() => {
            this.consumptionLoading = false
          })

      httpClient.get(process.env.VUE_APP_API_SHARED + `tree/effective-consumption/${endpoint}/${this.entity.internalID}/${this.date.format('YYYY-MM-DD')}`)
          .then(response => {
            this.effectiveConsumption = Object.keys(response).length > 0 ? response : {}
          })
          .finally(() => {
            this.effectiveConsumptionLoading = false
          })

    },

    /**
     *
     * @param data
     * @param article
     */
    handleDayClicked (data, article) {
      if (this.isLivingUnit) {
        return
      }

      if (this.editMode === 'manual') {
        const pos = data.target.getClientRects()[0]
        this.showInput = true

        const $counterInput = this.$refs.counterInput

        $counterInput.style.top = pos.top + pos.height + 'px'
        $counterInput.style.left = (pos.left - 4) + 'px'

        $counterInput.dataset.articleId = article.internalID
        $counterInput.dataset.data = JSON.stringify(data)
        $counterInput.dataset.orderCount = 0

        const $input = this.$refs.counterInput.querySelector('input')

        var value = data.timelineCount

        try {
          const effectiveConsumption = this.residentEffectiveArticleConsumption(this.entity.internalID, article.internalID)
          if (effectiveConsumption && effectiveConsumption[data.day.format('D')]) {
            const orderCount = effectiveConsumption[data.day.format('D')].orderCount
            value += orderCount
            $counterInput.dataset.orderCount = orderCount
          }
        } catch (e) {
          // prevent any errors if there is no effective consumption
        }

        $input.value = value

        this.$nextTick(() => {
          $input.focus()
          $input.select()
        })
      } else {
        this.dayClicked(data, article)
      }
    },

    /**
     *
     * @param event
     */
    updateCounter (event) {
      const $counterInput = this.$refs.counterInput
      var counter = 0

      if (+event.target.value > +$counterInput.dataset.orderCount) {
        counter = +event.target.value - +$counterInput.dataset.orderCount
      }

      const data = JSON.parse($counterInput.dataset.data)
      const day = this.$moment(data.day).format('D')
      const articleId = $counterInput.dataset.articleId
      this.showInput = false

      if (counter === +data.timelineCount) {
        return
      }

      data.timelineCount = +counter

      // timelineCount can't be negative
      if (data.timelineCount < 0) {
        data.timelineCount = 0
      }

      data.count = data.intervalCount + data.timelineCount

      this.adjustCounter(articleId, day, data)
    },

    /**
     *
     * @param data
     * @param article
     */
    dayClicked (data, article) {
      if (this.editMode === 'add') {
        data.timelineCount++
        this.adjustCounter(article.internalID, data.day.format('D'), data)
      } else if (this.editMode === 'remove' && data.timelineCount > 0) {
        data.timelineCount--
        this.adjustCounter(article.internalID, data.day.format('D'), data)
      }
    },

    /**
     *
     * @param articleId
     * @param day
     * @param data
     */
    adjustCounter (articleId, day, data) {
      if (this.updatingDay) {
        return
      }

      this.updatingDay = true

      const curConsumption = {
        choosenMonthYear: this.date.format('YYYY-MM-DD'),
        day: +day,
        counter: data.timelineCount,
        prodID: articleId,
        bewohnerID: this.entity.internalID,
        editmode: this.editMode
      }

      httpClient.post(process.env.VUE_APP_API_CHECKLIST + 'add/product-to-date', {
        choosenMonthYear: curConsumption.choosenMonthYear,
        day: curConsumption.day,
        counter: curConsumption.counter > 0 ? curConsumption.counter : 'unset',
        prodID: curConsumption.prodID,
        bewohnerID: curConsumption.bewohnerID,
        editmode: curConsumption.editmode
      })
          .then(response => {
            if (response.type === 'success') {
              const key = this.entity.internalID + '-' + articleId + '-' + day

              const count = response.count

              var newConsumption = null

              if (this.consumption[key]) {
                newConsumption = Object.assign({}, this.consumption[key])

                newConsumption.count = count
                delete newConsumption.planung

              } else {
                newConsumption = {
                  artnr: articleId,
                  bewohnerID: this.entity.internalID,
                  count: count,
                  day: day,
                }
              }

              const intervalCount = data.intervalCount
              newConsumption.timelineCount = data.timelineCount
              newConsumption.intervalCount = intervalCount > 0 ? intervalCount : 0

              this.$store.commit('tree/adjustConsumption', {
                dateString: this.date.format('YYYY-MM-DD'),
                consumptionData: newConsumption
              })

              this.$set(this.consumption, key, Object.assign({}, newConsumption))
            }
          })
          .finally(() => {
            this.updatingDay = false
          })
    },
  },

  watch: {
    date () {
      if (!this.isLivingUnit) {
        this.updateResidentData()
      } else {
        this.updateLivingUnitData()
        this.updateConsumption()
      }

      if (this.isEditable) {
        this.updateConsumption()
      }
    },

    isEditable () {
      if (this.isEditable) {
        this.updateConsumption()
      }
    }
  }
  ,

  mounted () {
    if (this.isLivingUnit) {
      this.articles = this.entity.data
    } else {
      this.articles = this.entity.children

      if (!this.date.isSame(this.overviewDate)) {
        this.updateResidentData()
      } else {
        this.consumption = this.$store.getters['tree/consumptionData'](this.date.format('YYYY-MM-DD'))
      }
    }

    this.updateConsumption()
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-btn {
    border-radius: 6px;

    span {
      font-size: 18px;
    }
  }
}

.counterInput {
  position: absolute;
  border: 1px solid #CE20A8;
  background-color: #ffffff;
  transform: translateX(-10px) translateY(-10px);
  padding: 5px;

  &::before {
    content: '';
    position: absolute;
    top: -20px;
    left: 20px;
    border: 10px solid transparent;
    border-bottom-color: #CE20A8;
  }

  input {
    width: 50px;
    outline: none;
  }
}

.timeline-wrapper {
  height: calc(90vh - 250px);
}

.timeline-article-list {
  position: relative;

  &.loading {
    overflow: hidden;
  }
}

.loading-indicator {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 30px;
  background: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.own-products {
  border-top: 1px solid #00000054;
}
</style>
