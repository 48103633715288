<template>
  <div v-if="settings.interval">für <b>
  <span>
    {{ settings.interval }}
    </span>
    Woche<template v-if="settings.interval > 1">n</template></b> gemäss Versorgungsplan
  </div>
</template>

<script>
export default {
  name: 'IntervalTextLabel',

  props: {
    unitLevel: {
      type: Object,
      required: true,
    },
  },

  computed: {
    settings() {
      return this.$store.getters['smartLogisticSettings/livingUnitSettingsById'](this.unitLevel.internalID)
    },
  },
}
</script>
