<template>
  <v-dialog
      v-model="dialog"
      persistent
      scrollable
      width="1340"
      content-class="bewohner-details-dialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <div class="d-flex align-center justify-end" style="gap: 10px;">
        <ConfirmDialog
            v-if="edited"
            title="Aktualisieren"
            @confirm="resetCart"
        >
          <template #button>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-img
                  v-if="attrs['aria-expanded'] == 'true'"
                  class="mt-0 cursor-pointer"
                  :src="require('@/assets/img/ico/neu-laden-aktiv.svg')"
                  alt=""
                  height="22"
                  width="22"
              />
              <v-img
                  v-else
                  class="mt-0 cursor-pointer"
                  :src="require('@/assets/img/ico/neu-laden.svg')"
                  alt=""
                  height="22"
                  width="22"
              />
            </span>
              </template>
              <span>Aktualisieren</span>
            </v-tooltip>
          </template>

          <template v-slot:cancel="slotProps">
            <v-btn color="beige" depressed @click="slotProps.cancel">Abbrechen</v-btn>
          </template>

          <template v-slot:confirm="slotProps">
            <v-btn
                depressed
                color="primary"
                @click="slotProps.confirm"
            >
              Aktualisieren
            </v-btn>
          </template>

          Wollen Sie die Abrechnung aktualisieren? Manuelle Änderungen werden überschrieben.
        </ConfirmDialog>

        <v-hover v-slot="{ hover }" open-delay="0">
          <v-img v-if="hover" class="mt-0 cursor-pointer"
                 @click="showCart"
                 v-bind="attrs"
                 v-on="on"
                 :src="require('@/assets/img/ico/bearbeiten-aktiv.svg')" alt=""
                 height="16"
                 max-height="16"
                 width="16"
                 max-width="16"
          />
          <v-img v-else class="mt-0 cursor-pointer"
                 @click="showCart"
                 v-bind="attrs"
                 v-on="on"
                 :src="require('@/assets/img/ico/bearbeiten.svg')"
                 height="16"
                 max-height="16"
                 width="16"
                 max-width="16"
          />
        </v-hover>

      </div>
    </template>
    <v-card
        v-if="dialog && carts && carts.length > 0 && bewohner"
        class="bewohner-detail px-8 py-8 relative"
    >
      <div class="d-flex my-4 opener-pauschalen">
        <Jahrespauschalen :bewohnerID="carts[0].bewohner.bewohnerID" :bewohner="bewohner" :year="date.format('YYYY')" :month="date.format('MM')" class="mr-4"/>
        <div class="mt-3 cursor-pointer" style="margin-right: -25px;" @click="dialog=false">
          <img alt="" :src="require('@/assets/img/ico/schliessen.svg')" width="25"/>
        </div>
      </div>
      <v-card-title>
        <h2 class="text" style="color: #000 !important;">Abrechnungsperiode {{ date.format('MMMM YYYY') }} <span class="ml-1 text--primary">{{ bewohner.vorname }} {{ bewohner.name }}</span></h2>
      </v-card-title>

      <v-card-subtitle class="mt-1">
        <b>BewohnerId {{ bewohner.bewohnerID }}, Zimmer {{ bewohner.zimmer }}, {{ bewohner.wohneinheitenName }}</b>
      </v-card-subtitle>

      <v-card-text style="height: 70vh;">
        <v-row>
          <v-col>
            <v-expansion-panels
                v-model="panel"
                v-for="(c, i) in carts"
                class="mt-4"
                accordion
                flat
            >
              <v-expansion-panel
                  :key="i"
                  expand
                  class="mb-3"
              >
                <v-expansion-panel-header
                    class="py-2"
                    :class="{ beige: c.status === 'prepared', lightgray: c.status !== 'prepared'}"
                >
                  <div class="d-flex justify-space-between">
                    <div>
                      <h3 class="mt-2">Abrechnung {{ c.id }}</h3>
                    </div>
                    <div class="detail-list">
                      <div>
                        <div>
                          <b>Total</b>
                        </div>
                        <div>
                          {{ c.KrankenKassenTotalVerrechenbar }}
                        </div>
                      </div>
                      <div class="d-flex" style="gap: 20px">
                        <div>
                          <div>
                            <b>Status</b>
                          </div>
                          <div>
                            {{ $t('direktabrechnung.status.' + getStatus(c)) }}
                            <span v-if="c.status !== 'prepared'">
                              {{ c.status === 'declined' ? $moment(c.response_at).format('DD.MM.YYYY') : '' }}
                            </span>
                          </div>
                        </div>
                        <div class="d-flex align-center">
                          <v-img
                              v-if="c.status === 'declined'"
                              :src="require('@/assets/img/ico/rueckweisung.svg')" alt=""
                              width="26" height="26" contain/>
                          <v-img
                              v-else-if="c.status === 'prepared'"
                              :src="require('@/assets/img/ico/sendebereit.svg')" alt=""
                              width="26" height="26" contain/>
                          <v-img
                              v-else-if="c.status === 'sent'"
                              :src="require('@/assets/img/ico/verschickt.svg')" alt=""
                              width="26" height="26" contain/>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                  <div class="bewohner-detail-meta pt-4 px-4" :class="{ beige: c.status === 'prepared', lightgray: c.status !== 'prepared'}" style="margin-top: -10px;">
                    <v-divider class="mb-2" style="background-color: #000000;"/>
                    <BewohnerDetailMeta
                        :cart="c"
                        @update="updateBewohnerCustomData"
                    />
                  </div>
                  <div class="d-flex mt-6 mb-3">
                    <v-btn
                        outlined
                        @click="currentShowType = 'krankenkasse'"
                        class="mr-2 font-weight-bold"
                        :class="{ passive: currentShowType !== 'krankenkasse'}"
                    >
                      Krankenkasse
                    </v-btn>
                    <v-btn
                        outlined
                        @click="currentShowType = 'bewohner'"
                        class="mr-2 font-weight-bold"
                        :class="{ passive: currentShowType !== 'bewohner'}"
                    >
                      Bewohner
                    </v-btn>
                    <v-btn
                        outlined
                        @click="currentShowType = 'company'"
                        class="font-weight-bold"
                        :class="{ passive: currentShowType !== 'company'}"
                    >
                      Betriebsmaterial
                    </v-btn>
                    <div
                        v-show="currentShowType==='krankenkasse'"
                        style="margin-left: auto;"
                    >
                      <div class="d-flex align-center">
                      <span class="mr-4">
                        Nicht verrechenbare Beträge an Bewohner übertragen.
                      </span>
                        <v-switch
                            :disabled="c.status!=='prepared'"
                            v-model="splitAll"
                            dense
                            flat
                            inset
                            hide-details
                            class="ma-0 pa-0"
                        />
                      </div>
                    </div>
                  </div>
                  <v-divider
                      class="mb-6"
                      style="background-color: #000000"
                  />
                  <BewohnerProductListingKrankenkasse v-show="currentShowType==='krankenkasse'" :cart="c"/>
                  <BewohnerDetailListingBewohner v-show="currentShowType==='bewohner'" :cart="c"/>
                  <BewohnerProductListingCompany v-show="currentShowType==='company'" :cart="c"/>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel :key="i+'-declined'" v-if="c.status === 'declined'" class="declined">
                <v-expansion-panel-header>
                  <h4>Rückweisung</h4>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="px-8 py-4">
                  {{ $moment(c.response_date).format('DD.MM.YYYY hh:mm:ss')}}<br>
                  <span v-html="c.response_explanation"></span>
                  <br><br>
                  <v-btn color="primary" elevation="0" small v-if="c.has_response_pdf === 1" @click="requestPDF(c)">Details zur Rückweisung (PDF)</v-btn>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <ConfirmDialog
            v-if="preparedCart"
            title="Aktualisieren"
            @confirm="resetCart"
        >
          <template #button>
            <v-hover v-slot="{ hover }" open-delay="0">

              <v-btn
                  color="white"
                  depressed
                  :loading="loading.reset"
                  :disabled="isLoading && !loading.reset"
              >
                <v-img
                    v-if="hover"
                    class="mt-0 cursor-pointer"
                    :src="require('@/assets/img/ico/neu-laden-aktiv.svg')"
                    alt=""
                    width="33"
                />
                <v-img
                    v-else
                    class="mt-0 cursor-pointer"
                    :src="require('@/assets/img/ico/neu-laden.svg')"
                    alt=""
                    width="33"
                />
                <span class="ml-2 font-weight-bold">
                  Aktualisieren
                </span>
              </v-btn>
            </v-hover>
          </template>

          <template v-slot:cancel="slotProps">
            <v-btn color="beige" depressed @click="slotProps.cancel">Abbrechen</v-btn>
          </template>

          <template v-slot:confirm="slotProps">
            <v-btn
                depressed
                color="primary"
                @click="slotProps.confirm"
            >
              Aktualisieren
            </v-btn>
          </template>

          Wollen Sie die Abrechnung aktualisieren? Manuelle Änderungen werden überschrieben.
        </ConfirmDialog>

        <v-btn
            v-else-if="!cartHasBeenSent"
            color="white"
            depressed
            :loading="loading.generate"
            :disabled="isLoading && !loading.generate"
            @click="generateCart"
        >
          <v-img
              class="mt-0 cursor-pointer"
              :src="require('@/assets/img/ico/hinzufuegen.svg')"
              alt=""
              width="33"
          />
          <span class="ml-4 text-left">
              Neue<br>
              Abrechnung
            </span>
        </v-btn>

        <v-spacer/>

        <div class="mr-4">
          <ToNextBewohner :cur-bewohner="bewohner" :hasBeenEdited="cartHasBeenEdited" :next="false" @save="saveCart"/>
        </div>
        <div>
          <ToNextBewohner :cur-bewohner="bewohner" :hasBeenEdited="cartHasBeenEdited" @save="saveCart"/>
        </div>

        <v-spacer />

        <v-btn
            color="secondary"
            depressed
            class="mt-4"
            @click="dialog = false"
            :disabled="isLoading"
            style="color: #000000"
        >
          Abbrechen
        </v-btn>
        <v-btn
            v-if="!cartHasBeenSent && preparedCart"
            color="primary"
            class="mt-4"
            depressed
            @click="saveCart"
            :loading="loading.save"
            :disabled="isLoading && !loading.save"
        >
          Speichern und schliessen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import BewohnerDetailMeta from "./BewohnerDetailMeta.vue";
import httpClient from "../../utils/httpClient";
import Jahrespauschalen from "./Jahrespauschalen.vue";
import Status from "@/components/direktabrechnung/Status.vue";
import BewohnerProductListingKrankenkasse from "@/components/direktabrechnung/BewohnerProductListingKrankenkasse.vue";
import BewohnerDetailListingBewohner from "@/components/direktabrechnung/BewohnerProductListingBewohner.vue";
import BewohnerProductListingCompany from "@/components/direktabrechnung/BewohnerProductListingCompany.vue";
import ConfirmDialog from "@/components/shared/ConfirmDialog.vue";
import ToNextBewohner from "@/components/direktabrechnung/Navigation/ToNextBewohner.vue";
import hash from 'object-hash'
import {mapGetters} from "vuex";
import ca from "vue2-datepicker/locale/es/ca";

export default {
  name: "BewohnerDetail",
  components: {ToNextBewohner, ConfirmDialog, BewohnerDetailListingBewohner, BewohnerProductListingKrankenkasse, BewohnerProductListingCompany, Status, Jahrespauschalen, BewohnerDetailMeta},

  props: {
    bewohnerID: {
      type: Number,
      required: true
    },
    showType: {
      type: String,
      default: 'krankenkasse'
    }
  },
  data() {
    return {
      loading: {
        save: false,
        reset: false,
        generate: false
      },
      panel: null,
      currentShowType: this.showType,
      dialog: false,
      bewohnerCustomData: null,
      cartHash: null
    }
  },

  computed: {
    ...mapGetters({
      bewohnerInEditmode: 'direktabrechnung/bewohnerInEditmode',
      pauschalen: 'direktabrechnung/bewohnerPauschalenStatus',
    }),

    isLoading() {
      return Object.values(this.loading).filter(l => l).length > 0
    },

    edited() {
      try {
        const cart = this.$store.state['direktabrechnung'].carts.find(c => c.bewohner_id === this.bewohnerID)

        return !!cart.edited
      } catch (e) {
        return false
      }
    },

    carts() {
      return this.$store.state['direktabrechnung'].bewohnerCarts;
    },

    bewohner() {
      if (!this.carts || this.carts.length <= 0) {
        return null
      }

      return this.carts[0].bewohner
    },

    date() {
      if (!this.carts || this.carts.length <= 0) {
        return null
      }

      return this.$moment(this.carts[0].verrechnungsmonat)
    },

    latestCart() {
      return this.carts[this.carts.length - 1]
    },

    cartHasBeenSent() {
      if (!this.carts) {
        return false
      }

      return this.latestCart.status === 'sent'
    },

    preparedCart() {
      if (this.carts) {
        if (this.getTotalPanels() - 1 != this.panel) {
          this.panel = this.getTotalPanels() - 1
        }

        const preparedCart = this.carts.find(c => c.status === 'prepared')

        if (!this.cartHash && preparedCart) {
          this.cartHash = hash(preparedCart)
        }

        return preparedCart
      }
      return null
    },

    cartHasBeenEdited() {
      if (this.preparedCart) {
        return this.cartHash != hash(this.preparedCart)
      } else {
        return false
      }
    },

    recordsWithSwitcher() {
      var records = []

      if(this.preparedCart && this.preparedCart['cart_krankenkasse']) {
        this.preparedCart['cart_krankenkasse'].forEach(record => {
          const hasMigelFixedRate = record.jahrespauschale
          const fixedRateDepleted = this.pauschalen && this.pauschalen.filter(p => !p.active && p.MiGeL_Nummern.includes(record.migelnr)).length > 0

          if((hasMigelFixedRate && fixedRateDepleted) || ((parseFloat(record.nicht_verrechenbar) * record.menge).toFixed(2) > 0)) {
            records.push(record)
          }
        })
      }

      return records
    },

    nSwitcher() {
      return this.recordsWithSwitcher.length
    },

    splitAll: {
      get() {
        if (!this.preparedCart)
          return false

        return this.nSwitcher == this.preparedCart['cart_krankenkasse'].filter(r => !!r.split).length
      },
      set(value) {
        if (this.preparedCart) {
          this.recordsWithSwitcher.forEach(r => {
            r.split = !!value
          })
        }
      }
    }
  },

  methods: {
    getStatus(cart){
      let status = cart.status;

      if(cart.status === 'prepared' && cart.validation && cart.validation.length > 0) {
        status = 'flawed'
      }
      return status
    },

    getTotalPanels(){
      let count = 0;
      this.carts.forEach(cart => {
        count++;
        if (cart.status === 'declined') {
          count++;
        }
      });
      return count;
    },

    showCart() {
      this.$store.dispatch('direktabrechnung/show', this.bewohnerID);
      this.$store.commit('direktabrechnung/setBewohnerInEditmode', this.bewohnerID)
    },

    saveCart() {
      this.loading.save = true

      // fetch the cart with status 'prepared' as there only can be one per month with that status
      var cart = Object.assign({}, this.carts.find(c => c.status === 'prepared'))


      // replace modified bewohner data if changed
      if (this.bewohnerCustomData) {
        cart = Object.assign(cart, this.bewohnerCustomData)
      }

      this.$store.dispatch('direktabrechnung/saveCart', cart)
          .then(() => {
            this.dialog = false
          })
          .finally(() => {
            this.loading.save = false
          })
    },

    resetCart() {
      this.loading.reset = true
      this.panel = 0
      this.$store.dispatch('direktabrechnung/resetCart', this.bewohnerID)
          .finally(() => {
            this.cartHash = null
            this.$store.dispatch('direktabrechnung/index');
            this.loading.reset = false
            this.panel = this.getTotalPanels() - 1
          })
    },


    generateCart() {
      this.loading.generate = true
      this.$store.dispatch('direktabrechnung/createCart', this.bewohnerID)
          .finally(() => {
            this.cartHash = null
            this.$store.dispatch('direktabrechnung/index');
            this.loading.generate = false
            this.panel = this.getTotalPanels() - 1
          })
    },

    updateBewohnerCustomData(data) {
      this.bewohnerCustomData = data
    },

    generateSlug(text){
      return text.toLowerCase()
          .trim()
          .replace(/[^\w- ]+/g, '')
          .replace(/ /g, '-')
          .replace(/[-]+/g, '-');
    },

    requestPDF(c) {
      const exportedFilenmae = this.generateSlug(c.bewohner.name+'-'+c.bewohner.vorname+'-'+this.date.format('YYYY-MM'))+ '.pdf';
      this.$store.dispatch('direktabrechnung/requestPDF', c.id).then((response,c) => {
        const blob = new Blob([response], {type: 'application/pdf;charset=utf-8;'});
        if (navigator.msSaveBlob) {
          navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
          const link = document.createElement("a");
          if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      })
    },
  },

  watch: {
    bewohnerInEditmode() {
      if (this.bewohnerInEditmode == this.bewohnerID) {
        this.$store.dispatch('direktabrechnung/show', this.bewohnerID);
        this.dialog = true
      } else {
        this.dialog = false
      }
    },

    dialog() {
      this.cartHash = null
      this.currentShowType = this.showType
    },

    showType() {
      this.currentShowType = this.showType
    }
  }
}
</script>

<style lang="scss">

.opener-pauschalen{
  position: absolute;
  top: 10px;
  right: 60px;
}

.direktabrechnung-data {
  /* Firefox */
  input[type=number] {
    width: 50px;
    border: 1px solid #C7C5C5;
    text-align: center;
    outline: none;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .v-input__slot {
    &::before {
      display: none;
    }
  }

  .data-row {
    display: flex;
    flex-flow: row;
    gap: 20px;
    border-top: 1px solid #C7C5C5;

    &.-header {
      border-top: none;
    }

    .controls {
      flex: 1 0 50px;
      display: flex;
      justify-content: flex-end;
      margin-left: -20px;
    }

    &.-editable {
      gap: 0;
      border-top: 0;

      > div {
        box-sizing: initial;
        padding-right: 20px;
        background-color: #eeeeee;

        &:first-child {
          margin-left: -10px;
        }

        &:first-child,
        &:last-child {
          background-color: inherit;
        }

        &:nth-child(2) {
          padding-left: 10px;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }

        &:nth-last-child(2) {
          box-sizing: border-box;
          margin-bottom: 0;
        }

        &:nth-last-child(3) {
          margin-left: -20px;
          margin-right: 20px;
          padding-left: 10px;
          padding-right: 10px;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }
    }
  }
}

.detail-list {
  display: flex;
  flex-flow: row;
  gap: 10px;

  > div {
    padding-left: 10px;
    border-left: 1px solid #000000;

    &:first-child {
      border-left: none;
    }
  }
}

.bewohner-details-dialog {
  overflow: hidden !important;
  border-radius: 36px;

  .v-input--switch--flat {
    .v-input--selection-controls__input {
      margin-right: 0 !important;
    }
  }

  .v-btn.passive {
    border-color: transparent;
  }

  .bewohner-detail-meta {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .v-expansion-panels {
    .v-expansion-panel-header {
      border-radius: $expansion-panel-border-radius !important;

      &.v-expansion-panel-header--active {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }

    .v-expansion-panel-header.beige:hover,
    .v-expansion-panel-header--active.beige {
      background-color: #F4EDE7 !important;
    }

    .v-expansion-panel-header.lightgray:hover,
    .v-expansion-panel-header--active.lightgray {
      background-color: #eeeeee !important;
    }
  }

  .v-expansion-panel.declined{
    border-radius: $expansion-panel-border-radius !important;

    .v-expansion-panel-content{
      background-color: #F9F6F3 !important;
      border-bottom-left-radius: $expansion-panel-border-radius !important;
      border-bottom-right-radius:  $expansion-panel-border-radius !important;
    }

    button{
      border: none !important;

    }
    .v-expansion-panel-header{
      background-color: #F9F6F3 !important;
    }
  }
}
</style>