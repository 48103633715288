<template>
  <v-col cols="12">
    <v-row class="b-bottom mx-0">
      <v-col sm="12" md="6" lg="6" xl="6" class="d-flex">
       <HeaderLabel text="Bezeichnung"></HeaderLabel>
      </v-col>
      <v-col sm="12" md="2" lg="2" xl="2" class="d-flex">
        <HeaderLabel text="Verpackungseinheit"></HeaderLabel>
      </v-col>

      <v-col sm="12" md="2" lg="2" xl="2" class="d-flex justify-end">
        <HeaderLabel text="Bestellmenge"></HeaderLabel>
      </v-col>

      <v-col sm="12" md="2" lg="2" xl="2" class="d-flex justify-end">
        <HeaderLabel text="Sofort bestellen" add-classes="text-right"></HeaderLabel>
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
import HeaderLabel from '../../Design/HeaderLabel.vue'

export default {
  name: 'DirectOrderHeader',
  components: { HeaderLabel }

}
</script>