import httpClient from '../../utils/httpClient'
import {smartLogisticSettings} from "@/store/modules/smartLogisticSettings";

export const articleSettings = {
    namespaced: true,

    state: {
        articleSettings: null
    },

    getters: {
        isCollectiveByArticle: function (state) {
            return function (articleId, defaultValue = false) {
                if (!state.articleSettings || state.articleSettings.length == 0) {
                    return defaultValue;
                }
                let articleSettings = state.articleSettings.find(articleSettings => articleSettings.ArtikelNrLAG == articleId);
                return articleSettings ? !!articleSettings.is_collective : defaultValue;
            };
        },

        storageLocationByArticle: function (state) {
            return function (articleId) {
                // if not model c, return null since this is not used
                if(smartLogisticSettings.state.model != 'c') {
                    return null;
                }
                if (!state.articleSettings || state.articleSettings.length == 0) {
                    return null;
                }
                let articleSettings = state.articleSettings.find(articleSettings => articleSettings.ArtikelNrLAG == articleId);
                return articleSettings && articleSettings.storage_location ? articleSettings.storage_location : null;
            };
        },
    },

    actions: {
        async setArticleSettings({commit, state}, params) {
            const data = await httpClient({
                url: process.env.VUE_APP_API_SHARED + 'article-settings/' + params.id,
                method: 'put',
                data: params.updateData
            }).catch(() => {
                // commit('setSingleArticleSettings', data.articleSettings)
            }).then((data) => {
                commit('setSingleArticleSettings', data.articleSettings)
            })

        },

        async fetchArticleSettings({commit, state}, params) {

            const data = await httpClient({
                url: process.env.VUE_APP_API_SHARED + 'article-settings',
                method: 'get'
            })

            if (data) {
                commit('setArticleSettings', data)
            }
        }
    },

    mutations: {
        setCollective(state, data) {
            let articleSettings = state.articleSettings.find(articleSettings => articleSettings.ArtikelNrLAG == data.id);
            articleSettings.is_collective = data.isCollective;
            state.isCollective[data.id] = data.isCollective;
        },

        setArticleSettings(state, data) {
            state.articleSettings = data;
        },

        setSingleArticleSettings(state, data) {
            let index = state.articleSettings.findIndex(articleSettings => articleSettings.ArtikelNrLAG == data.ArtikelNrLAG);
            if (index > -1) {
                state.articleSettings.splice(index, 1, data);
            } else {
                state.articleSettings.push(data);
            }
        },

    },
    persist: false
}
