<template>
  <div style="position:relative;">
    <v-card flat>

      <div class="card-header">
        <div class="d-flex justify-center">
          <MonthNavigator v-model="chosenDate" :viewOnly="true"/>
        </div>
        <img
            class="close"
            @click="$emit('closeTimeModal')"
            :src="require('@/assets/img/ico/schliessen.svg')"
        >
      </div>
      <v-card-title class="pb-8 pt-0 px-8 d-block">
        <div class="d-flex px-4 py-4 align-center justify-space-between"
             style="background:#FCF8F4; border-radius: 10px">
          <div class="name flex">
            <span class="text-h6 strong">{{ b.name }}</span>
            <span class="text-h6 font-weight-light pl-2 strong">{{ b.zimmer }}</span>
          </div>
          <div class="flex d-flex align-center justify-center" style="gap:20px">
            <span class="text-h6 strong">Verbrauch erfassen:</span>
            <div>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on="on" class="hov-opacity" small depressed
                         :style="editMode !== 'add' ? 'background-color: #e0dbd7 !important;' : ''"
                         :color="editMode === 'add' ? 'primary' : 'secondary'" @click="changeEditMode('add')">
                    <v-icon size="20">
                      mdi-plus-circle-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>bei Klick +1</span>
              </v-tooltip>
            </div>
            <div>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on="on" small depressed
                         :style="editMode !== 'remove' ? 'background-color: #e0dbd7 !important;' : ''"
                         :color="editMode === 'remove' ? 'primary' : 'secondary'"
                         @click="changeEditMode('remove')">
                    <v-icon size="20">
                      mdi-minus-circle-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>bei Klick -1</span>
              </v-tooltip>
            </div>
            <div>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on="on" small depressed
                         :style="editMode !== 'manual' ? 'background-color: #e0dbd7 !important;' : ''"
                         :color="editMode === 'manual' ? 'primary' : 'secondary'"
                         @click="changeEditMode('manual')">
                    <v-icon size="20">
                      mdi-keyboard-outline
                    </v-icon>
                  </v-btn>
                </template>
                <span>manuelle Eingabe</span>
              </v-tooltip>
            </div>
          </div>
          <div class="flex"></div>
        </div>
      </v-card-title>
      <v-card-text class="body-1 px-8">
        <v-row v-if="b && b.children.length > 0" v-for="(p,index) in b.children"
               :key="'p-'+p.internalID" class="d-flex align-center" style="min-height:85px;">
          <v-col cols="1" class="my-0 py-0">
            <v-lazy>
              <span v-if="bilder[p.internalID] ">
                <v-img :src="bilder[p.internalID]['Z_URL']+'?width=80'"
                       max-height="60" max-width="60" contain></v-img>
              </span>
              <span v-else-if="p.bild">
                <v-img :src="p.bild"
                       max-height="60" max-width="60" contain></v-img>
              </span>
              <span v-else>
                <v-img :src="require('@/assets/img/ico/sortimentsverwaltung-aktiv.svg')" alt=""
                       width="35"></v-img>
              </span>
            </v-lazy>
          </v-col>
          <v-col cols="2" class="my-0 py-0">
            <product-caption :zaehleinheit="p.zaehleinheit" :artnr="p.showID" :name="p.name"
                             :ownartno="p.ownartno"></product-caption>
          </v-col>

          <v-col cols="9" class="my-0 py-0">

            <div class="noselect checkrect cursor-pointer"
                 :class="[0,6].indexOf(moment(moment(datum.choosenMonthYear).add(index-1,'day')).day()) == -1 ? 'weekday' : 'weekend'"
                 v-for="index in datum.daysInMonth">
              <div class="manual-edit-holder"
                   v-if="used[b.internalID+'-'+p.internalID+'-'+index] && used[b.internalID+'-'+p.internalID+'-'+index]['manualEdit'] === true">

                <div class="arrow-up"></div>
                <v-text-field class="manual-edit-input" style="width: 90px;" dense type="number"
                              min="0"
                              max="999"
                              oninput="validity.valid||(value='')"
                              @focus="$event.target.select()" autofocus
                              @blur="removeManualWindowsAndSave(index,p.internalID,b.internalID,  used[b.internalID + '-' + p.internalID + '-' + index].count)"
                              @keyup.en.enter="removeManualWindowsAndSave(index,p.internalID,b.internalID,  used[b.internalID + '-' + p.internalID + '-' + index].count)"
                              v-model="used[b.internalID + '-' + p.internalID + '-' + index].count"></v-text-field>
              </div>
              <div
                  @click="addProdToDate(index,p.internalID,b.internalID,  used[b.internalID + '-' + p.internalID + '-' + index] ? used[b.internalID + '-' + p.internalID + '-' + index].count : false)"
                  :style="index == 1 ? 'border-left: 1px #ccc solid;' : ''">
                <v-lazy>
                  <div class="check-value">
                  <span style="font-weight: bold; color: #000"
                        :class="used[b.internalID+'-'+p.internalID+'-'+index] && used[b.internalID+'-'+p.internalID+'-'+index]['planung'] ? 'planungswert' : ''"
                        v-if="used[b.internalID+'-'+p.internalID+'-'+index]">
                    <span>
                        {{ used[b.internalID + '-' + p.internalID + '-' + index].count }}
                    </span>
                  </span>
                    <span v-else style="color: #ccc">
                    {{ weekdayShort[moment(moment(datum.choosenMonthYear).add(index - 1, 'day')).day()] }}
                  </span>
                  </div>
                </v-lazy>
                <span class="day-index">
                <span
                    :class="moment(moment(datum.choosenMonthYear).add((index-1),'day')).format('YMD') == moment().format('YMD') ? 'today' : ''">
                    {{ index }}
                </span>
              </span>
              </div>
            </div>
            <div class="total-checks font-weight-bold my-0" style="margin-top: 7px !important;">
              <div>
                {{ getTotalFromProd(b.internalID, p.internalID) }}
              </div>
              <span>
                Total
              </span>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <div class="card-footer">
        <div class="list status-info d-none d-md-block">
          <div class="timeline-status -status-ordered">
            geplant
          </div>
          <div class="timeline-status -status-adjusted">
            manuell angepasst
          </div>
        </div>
      </div>

    </v-card>
    <v-dialog v-model="editModeAlert" max-width="400">
      <v-card>
        <v-card-text class="body-1 pa-6">
          Sie befinden sich im «Minus»-Modus. Wenn sie Verbrauch erfassen möchten, stellen Sie den Schalter oben links
          auf «Plus» um.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed @click="editModeAlert = false">Abbrechen</v-btn>
          <v-btn color="primary" depressed @click="editModeAlert = false;editMode = 'add'">
            Modus wechseln
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

</template>
<script>
import moment from 'moment'
import ProductCaption from "./ProductCaption";
import httpClient from "../../utils/httpClient";
import MonthNavigator from '@/components/SmartLogistics/Design/MonthNavigator.vue'

export default {
  components: {MonthNavigator, ProductCaption},
  props: ['datum', 'b', 'usedprop'],

  name: 'ProductTimeline',

  data() {
    return {
      tmpClickValue: 0,
      editModeAlert: false,
      used: false,
      updateTimeout: false,
      editMode: 'add',
      weekdayShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
      moment: moment
    }
  },

  methods: {

    changeEditMode(mode) {
      this.editMode = mode;
    },

    getTotalFromProd(bewohnerID, artNr) {
      var sum = 0;
      for (const [key, el] of Object.entries(this.used)) {
        if (key.startsWith(bewohnerID + '-') && key.indexOf('-' + artNr + '-') != -1 && el.count !== '' && el.count !== undefined) {
          sum += parseInt(el.count);
        }
      }
      return sum;
    },

    async removeManualWindowsAndSave(day, prodID, bewohnerID, actualCount) {

      //wenn Leereingabe aber bereits ein Wert vorhanden war dann darf genullt werden. Wenn kein Wert vorhanden nichts eingetragen, dann ignorieren
      if (
          this.used[bewohnerID + '-' + prodID + '-' + day] &&
          actualCount === '' &&
          (this.tmpClickValue > 0 && this.tmpClickValue != '')
      ) {
        actualCount = 0;
      }

      this.tmpClickValue = '';

      for (const [key, el] of Object.entries(this.used)) {
        this.used[key].manualEdit = false;
      }

      if (actualCount === undefined || actualCount === '' || actualCount === false || actualCount < 0) {

        for (const [key, el] of Object.entries(this.used)) {
          if (key == bewohnerID + '-' + prodID + '-' + day) {
            delete this.used[key];
          }
        }

        this.used = JSON.parse(JSON.stringify(this.used))

        return false;
      }

      this.used = JSON.parse(JSON.stringify(this.used))

      await this.setCount(day, prodID, bewohnerID, actualCount);
    },


    async addProdToDate(day, prodID, bewohnerID, actualCount) {


      if (actualCount > 998) {
        actualCount = 999;
      }


      this.tmpClickValue = actualCount;


      if (this.editMode === 'manual') {

        for (const [key, el] of Object.entries(this.used)) {

          this.used[key].manualEdit = false;
        }
        this.used = JSON.parse(JSON.stringify(this.used))

        if (!this.used[bewohnerID + '-' + prodID + '-' + day]) {
          let obj = {
            [bewohnerID + '-' + prodID + '-' + day]: {
              'primKey': bewohnerID + '-' + prodID + '-' + day,
              'manualEdit': true
            }
          };

          Object.assign(this.used, obj);


        } else {
          this.used[bewohnerID + '-' + prodID + '-' + day]['manualEdit'] = true;
        }
      } else {
        await this.setCount(day, prodID, bewohnerID, actualCount);
      }

    },

    async setCount(day, prodID, bewohnerID, actualCount) {


      if (this.updateTimeout) {
        clearTimeout(this.updateTimeout);
      }

      var counter = 0;

      if (!actualCount && this.editMode === 'remove') {
        this.editModeAlert = true;
        return false;
      }

      //auf 0 und keine Plandaten? Lösche das Element
      if (actualCount == 1 && this.editMode === 'remove') {

        counter = 'unset';

        for (const [key, el] of Object.entries(this.used)) {
          if (key == bewohnerID + '-' + prodID + '-' + day) {
            delete this.used[key];
          }
        }

        //wenn bereits ein Wert da ist -> dann nimm das Element und setze den neuen Count
      } else if (this.used[bewohnerID + '-' + prodID + '-' + day] != undefined) {

        if (this.editMode === 'manual') {
          counter = parseInt(actualCount);

        } else {
          counter = this.editMode === 'remove' ? (actualCount - 1) : (actualCount + 1)
        }

        this.used[bewohnerID + '-' + prodID + '-' + day]['count'] = counter;

        this.used[bewohnerID + '-' + prodID + '-' + day]['planung'] = false;
        //wenn keine Einträge vorhanden dann erstelle ein neues Element mit Count 1?
      } else {

        counter = 1;
        let obj = {
          [bewohnerID + '-' + prodID + '-' + day]: {
            'count': 1,
            'primKey': bewohnerID + '-' + prodID + '-' + day
          }
        };

        if (!this.used || this.used.length == 0) {
          this.used = obj;
        } else {
          Object.assign(this.used, obj);
        }
      }

      var self = this;
      this.updateTimeout = setTimeout(function () {
        const res = httpClient({
          url: process.env.VUE_APP_API_CHECKLIST + 'add/product-to-date',
          method: 'post',
          data: {
            choosenMonthYear: self.datum.choosenMonthYear,
            day: day,
            counter: counter,
            prodID: prodID,
            bewohnerID: bewohnerID,
            editmode: self.editMode
          }
        }).then(function (res) {
              if (res.type == 'success') {

                //auf 0 und keine Plandaten? Lösche das Element
                if (res.count == 'unset') {

                  for (const [key, el] of Object.entries(self.used)) {
                    if (key == bewohnerID + '-' + prodID + '-' + day) {
                      delete this.used[key];
                    }
                  }

                  //wenn bereits ein Wert da ist -> dann nimm das Element und setze den neuen Count
                } else if (self.used[bewohnerID + '-' + prodID + '-' + day] != undefined) {
                  self.used[bewohnerID + '-' + prodID + '-' + day]['count'] = res.count;
                  self.used[bewohnerID + '-' + prodID + '-' + day]['planung'] = false;

                  //wenn keine Einträge vorhanden dann erstelle ein neues Element mit Count 1?
                } else {
                  let obj = {
                    [bewohnerID + '-' + prodID + '-' + day]: {
                      'count': 1,
                      'primKey': bewohnerID + '-' + prodID + '-' + day
                    }
                  };
                  Object.assign(self.used, obj);
                }
                self.used = JSON.parse(JSON.stringify(self.used))
              }
            }
        );
      }, 400)
    }
  },

  computed: {

    chosenDate: {
      get() {
        return moment(this.datum.choosenMonthYear);
      },
      set(date) {
        this.datum.choosenMonthYear = date.format("YYYY-MM-DD")
      }
    },

    bilder: {
      get() {
        return this.$store.state['bilder'].produktbilder
      }
    }
  },

  mounted() {
    this.used = this.usedprop;
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/smartlogistics/mixins/_breakpoint.scss";


.card-header {
  position: sticky;
  top: 0;
  z-index: 10;
  background: white;
  padding: 20px 20px 18px;

  @include breakpoint('md') {
    padding: 40px 50px 18px;
  }

  h1 {
    line-height: 1.1em;
    color: #CE20A8;
  }

  .close {
    position: absolute;
    top: 25px;
    right: 20px;
    width: 25px;
    height: 25px;
    cursor: pointer;

    @include breakpoint(md) {
      top: 40px;
      right: 50px;
    }
  }
}

.card-footer {
  position: sticky;
  bottom: 0;
  width: 100%;
  background: white;
  border-top: 1px solid #000000;
  padding: 20px 50px;

  .status-info {
    display: flex !important;
    justify-content: flex-end;
  }
}
</style>
