<template>
  <Dialog
      :forceOpen="forceOpen"
      :width="620"
  >
    <template v-slot:button="{ on, attrs }">
      <div class="icon-kommentar"
           v-bind:class="{'icon-kommentar-erfassen': !owner.note, 'icon-kommentar-hinterlegt': owner.note}"
           v-bind="attrs"
           v-on="on"></div>
    </template>

    <template #header>
      <h2>Notiz für <span class="text--primary">{{ owner.name }}</span></h2>
    </template>
    <template v-slot:close-button="{close}">
      <img
          class="close"
          @click="cancel(close)"
          :src="require('@/assets/img/ico/schliessen.svg')"
      >
    </template>

    <template v-slot:actions="{close}">
      <v-btn
          color="beige"
          depressed
          @click="cancel(close)"
      >
        Abbrechen
      </v-btn>
      <v-btn
          color="primary"
          depressed
          :disabled="saving"
          @click="save(close)"
      >
        Speichern
      </v-btn>
    </template>
    <v-textarea
        label="Notiz"
        class="mt-2"
        maxlength="250"
        outlined
        multi-line
        color="black"
        rows="5"
        :disabled="saving"
        v-model="owner.note"
        clearable></v-textarea>

  </Dialog>
</template>

<script>
import Dialog from '@/components/SmartLogistics/Design/Dialog.vue'
import {bewohnerActions} from "@/components/observables/bewohner";
import {wohneinheitenActions} from "@/components/observables/wohneinheiten";

export default {
  name: 'CommentDialog',
  components: {Dialog},

  props: {
    owner: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: 'resident',
      validator: function (value) {
        return ['resident', 'living-unit'].indexOf(value) !== -1
      }
    },
  },

  mounted() {
    this.initNote = this.owner.note;
  },

  data() {
    return {
      forceOpen: false,
      activeSection: null,
      selectedDate: null,
      saving: false,
      initNote: null,
    }
  },

  methods: {
    ...bewohnerActions,
    ...wohneinheitenActions,
    save(callback) {
      this.saving = true;
      let promise = null;
      if (this.type == 'resident') {
        promise = this.saveForResident()

      } else {
        promise = this.saveForLivingUnit();
      }
      promise.then((response) => {
        this.saving = false;
        if (response.type == "success") {
          this.initNote = this.owner.note;
          callback();
        }
      })
    },

    cancel(callback) {
      this.saving = false;
      this.owner.note = this.initNote;
      callback();
    },

    saveForResident() {
      return this.updateBewohnerNote({
        bewohnerID: this.owner.internalID,
        checklist_note: this.owner.note
      })
    },
    saveForLivingUnit() {
      return this.updateWohneinheitenNote({
        id: this.owner.internalID,
        checklist_note: this.owner.note
      })
    }
  },
}
</script>

<style lang="scss">
.icon-kommentar {
  font-size: 30px;
}

.card-actions {
  text-align: right;

  button + button {
    margin-left: 10px;
  }
}
</style>
