<template>
  <div>
    <template v-if="!show && isMobile">
      {{ text }}
      <v-btn
          small
          depressed
          @click="show = true"
      >
        mehr
      </v-btn>
    </template>
    <template v-else-if="show || !isMobile">
      <slot/>
      <v-btn
          v-if="isMobile"
          small
          depressed
          @click="show = false"
      >
        weniger
      </v-btn>
    </template>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'ExpandableText',

  props: {
    text: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      show: false
    }
  },

  computed: {
    ...mapGetters({
      isMobile: 'system/isMobile'
    })
  }
}
</script>