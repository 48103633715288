<template>
  <component :is="planComponent" v-if="modelTypeResolved"/>
</template>

<script>
import ModelB from '@/views/SmartLogistics/ModelB.vue'
import ModelC from '@/views/SmartLogistics/ModelC.vue'
import { mapActions, mapGetters } from 'vuex'
import router from '@/router'

export default {
  name: 'SmartLogistics',
  components: { ModelC, ModelB },

  data () {
    return {
      modelTypeResolved: false
    }
  },

  computed: {
    ...mapGetters({
      planModel: 'smartLogisticSettings/planModel',
      selectedDate: 'smartLogisticSettings/selectedDate',
      activePlanModel: 'smartLogisticSettings/planModel'
    }),

    planComponent () {
      if (this.planModel === 'c') {
        return ModelC
      } else {
        return ModelB
      }
    }
  },

  beforeCreate () {
    if (!this.selectedDate) {
      // ensure that selectedDate is set
      this.$store.commit('smartLogisticSettings/setSelectedDate', this.$moment().startOf('month'))
    }
  },

  created () {
    this.$store.dispatch('bilder/fetchImages')
    this.$store.dispatch('articles/fetchCategoriesSortArray')
    this.$store.dispatch('articles/fetchArticleInfos')
    this.$store.dispatch('articles/fetchMySortiment')
    this.$store.dispatch('articleSettings/fetchArticleSettings')

    // firewall to ensure that customer has model a or b
    // since smartLogistics settings are fetched outside this vue, it is necessary to check the model via interval as model a is default
    let intervalCounter = 0
    const interval = setInterval(() => {
      if (['b', 'c'].includes(this.activePlanModel)) {
        this.modelTypeResolved = true
        clearInterval(interval)
      }

      if (intervalCounter > 50) {
        clearInterval(interval)
        router.push('/versorgungsplan')
      }

      intervalCounter++
    }, 50)
  }
}
</script>


<style lang="scss">
@import "./src/scss/smartlogistics/smartlogistics.scss";

.v-expansion-panel-content__wrap {
  &:last-child {
    margin-bottom: 20px;
  }
}
</style>
