import httpClient
    from './httpClient'
import router from '../router'

const actionScope = `loader`

export function setupInterceptors({dispatch}) {
    let requestsPending = 0
    const req = {
        pending: () => {
            requestsPending++
            dispatch(`${actionScope}/show`)
        },
        done: () => {
            requestsPending--
            if (requestsPending <= 0) {
                dispatch(`${actionScope}/hide`)
            }
        }
    }

    httpClient.interceptors.request.use(
        config => {
            req.pending()

            return config
        },
        error => {

            requestsPending--
            req.done()

            return Promise.reject(error)
        }
    )
    httpClient.interceptors.response.use(
        ({data}) => {
            req.done()
            //normale fehlermeldung oder fehler popup aufgrund LOCK (da zb. offener WK existiert & Planungsdaten angepasst werden möchten)
            dispatch(`${actionScope}/hide`)
            if (data.message) {
                dispatch(`${actionScope}/setmsg`, data.message)
                dispatch(`${actionScope}/setmsgtype`, data.type)
                dispatch(`${actionScope}/setmsgvisible`, true)
            }

            return Promise.resolve(data)
        },
        (error) => {
            dispatch(`${actionScope}/hide`)

            //if host contains .staging return
            if (window.location.host.includes('.staging')) {
                return Promise.reject(error)
            }


            if (error.response.status === 503) {
                sessionStorage.clear()
                localStorage.clear()
                if (router.currentRoute.path != '/login') {
                    router.push('/login')
                }
            }

            if (error.response.status == 401 || error.response.status == 403) {
                sessionStorage.clear()
                localStorage.clear()
                window.location.href = '/'
            }

            if (error.response.status == 422 && (error.response.data.errors !== undefined)) {
                var errors = error.response.data.errors

                var errorMessages = []
                Object.values(errors).forEach(e => {
                    try {
                        errorMessages.push(e[0])
                    } catch (e) {
                    }
                })

                dispatch(`${actionScope}/setmsg`, errorMessages)
                dispatch(`${actionScope}/setmsgtype`, 'error')
                dispatch(`${actionScope}/setmsgvisible`, true)
            } else {
                if (error.message) {
                    dispatch(`${actionScope}/setmsg`, error.response.data.message)
                    dispatch(`${actionScope}/setmsgtype`, 'error')
                    dispatch(`${actionScope}/setmsgvisible`, true)
                }
            }

            req.done()

            return Promise.reject(error)
        }
    )
}