import httpClient from '../../utils/httpClient'
import {smartLogisticDirectOrder} from "@/store/modules/smartLogisticDirectOrder";
import moment from 'moment'

export const smartLogisticBasket = {
    namespaced: true,

    state: {
        loading: false,
        basket: null,
        updateTimeout: null,
        updateBasketTimeout: null,
        changesMadeInUnitsWithIds: [],
    },

    getters: {
        changesMadeInBasketWithId: state => (id) => {
            return state.changesMadeInUnitsWithIds.indexOf(id) !== -1
        },
        basketItems: state => state.basket ? state.basket.items : [],
        basket: state => state.basket,
        isLoading: state => state.loading,
    },

    actions: {
        async fetch({commit, state}, payload) {

            state.loading = true

            let date = payload.dateObject?.date ?  '/' + payload.dateObject.date : ''

            let response = await httpClient({
                url: process.env.VUE_APP_API_LOGISTICS + 'basket/by-living-unit/' + payload.unit + date,
                method: 'get',
            })
            if (response.data) {
                commit('setBasket', response.data)
            }
            state.loading = false
        },

        async fetchDirectOrderBasket({commit, state}, payload) {
            state.loading = true
            let directOrderId = smartLogisticDirectOrder.state.currentId;
            let response = await httpClient({
                url: process.env.VUE_APP_API_LOGISTICS + 'basket/direct-order/' + payload.unit + '/' + moment().format('YYYY-MM-DD'),
                method: 'get',
            })
            if (response.data) {
                commit('setBasket', response.data)
            }
            state.loading = false
        },

        async updateArticle({commit, state}, payload) {

            if (state.updateTimeout) {
                clearTimeout(state.updateTimeout)
            }

            state.updateTimeout = setTimeout(async () => {
                let response = await httpClient({
                    url: process.env.VUE_APP_API_LOGISTICS + 'basket/' + state.basket.id + '/item',
                    method: 'put',
                    data: payload
                })
                if (response.data) {
                    commit('setChangesMade', response.data.living_unit_id)
                    commit('setBasket', response.data)
                }
            }, 500)

        },

        async assignArticle({commit, state}, payload) {
            let response = await httpClient({
                url: process.env.VUE_APP_API_LOGISTICS + 'basket/' + state.basket.id + '/item',
                method: 'post',
                data: payload
            })
            if (response.data) {
                commit('setChangesMade', response.data.living_unit_id)
                commit('setBasket', response.data)
            }
        },

        async convertArticle({commit, state}, payload) {
            let response = await httpClient({
                url: process.env.VUE_APP_API_LOGISTICS + 'basket/' + state.basket.id + '/item/convert',
                method: 'post',
                data: payload
            })
            if (response.data) {
                commit('setChangesMade', response.data.living_unit_id)
                commit('setBasket', response.data)
            }
        },

        async deleteArticle({commit, state}, payload) {
            let response = await httpClient({
                url: process.env.VUE_APP_API_LOGISTICS + 'basket/' + state.basket.id + '/item',
                method: 'delete',
                data: {artikelNrLAG: payload.artikelNrLAG + '-' + payload.articlePackageUnitId}
            })
            if (response.data) {
                commit('setBasket', response.data)
            }
        },

        async reset({commit, state}) {
            let response = await httpClient({
                url: process.env.VUE_APP_API_LOGISTICS + 'basket/' + state.basket.id,
                method: 'delete',
            })
            if (response.data) {
                commit('removeChangesMade', response.data.living_unit_id)
                commit('setBasket', response.data)
            }
        },

        async update({commit, state}, payload) {
            if (state.updateBasketTimeout) {
                clearTimeout(state.updateBasketTimeout)
            }
            state.updateBasketTimeout = setTimeout(async () => {
                let response = await httpClient({
                    url: process.env.VUE_APP_API_LOGISTICS + 'basket/' + state.basket.id,
                    method: 'patch',
                    data: payload
                })
                if (response.data) {
                    commit('removeChangesMade', response.data.living_unit_id)
                    commit('setBasket', response.data)
                }
            }, 500);
        },

        async order({commit, state, dispatch}, payload) {
            let response = await httpClient({
                url: process.env.VUE_APP_API_LOGISTICS + 'basket/' + state.basket.id + "/confirm",
                method: 'put',
                data: {
                    customer_order_no: state.basket.customer_order_no,
                    custom_order_date: state.basket.custom_order_date,
                    comment: state.basket.comment
                },
            })

            if (response.data) {
                commit('removeChangesMade', response.data.living_unit_id)
                commit('setBasket', response.data)

                if (!payload.isDirectOrder) {
                    payload.status = 'order_confirmed';
                    dispatch('smartLogisticOrderSuggestion/updateSuggestionStatus', payload, {root: true});
                }
            }
        },

        async revert({commit, state, dispatch}, payload) {
            let response = await httpClient({
                url: process.env.VUE_APP_API_LOGISTICS + 'basket/' + state.basket.id + "/unconfirm",
                method: 'put',
            })
            if (response.data) {
                commit('setBasket', response.data)

                payload.status = 'edited';
                dispatch('smartLogisticOrderSuggestion/updateSuggestionStatus', payload, {root: true});
            }
        },

        setChangesMade({commit}, data) {
            commit('setChangesMade', data)
        }
    },

    mutations: {
        setBasket(state, data) {
            state.basket = data
        },

        setChangesMade(state, data) {
            if (state.changesMadeInUnitsWithIds.indexOf(data) === -1) {
                state.changesMadeInUnitsWithIds.push(data)
            }
        },

        removeChangesMade(state, data) {
            let index = state.changesMadeInUnitsWithIds.indexOf(data)
            if (index !== -1) {
                state.changesMadeInUnitsWithIds.splice(index, 1)
            }
        },
    },
    persist: false
}
