<template>
  <v-checkbox
      :value="value"
      @change="$emit('input', !!$event)"
      light
  >
    <template #label>
      <div>Ich habe die <span class="text-link" @click="openTerms">Allgemeinen Geschäftsbedingungen</span> gelesen und stimme diesen ausdrücklich zu.</div>
    </template>
  </v-checkbox>
</template>

<script>

export default {
  name: 'BasketAGB',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    openTerms(e) {
      e.preventDefault()
      window.open('https://puras.ch/de/agb.html', '_blank')
    },
  }
}
</script>