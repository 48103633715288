<template>
  <v-expansion-panels
      v-model="panels"
      :multiple="searchIsActive"
      accordion
      flat
      class="expansion-panel">
    <slot/>
  </v-expansion-panels>
</template>
<script>

import { mapGetters } from 'vuex'

export default {
  name: 'ExpansionPanels',

  computed: {
    ...mapGetters({
      searchIsActive: 'smartLogisticSettings/searchIsActive',
      activeModel: 'smartLogisticSettings/planModel',
    }),

    panels: {
      get() {
        return this.$store.getters['smartLogisticSettings/activePanels']
      },
      set(value) {
        this.$store.commit('smartLogisticSettings/setPanels', value)
      }
    }
  }
}
</script>
