<template>
  <div class="font-weight-bold align-self-end" :class="addClasses" v-html="text">

  </div>
</template>

<script>
export default {
  name: 'HeaderLabel',
  props: {
   text: {
     type: String,
     required: true
   },
    addClasses: {
      type: String,
      default: ''
    },
  },
}
</script>