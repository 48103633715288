<template>
  <v-expansion-panel ref="panel" @change="changePanelState()">
    <v-expansion-panel-header
        v-if="!searchIsActive"
        class="sl-expansion-panel pl-2 v-expansion-panel-header--reversed">
      <div class="sl-expansion-panel-header">
        <div class="panel-title">
          <h2 class="font-weight-medium">
            <slot name="name"/>
          </h2>
        </div>
        <div v-if="!isDirectOrder" class="panel-notification">
          <slot name="notification"/>
        </div>
        <div v-if="!isDirectOrder" class="panel-info">
          <slot name="info"/>
        </div>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="px-0">
      <template v-if="settings && settings.interval">
        <slot/>
      </template>
      <template v-else-if="!isDirectOrder">
        <div class="mt-16 mb-16">
          <v-alert outlined dense transition="appear">
            <div class="d-flex">
              <v-icon size="30" color="error">mdi-alert</v-icon>
              <div class="ml-4 pt-1">
                <b>ACHTUNG: </b> Es fehlen Einstellungen (Startdatum, Intervall) zu dieser Wohneinheit. Bitte nehmen Sie
                mit Puras Kontakt auf.
              </div>
            </div>
          </v-alert>
        </div>
      </template>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>

import {mapGetters} from 'vuex'

export default {
  name: 'ExpansionPanel',
  props: {
    unit: {
      type: Object,
      required: true,
    },
    detailViewIsBasket: {
      type: Boolean,
      default: false
    },
    isDirectOrder: {
      type: Boolean,
      default: false
    }
  },
  emits: ['selectedUnitChange'],
  mounted() {
    this.changePanelState();
  },
  methods: {
    changePanelState() {
      setTimeout(() => {
        try {
          if (this.$refs.panel.isActive) {
            this.$emit('selectedUnitChange', this.unit.internalID)
          } else {
            this.$emit('selectedUnitChange', null)
          }
        } catch (e) {
          this.$emit('selectedUnitChange', null)
        }
      }, 200);
    }
  },
  computed: {
    ...mapGetters({
      searchIsActive: 'smartLogisticSettings/searchIsActive',
    }),

    settings() {
      return this.$store.getters['smartLogisticSettings/livingUnitSettingsById'](this.unit.internalID)
    },


    basketHasChanged() {
      let basket = this.$store.getters['smartLogisticBasket/basket']
      return basket ? basket.living_unit_id == this.unit.internalID && basket.edited : false
    }
  },
}
</script>

<style type="scss" scoped>
  .panel-notification {
    justify-content: center;
    align-items: center;
    display: flex;
  }
</style>
