<template>
   <v-col cols="12" class="mt-4 pb-0">
     <v-tooltip bottom max-width="390" content-class="custom-tooltip sand large">
       <template v-slot:activator="{ on, attrs }">
         <span
               v-bind="attrs"
               v-on="on">
           <span class="icon-gemeinschaftsartikel"></span>
           <span class="grey--text ml-3">{{ $t('sl.collectiveArticles.collectiveArticle') }}</span>
           <span class="icon-informationen grey--text ml-3"></span>
         </span>
       </template>
       <span v-html="$t('sl.collectiveArticles.tooltipSettings_' + $store.state.smartLogisticSettings.model)"></span>
     </v-tooltip>
   </v-col>
</template>
<script>
export default {
  name: 'OrderSuggestionCollectiveArticleInfo'
}
</script>
