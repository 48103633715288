export const inlineSearch = {
    namespaced: true,

    state: {
        searchTerm: null,
        searchTarget: 'both',
    },

    getters: {
        searchTerm: state => state.searchTerm ? state.searchTerm : null,
        searchTarget: state => state.searchTarget,
        searchIsActive: state => state.searchTerm && state.searchTerm.length > 0,
    },

    actions: {
        resetSearch({commit,dispatch}) {
            commit('setSearchTerm', null)
            commit('setSearchTarget', 'both')
        }
    },

    mutations: {
        setSearchTerm(state, value) {
            state.searchTerm = value
        },
        setSearchTarget(state, value) {
            state.searchTarget = value
        },
        setSearchTargetToResident(state) {
            state.searchTarget = 'resident'
        },
        setSearchTargetToArticle(state) {
            state.searchTarget = 'article'
        }
    },

    persist: false
}