<template>
  <div class="overview-data-row py-1 d-flex item b-bottom" style="gap: 20px;">
    <div style="flex-shrink: 0; flex-basis: 50px; align-items: center;">

      <v-checkbox
          :disabled="!isActive"
          v-model="$store.state['direktabrechnung'].selectedBewohner"
          :value="cart.bewohner_id"
          hide-details
          class="pa-0"
          style="margin-top: 2px;"
      />
    </div>
    <div
        v-for="(col,index) in dataCols"
        :key="'content_index_'+index"
        style="flex-shrink: 0; align-items: center;"
        :style="{ 'flex-basis': col.width, 'text-align': col.text, 'padding-top': '2px'  }"
    >
      <component
          v-if="col.component !== undefined"
          :key="'component_' + col.title + col.attr+'_index_'+index"
          :is="col.component"
          :cart="cart"
          :bewohnerID="cart.bewohner_id"
          :showType="showType"
          :attr="col.attr"
      />
      <span v-else-if="col.function !== undefined">
          {{ callFunc(col, cart) }}
      </span>
      <template v-else-if="col.attr !== undefined">
        <v-tooltip
            v-if="bewohnerHasBeenDeleted && (col.attr==='bewohner.vorname' || col.attr==='bewohner.name')"
            top
        >
          <template v-slot:activator="{ on, attrs }">
            <span
                v-bind="attrs"
                v-on="on"
                class="text-decoration-line-through"
            >
              {{ getAttr(col) }}
            </span>
          </template>
          Dieser Bewohner wurde am {{ $moment(cart.austritt).format('DD.MM.YYYY') }} gelöscht<br>
          und ist nur bis Ende Monat des Lösch-Datums verfügbar.
        </v-tooltip>
        <span v-else>
          {{ getAttr(col) }}
        </span>
      </template>
    </div>
  </div>
</template>
<script>

import {rowConfig} from "@/config/DirektabrechnungOverviewConfig"
import BewohnerDetail from "@/components/direktabrechnung/BewohnerDetail";
import Status from "@/components/direktabrechnung/Status";
import {isCartActive} from "@/composables/direktabrechnung";
import CartValidationDialog from "@/components/direktabrechnung/CartValidationDialog.vue";


export default {
  components: {
    CartValidationDialog,
    BewohnerDetail, Status
  },
  name: "OverviewDataRow",
  props: {
    showType: {
      type: String,
      required: true
    },
    cart: {
      type: Object,
      required: true
    }
  },

  computed: {
    dataCols() {
      return rowConfig[this.showType]
    },

    isActive() {
      return isCartActive(this.cart, this.showType)
    },

    bewohnerHasBeenDeleted() {
      const now = this.$moment()
      const austritt = this.$moment(this.cart.austritt)
      return now.isSameOrAfter(austritt, 'year') && now.isSameOrAfter(austritt, 'month')
    }
  },

  methods: {
    getAttr(col) {

      if (col.attr.indexOf(".") > -1) {
        let attr = col.attr.split(".");

        if (this.cart[attr[0]] === undefined || this.cart[attr[0]] === null) {
          return "";
        }

        var value = this.cart[attr[0]][attr[1]]

        return isNaN(value) || col.attr.indexOf('zimmer')  != -1 || col.attr.indexOf('externalID')  != -1? value: (value > 0? parseFloat(value).toFixed(2): '') ;
      }

      if (this.cart[col.attr] === undefined) {
        return "";
      }

      var value = this.cart[col.attr]

      // nesure that KrankenKassenTotalVerrechenbar is always shown even when 0
      if(col.attr === 'KrankenKassenTotalVerrechenbar') {
        return parseFloat(value).toFixed(2)
      }

      return isNaN(value) || col.attr.indexOf('zimmer')  != -1 || col.attr.indexOf('externalID')  != -1? value: (value > 0? parseFloat(value).toFixed(2): '') ;
    },

    callFunc(col, cart) {
      return this[col.function](cart);
    },
  }
}
</script>

<style lang="scss">
.overview-data-row {
  transition: background-color 200ms ease-in;

  .v-input--is-disabled {
    opacity: .2;
  }

  &:hover {
    background-color: #F4EDE7;
  }
}
</style>