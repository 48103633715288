const rowConfig = {
    krankenkasse: [
        {
            width: '100px',
            attr: 'bewohner.vorname',
            title: 'Vorname',
            text: 'left',
        }, {
            width: '220px',
            attr: 'bewohner.name',
            title: 'Name',
            text: 'left',
        }, {
            width: '80px',
            attr: 'bewohner.zimmer',
            title: 'Zimmer',
            text: 'left',
        }, {
            width: '100px',
            attr: 'bewohner.externalID',
            title: 'BewohnerID',
            text: 'left',
        }, {
            width: '210px',
            attr: 'krankenkasse.Versicherer_Name',
            title: 'Krankenkasse',
            text: 'left',
        }, {
            width: '70px',
            attr: 'KrankenKassenTotalVerrechenbar',
            title: 'Total',
            text: 'right',
        }, {
            width: '120px',
            attr: 'KrankenKassenTotalNichtVerrechenbar',
            title: 'Nicht verrechenbar',
            text: 'right',
        }, {
            width: '130px',
            component: 'Status',
            title: 'Status',
            text: 'left',
        }, {
            width: '70px',
            component: 'CartValidationDialog',
            title: 'Hinweis',
            text: 'right',
        }, {
            width: '80px',
            component: 'BewohnerDetail',
            title: 'Detail',
            text: 'right',
        }
    ],

    bewohner: [
        {
            width: '100px',
            attr: 'bewohner.vorname',
            title: 'Vorname',
            text: 'left',
        }, {
            width: '220px',
            attr: 'bewohner.name',
            title: 'Name',
            text: 'left',
        }, {
            width: '80px',
            attr: 'bewohner.zimmer',
            title: 'Zimmer',
            text: 'left',
        }, {
            width: '100px',
            attr: 'bewohner.externalID',
            title: 'BewohnerID',
            text: 'left',
        }, {
            width: '210px',
            text: 'right',
        }, {
            width: '70px',
            attr: 'BewohnerTotalVerrechenbar',
            title: 'Total',
            text: 'right',
        }, {
            width: '120px',
            text: 'right',
        }, {
            width: '130px',
            attr: '',
            title: '',
            text: 'left',
        }, {
            width: '70px',
            component: 'CartValidationDialog',
            title: 'Hinweis',
            text: 'right',
        }, {
            width: '80px',
            component: 'BewohnerDetail',
            title: 'Detail',
            text: 'right',
        },
    ],

    company: [
        {
            width: '100px',
            attr: 'bewohner.vorname',
            title: 'Vorname',
            text: 'left',
        }, {
            width: '220px',
            attr: 'bewohner.name',
            title: 'Name',
            text: 'left',
        }, {
            width: '80px',
            attr: 'bewohner.zimmer',
            title: 'Zimmer',
            text: 'left',
        }, {
            width: '100px',
            attr: 'bewohner.externalID',
            title: 'BewohnerID',
            text: 'left',
        }, {
            width: '210px',
            text: 'right',
        }, {
            width: '70px',
            attr: 'BetriebsmaterialTotal',
            title: 'Total',
            text: 'right',
        }, {
            width: '120px',
            text: 'right',
        }, {
            width: '130px',
            attr: '',
            title: '',
            text: 'left',
        }, {
            width: '70px',
            component: 'CartValidationDialog',
            title: 'Hinweis',
            text: 'right',
        }, {
            width: '80px',
            component: 'BewohnerDetail',
            title: 'Detail',
            text: 'right',
        },
    ]
}

export {
    rowConfig
}