<template>
  <v-row class="b-bottom mx-0 direct-order-article align-center" :class="article.geloescht == '1' ? 'deleted' : ''">
    <v-col cols="1">
      <ArticleImage :article="article"/>
    </v-col>

    <v-col cols="5">
      <ArticleCaption :article="article"/>
      <div class="d-flex align-center">
        <template v-if="article.manuallyAdded && currentStatus !== 'completed'">
          <v-hover v-slot="{hover}">
            <img :src="hover ? require('@/assets/img/ico/loeschen-hover.svg') : require('@/assets/img/ico/loeschen.svg')"
                 @click="removeArticle(article.internalID, $event)" class="cursor-pointer mr-3" width="27">
          </v-hover>
        </template>
        <ArticleDetailDialog :article="article" linkClasses="text-decoration-underline grey--text cursor-pointer" linkText="Mehr" :noScroll="true"></ArticleDetailDialog>
      </div>
    </v-col>

    <v-col cols="2">
      {{ article.unit }}
    </v-col>

    <v-col cols="2">
      <v-text-field
          class="float-right number-input"
          type="number"
          min-value="0"
          light
          v-model="quantity"
          autocomplete="off"
          :disabled="article.geloescht == '1'"
          style="width: 70px"
      ></v-text-field>
    </v-col>
    <v-col cols="2" class="d-flex justify-end">
      <v-switch
          style="margin-right: -15px"
          :disabled="article.geloescht == '1'"
          v-model="active"
          dense small light flat inset
          :true-value="1" :false-value="0"
          label=""
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import ArticleImage from '../../Article/ArticleImage.vue'
import ArticleCaption from '../../Article/ArticleCaption.vue'
import ArticleDetailDialog from '@/components/SmartLogistics/Article/ArticleDetailDialog.vue'

export default {
  name: 'DirectOrderArticle',
  components: { ArticleDetailDialog, ArticleCaption, ArticleImage },

  props: {
    article: {
      type: Object,
      required: true
    },
  },

  computed: {
    ...mapGetters({
      images: 'bilder/produktbilder',
      saving: 'smartLogisticDirectOrder/isSaving',
      currentStatus: 'smartLogisticOrderSuggestion/currentStatus'
    }),

    quantity: {
      get () {
        return this.article.quantity
      },
      set (value) {
        let quantity = parseInt(value) < 0 ? 0 : parseInt(value)
        if (this.active) {
          if (quantity === 0) {
            this.article.active = false
            this.$store.dispatch('smartLogisticDirectOrder/updateArticle', {
              artikelNrLAG: this.article.internalID,
              ownerType: this.article.ownerType,
              ownerId: this.article.ownerId,
              quantity: 0,
              active: false
            })
          } else {
            this.$store.dispatch('smartLogisticDirectOrder/updateArticle', {
              artikelNrLAG: this.article.internalID,
              ownerType: this.article.ownerType,
              ownerId: this.article.ownerId,
              quantity: quantity,
              active: this.article.active,
            })
          }
        } else {
          if (quantity > 0) {
            this.article.quantity = quantity
            this.article.active = true
            this.$store.dispatch('smartLogisticDirectOrder/updateArticle', {
              artikelNrLAG: this.article.internalID,
              ownerType: this.article.ownerType,
              ownerId: this.article.ownerId,
              quantity: quantity,
              active: this.article.active,
            })
          }
        }
      }
    },

    active: {
      get () {
        return this.article.active ? 1 : 0
      },
      set (value) {
        let quantity = this.quantity

        //set quantity to 1 if activated for first time
        if (value && quantity === 0) {
          quantity = 1
        }
        //set quantity to 0 if deactivated
        else if (!value || value === 0) {
          quantity = 0
        }

        this.$store.dispatch('smartLogisticDirectOrder/updateArticle', {
          artikelNrLAG: this.article.internalID,
          ownerType: this.article.ownerType,
          ownerId: this.article.ownerId,
          quantity: quantity,
          active: !!value,
        })
      }
    }

  },
  methods: {
    removeArticle (artikelNrLAG, event) {
      event.preventDefault()
      this.$store.dispatch('smartLogisticDirectOrder/removeArticle', { artikelNrLAG: artikelNrLAG, ownerType: this.article.ownerType, ownerId: this.article.ownerId })
    },
  }

}
</script>
<style lang="scss">
.direct-order-article.deleted {
  * {
    color: #9E9E9E;
  }
}
</style>
