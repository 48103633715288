<template>
  <div>
    <div class="search " style="width: 280px">
      <v-text-field
          v-model="searchTerm"
          :placeholder="searchTarget === 'resident' ? 'Suche' : 'Suche'"
          clearable
          light
          autocomplete="off"
          class="d-none d-md-block mt-4 inline-search"
          style="margin-top: -9px;"
      >
        <template v-slot:append>
          <img width="20" height="20" :src="require('@/assets/img/ico/suche.svg')"
               style="position:relative; top: 1px; opacity: 0.5;"
               class="cursor-pointer">
        </template>
      </v-text-field>

      <!--<div class="d-flex pt-5">
        <div class="px-3 pt-2" :class="searchTarget === 'resident' ? 'text--primary' : ''"
             style="font-size: 15px;">
          <div class="cursor-pointer hov-opacity" style="margin-top: 3px;"
               @click="setSearchTargetResident">
            Bewohner
          </div>
        </div>
        <div class="pt-2 mt-0" style="position:relative;" id="srchSwitch">
          <v-switch style="" v-model="searchTarget" class=" mt-0"
                    :ripple="false"
                    true-value="article" false-value="resident" dense x-small light flat>
          </v-switch>
        </div>
        <div class="pt-2 px-3" :class="searchTarget === 'article' ? 'text--primary' : ''" style="font-size: 15px;">
          <div class="cursor-pointer hov-opacity" style="margin-top: 3px;"
               @click="setSearchTargetArticle">
            Produkte
          </div>
        </div>
      </div>-->
    </div>

    <div v-if="searchTerm && searchTerm !== '' && searchInstancesCount === 0" class="mt-4">
      Keine Resultate mit diesem Suchbegriff
    </div>
  </div>
</template>
<script>

export default {
  name: 'InlineSearch',
  data() {
    return {
      searchDebounce: null,
      searchInstancesCount: 0
    }
  },

  computed: {
    searchTerm: {
      get() {
        return this.$store.getters['inlineSearch/searchTerm']
      },
      set(value) {

        if (!value || value.length < 1) {
          this.$store.dispatch('tree/closeAllExpandedResidents')
        }

        if (this.searchDebounce) {
          clearTimeout(this.searchDebounce);
        }

        this.searchDebounce = setTimeout(() => {
          this.$store.commit('inlineSearch/setSearchTerm', value)
          setTimeout(() => {
            this.updateSearchInstancesCount();
          }, 200);
        }, 450);
      }
    },
    searchTarget: {
      get() {
        return this.$store.getters['inlineSearch/searchTarget']
      },
      set(value) {
        this.$store.commit('inlineSearch/setSearchTarget', value)
      }
    },
  },


  methods: {

    updateSearchInstancesCount() {
      this.searchInstancesCount = Array.from(
          document.querySelector('.v-expansion-panel-content__wrap').children
      ).filter(child => {
        return child && child.children.length > 0 && child.classList.contains('v-lazy')
      }).length
    },

    setSearchTargetResident() {
      this.$store.commit('inlineSearch/setSearchTargetToResident')
    },
    setSearchTargetArticle() {
      this.$store.commit('inlineSearch/setSearchTargetToArticle')
    }
  },

  mounted() {
    this.$store.dispatch('inlineSearch/resetSearch')
  }
};
</script>
<style lang="scss">
.inline-search {
  .v-text-field__details {
    display: none;
  }
}
</style>
