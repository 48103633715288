export const intervalMixin = {
  computed: {
    /**
     *
     */
    intervalKey () {
      const key = Object.keys(this.articleConsumption)

      if(key.length === 0) {
        return 0
      }

      return this.articleConsumption[key[0]].intervall
    },

    /**
     *
     */
    isDaily () {
      return this.intervalKey=== 1
    },

    /**
     *
     */
    isWeekly () {
      return this.intervalKey === 7
    },

    /**
     *
     */
    isMonthly () {
      return this.intervalKey === 28
    },

    /**
     *
     * @returns {*}
     */
    daysInMonth () {
      return this.date.daysInMonth()
    },

    /**
     *
     * @returns {*[]}
     */
    dayMatrix () {
      const matrix = []
      const weeks = Math.ceil(this.daysInMonth / 7)
      // as we increment each loop, it has to be the day before the first day of the month
      const date = this.date.clone().subtract(1, 'days')

      for (let i = 0; i < this.daysInMonth; i++) {
        matrix.push(date.add(1, 'days').clone())
      }

      return matrix
    },

    /**
     *
     * @returns {number}
     */
    intervalDaysInMonth () {
      var nDays = 0

      this.dayMatrix.forEach(day => {
        if (day.day() === 1) {
          nDays++
        }
      })

      return nDays
    },

    /**
     *
     * @returns {number}
     */
    intervalDays () {
      var nDays = 0

      if (this.isDaily) {
        nDays = this.daysInMonth
      } else if (this.isWeekly) {
        nDays = this.intervalDaysInMonth
      }
      // every month
      else {
        nDays = 1
      }

      return nDays
    },
  }
}