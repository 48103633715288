<template>
  <div v-if="!searchIsActive">
    <!-- Order Section -->
    <template v-if="sectionIsOrder">
      <div class="title-section-order my-6">
        <!-- Order Suggestion View -->
        <div v-if="detailViewIsOrderSuggestion" class="section-title">
          <h2 class="primary--text">Bestellvorschlag</h2>
            <IntervalTextLabel :unit-level="unit"/>
        </div>

        <!-- Direct Order View -->
        <div v-if="isDirectOrder && !detailViewIsDirectOrderBasket" class="section-title">
          <h2 class="primary--text">Sofortbestellung</h2>
          <span>wird <b>direkt</b> an Puras übermittelt</span>
        </div>

        <!-- Basket View -->
        <div v-if="detailViewIsBasket" class="section-title">
          <h2 class="primary--text">Warenkorb</h2>
        </div>
        <!-- Basket View -->
        <div v-if="detailViewIsDirectOrderBasket" class="section-title">
          <h2 class="primary--text">Warenkorb - Sofortbestellung</h2>
        </div>
        <OrderHistoryPills v-if="!isDirectOrder" :unit="unit.internalID" class="order-pills"/>
      </div>

      <div class="d-flex relative">
        <div class="d-flex">
          <OrderResidentBasketSelector v-if="!suggestionsLoading"/>

          <!-- no basket reloads - at least for model b
          <div v-if="showResetBasketButton" @click="resetBasket"
               class="d-flex cursor-pointer op-hover mb-8">
            <div class="icon-neu-laden ml-8 mt-3" style="font-size: 25px">
            </div>
            <div class="ml-3 mt-4">
              Warenkorb laden
            </div>
          </div>-->
          <div v-if="showResetOrderSuggestionButton"
               @click="resetOrderSuggestion" class="d-flex cursor-pointer op-hover mb-8">
            <div class="icon-neu-laden ml-8 mt-3" style="font-size: 25px">
            </div>
            <div class="ml-3 mt-4">
              Bestellvorschlag neu laden
            </div>
          </div>
        </div>
        <div class="justify-end flex-grow-1">
          <div class="float-right d-flex" style="gap: 10px">
            <DirectOrderSwitch v-if="showDirectOrderSwitchButton"/>
            <template v-if="showAddArticleToCartButton">
              <ArticlePickerDialog
                  v-if="['draft', 'edited'].includes(basket.status) || basket.directOrder"
                  type="basket"
                  current-view="basket"
                  :owner="unit"
              />
            </template>
          </div>
        </div>
        <div class="print-buttons" v-bind:class="{'fixedpos': !orderViewModeIsBasket}">
          <template v-if="showOrderSuggestionPrintButton && selectedDate">
            <PrintPdf
                :filename="'bestellvorschlag_'+unit.internalID+'_'+selectedDate.date+'.pdf'"
                :uri="`order-suggestion/${currentOrderSuggestionId}`"
            >
              <template v-slot:default="{ eventHandlers, loading }">
                <div v-on="eventHandlers" class="hover-link text-center" style="width: 100px;">
                  <v-progress-circular color="primary" size="35" class="mb-2 text-center"
                                       indeterminate v-if="loading"></v-progress-circular>
                  <div class="icon-drucken text-center mb-2" style="font-size: 35px;" v-if="!loading"></div>
                  <div style="font-size: 14px">Bestellvorschlag</div>
                </div>
              </template>
            </PrintPdf>
          </template>
          <template v-if="showClearingList">
            <PrintPdf
                :filename="'einrauemliste_'+unit.internalID+'_'+selectedDate.date+'.pdf'"
                :uri="`clearing-list/${basket.id}`"
            >
              <template v-slot:default="{ eventHandlers, loading }">
                <div v-on="eventHandlers" class="hover-link text-center" style="width: 80px;">
                  <v-progress-circular color="primary" size="35" class="mb-2 text-center"
                                       indeterminate v-if="loading"></v-progress-circular>
                  <div class="icon-drucken text-center mb-2" style="font-size: 35px;" v-if="!loading"></div>
                  <div style="font-size: 14px">Einräumliste</div>
                </div>
              </template>
            </PrintPdf>
          </template>
        </div>
      </div>
    </template>

    <!-- History View -->
    <template v-else-if="detailViewIsHistory">
      <h2 class="primary--text mt-6 mb-2">Bestellhistorie</h2>
    </template>

    <!-- Care Plan View -->
    <template v-else-if="detailViewIsCarePlan">
      <h2 class="primary--text mt-6 mb-2">Versorgungsplan</h2>
    </template>
  </div>

</template>
<script>

import OrderHistoryPills from '../Order/OrderHistoryPills.vue'
import OrderResidentBasketSelector from '../Order/OrderResidentBasketSelector.vue'
import DirectOrderSwitch from '../Order/DirectOrderSwitch.vue'
import ArticlePickerDialog from '../Article/ArticlePickerDialog.vue'
import IntervalTextLabel from '../UnitLevel/IntervalTextLabel.vue'
import {mapGetters} from 'vuex'
import PrintPdf from '@/components/SmartLogistics/Print/PrintPdf.vue'

export default {
  name: 'TitleSection',
  components: {
    PrintPdf,
    OrderHistoryPills,
    OrderResidentBasketSelector,
    DirectOrderSwitch,
    ArticlePickerDialog,
    IntervalTextLabel
  },
  props: {
    // View state props
    searchIsActive: {
      type: Boolean,
      default: false
    },
    sectionIsOrder: {
      type: Boolean,
      default: false
    },
    detailViewIsOrderSuggestion: {
      type: Boolean,
      default: false
    },
    isDirectOrder: {
      type: Boolean,
      default: false
    },
    detailViewIsBasket: {
      type: Boolean,
      default: false
    },
    detailViewIsDirectOrderBasket: {
      type: Boolean,
      default: false
    },
    detailViewIsHistory: {
      type: Boolean,
      default: false
    },
    detailViewIsCarePlan: {
      type: Boolean,
      default: false
    },
    orderViewModeIsResident: {
      type: Boolean,
      default: false
    },
    orderViewModeIsBasket: {
      type: Boolean,
      default: false
    },
    // Data props
    unit: {
      type: Object,
      required: true,
      validator: function (value) {
        return value.hasOwnProperty('internalID')
      }
    }
  },

  computed: {
    ...mapGetters({
      basket: 'smartLogisticBasket/basket',
      currentOrderSuggestionId: 'smartLogisticOrderSuggestion/currentSuggestionId',
      isOrderSuggestionEditable: 'smartLogisticOrderSuggestion/isOrderSuggestionEditable',
      isBasketEdtiable: 'smartLogisticOrderSuggestion/isBasketEditable',
      suggestionsLoading: 'smartLogisticOrderSuggestion/isLoading',
      directOrderLoading: 'smartLogisticDirectOrder/isLoading',
      basketLoading: 'smartLogisticBasket/isLoading',
      selectedDate: 'smartLogisticOrderSuggestion/chosenDateObject',
    }),

    orderSuggestion() {
      return this.$store.getters['smartLogisticOrderSuggestion/orderSuggestionLivingUnit']({unit: this.unit.internalID});
    },

    isBasketOrSuggestionLoading() {
      return this.suggestionsLoading || this.directOrderLoading || this.basketLoading
    },

    basketHasChanged() {
      let basket = this.$store.getters['smartLogisticBasket/basket'];
      return basket ? basket.living_unit_id == this.unit.internalID && basket.edited : false;
    },

    orderSuggestionHasChanged() {
      let orderSuggestionStatus = this.$store.getters['smartLogisticOrderSuggestion/currentStatus'];
      return orderSuggestionStatus == 'edited';
    },

    isCurrentBasket() {
      if (!this.basket)
        return false

      return this.$moment.unix(this.basket.due_at).isAfter(this.$moment().endOf('day'));
    },

    showOrderSuggestionPrintButton() {
      //only show on resident view mode on order suggestion
      if(!this.orderViewModeIsResident || !this.detailViewIsOrderSuggestion) {
        return false;
      }

      //don't show if loading
      if (this.suggestionsLoading) {
        return false;
      }
      //show for draft or edited order suggestions
      if (this.isOrderSuggestionEditable) {
        return true;
      }
    },

    showClearingList() {
      //don't show when no basket is set
      if (!this.basket) {
        return false;
      }
      //don't show if loading
      if (this.isBasketOrSuggestionLoading) {
        return false;
      }

      //show for confirmed or completed orders
      if (['confirmed', 'processing', 'completed'].includes(this.basket.status)) {
        return true;
      }
      return false;
    },
    showDirectOrderSwitchButton() {
      if (this.suggestionsLoading) {
        return false;
      }
      if(this.orderViewModeIsResident && this.isBasketEdtiable) {
        return true;
      }
      return false;
    },
    showAddArticleToCartButton() {
      //don't show if loading
      if (this.isBasketOrSuggestionLoading) {
        return false;
      }

      if (this.orderViewModeIsBasket && this.basket && this.isBasketEdtiable) {
        return true;
      }
      return false;
    },

    showResetBasketButton() {
      if (this.isBasketOrSuggestionLoading) {
        return false;
      }
      if (this.basketHasChanged && this.detailViewIsBasket && this.isBasketEdtiable) {
        return true;
      }
      return false;
    },
    showResetOrderSuggestionButton() {
      if (this.isBasketOrSuggestionLoading) {
        return false;
      }
      if (this.orderSuggestionHasChanged && this.detailViewIsOrderSuggestion && this.isOrderSuggestionEditable) {
        return true;
      }
      return false;
    }
  },

  methods: {
    resetBasket() {
      this.$store.dispatch('smartLogisticBasket/reset')
    },
    resetOrderSuggestion() {
      this.$store.dispatch('smartLogisticOrderSuggestion/reset')
    },
    moment(date) {
      return moment(date)
    }
  }
}

</script>

<style lang="scss" scoped>
h2.primary--text {
  font-size: clamp(1.5rem, 3vw, 2.1rem) !important;
}

.print-buttons.fixedpos {
  position: absolute;
  right: 0;
  bottom: -52px;
}
</style>
