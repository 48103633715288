import httpClient from '../../utils/httpClient'
import moment from 'moment'
import {smartLogisticBasket} from "@/store/modules/smartLogisticBasket";

import router from "@/router";

export const smartLogisticSettings = {
    namespaced: true,

    state: {
        model: null,
        currentSection: 'care-plan',
        currentResident: null,
        currentLivingUnit: null,
        currentTimelineSection: 'planning',
        panels: [],
        selectedDate: null,
        searchTerm: null,
        loading: false,
        directOrderAllowed: false,
        directOrder: false,
        livingUnitSettings: [],
        baseLivingUnitSettings: [],
        orderLockOffeset: 0,
        editMode: 'add',

        order: {
            viewMode: 'resident'  //resident or basket
        },

        config: {
            b: {
                sections: ['care-plan', 'order', 'order-history'],
                timelineSections: ['planning', 'order']
            },
            c: {
                sections: ['care-plan', 'order-suggestion'],
                timelineSections: ['planning', 'order-suggestion']
            }
        },

        blueprint: {
            model: [
                {
                    label: 'Basic (ohne bewohnerbezogene Bestellung)',
                    value: 'a'
                },
                {
                    label: 'Plus - Externe Logistik',
                    value: 'b'
                },
                {
                    label: 'Plus - Interne Logistik',
                    value: 'c'
                }
            ],
            interval: [
                {
                    label: 'Jede Woche',
                    value: 1
                },
                {
                    label: 'Alle 2 Wochen',
                    value: 2
                },
                {
                    label: 'Alle 3 Wochen',
                    value: 3
                },
                {
                    label: 'Alle 4 Wochen',
                    value: 4
                }
            ]
        }
    },


    getters: {
        blueprint: state => state.blueprint,
        planModel: state => state.model,
        currentSection: state => state.currentSection,
        currentResident: state => state.currentResident,
        currentLivingUnit: state => state.currentLivingUnit,
        currentTimelineSection: state => state.currentTimelineSection,
        currentOrderViewMode: state => state.order.viewMode,
        isLoading: state => state.loading,
        isDirectOrder: state => state.directOrder,
        editMode: state => state.editMode,
        planConfig: state => {
            const model = state.model !== 'a' ? state.model : 'b'
            return state.config[model]
        },
        activePanels: state => {
            return state.panels
        },
        selectedDate: state => state.selectedDate,
        searchTerm: state => state.searchTerm,
        searchIsActive: state => !(state.searchTerm === null || state.searchTerm === ''),
        orderLockOffset: state => state.orderLockOffset,

        livingUnitSettingsById: (state) => (id) => {
            return state.livingUnitSettings.find(l => l.living_unit_id === id)
        },

        baseLivingUnitSettingsById: (state) => (id) => {
            return state.baseLivingUnitSettings.find(l => l.id === id)
        },

        baseLivingUnitNotificationEmailsById: (state) => (id) => {
            let mails = state.baseLivingUnitSettings.find(l => l.id === id).notificationmails
            if (mails && mails !== "") {
                return mails
            }
            return null
        },

        baseLivingUnitPickUpPointById: (state) => (id) => {
            let pickUpPoint = state.baseLivingUnitSettings.find(l => l.id === id).Avisierung

            if (pickUpPoint && pickUpPoint !== "") {
                return pickUpPoint
            }
            return null
        },

        baseLivingUnitDeliveryInformationById: (state) => (id) => {
            let deliveryInformation = state.baseLivingUnitSettings.find(l => l.id === id).Adresszeile2

            if (deliveryInformation && deliveryInformation !== "") {
                return deliveryInformation
            }
            return null
        }
    },

    actions: {
        async fetch({commit, state}, force = false) {
            return new Promise(async (resolve, reject) => {
                if (force || !state.livingUnitSettings.length) {
                    httpClient.get(process.env.VUE_APP_API_LOGISTICS + 'living-unit-settings')
                        .then(response => {
                            commit('setLivingUnitSettings', response)

                            resolve(response)
                        })
                        .catch(error => {
                            reject(error)
                        })
                } else {
                    resolve()
                }
            })
        },

        async update({commit, state, dispatch}, payload) {

            let livingUnitSettings = state.livingUnitSettings.find(livingUnit => livingUnit.living_unit_id === payload.livingUnit.living_unit_id)
            livingUnitSettings.clearOpenBasketsAndSuggestions = payload.clearOpenBasketsAndSuggestions ?? null


            await httpClient({
                url: process.env.VUE_APP_API_LOGISTICS + 'living-unit-settings/' + payload.livingUnit.living_unit_id,
                method: 'put',
                data: livingUnitSettings
            })

            if (livingUnitSettings.clearOpenBasketsAndSuggestions) {
                dispatch('smartLogisticOrderSuggestion/fetchStates', null, {root: true});
            }
        },

        async settings({commit, state}, payload) {
            await httpClient({
                url: process.env.VUE_APP_API_SHARED + 'settings/' + payload.call,
                method: 'post',
                data: payload
            })
        },
    },

    mutations: {
        setLivingUnitSettings(state, data) {
            state.model = data.data.clientSettings.model
            state.directOrderAllowed = data.data.clientSettings.directOrderAllowed
            state.livingUnitSettings = data.data.livingUnitSettings
            state.orderLockOffset = data.data.orderLockOffset
            state.baseLivingUnitSettings = data.data.baseLivingUnitSettings

            if (router.history.current.fullPath == "/versorgungsplan" && state.model != "a") {
                router.push("/versorgung-bewohner")
            } else if (["/versorgung-bewohner", "/verbrauchsliste"].includes(router.history.current.fullPath) && state.model == "a") {
                router.push("/versorgungsplan")
            }
        },

        setDirectOrder(state, value) {
            state.directOrder = value
        },

        setEditMode(state,value) {
            state.editMode = value
        },

        setViewMode(state, mode) {
            state.order.viewMode = mode;

            //switch back from direct order to order suggestion after direct order was confirmed
            if (state.order.viewMode === "resident" && state.directOrder && smartLogisticBasket.state.basket && ["confirmed", "processing"].includes(smartLogisticBasket.state.basket.status)) {
                state.directOrder = false;
                smartLogisticBasket.state.basket = null;
            }
        },

        setPanels(state, panels) {
            state.panels = panels
        },

        setSelectedDate(state, date) {
            state.selectedDate = date
        },

        setSearchTerm(state, value) {
            state.searchTerm = value
        },

        setCurrentSection(state, section) {
            state.currentSection = section
            //switch back from direct order to order suggestion after direct order was confirmed
            if (state.currentSection === "order" && state.directOrder && smartLogisticBasket.state.basket && ["confirmed", "processing"].includes(smartLogisticBasket.state.basket.status)) {
                state.directOrder = false;
                state.order.viewMode = "resident";
                smartLogisticBasket.state.basket = null;
            }
        },

        setCurrentResident(state, resident) {
            state.currentResident = resident;
        },

        setCurrentLivingUnit(state, livingUnit) {
            state.currentLivingUnit = livingUnit
        },

        setCurrentTimelineSection(state, section) {
            state.currentTimelineSection = section
        },
    },
    persist: false
}
