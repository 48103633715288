<template>
  <div class="d-flex align-center">
    @todo use CountDown Component
<!--    <div>-->
<!--      <div>{{ orderDateString }}</div>-->
<!--      <div class="text&#45;&#45;darkestgrey mt-1">-->
<!--        {{ formatTimeLeft }}-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="ml-16 mt-1">-->
<!--      <span style="font-size: 25px" :class="activePanel ? 'icon-haeckchen-kreis text&#45;&#45;darkestgrey' : 'icon-haeckchen-kreis-ausgefuellt text&#45;&#45;beige'">-->

<!--      </span>-->
<!--    </div>-->
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'OrderSuggestionDateInfo',

  props: {
    orderDate: {
      type: String,
      required: true
    },
    activePanel: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      now: moment()
    }
  },

  computed: {
    timeLeft () {
      const orderDate = moment(this.orderDate)
      const diff = orderDate.diff(this.now)
      const duration = moment.duration(diff)

      return {
        days: duration.days(),
        hours: duration.hours(),
        minutes: duration.minutes(),
        seconds: duration.seconds()
      }
    },

    formatTimeLeft () {
      const { days, hours, minutes, seconds } = this.timeLeft

      if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
        return 'Abgelaufen'
      }

      let dayLabel = days === 1 ? 'Tag' : 'Tage'
      let hourLabel = 'h'
      let minuteLabel = 'm'
      let secondLabel = 's'

      return 'Noch ' + days + ' ' + dayLabel + ' ' + hours + ' ' + hourLabel + ' ' + minutes + ' ' + minuteLabel + ' ' + seconds + ' ' + secondLabel
    },

    orderDateString () {
      return moment(this.orderDate).format('dddd DD.MM.YYYY')
    }
  },

  created () {
    this.startTimer()
  },

  beforeDestroy () {
    this.stopTimer()
  },

  methods: {
    startTimer () {
      this.timer = setInterval(() => {
        this.now = moment()
      }, 1000)
    },

    stopTimer () {
      if (this.timer) {
        clearInterval(this.timer)
      }
    }
  }
}
</script>
