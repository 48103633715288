<template>
  <div id="login">
    <v-container>
      <v-row>
        <v-col>
          <img
              :src="require('@/assets/img/logo-carepro.svg')"
              alt=""
              height="100"
              class="mt-16 my-0 py-0 login-logo"
          />
          <h1 class="">Willkommen bei<br> Puras Care Pro</h1>
        </v-col>
      </v-row>
      <v-row v-if="maintenanceInfo && maintenanceInfo.verbrauchsmanager_show_text" style="margin-top: -50px">
        <v-col cols="12" sm="9" md="6" lg="4">
          <div class="maintenance-info pt-4 pb-2 px-6">
            <div class="font-weight-bold">
              {{ maintenanceInfo.verbrauchsmanager_title }}
            </div>
            <div v-html="maintenanceInfo.verbrauchsmanager_text">

            </div>
          </div>
        </v-col>
      </v-row>
      <template v-if="!maintenanceInfo || !maintenanceInfo.verbrauchsmanager_maintenance_mode">
        <v-row>
          <v-col cols="12" sm="9" md="6" lg="4">

            <v-text-field
                @keyup.enter="login"
                append-icon="mdi-account"
                autocomplete="off"
                clearable label="E-Mail"
                @keyup="error = false"
                hide-details="auto"
                v-model="input.email"
                class="mr-9"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="9" md="6" lg="4">
            <div class="d-flex">
              <v-text-field
                  @keyup.enter="login"
                  :type="showPassword ? 'text' : 'password'"
                  append-icon="mdi-key"
                  autocomplete="off"
                  clearable @keyup="error = false"
                  label="Passwort"
                  hide-details="auto"
                  v-model="input.password"
              ></v-text-field>
              <v-btn icon @click="showPassword = !showPassword">
                <v-icon v-if="!showPassword">mdi-eye</v-icon>
                <v-icon v-else>mdi-eye-off</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="5">
            <v-alert outlined dense transition="appear" v-model="error">
              <v-row dense>
                <v-col cols="1">
                  <v-icon size="30" color="error">mdi-alert</v-icon>
                </v-col>
                <v-col cols="10" class="body-1" style="line-height: 30px">
                  <div class="pl-4 block">
                    Falsche Zugangsdaten
                  </div>
                </v-col>
              </v-row>
            </v-alert>
            <v-row>
              <v-col cols="12" sm="3">
                <v-btn depressed @click="login" color="primary">Anmelden</v-btn>
              </v-col>
              <v-col cols="12" sm="6">
                <v-hover v-slot="{ hover }" open-delay="0">
                  <div @click="showForgotPWPopup = true" class="inline-block caption pt-2 cursor-pointer"
                       :class="hover ? 'text-decoration-underline' : ''">
                    Passwort vergessen?
                  </div>
                </v-hover>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>


    </v-container>

    <!--
    ******************************************************************************************************************************
    ForgotPW Popup
    ******************************************************************************************************************************
    -->
    <v-dialog v-model="showForgotPWPopup" persistent max-width="500">
      <v-card>
        <v-card-title class="headline">
          <v-row>
            <v-col cols="11">
              Passwort vergessen? Kein Problem!
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="body-2 multiprintpopup">
          Bitte geben Sie Ihre Mailadresse ein, Sie erhalten umgehend per Mail einen Bestätigungslink. Sobald Sie Ihre
          Mailadresse bestätigt haben, wird Ihnen erneut eine Mail-Nachricht mit einem neuen Passwort zugestellt. Aus
          Sicherheitsgründen bitten wir Sie das Passwort schnellst möglich zu ändern.
          <v-divider class="my-8"></v-divider>

          <v-row>
            <v-col sm class="py-0 my-0" cols="10">
              <v-text-field ref="forgotinput" type="email" append-icon="mdi-account" autocomplete="off" clearable
                            label="E-Mail" hide-details="auto" :rules="emailRules"
                            v-model="forgotmail"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="danger" depressed @click="showForgotPWPopup = false;">Abbrechen</v-btn>
          <v-btn v-if="$refs.forgotinput" color="primary" :disabled="$refs.forgotinput.hasError" depressed
                 @click="sendResetLink()">
            Passwort-Link zustellen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--
   ******************************************************************************************************************************
   DataProtection Popup
   ******************************************************************************************************************************
   -->
    <v-dialog v-model="showWarningPopUp" persistent max-width="500">
      <v-card>
        <v-card-title class="headline">
          <v-row>
            <v-col cols="11">
              {{ warningTitle }}
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="body-2 multiprintpopup">
          <v-divider class="mt-2 mb-8"></v-divider>

          <v-row>
            <v-col sm class="py-0 my-0" cols="10">
              {{ warningText }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="danger" depressed @click="showWarningPopUp = false;"> {{ warningDeclineButton }}</v-btn>
          <v-btn color="primary" depressed @click="acceptDataProtection()">
            {{ warningAcceptButton }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>
import { isLoggedIn, loginUser } from '@/utils/auth'
import httpClient from '@/utils/httpClient'
import {acceptDataProtection} from '../utils/auth'
import axios from 'axios'

export default {
  name: 'Login',
  data() {
    return {
      error: false,
      forgotmail: '',
      emailRules: [
        v => !!v || 'Bitte geben Sie eine gültige Mailadresse ein',
        v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Bitte geben Sie eine gültige Mailadresse ein',
      ],
      showForgotPWPopup: false,
      showWarningPopUp: false,
      warningTitle: '',
      warningText: '',
      warningAcceptButton: '',
      maintenanceInfo: false,
      warningDeclineButton: '',
      showPassword: false,
      input: {
        email: '',
        password: ''
      }
    }
  },
  methods: {

    async acceptDataProtection() {
      let response = await acceptDataProtection(this.input.email)

      if (response.type === 'success') {
        await this.login()
      }
    },

    async login() {

      let response = await loginUser(this.input.email, this.input.password)

      if (response.type === 'success') {
        this.$store.dispatch('smartLogisticSettings/fetch')
            .then(() => {
              if (this.$store.getters['smartLogisticSettings/planModel'] === 'a') {
                this.$router.push({name: 'Versorgungsplan'})
              } else {
                this.$router.push({name: 'smartlogistics'})
              }
            })
            .catch(() => {
              this.$router.push({name: 'Versorgungsplan'})
            })
        return true
      }

      if (response.type === 'warning') {
        this.warningTitle = response.warning_title
        this.warningText = response.warning_text
        this.warningAcceptButton = response.warning_accept_button
        this.warningDeclineButton = response.warning_decline_button
        this.showWarningPopUp = true
        return false
      }

      this.error = true
      return false
    },

    async sendResetLink() {
      const res = await httpClient({
        url: process.env.VUE_APP_API_BASEURL + 'send/resetlink',
        method: 'post',
        data: {'mail': this.forgotmail}
      })

      this.showForgotPWPopup = false
    },

    async getMaintenanceStatus() {
      const res = await axios.get(process.env.VUE_APP_PURAS_CONTENT_API + 'globals/maintenance')

      if (res) {
        this.maintenanceInfo = res.data.data
      }
    }
  },

  mounted() {
    if(isLoggedIn()) {
      this.$router.push({ name: 'Versorgungsplan' })
    }

    this.getMaintenanceStatus()
  }
}
</script>
<style lang="scss" scoped>
@import '@/scss/smartlogistics/mixins/_breakpoint.scss';

#login {
  height: 100%;
  background-image: url("../assets/img/loginbg2024.jpg");
  background-size: cover;
  padding-inline: 20px;
  background-position: -400px;

  @include breakpoint(md) {
    padding-inline: 48px;
    background-position: -200px;
  }

  @include breakpoint(lg) {
    background-position: initial;
  }
}

h1 {
  margin-bottom: 60px;
  margin-top: 50px;
  color: #000;
  line-height: 60px;
  font-weight: 300;
  font-size: clamp(2rem, 5vw, 2.8rem) !important;
}

.v-text-field {
  padding-top: 0 !important;
  margin-top: 0;
}

.maintenance-info {
  border-radius: 10px;
  border: 1px #D13840 solid;
}

.login-logo {
  width:auto;
}

</style>
