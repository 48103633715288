
<template>
  <v-row v-if="state">
    <v-col sm="12" md="6" lg="4" v-for="hit in getHits()" :key="hit.objectID">
      <slot name="item" :item="hit">
        {{ hit.objectID}}
      </slot>
    </v-col>
    <v-col class="sentinel" v-observe-visibility="visibilityChanged" />
  </v-row>
</template>

<script>
import { createWidgetMixin } from 'vue-instantsearch';
import { connectInfiniteHits } from 'instantsearch.js/es/connectors';

export default {
  name: 'ArticlePickerInfiniteHits',
  props: {
    filtered: {
      type: Array,
      required: false,
      default: [],
    },
  },
  mixins: [createWidgetMixin({ connector: connectInfiniteHits })],
  methods: {
    visibilityChanged(isVisible) {
      if (isVisible && !this.state.isLastPage) {
        this.state.showMore();
      }
    },

    getHits() {
      if(this.filtered.length > 0) {
        return this.state.hits.filter(hit => !this.filtered.includes(hit.objectID));
      }
      return this.state.hits;
    }
  }
};
</script>

<style scoped>
.sentinel {
  list-style-type: none;
}
</style>
