import httpClient from '@/utils/httpClient'
import Promise from 'lodash/_Promise'

export const bilder = {
  namespaced: true,
  state: {
    produktbilder: []
  },

  getters: {
    produktbilder: state => state.produktbilder
  },

  actions: {
    fetchImages({ commit, state }, force) {
      if (state.produktbilder.length === 0 || force) {
        return httpClient.get(process.env.VUE_APP_API_SHARED + 'get/product-images')
          .then(response => {
            commit('setProduktBilder', response.produktbilder)
          })
      } else {
        return Promise.resolve(undefined)
      }
    }
  },
  mutations: {
    setProduktBilder(state,val){
      state.produktbilder = val;
    }
  }
};
