<template>
  <v-dialog
      v-model="dialog"
      :width="width"
      class="rounded-lg"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="button" :on="on" :attrs="attrs"/>
    </template>

    <div class="card pt-0">
      <div class="card-header">
        <slot name="header"/>
      </div>

      <div class="card-content">
        <slot/>
      </div>
      <div class="card-actions">
        <v-btn @click="confirm" color="primary">
          <slot name="confirmText">Ja</slot>
        </v-btn>
        <v-btn @click="cancel">
          <slot name="cancelText">Nein</slot>
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>

export default {
  name: 'ConfirmDialog',

  props: {
    width: {
      type: Number,
      default: 800,
    },
  },

  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null
    }
  },
  methods: {
    open() {
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    confirm() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    }

  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-dialog {
    border-radius: 20px;
    overflow: hidden;
  }
}

.card {
  padding: 40px 0px 23px 0px;
  background-color: #ffffff;
}

.card-content {
  padding: 0 50px;
  max-height: 60vh;
  overflow-y: scroll;

  &.-no-scroll {
    overflow-y: hidden;
  }
}

.card-header {
  position: sticky;
  top: 0;
  z-index: 10;
  background: white;
  padding: 40px 50px 18px;

  h1 {
    line-height: 1.1em;
    color: #CE20A8;
  }

  .close {
    position: absolute;
    right: 50px;
    top: 40px;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
}

.card-footer {
  border-top: 1px solid #000000;
  padding: 0 50px;
}

.card-actions {
  margin-top: 30px;
  padding: 0 50px 10px;
  text-align: left;
}

</style>
