<template>
  <div style="position:relative">
    <ais-instant-search key="instantsearch" class="flex flex-wrap"
                        style="display: flex !important;grid-gap: inherit !important;" index-name="artikel"
                        :search-client="searchClient">
      <ais-configure :filters="activeFilter" :hits-per-page.camel="12"></ais-configure>
      <v-card flat class="px-0 pb-0" id="prodsearch-dialog">
        <v-card-text class="body-1 px-0 pt-0" v-if="$store.state['articles'].categoriesSortArray.length > 0">
          <div class="sticky-header">
            <v-row>
              <v-col cols="5">
                <ais-search-box>
                  <template v-slot="{ currentRefinement, isSearchStalled, refine }">
                    <v-text-field
                        ref="srch"
                        clearable
                        placeholder="Suche nach Artikelname, Artikelnummer"
                        v-model="searchTerm"
                        @focus="refine($event.currentTarget.value)"
                        @keyup="refine($event.currentTarget.value);"
                        append-icon="mdi-magnify"
                        class="search-field mb-0 mt-0 pt-0"
                    >
                    </v-text-field>
                  </template>
                </ais-search-box>
              </v-col>
              <v-col cols="7">
                <div style="font-size: 13px; margin-top: 17px">
                  <ais-stats>
                    <template v-slot="{ nbHits }">
                      Mein Sortiment <span class="font-weight-bold">{{ nbHits }} Produke</span>
                    </template>
                  </ais-stats>
                </div>
              </v-col>
            </v-row>
            <hr class="line" />
          </div>
          <v-row>
            <v-col cols="4">
              <div class="pr-8" style="position: sticky; top: 115px;">
                <!-- filter row 1 -->
                <div class="hide-v-massages">
                  <v-checkbox inset v-if="migel" v-model="migel.value" label="nur MiGeL" dense :false-value="false"
                            medium light flat :true-value="1" class="mb-2"></v-checkbox>
                </div>
                <v-divider class="mt-4"></v-divider>
                <ais-refinement-list attribute="klassifizierung" :sort-by="refinementSort" :limit="20"
                                     key="refinelist">
                  <div slot-scope="{items,isShowingMore, isFromSearch, refine, createURL, searchForItems, sendEvent}">

                    <div v-if="items.length > 0">
                      <!-- filter row 1 -->
                      <div v-if="items.find(item => item.value === 'Eigene Produkte') && currentView !== 'order-suggesion' && currentView !== 'basket'">
                        <div class="py-4 hide-v-massages">
                          <div v-for="item in items" :key="`filter-${item.label}`">
                            <div v-if=" item.value === 'Eigene Produkte'">
                              <v-checkbox
                                  dense
                                  light
                                  @click.prevent="refine(item.value)"
                                  class="full-color"
                              >
                                <template v-slot:label>
                                  <span class="body-1">
                                    <span style="color:black;">{{ item.value }}</span> <span class="count">{{ item.count.toLocaleString() }}</span>
                                  </span>
                                </template>
                              </v-checkbox>
                            </div>
                          </div>
                        </div>
                        <v-divider></v-divider>
                      </div>
                      <!-- filter row 2 -->
                      <div class="pt-4 hide-v-massages">
                        <div v-for="item in items" :key="`filter-${item.label}`">
                          <div v-if="item.value !== 'Eigene Produkte'">
                            <v-checkbox dense light @click.prevent="refine(item.value)" class="full-color">
                              <template v-slot:label>
                                    <span class="body-1">
                                      <span style="color:black;">{{ item.value }}</span> <span class="count">{{
                                        item.count.toLocaleString()
                                      }}</span>

                                    </span>
                              </template>
                            </v-checkbox>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="items.length === 0">
                      <p class="my-4">
                        Keine Filter verfügbar
                      </p>
                    </div>
                  </div>
                </ais-refinement-list>
              </div>
            </v-col>
            <v-col cols="8">
              <ArticlePickerInfiniteHits key="ais-auto" class="pb-16 mt-2" :filtered="[]">
                <template slot="item" slot-scope="{ item }">
                  <ArticlePickerTeaser
                      :item="item"
                      :recentButDeletedArticles="recentButDeletedProducts"
                      :alreadyAssignedArtnos="alreadyAssignedArtnos"
                      :type="type"
                      :ref="'card-'+item.objectID"
                      :strictAssigned="isHidden(item)"
                      @assign="assignArticle"
                      @detach="detachArticle"
                  />
                </template>
              </ArticlePickerInfiniteHits>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </ais-instant-search>
  </div>
</template>
<script>
import algoliasearch from 'algoliasearch/lite'
import 'instantsearch.css/themes/satellite-min.css'
import { AisInstantSearch, AisSearchBox } from 'vue-instantsearch'
import AppInfiniteHits from '@/components/search/InfiniteHits'
import AppRefresh from '@/components/search/Refresh'
import httpClient from '@/utils/httpClient'
import ArticlePickerInfiniteHits from '@/components/SmartLogistics/Article/ArticlePicker/ArticlePickerInfiniteHits.vue'
import ArticlePickerTeaser from '@/components/SmartLogistics/Article/ArticlePicker/ArticlePickerTeaser.vue'

export default {
  components: {
    ArticlePickerTeaser,
    ArticlePickerInfiniteHits,
    AppInfiniteHits,
    AisInstantSearch,
    AisSearchBox,
    AppRefresh,
  },
  name: 'ArticlePicker',
  props: {
    alreadyAssignedArticles: {
      type: Array,
      default: []
    },
    filteredArticles: {
      type: Array,
      default: []
    },
    currentView: {
      type: String,
      default: 'care-plan',
      required: false
    },
    type: {
      type: String,
      default: 'resident',
      validator: function (value) {
        return ['resident', 'living-unit', 'basket'].indexOf(value) !== -1
      }
    },
  },
  emits: ['assignArticle', 'detachArticle'],

  data () {
    return {

      searchTerm: '',
      recentButDeletedProducts: [],

      searchClient: algoliasearch(
          process.env.VUE_APP_ALGOLIA_ID,
          process.env.VUE_APP_ALGOLIA_KEY,
      ),

      adressNrADR: this.$store.state.benutzer.info.AdressNrADR,

      filterTypes: [
        {
          'name': 'MiGel',
          'value': false,
          'filterType': 'filter',
          'operator': '=',
          'key': 'isArticleMiGeL'
        },
        {
          'name': 'mySortiment',
          'value': this.$store.state.benutzer.info.AdressNrADR,
          'filterType': 'mysortiment',
        }
      ],
    }
  },

  computed: {
    /**
     *
     */
    bilder() {
        return this.$store.state['bilder'].produktbilder
    },

    /**
     *
     */
    migel () {
      let migel = this.filterTypes.filter(function (el, k) {
        if (el.name == 'MiGel') {
          return el
        }
      })
      return migel[0]
    },

    /**
     *
     */
    alreadyAssignedArtnos () {
      return this.alreadyAssignedArticles.map((el) => {
        if(el.internalID) {
          return el.internalID
        }
        else {
          return el.artikelNrLAG
        }
      })
    },

    /**
     *
     */
    mySortiment () {
      let mySortiment = this.filterTypes.filter(function (el, k) {
        if (el.name == 'mySortiment') {
          return el
        }
      })
      return mySortiment[0]
    },

    /**
     *
     */
    activeFilter () {
      if(this.currentView !== 'basket' && this.currentView !== 'order-suggestion'){
        var outputFilter = '(isClient:false OR isClient:' + this.adressNrADR + ') AND ((geloscht:0 OR NOT isClient:false)  '
      }else{
        var outputFilter = '(isClient:false) AND ((geloscht:0)  '
      }

      var hasRecentDeleted = false
      this.recentButDeletedProducts.forEach(function (artnr, k) {
        hasRecentDeleted = true
        if (k === 0) {
          outputFilter += ' OR (objectID:' + artnr
        } else {
          outputFilter += ' OR objectID:' + artnr
        }
      })

      if (hasRecentDeleted) {
        outputFilter += ')'
      }

      outputFilter += ')'

      var self = this
      this.filterTypes.map((el, k) => {

        if (el.value && outputFilter != '') {
          outputFilter += ' AND '
        }

        if (el.filterType != 'mysortiment') {
          if (el.value) {
            outputFilter += el.key + el.operator + el.value //+ ' AND objectID:1351046'
          }
        } else {
          if (el.value) {
            outputFilter += '('
            outputFilter += 'objectID:' + this.$store.state.articles.mySortiment.join(' OR objectID:')
            outputFilter += ')'
          }
        }
      })
      return outputFilter
    },
  },

  methods: {
    /**
     *
     * @param a
     * @param b
     * @returns {number}
     */
    refinementSort: function (a, b) {
      return this.$store.state['articles'].categoriesSortArray.indexOf(a.name) - this.$store.state['articles'].categoriesSortArray.indexOf(b.name)
    },

    /**
     *
     * @param data
     */
    assignArticle (data) {
      this.$emit('assignArticle', data)
    },

    /**
     *
     * @param data
     */
    detachArticle (data) {
      this.$emit('detachArticle', data)
    },

    isAlreadyAssigned (item) {
      return this.alreadyAssignedArtnos.includes(item.objectID)
    },

    isHidden (item) {
      return this.filteredArticles.includes(item.objectID)
    },
  },

  watch: {
    //Suche in PopUp Produkte
    searchTerm (val) {
      if (!val) {
        this.searchTerm = ''
        var self = this
        self.$refs.srch.blur()
        setTimeout(function () {
          self.$refs.srch.focus()
        }, 100)
      }
    }
  },

  async mounted () {
    const resRecent = await httpClient({
      url: process.env.VUE_APP_API_SHARED + 'get/recent-deleted-but-history-products',
      method: 'get'
    })

    if (resRecent.type == 'success') {
      this.recentButDeletedProducts = resRecent.products
    }
  }
}
</script>

<style lang="scss">
.search-field {
  .v-text-field__details {
    display: none;
  }
}



.sticky-header {
  position: sticky;
  background: white;
  z-index: 2;
  top: 0px;
  margin: 0 -12px;

  > .row {
    padding: 0 12px;
  }
  .line {
    margin:25px 12px 30px;
    border-top: 1px solid #E6E6E6;
  }

}
</style>
