<template>
  <v-app>
    <Loader :visible="loading"/>
    <v-app-bar
        v-if="this.$route.name != 'login' && this.$route.name != 'passwortreset'"
        class="app-bar"
        height="116"
        app
        color="white"
        style="z-index: 98"
    >
      <PageHeader/>
    </v-app-bar>

    <v-main class="app-main"
            :class="this.$route.name != 'login' && this.$route.name != 'passwortreset' ? 'pb-16' : 'pb-0'"
            :style="wrongSettings ? 'display:none !important' : '' "
    >
      <v-layout>
        <Navigation v-if="this.$route.name != 'login' && this.$route.name != 'passwortreset'"
                    :accountant="this.$store.state.benutzer.info.accountant"
                    :superadmin="this.$store.state.benutzer.info.superadmin"
                    :tooladmin="this.$store.state.benutzer.info.tooladmin"></Navigation>
      </v-layout>
      <router-view :class="{ 'layout-container': $router.currentRoute.name != 'login' }"/>
    </v-main>
    <div id="settingserror" class="d-print-none app-error px-8" style="padding-top: 100px"
         :style="wrongSettings ? 'display:block !important' : '' ">
      <v-container>
        <v-row>
          <v-col>
            <v-img width="160" :src="require('@/assets/img/logo-carepro.svg')" alt=""
                   class="mt-16 my-0 py-0"></v-img>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2 class="mb-2">
              Warnung
            </h2>
            <div style="font-size: 20px">
              Um mit Puras Care Pro zu arbeiten, verwenden Sie bitte einen grösseren Bildschirm<span
                v-if="wrongSettings"> und einen der folgenden Browser: Chrome, Firefox, Safari, Edge, Brave</span>.<br><br>
              <span class="caption" style="font-size: 14px !important">Puras AG – Normannenstrasse 8 – 3018 Bern –
              Telefon 031 996 85 85 –
                <a href="mailto:contact@puras.ch">contact@puras.ch</a></span>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-snackbar v-model="showmsg" timeout="3500" color="secondary" class="black--text" v-if="msg != 'Unauthenticated.'">
      <v-row dense>
        <v-col cols="1">
          <v-icon size="30" v-if="msgtype == 'error'" color="error" class="pt-2">mdi-alert</v-icon>
          <v-icon size="30" v-if="msgtype == 'success'" color="green" class="pt-0">mdi-check-circle</v-icon>
        </v-col>
        <v-col cols="10" class="body-1">
          <div class="pl-4 pt-1 block black--text">
            <template v-if="Array.isArray(msg)">
              <div v-for="(line, i) in msg" :key="'error-' + i">
                {{ line }}
              </div>
            </template>
            <template v-else>
              {{ msg }}
            </template>
          </div>
        </v-col>
      </v-row>
    </v-snackbar>
  </v-app>
</template>
<script>

import Loader from "@/components/layout/Loader";
import Navigation from "@/components/layout/Navigation";
import {mapGetters, mapState} from "vuex";
import PageHeader from "@/components/layout/PageHeader.vue";

export default {
  name: 'App',
  components: {
    PageHeader,
    Loader, Navigation
  },


  computed: {
    ...mapState("loader", ["loading", 'msg', 'msgtype']),
    wrongSettings() {
      return this.$browserDetect.isIE || this.$browserDetect.isOpera;
    },

    showmsg: {
      get() {
        return this.$store.state['loader'].showmsg
      },
      set(newValue) {
        return this.$store.commit('loader/setmsgvisible', newValue)
      }
    },
  },
  methods: {
    isLoggedIn() {
      return 1;
      //return isLoggedIn()
    },

    onResize() {
      this.$store.commit('system/setIsMobile', window.innerWidth < 960)
    }
  },

  mounted() {
    this.onResize()

    window.addEventListener('resize', this.onResize, {passive: true})
  },

  beforeDestroy() {
    if (typeof window === 'undefined') return

    window.removeEventListener('resize', this.onResize, {passive: true})
  }
}
</script>
<style lang="scss">
@import './scss/custom';


h1 {
  margin-top: 30px;
}

.name-container {
  flex: 0 0 calc(100% - 63px);
  max-width: calc(100% - 63px);
}


#settingserror {
  height: 100%;
  background-image: url("./assets/img/loginbg2024.jpg");
  background-size: cover;
  background-position: left center;
}

#maincontent {
  height: 100%;
}

.v-snack__wrapper {
  box-shadow: none !important;
}

.icon-container {
  position: relative;
  top: -5px;
  flex: 0 0 63px;
  max-width: 63px;
}

</style>
