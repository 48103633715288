import { render, staticRenderFns } from "./OrderSurggestionState.vue?vue&type=template&id=6a538748&scoped=true"
import script from "./OrderSurggestionState.vue?vue&type=script&lang=js"
export * from "./OrderSurggestionState.vue?vue&type=script&lang=js"
import style0 from "./OrderSurggestionState.vue?vue&type=style&index=0&id=6a538748&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a538748",
  null
  
)

export default component.exports