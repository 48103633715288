<template>
  <span v-bind="$attrs">
    <slot
        :seconds="seconds"
        :minutes="minutes"
        :hours="hours"
        :days="days"
        :dayString="dayString"
        :hoursString="hoursString"
        :minutesString="minutesString"
        :andString="andString"
    >
      {{ days }} {{ dayString }} - {{ hours }} {{hoursString}} : {{ minutes }} {{minutesString}} : {{ seconds}}
    </slot>
  </span>
</template>

<script>
import moment from 'moment'
export default {
  name: 'CountDown',
  props: {
    date: {
      type: moment,
      required: true
    }
  },

  data() {
    return {
      now: null,
      interval: null,
      secondsOffset: 0
    }
  },

  computed: {
    attrs () {
      return attrs
    },
    seconds() {
      const seconds = Math.floor(this.secondsOffset% 60);
      return String(seconds).padStart(2, '0');
    },

    minutes() {
      const minutes = Math.floor((this.secondsOffset / 60) % 60);
      return String(minutes).padStart(2);
    },

    minutesString() {
      if(!this.minutes) {
        return '';
      }
      return (this.minutes == 1)? 'Minute': 'Minuten'
    },

    hours() {
      const hours = Math.floor((this.secondsOffset / 3600) % 24);
      return hours > 0 ? hours : '';
    },

    days() {
      let days =Math.floor(this.secondsOffset / 86400);
      return days > 0 ? days : '';
    },

    dayString() {
      if(!this.days) {
        return '';
      }
      return (this.days == 1)? 'Tag': 'Tage'
    },

    hoursString() {
      if(!this.hours) {
        return '';
      }
      return (this.hours == 1)? 'Stunde': 'Stunden'
    },

    andString() {
        return this.hours ? 'und' : ''
    }
  },

  methods: {
    clearInterval () {
      if (this.interval) {
        clearInterval(this.interval)
        this.interval = null
      }
    }
  },

  mounted () {
    this.now = this.$moment()
    this.secondsOffset = +this.date.diff(this.now, 'seconds')

    this.interval = setInterval(function () {
      this.secondsOffset--

      if(this.secondsOffset <= 0) {
        this.clearInterval()
      }
    }.bind(this), 1000)
  },

  beforeDestroy () {
    this.clearInterval()
  }
}
</script>
