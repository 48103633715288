<template>
  <div style="display: inline-block">
    <div>
      <span class="body-2 heavy" v-html="highlightedArticleId(articleNo)"></span>
      <span>
          <ArticleMessageIndicator  :article="article"></ArticleMessageIndicator>
      </span>
    </div>
    <div v-html="highlightedArticleName(article.name)">
    </div>
  </div>
</template>

<script>

import ArticleMessageIndicator from "@/components/SmartLogistics/Article/ArticleMessageIndicator.vue";
import {inlineSearchMixin} from "../../../mixins/SmartLogistics/inlineSearchMixin";

export default {
  name: 'ArticleCaption',
  components: {ArticleMessageIndicator},
  mixins: [inlineSearchMixin],
  props: {
    article: {
      type: Object,
      required: true,
    },
  },

  computed:{
    articleNo(){
      if(this.article.internalID){
        return this.article.internalID
      }

      if(this.article.artikelNrLAG){
        return this.article.artikelNrLAG
      }
    }
  }
}
</script>
