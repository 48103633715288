<template>
  <transition name="fade">
    <div>
      <template v-if="isLoading">
        <v-progress-circular
            class="mt-2 mb-8"
            color="primary"
            indeterminate
        />
      </template>


      <template v-if="!isLoading && history && history.length === 0">
        <v-row>
          <v-col cols="12">
            <v-alert
                elevation="0"
                icon="mdi-information"
            >
              Es liegen keine Bestellungen vor.
            </v-alert>
          </v-col>
        </v-row>
      </template>

      <div v-else class="mb-12 mt-6">
        <v-expansion-panels
            v-model="currentHistory"
            :multiple="false"
            accordion
            flat
            class="history_accordion">
          <v-expansion-panel
              v-for="(order, index) in history"
              ref="historyPanels"
              class="history-expansion-panel"
              :key="index">
            <v-expansion-panel-header>
              <OrderHistoryPanelHeader :order="order"/>
            </v-expansion-panel-header>
            <v-expansion-panel-content>

              <!--              @todo include print button-->
              <!--              <PrintPdf filename="einrauemliste.pdf" :uri="`clearing-list/${currentHistory.id}`">-->
              <!--                <template v-slot:default="{ eventHandlers }">-->
              <!--                  <v-btn v-on="eventHandlers">-->
              <!--                    Download PDF-->
              <!--                  </v-btn>-->
              <!--                </template>-->
              <!--              </PrintPdf>-->

              <template v-if="!isLoadingDetail && currentHistory !== null">
                <v-container>
                <v-row>
                    <OrderHistoryArticleHeader :is-pending="order.is_pending"/>

                  <v-col
                      v-for="article in articles"
                      cols="12"
                      class="b-bottom"
                  >
                        <OrderHistoryArticle :article="article"/>
                  </v-col>
                </v-row>
                </v-container>
              </template>
              <template v-else>
                <v-progress-circular
                    class="mt-2 mb-8"
                    color="primary"
                    indeterminate
                />
              </template>

            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
  </transition>
</template>

<script>

import OrderHistoryArticle from './OrderHistoryArticle.vue'
import OrderHistoryPanelHeader from './OrderHistoryPanelHeader.vue'
import OrderHistoryArticleHeader from './OrderHistoryArticleHeader.vue'
import PrintPdf from '@/components/SmartLogistics/Print/PrintPdf.vue'

export default {
  name: 'History',
  components: {PrintPdf, OrderHistoryArticleHeader, OrderHistoryPanelHeader, OrderHistoryArticle},

  computed: {

    currentHistory: {
      get() {
        return this.$store.getters['smartLogisticHistory/currentHistory']
      },
      set(value) {
        this.$store.commit('smartLogisticHistory/setCurrentHistory', value)
      }
    },

    articles() {
      return this.$store.getters['smartLogisticHistory/articles']
    },

    isLoading() {
      return this.$store.getters['smartLogisticHistory/isLoading']
    },

    isLoadingDetail() {
      return this.$store.getters['smartLogisticHistory/isLoadingDetail']
    },

    history() {
      return this.$store.getters['smartLogisticHistory/historyOverview']
    }
  },

  props: {
    unit: {
      type: Object,
      required: true
    },
  },

  watch: {
    currentHistory() {
      if (this.currentHistory !== null && this.currentHistory !== undefined) {
        window.setTimeout(() => {
          this.$vuetify.goTo(this.$refs.historyPanels[this.currentHistory], {offset: 90});
        },50);
        this.$store.dispatch('smartLogisticHistory/fetchDetail', {
          unit: this.unit.internalID,
          id: this.history[this.currentHistory].id
        }).then(() => {
          window.setTimeout(() => {
            this.$vuetify.goTo(this.$refs.historyPanels[this.currentHistory], {offset: 90});
          },50);
        })
      }
    }
  },

  mounted() {
    this.$store.commit('smartLogisticHistory/setCurrentHistory', null)
    this.$store.dispatch('smartLogisticHistory/fetchOverview', {unit: this.unit.internalID})
  }
}
</script>
<style lang="scss">
html body {
  .v-expansion-panels:not(.default) {
    .history_accordion {

      .v-expansion-panel-header {
        border-radius: 12px !important;
        border: none !important;
        margin-bottom: 10px !important;
        background-color: #F4EDE7 !important;
      }

      .v-expansion-panel-header--active {
        background-color: #F4EDE7 !important;
      }
      .v-expansion-panel-content {
        padding-bottom: 20px;
      }
    }
  }
}

</style>
