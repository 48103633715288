<template>
  <div>

    <template v-if="basket.directOrder">
      <p>
        Die Sofortbestellung wurde erfolgreich übermittelt.
      </p>
    </template>
    <template v-else>
      <p>
        Dieser Warenkorb ist zurzeit im Bestell-Prozess.
      </p>
    </template>

    <h4>Aktuell</h4>
    <template v-if="!isAlreadyLocked">
      <p>
        Änderungen möglich bis: {{ lockAt.format('DD.MM.YYYY HH:mm') }} Uhr. <br/>
        Die Lieferung erfolgt unter Berücksichtigung der Verfügbarkeiten voraussichtlich am {{ deliveryAt.format('DD.MM.YYYY') }}.
      </p>
    </template>
    <template v-else>
      <p>
        <template v-if="!basket.directOrder">
          Die Bestellung wird vom System verarbeitet. Änderungen sind nicht mehr möglich.<br/>
          Die Lieferung erfolgt unter Berücksichtigung der Verfügbarkeiten voraussichtlich am {{ deliveryAt.format('DD.MM.YYYY') }}.
        </template>
        <template v-else>
          Sie erhalten in Kürze eine Bestätigung per Mail.
        </template>
      </p>
    </template>
    <div class="pt-4 pb-12 list">
      <v-btn
          @click="showHistory"
          depressed
      >
        Bestellung ansehen
      </v-btn>
      <v-btn
          v-if="!isAlreadyLocked"
          color="primary"
          depressed
          @click="revertOrder"
      >
        <span class="icon-schliessen-klein" style="font-size: 10px"></span>
        <span class="ml-4">
              Warenkorb zurücknehmen
            </span>
      </v-btn>
    </div>

  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import CountDown from '@/components/SmartLogistics/Design/CountDown.vue'

export default {
  name: 'BasketConfirmedCard',
  components: {CountDown},

  props: {
    unit: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters({
      basket: 'smartLogisticBasket/basket',
      orderLockOffset: 'smartLogisticSettings/orderLockOffset',
    }),

    now() {
      return this.$moment()
    },

    dueAt() {
      return this.$moment.unix(this.basket.due_at)
    },

    lockAt() {
      return this.$moment(this.dueAt).subtract(this.orderLockOffset, 'seconds')
    },

    deliveryAt() {
      return this.$moment(this.basket.approximateDeliverDate)
    },

    isAlreadyLocked() {
      return this.now.diff(this.lockAt) >= 0
    }
  },

  methods: {

    showHistory() {
      this.$store.commit('smartLogisticHistory/setOpenOrderById', this.basket.id)
      this.$store.commit('smartLogisticSettings/setCurrentSection', 'order-history')
    },


    async revertOrder() {
      await this.$store.dispatch('smartLogisticBasket/revert', {
        unit: this.unit.internalID,
        date: null
      });

    },
  },
}
</script>

<style scoped lang="scss">

</style>
