<template>
  <transition name="fade">
    <template v-if="images">
      <v-img
          v-if="images[article.internalID]"
          :src="images[article.internalID]['Z_URL']"
          :height="height"
          max-width="90"
          contain
      ></v-img>
      <v-img
          v-else-if="article.bild"
          :src="article.bild"
          :height="height"
          max-width="90"
          contain
      ></v-img>
      <v-img
          v-else-if="article.image"
          :src="article.image+'?width=80'"
          :height="height"
          max-width="90"
          contain
      ></v-img>
      <v-img
          v-else
          :src="require('@/assets/img/ico/sortimentsverwaltung-aktiv.svg')" alt=""
          width="35"
          height="60"
          max-width="90"
          contain
      ></v-img>
    </template>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ArticleImage',

  props: {
    article: {
      type: Object,
      required: true,
    },
    height: {
      type: Number,
      required: false,
      default: 60
    },
  },

  computed: {
    ...mapGetters({
      images: 'bilder/produktbilder'
    })
  }
}
</script>
