<template>
  <div>
    <v-select style="font-size: 14px;width: 300px;"
              :items="items"
              label=""
              v-model="selected"
              class="custom-select"
              outlined
              height="10"
              dense
              :disabled="!isEditable"
              item-text="name"
              item-value="key"></v-select>
  </div>

</template>

<script>


export default {
  name: 'BasketPackageSelect',

  props: {
    quantity: {
      type: Number,
      required: true
    },
    packages: {
      type: Array,
      required: true
    },
    selectedPackageId: {
      type: Number | String,
      required: true
    },
    artno: {
      type: Number | String,
      required: true
    },
    isEditable: {
      type: Boolean,
      default: true
    }
  },

  computed: {

    items() {
      return this.packages.filter((item) => {
        return item.isOrderable
      }).map((item) => {
        return {
          key: item.id,
          name: item.label
        }
      })
    },

    selected: {
      get() {
        return this.items.filter((item) => {
          return parseInt(item.key.split('-')[1]) === parseInt(this.selectedPackageId)
        })[0]
      },
      set(value) {
        if (value != this.artno + '-' + this.selectedPackageId) {
          this.$store.dispatch('smartLogisticBasket/convertArticle', {
            artikelNrLAG: this.artno + '-' + this.selectedPackageId,
            newArtikelNrLAG: value,
            quantity: this.quantity
          })
        }
      }
    }
  }
}
</script>
